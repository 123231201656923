import React from "react";
import { Form, Dropdown } from "semantic-ui-react";
import { UserPermissionsContext } from "../../../../Contexts";


class SecurityStateEdit extends React.Component {
    state = {
        items: [
            {text: t('SecurityState_NotChecked'), value: 0},
            {text: t('SecurityState_Declined'), value: 1},
            {text: t('SecurityState_Approved'), value: 2},
            {text: t('SecurityState_Blocked'), value: 3}
        ]
    }

    handleOnChange = (e, {name, value}) => {
        const {onChange} = this.props;
        onChange && onChange(e, {name, value});
    }

    render() {
        const {name, value, isDisabled, isView, isClearable, isEdit, className} =  this.props;

        let items = this.state.items;
        
        return (
            <>
                {isView && <Form.Field>
                    {name && <label className={ isDisabled ? "label-disabled" : null}>{t(name) + ' *'}</label>}
                    <Dropdown
                        key={'7680879567808790=yturtyu'}
                        name={name}
                        value={value}
                        onChange={this.handleOnChange}
                        disabled={!isEdit}
                        selection
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        clearable={isClearable}
                        className={className}
                        options={items}
                    />
                </Form.Field>}
            </>           
        );
    }
}

SecurityStateEdit.contextType = UserPermissionsContext;
export default SecurityStateEdit;
