import React from "react";
import { Button, Label, Table, Dropdown, Form, Message, Checkbox } from "semantic-ui-react";

export default class CustomFieldsEditor extends React.Component {

    state = {
        isAdding: false,
        editField: {},
        addFieldName: "",
        addFieldType: "Text",
        options: []
    }

    handleAddition = (e, { value }) => {
        this.setState({
            options: [{ text: value, value }, ...this.state.options],
        });
    }

    handleChange = (e, { value }) => this.setState({ currentValues: value })

    renderValuesForCustomField = (customField) => {
        switch (customField.type) {
            case "Text":
                return `${t('Any line')}`;
            case "Textarea":
                return `${t('Any line of increased length')}`;
            case "Number":
                return `${t('Any number')}`;
            case "Date":
                return `${t('Any date')}`;
            case "DateTime":
                return `${t('Any date and time')}`;
            case "Select":
            case "MultiSelect":
                return <div> {(customField.valuesForSelectTypeAsArray || []).map(valueForSelectType => <Label className='label-margin'>{valueForSelectType}</Label>)}</div>;
            default:
                return "";
        }
    }

    save = (customFieldId) => {
        this.setState({ editField: {} });
        this.props.onSave(Object.assign(this.state.editField, { valuesForSelectTypeAsArray: this.state.currentValues || [] }));
    }

    toggleIsActive = (e, { customfieldid }) => {
        let customField = this.props.fields.find(x => x.id == customfieldid);
        customField.isActive = !customField.isActive;
        this.props.onSave(customField);
    }

    add = () => {
        if (this.state.addFieldName) {
            this.setState({ isAdding: false });
            this.props.onSave({
                id: this.newMongoObjectId(),
                isActive: true,
                name: this.state.addFieldName,
                type: this.state.addFieldType,
                valuesForSelectTypeAsArray: this.state.currentValues || []
            });
        }
    }

    newMongoObjectId = () => {
        let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + "xxxxxxxxxxxxxxxx".replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    };

    render() {
        const types = [
            { text: "Text", value: "Text" },
            { text: "Textarea", value: "Textarea" },
            { text: "Number", value: "Number" },
            { text: "Date", value: "Date" },
            { text: "DateTime", value: "DateTime" },
            { text: "Select", value: "Select" },
            { text: "MultiSelect", value: "MultiSelect" },
        ];

        const { currentValues } = this.state;
        return (
            <div>
                {this.props.fields.length > 0
                    ? <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('Type')}</Table.HeaderCell>
                                <Table.HeaderCell style={{ width: "300px" }}>{t('Values')}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.props.fields.map(customField => <Table.Row key={customField.id}>
                                {this.state.editField.id == customField.id ?
                                    <Table.Cell>
                                        <Form.Input
                                            value={this.state.editField.name}
                                            onChange={(e) => { this.setState({ editField: Object.assign(this.state.editField, { name: e.target.value }) }); }} />
                                    </Table.Cell> :
                                    <Table.Cell>{customField.name}</Table.Cell>}
                                <Table.Cell>{customField.type}</Table.Cell>
                                <Table.Cell>
                                    {
                                        this.state.editField.id == customField.id && ['Select', 'MultiSelect'].includes(this.state.editField.type)
                                            ? <Dropdown
                                                options={this.state.options}
                                                placeholder={t('Values')}
                                                search
                                                selection
                                                fluid
                                                multiple
                                                allowAdditions
                                                value={currentValues}
                                                onAddItem={this.handleAddition}
                                                onChange={this.handleChange}
                                            />
                                            : this.renderValuesForCustomField(customField)
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    <Checkbox toggle customfieldid={customField.id} checked={customField.isActive} onChange={this.toggleIsActive} />
                                </Table.Cell>
                                {this.state.editField.id == customField.id ?
                                    <Table.Cell style={{ width: "246px" }}>
                                        <Button size="small" onClick={() => { this.save(customField.name); }}>{t('Ok')}</Button>
                                        <Button size="small" onClick={() => { this.setState({ editField: {} }); }}>{t('Cancel')}</Button>
                                    </Table.Cell> :
                                    <Table.Cell>
                                        <Button size="small" onClick={() => {
                                            this.setState({
                                                editField: Object.assign({}, customField),
                                                currentValues: customField.valuesForSelectTypeAsArray,
                                                options: (customField.valuesForSelectTypeAsArray || []).map(x => ({ value: x, text: x })),
                                            });
                                        }}>{t('Change')}</Button>
                                    </Table.Cell>}
                            </Table.Row>)}
                        </Table.Body>
                    </Table>
                    : <Message content={t('This entity does not have custom fields. You can create them')} />
                }
                {this.state.isAdding ?
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('Type')}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Form.Input
                                        value={this.state.addFieldName}
                                        onChange={(e) => { this.setState({ addFieldName: e.target.value }); }} />
                                </Table.Cell>
                                <Table.Cell><Form.Select
                                    value={this.state.addFieldType}
                                    options={types}
                                    onChange={(e, { value }) => { this.setState({ addFieldType: value }); }} /></Table.Cell>
                                <Table.Cell>
                                    <Button onClick={() => { this.add(); }}>{t('Save')}</Button>
                                    <Button onClick={() => { this.setState({ isAdding: false }); }}>{t('Cancel')}</Button>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table> : <Button onClick={() => { this.setState({ currentEditabledFieldId: null, isAdding: true, addFieldName: "", currentValues: null }); }}>{t('Create custom field')}</Button>}
            </div>
        );
    }
};
