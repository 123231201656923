import React from 'react';
import {Button, Confirm, Dropdown, Grid, Icon} from "semantic-ui-react";
import InputTime from "../../../shared/columns/time/inputTime";

const dayOfWeeksStub = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

export default class StandardSchedule extends React.Component{
    state = {
        dayOfWeeks: [],
        isValid: true,
        timeStart: '',
        timeEnd: ''
    }

    componentDidMount () {
        const {item: { dayOfWeeks, timeStart, timeEnd}}= this.props;
        this.setState({
            dayOfWeeks,
            timeStart,
            timeEnd
        })
    }

   /* componentDidUpdate (prevProps, prevState) {
        const {isValid} = this.state;
        if (prevState.isValid !== isValid) {
            const {onChangeIsValid} = this.props;
            onChangeIsValid && onChangeIsValid(isValid)
        }
    }*/

    validEditForm = (value) => {
        const {onChangeIsValid} = this.props;
        onChangeIsValid && onChangeIsValid(value)
    };

    deleteStandardSchedule = () => {
        const {onDelete} = this.props
        onDelete && onDelete()
    }

    handleChange = (e, {name, value}) => {
        const {onChange} = this.props;
        onChange && onChange(e, {name, value})
    }

    render () {
        const {disabled, item : {dayOfWeeks, timeStart, timeEnd}} = this.props
        return (
            <Grid.Row columns="equal" className="padding-bottom-0" verticalAlign={disabled ? "middle" : null}>
                { disabled ? (
                    <>
                        <Grid.Column width={7}>
                            {dayOfWeeks.length < dayOfWeeksStub.length ?
                                dayOfWeeks
                                    .sort((a, b) => {
                                        return <div> dayOfWeeksStub.indexOf(a) - dayOfWeeksStub.indexOf(b) </div>;
                                    })
                                    .map(x => t(x)).join(', ') :
                                <div>{t('All days')}</div>
                            }
                        </Grid.Column>
                    </>
                    ) :
                    <Grid.Column width={6}>
                        <Dropdown placeholder={t('Choose a day')}
                              fluid
                              error={!dayOfWeeks.length}
                              multiple
                              //upward={this.isUpward(index)}
                              selection
                              name={'dayOfWeeks'}
                              className='circular'
                              value={dayOfWeeks}
                              onChange={this.handleChange}
                              options={dayOfWeeksStub.map(item => ({
                                  key: item, text: t(item), value: item
                              }))}
                    />
                </Grid.Column> }
                <Grid.Column width={4} className={'standard-schedule__input'}>
                    <InputTime
                        value={timeStart}
                        placeholder={"HH:mm"}
                        validation={(value) => this.validEditForm(value)}
                        max={timeEnd}
                        name={'timeStart'}
                        disabled={disabled}
                        onChange={this.handleChange}
                    />
                </Grid.Column>
                <Grid.Column width={4} className={'standard-schedule__input'}>
                    <InputTime
                        value={timeEnd}
                        placeholder={"HH:mm"}
                        validation={(value) => this.validEditForm(value)}
                        min={timeStart}
                        name={'timeEnd'}
                        disabled={disabled}
                        onChange={this.handleChange}
                    />
                </Grid.Column>
                {disabled ? null :
                    <Grid.Column width={1} className={"padding-0"}>
                        <Button style={{fontSize: "10px"}} className="margin-top-for-delete" size="small" onClick={
                            this.deleteStandardSchedule}> <Icon name='delete'/></Button>
                    </Grid.Column>
                }
            </Grid.Row>
        )
    }
}
