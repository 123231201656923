import React from "react";
import {Modal, Button, Confirm, Tab, Message, Label, Form, Header, Icon, Segment, Grid} from "semantic-ui-react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {UserPermissionsContext} from "../../Contexts";
import ChangePasswordModal from "./ChangePasswordModal";

export default class ProfileModal extends React.Component {
    static contextType = UserPermissionsContext;
    state = this.initialState;

    get initialState() {
        return {
            modalOpen: false,
            profileData: {
                email: '',
                name: '',
                platformNames: null,
                activityNames: null,
                companyNames: null,
            },
        };
    }

    onOpen = () => {

        callApi("profile/get")
            .then(profileData => {
                this.setState({
                    profileData: profileData,
                    modalOpen: true,
                });
            });
    }

    onClose = () => {
        this.setState(this.initialState);
    }

    render() {
        return (<Modal dimmer="blurring" id="fieldModal"
                       className="profile-modal"
                       trigger={this.props.children}
                       open={this.state.modalOpen}
                       onOpen={this.onOpen}
                       onClose={this.onClose}
                       closeOnEscape
                       closeOnDimmerClick={false}
                       closeIcon>
            <Modal.Header>{t("profile")}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Field>
                            <Form.Input label={t("email")} >
                                <input type="text" value={this.state.profileData.email}/>
                            </Form.Input>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input label={t("user name")} >
                                <input type="text" value={this.state.profileData.name}/>
                            </Form.Input>
                        </Form.Field>
                        <Form.Field>
                            <label>{t('platforms')}</label>
                            <Segment>
                                {this.state.profileData.platformNames !== null
                                    ? <Form.Dropdown  fluid multiple search selection value={this.state.profileData.platformNames || ''} disabled={true} options={this.state.profileData.platformNames || []} />
                                    : <Form.Checkbox label={t('All platforms')} checked={this.state.profileData.platformNames === null} />
                                }
                            </Segment>
                        </Form.Field>                        
                        <Form.Field>
                            <label>{t('activities')}</label>
                            <Segment>
                                {this.state.profileData.activityNames !== null
                                    ? <Form.Dropdown  fluid multiple search selection value={this.state.profileData.activityNames || ''} disabled={true} options={this.state.profileData.activityNames || []} />
                                    : <Form.Checkbox label={t('All activities')} checked={this.state.profileData.activityNames === null} />
                                }
                            </Segment>
                        </Form.Field>                        
                        <Form.Field>
                            <label>{t('companies')}</label>
                            <Segment>
                                {this.state.profileData.companyNames !== null
                                    ? <Form.Dropdown  fluid multiple search selection value={this.state.profileData.companyNames || ''} disabled={true} options={this.state.profileData.companyNames.map(x=> {return {key: x, value: x, text: x};}) || []} />
                                    : <Form.Checkbox label={t('All companies')} checked={this.state.profileData.companyNames === null} />
                                }
                            </Segment>
                        </Form.Field>
                        <ChangePasswordModal>
                            <Button icon='lock' color={"green"} content={t('Change Password')} />
                        </ChangePasswordModal>
                    </Form>                    
                </Modal.Description>
            </Modal.Content>
        </Modal>);
    }
};