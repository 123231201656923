import React, { Component } from "react";
import { Segment, Header, Icon, Loader, Dimmer } from "semantic-ui-react";

export default class FileUploader extends Component {
    state = {
        active: false,
        loading: false
    }

    onDragEnter = () => {
        this.setState({ active: true });
    }

    onDragLeave = () => {
        this.setState({ active: false });
    }

    onDragOver = (e) => {
        e.preventDefault();
    }

    onDrop = (e) => {
        e.preventDefault();
        this.setState({ active: false });
        this.uploadFile(e, e.dataTransfer.files[0]);
    }

    setLoading = (bool) => {
        this.setState({ loading: bool });
    }

    uploadFile = (e, file) => {
        this.setLoading(true);
        this.props.onChange();
        let f = new FormData();
        file = file || e.target.files[0];
        if (file) {
            f.append("file", file);
            callApi("files/uploadfile", f, true)
                .then((fileDto) => {
                    this.props.onChange(fileDto);
                    this.setLoading(false);
                });
        }
    }

    render() {
        const state = this.state,
            props = this.props,
            loading = state.loading,
            document = props.document,
            loaded = (document && document.fileId),
            name = document.name || "",
            extension = loaded ? name.substr(name.lastIndexOf(".") + 1) : "",
            isImage = ["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase()),
            src = loaded ? ("/api/files/viewfile?fileId=" + document.fileId) : "",
            labelClass = `uploader ${loaded && "loaded"} ${state.active && "dragndrop"}`;

        let preview = loading ? (
            <Dimmer active inverted>
                <Loader size='large'>{t('Loading')}</Loader>
            </Dimmer>
        ) : (
                <Segment placeholder>
                    <Header icon>
                        <Icon name={loaded ? "file outline" : "upload"} />
                    </Header>
                    <p>{name}</p>
                </Segment>
            );

        if (loaded && isImage)
            preview = (
                <img src={src} className={state.loaded && "loaded"} />
            );

        return (
            <label
                className={labelClass}
                onDragEnter={this.onDragEnter}
                onDragLeave={this.onDragLeave}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}>

                {preview}

                <input type="file" onChange={this.uploadFile} />
            </label>
        );
    }
}
