import React, {useEffect, useState, forwardRef} from "react";
import {toast} from "react-toastify";
import {debounce} from "throttle-debounce";
import {Button, Container, Grid, Icon, Table, Input} from "semantic-ui-react";
import Search from "../../../shared/Search";
import PlatformDropdown from "../../../shared/PlatformDropdown";
import DatePicker from "react-datepicker";
import {formatDate} from "../../../const/functions";
import {dateFormat} from "../../../utils/dateFormat";
import {getRequestStates} from "../../../utils/stateHelper";
import {EditableField} from "../../../shared/EditableField";
import Facet from "../../../shared/columns/state/Facet";
import ReadOnlyPlatforms from "../ReadOnlyPlatforms";

const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
        <Input icon="calendar alternate" value={value} onClick={onClick} ref={ref} />
    ),
);

export default function Requests() {

    const states = getRequestStates();

    const [list, setList] = useState([]);
    const [filterData, setFilterData] = useState({ date: formatDate(new Date()), states: [] });
    const [filterText, setFilterText] = useState("");
    const [allPlatforms, setAllPlatforms] = useState([]);

    const loadRequests = () => {
        callApi("Requests/GetList", {
            date: filterData.date,
            states: filterData.states.reduce((result, x) => {
                const state = states.find((y) => y.value === x);
                if (state) {
                    result.push(state.key);
                }

                return result;
            }, []),
        }).then((list) => {
            if (list.error) {
                toast.error(t("Lost connection with server"));
                throw t("Lost connection with server");
            }

            setList(list);
        });
    };

    const loadPlatforms = () => {
        callApi("Platforms/GetList")
            .then(list => {
                if (list.error) {
                    toast.error(t("Lost connection with server"));
                    throw (t("Lost connection with server"));
                }

                const platforms = list.map((x) => ({ key: x.id, value: x.id, text: x.name }));
                setAllPlatforms(platforms);
            });
    };

    const sendRequestUpdate = (request, onSuccess) => {
        callApi("Requests/Update", { id: request.id, badgeNumber: request.badgeNumber, state: request.state })
            .then((response) => {
                if (response.error) {
                    toast.error(t("An error has occurred. Please try again"));
                    return;
                }

                // Выставляем состояние только в случае успешности обновления сущности на бэке.
                const requests = [...list];
                const newRequests = requests.map(x => x.id === request.id ? request : x);
                setList(newRequests);

                if (onSuccess) {
                    onSuccess();
                }
            });
    };

    useEffect(loadRequests, [filterData]);

    useEffect(() => {
        loadPlatforms();
    }, []);

    const changeRequestState = (requestId, state) => {
        // Не меняем стейт здесь, меняем только в случае успешного обновления на бэке
        const requests = [...list];
        const requestCopy = {...requests.find(x => x.id === requestId), state};

        sendRequestUpdate(requestCopy, () => {
            const stateInfo = states.find(x => x.key === state);
            const stateText = stateInfo ? t(stateInfo.value) : "";
            toast.info(`${t("requestStatusChangedPart1")} ${requestCopy.visitorName} ${t("requestStatusChangedPart2")} ${stateText}`);
        });
    }

    const changeRequestBadge = (requestId, badgeNumber, onSuccess) => {
        // Не меняем стейт здесь, меняем только в случае успешного обновления на бэке
        const requestCopy = {...list.find(x => x.id === requestId)};
        requestCopy.badgeNumber = badgeNumber;

        sendRequestUpdate(requestCopy, onSuccess);
    };

    const handleFilterDataChange = (e, name, value) => {
        const filterDataCopy = { ...filterData };
        filterDataCopy[name] = value;
        setFilterData(filterDataCopy);
    };

    const handleFilterTextChange = (e, {value}) => {
        setFilterText(value.trim());
    };

    const debounceFilterChange = debounce(300, handleFilterTextChange);
    const debounceFilterDataChange = debounce(300, handleFilterDataChange);

    const requests = list
        .map((x) => {
            const stateInfo = states.find((elem) => elem.key === x.state);
            return { ...x, stateColor: stateInfo ? stateInfo.color : "", stateText: stateInfo ? t(stateInfo.value) : "",};
        })
        .filter((x) => {
            const excludeFromSearch = {
                id: null,
                platforms: null,
                stateColor: null
            };

            x = {
                ...x,
                badgeNumber: x.badgeNumber || t('noValue'),
                platformsNames: x.platforms && x.platforms.map(pl => (allPlatforms.find(p => p.key === pl) || {}).text),
                ...excludeFromSearch
            };
            return Object.values(x).some((y) => y && y.toString().toLowerCase().includes(filterText.toLowerCase()))
                || (x.truckNumber || '').split(/\s+/).join('').toLowerCase().includes(filterText.split(/\s+/).join('').toLowerCase());
        });

    return (
        <Container className='wider container-margin-top-bottom'>
            <h2>{t("invitations")}</h2>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <div className="date_calendar">
                            <DatePicker
                                className="input-as-semantic small full-width"
                                locale={localStorage.getItem("currentCulture")}
                                selected={parseDate(filterData.date || "")}
                                dateFormat="dd.MM.yyyy"
                                onChange={(date, e) => { handleFilterDataChange(e, "date", date ? formatDate(date) : null); }}
                                onChangeRaw={(e) => debounceFilterDataChange(e, "date", e.target.value) }
                            />
                            <Icon name="calendar alternate" className="date_calendar_icon"/>
                        </div>
                    </Grid.Column>

                    <Grid.Column width={6}>
                        <Search isAuto placeholder={t("requestsSearchPlaceholder")} value={filterText} onChange={debounceFilterChange}/>
                    </Grid.Column>

                    <Grid.Column width={7} textAlign='right'>
                        <PlatformDropdown onChange={() => loadRequests()}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Facet
                                stateColors={states}
                                values={filterData.states}
                                onChange={(e, {values}) => handleFilterDataChange(e, "states", values)}
                                isSortingEnabled={false}
                                allItemsSelectedText={t('requestState')}
                                states={states.map(s => s.value)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell>{t('requestDate')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestFullName')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestTruckNumber')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestVisitorCompanyName')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestBadgeNumber')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestRequester')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestPlatforms')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Visit purpose')}</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                        {requests.map(request => {
                            const startDate = dateFormat(request.startDate, "DD.MM.YYYY", true);
                            const finishDate = dateFormat(request.finishDate, "DD.MM.YYYY", true);
                            const dateInfo = startDate === finishDate ? startDate : startDate + " - " + finishDate;                            
                            
                            return (
                                <Table.Row key={request.id}>
                                    <Table.Cell width={1}><Icon color={request.stateColor} name="circle"/>{request.stateText}</Table.Cell>
                                    <Table.Cell width={2}>{dateInfo}</Table.Cell>
                                    <Table.Cell width={2}>{request.visitorName}</Table.Cell>
                                    <Table.Cell width={2}>{request.truckNumber}</Table.Cell>
                                    <Table.Cell width={2}>{request.visitorCompanyName}</Table.Cell>

                                    <Table.Cell width={3}>
                                        <EditableField
                                            requestId={request.id}
                                            currentValue={request.badgeNumber}
                                            onSave={changeRequestBadge}
                                        />
                                    </Table.Cell>

                                    <Table.Cell width={2}>{request.requesterName}<br/>{request.requesterPhoneNumber}</Table.Cell>

                                    <Table.Cell width={2}>
                                        <ReadOnlyPlatforms requestPlatforms={request.platforms} allPlatforms={allPlatforms} />
                                    </Table.Cell>

                                    <Table.Cell width={2}>{request.purpose}</Table.Cell>

                                    <Table.Cell width={1} textAlign="center">
                                        {request.state === 0 &&
                                            <Button
                                                content={t("Enter")}
                                                onClick={() => changeRequestState(request.id, 1)}
                                            />
                                        }

                                        {request.state === 1 &&
                                            <Button
                                                content={t("Exit")}
                                                onClick={() => changeRequestState(request.id, 2)}
                                            />
                                    }

                                    </Table.Cell>
                                </Table.Row>
                            );
                    })}
                </Table.Body>
            </Table>
        </Container>);
}
