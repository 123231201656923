import React, {useState} from "react";
import * as StateHelper from "../../utils/stateHelper";
import SuperGrid from "../../shared/grid/SuperGrid";

export default function OrdersModule(props) {
    const [autoLoadOff, setAutoLoadOff] = useState(false);
    return <>
        <SuperGrid
            gridName='ordersModule'
            storageSortItem="OrderSort"
            storageFilterItem="OrderFilters"
            apiGetColumns="ordersModule/getColumns"
            apiGetList="ordersModule/getList"
            apiAction="ordersModule/action"
            userColumnsName="ordersModule"
            createButtonEnabled
            createButtonPermission={["EditOrderModule", "37"]}
            createButtonName={t("create_order_module_btn")}
            createButtonModalTitle={t("create_order_modal_label")}
            editModalTitle={t("edit_order_modal_label")}
            stateColors={StateHelper.ordersModuleStatesColors()}
            checkedToTop
            autoLoadOff={autoLoadOff}
            setAutoLoadOff={setAutoLoadOff}
            withAllCheckbox
        />
    </>;
}