import React, {useState, useEffect} from 'react';
import {Input} from "semantic-ui-react";
import MaskedInput from "react-text-mask";

export default class InputTime extends React.Component {

    state = {
        errorInput: false,
        errorMessage: ''
    }

    componentWillMount () {
        const {value, validation} = this.props;
        if (value.length < 5) {
            this.setState({
                errorInput: true
            });

            validation && validation(false)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {value: valuePrev, max: maxPrev, min: minPrev} = prevProps;
        const {value, max, min, validation } = this.props;

        if (valuePrev !== value || max && maxPrev !== max  || min && minPrev !== min) {
            if ((value.length === 5)
                &&
                (
                    max && (this.getConvertInSeconds(value) >= this.getConvertInSeconds(max))
                    ||
                    min && (this.getConvertInSeconds(value) <= this.getConvertInSeconds(min))
                    ||
                    Number(value.slice(0, 2)) > 24
                    ||
                    Number(value.slice(3, 5)) > 59
                ) || value.length < 5) {
                this.setState({
                    errorInput: true
                });

                validation && validation(false)
            } else {  this.setState({
                errorInput: false
            });
                validation && validation(true)
            }
        }
    };

    componentWillUnmount () {
        const {validation} = this.props;
        validation && validation(true)
    }


    getConvertInSeconds = (time) => {
        const minutes = Number(time.slice(0,2));
        const seconds = Number(time.slice(3,5));
        return minutes * 60 + seconds
    };

    onChangeTime = (e, { value, name}) => {
        const {onChange} = this.props;
        onChange(e, {value, name})
    };
    render() {
        const {errorInput} = this.state;
        const {value,placeholder, disabled, name} =this.props;
        return (
            <Input
                disabled={disabled}
            >
                <MaskedInput
                    mask={[
                        /[0-9]/,
                        /\d/,
                        ":",
                        /\d/,
                        /\d/
                    ]}
                    className={errorInput ? 'input-error' : ''}
                    type={"text"}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => this.onChangeTime(e, {value: e.target.value, name: e.target.name})}
                    guide={false}
                />
            </Input>
        )
    }
};
