import React from "react";
import {Modal, Button, Grid, Label, Select, Tab, Input, Icon, Form, Popup, Confirm} from "semantic-ui-react";
import GridColumnDispatcher from "../../../../columns/GridColumnDispatcher";
import * as StateHelper from "../../../../../utils/stateHelper";
import { UserPermissionsContext } from "../../../../../Contexts";
import {toast} from "react-toastify";
import OrdersModuleModal from "../../../../../pages/ordersModule/OrdersModuleModal";

export default class Orders extends React.Component {
    static contextType = UserPermissionsContext;

    constructor(props) {
        super(props);
        this.state = {
            orderToModuleId: null
        };
    }

    handleOrderFieldChange = orderId => (e, { name, value }) => {
        let { task,
            onFieldChange, orderColumns } = this.props;
        let orders = task.orders;
        let orderIndex = orders.findIndex(x => x.id === orderId);
        if (!orderColumns.find(x => x.name === name).isCustom)
            orders[orderIndex][name.charAt(0).toLowerCase() + name.slice(1)] = value;
        else {
            let index = orders[orderIndex].customFields.findIndex(x => x.name === name);
            if (orders[orderIndex].customFields[index])
                orders[orderIndex].customFields[index].value = value;
            else
                orders[orderIndex].customFields.push({ name, value });
        }
        onFieldChange(task.id)(e, {
            name: "orders",
            value: orders
        });
    }

    handleAddOrder = (e) => {
        let { task,
            onFieldChange } = this.props;
        let orders = (task.orders || []);
        orders.push({
            id: StateHelper.newMongoObjectId(),
            name: "",
            customFields: []
        });
        onFieldChange(task.id)(e, {
            name: "orders",
            value: orders
        });
    }

    handleDeleteOrder = (e, { orderid }) => {
        let { task,
            onFieldChange } = this.props;
        let orders = (task.orders || []);
        let index = orders.findIndex(x => x.id === orderid);
        orders.splice(index, 1);
        onFieldChange(task.id)(e, {
            name: "orders",
            value: orders
        });
    }

    columnSorter = () => {
        let { orderColumns = [] } = this.props;
        return StateHelper.sortColumns(orderColumns, this.context.permissionsData.currentPlatformId, 'order', this.context.permissionsData.fieldsConfig);
    }

    returnToOrdersModule = (orderId) => {
        const { loadData, onSave } = this.props;

        onSave(true).then(r => {
            if (!r.error) {
                callApi('ordersModule/action', {name: 'RejectFromTask', id: orderId}).then(resp => {
                    if (resp.successMessage && resp.successMessage.length)
                        toast.info(resp.successMessage || "Done");
                    if (resp.errorMessage && resp.errorMessage.length)
                        toast.error(resp.errorMessage || "Error");
                    if (!resp.error) {
                        this.setState({orderToModuleId: null});
                        loadData && loadData();
                    }
                })
            }
        });
    }

    render() {
        let { task, loadData, onSave, setNewOrders } = this.props;
        let { orderToModuleId } = this.state;
        let orders = task.orders || [];
        let orderColumns = this.columnSorter().filter(field => { return (field.fieldMatrixAccessTypes.find(x => x.state === task.state) || {}).accessType !== "Hidden"; });

        return <Form.Field className="wide">
            <div className="order-container form small">
                <table className="ui celled table single line">
                    <thead>
                        <tr className="order-sticky-header">
                            {orderColumns.map(column =>
                                <th
                                    key={column.name} className={`column-${column.type.toLowerCase().replace(" ", "-")}-order-value`}>
                                    {t(column.name)}
                                </th>
                            )}
                            {(this.context.permissionsData.permissions.includes("GroupOrderModule") || this.context.permissionsData.permissions.includes("CreateOrders") || this.context.permissionsData.permissions.includes("EditOrderModule") || this.context.permissionsData.permissions.includes("37")) ? <th className="small-column" /> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {orders.length > 0
                            ? orders.map(order => <tr key={order.id} id={order.id}>
                                {orderColumns.map(column => {
                                    let isDisabled = (column.fieldMatrixAccessTypes.find(y => y.state === task.state) || {}).accessType === "Show";
                                    return (
                                        <td key={"td" + column.name + order.id} className={'order-column-item ' + (column.name == "Number"  ? "expandOrderNumber" : "")}>
                                            <GridColumnDispatcher
                                                key={column.name + order.id}
                                                column={column}
                                                value={column.isCustom
                                                    ? ((order.customFields.filter(y => y.name === column.name)[0] || {}).value || "")
                                                    : order[column.name.charAt(0).toLowerCase() + column.name.slice(1)]}
                                                task={task}
                                                isEdit
                                                onChange={this.handleOrderFieldChange(order.id)}
                                                valuesList={column.valuesForSelectType}
                                                isDisabled={isDisabled}
                                                noLabel
                                            />
                                        </td>);
                                })}
                                {
                                    (this.context.permissionsData.permissions.includes("GroupOrderModule") || this.context.permissionsData.permissions.includes("CreateOrders") || this.context.permissionsData.permissions.includes("EditOrderModule") || this.context.permissionsData.permissions.includes("37")) &&
                                        <td className="small-column">
                                            {this.context.permissionsData.permissions.includes("CreateOrders") &&
                                            <Button size="tiny" orderid={order.id} icon onClick={this.handleDeleteOrder}><Icon
                                                name="trash"/></Button>}
                                            {(this.context.permissionsData.permissions.includes("GroupOrderModule") || this.context.permissionsData.permissions.includes("EditOrderModule") || this.context.permissionsData.permissions.includes("37")) && order.orderId && order.orderId !== '' &&
                                            <Popup
                                                trigger={<Button size="tiny" icon={"external"} onClick={() => this.setState({orderToModuleId: order.orderId})}/>}
                                                content={t('return_to_orders_module')}
                                                position='top right'
                                            />}
                                        </td>
                                }
                            </tr>)
                            : <tr><td colSpan={orderColumns.length + 1}>{t("No orders")}</td></tr>}
                    </tbody>
                </table>
            </div>
            {this.context.permissionsData.permissions.includes("CreateOrders")
                ? <Button style={{ marginTop: 1 }} size="tiny" onClick={this.handleAddOrder}>{t("Create order")}</Button>
                : null}
            {(this.context.permissionsData.permissions.includes("GroupOrderModule") || this.context.permissionsData.permissions.includes("EditOrderModule") || this.context.permissionsData.permissions.includes("37")) &&
            <OrdersModuleModal task={task} loadData={loadData} onSave={onSave} setNewOrders={setNewOrders}>
                <Button style={{ marginTop: 1 }} size="tiny">{t("pick_from_orders_module")}</Button>
            </OrdersModuleModal>}
            <Confirm
                dimmer="blurring"
                open={!!orderToModuleId}
                onCancel={() => this.setState({orderToModuleId: null})}
                onConfirm={() => this.returnToOrdersModule(this.state.orderToModuleId)}
                content={t("return_to_orders_module_question")}
                cancelButton={t("CancelButton")}
                confirmButton={t("ReturnButton")}
            />
        </Form.Field>;
    }
};
