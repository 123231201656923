import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserPermissionsContext } from "../Contexts";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { permissionsData } = useContext(UserPermissionsContext);
    const isLoggedIn = permissionsData.isLogged;

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
                    )
            }
        />
    );
};

export default PrivateRoute;