import React from "react";
import {Button, Table, Checkbox, Icon, Container, Grid, Popup, Label} from "semantic-ui-react";
import CompanyEditModal from "./components/CompanyEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function Companies() {

    const newModal = (load) =>
        <CompanyEditModal
            title={t('Adding a company')}
            actionButton={t('Add company')}
            id={null}
            onClose={load}>
            <Icon name='plus' />{` ${t('Add company')}`}
        </CompanyEditModal>;

    const headerRow =
        <Table.Row>
            <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('activities')}</Table.HeaderCell>
            <Table.HeaderCell />
        </Table.Row>;

    return (
        <LazyLoadDictionary
            apiGetList="Companies/GetList"
            name={t("companies")}
            className='container-margin-top-bottom'
            newModal={newModal}
            headerRow={headerRow}>
            {(companies, load) =>
                companies.map(u =>
                    <Table.Row key={u.id}>
                        <Table.Cell>{u.name}</Table.Cell>
                        <Table.Cell>
                            {u.activities && u.activities.map((item, index )=> <Popup key={index} trigger={<Label>{item.name}</Label>} content={`${item.isGreenCorridor ? t('there is a green corridor') : t('no green corridor')}, ${t('Maximum number of tasks per time slot').toLowerCase()}: ${item.maxCountTaskOnTimeSlot}`} />)}
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            <CompanyEditModal
                                size="small"
                                title={t('Company Editing')}
                                actionButton={t('SaveButton')}
                                id={u.id}
                                onClose={load}>
                                <Icon name='edit' /> {` ${t('Edit')}`}
                            </CompanyEditModal>
                        </Table.Cell>
                    </Table.Row>
                )
            }
        </LazyLoadDictionary>
    );
}
