import React from "react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

export default function ReadOnlyPlatforms(props) {
    const { requestPlatforms, allPlatforms } = props;

    return (
        <>
            {requestPlatforms !== null &&
                requestPlatforms
                    .filter((x) => allPlatforms.some((y) => y.value === x))
                    .map((x, i) => (
                        <Label key={i} className="label-margin">
                            {allPlatforms.find((y) => y.value === x).text}
                        </Label>
                    ))}

            {requestPlatforms === null && t("All platforms")}
        </>
    );
}
