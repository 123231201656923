import React from "react";
import PropTypes from "prop-types";
import { Table, Visibility } from "semantic-ui-react";

const InfiniteScrollTable = ({
    children,
    header,
    headerRow,
    onBottomVisible,
    unstackable,
    celled,
    selectable,
    className,
    context,
    isVerticalScroll = false
}) => {
    return (
        <div style={{ position: "relative" }} className={isVerticalScroll ? 'scroll-table' : ''}>
            <Table
                style={{border: isVerticalScroll && 0}}
                celled={celled === undefined ? true : celled}
                selectable={selectable === undefined ? true : celled}
                unstackable={unstackable || false}
                className={className || ""} >

                <Table.Header className={"sticky-header-tb"}>
                    {headerRow}
                </Table.Header>

                <Table.Body>
                    {children}
                    {isVerticalScroll && <Visibility
                        continuous={false}
                        once={false}
                        context={context || undefined}
                        onTopVisible={onBottomVisible}
                        style={{bottom: 0, left: 0, right: 0}}/>}
                </Table.Body>
            </Table>
            {!isVerticalScroll && <Visibility
                continuous={false}
                once={false}
                context={context || undefined}
                onTopVisible={onBottomVisible}
                style={{position: "absolute", bottom: 0, left: 0, right: 0, height: "70vh", zIndex: -1}}/>}

        </div>
    );
};

InfiniteScrollTable.propTypes = {
    headerRow: PropTypes.element.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]).isRequired,
};

InfiniteScrollTable.defaultProps = {};

export default InfiniteScrollTable;
