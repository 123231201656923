/*import format from "date-fns/format";
import ru from "date-fns/locale/ru";

export const dateFormat = (date, formatDate = "D.MM.YYYY HH:mm") => {
    if (!date) return null;

    return format(new Date(date), formatDate);
};*/

import moment from "moment";

export const dateFormat = (date, format = "DD.MM.YYYY HH:mm", isNotUtc) => {
    if (!date) {
        return null;
    }

    if (isNotUtc) {
        return moment.utc(date).format(format);
    }

    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format(format);

    return local;
};

export const parseDateTime = (dateString) => {
    if (!dateString)
        return null;
    let reg = /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2})(:(\d{2}))?/;
    let dateArray = reg.exec(dateString);
    if (!dateArray)
        return null;
    let d = new Date(
        (+dateArray[3]),
        (+dateArray[2]) - 1,
        (+dateArray[1]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[7]) || '00'
    );
    return isNaN(d.getTime()) ? null : d;
};

