import React from "react";
import { Input, Form } from "semantic-ui-react";

const InputRegExp = ({ value, name, onChange, isDisabled, noLabel, className, reg, onBlur, label, type }) => {
    const thisRegExp = new RegExp(reg)
    const onChangeInput = (e, {name, value}) => {
        if (thisRegExp.test(value) || value === '') {
            onChange(e, {name, value})
        }
    };

    return (
        <Form.Field>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(label)}</label> : null}
            <Input value={value} type={type} onBlur={onBlur} onChange={onChangeInput} disabled={isDisabled || false} className={className}
                   name={name} autoComplete="off"/>
        </Form.Field>
    );
};
export default InputRegExp;
