import React, { useContext } from "react";
import { Checkbox, Icon, Label, Table } from "semantic-ui-react";
import TransportFormModal from "./components/TransportEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";
import { UserPermissionsContext } from "../../Contexts";
import { getSecurityStateKeyLocalization } from "../../utils/stateHelper";

export default function List({ parameter, }) {
    const { permissionsData } = useContext(UserPermissionsContext);
    const headerRow =
        <Table.Row>
            <Table.HeaderCell>{t('Model')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Number')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Description')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('companies')}</Table.HeaderCell>
            {parameter !== 'trailer' ? null : <Table.HeaderCell>{t('Type')}</Table.HeaderCell>}
            <Table.HeaderCell>{t('Documents')}</Table.HeaderCell>
            <Table.HeaderCell>{t('SB comment')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>{t('Activity')}</Table.HeaderCell>
            <Table.HeaderCell width={3} />
        </Table.Row>;

    const newModalTrailer = (load) =>
        permissionsData.permissions.includes("CreateTransports") ? <TransportFormModal
            title={t("adding trailer")}
            actionButton={t("add trailer")}
            id={null}
            isNotHistory={!permissionsData.permissions.includes("ExtendedEditTransports")}
            parameter={'trailer'}
            onClose={load}>
            <Icon name='plus' /> {t("add trailer")}
        </TransportFormModal> : null;

    const newModalTractor = (load) =>
        permissionsData.permissions.includes("CreateTransports") ? <TransportFormModal
            title={t("adding truck")}
            actionButton={t("add truck")}
            id={null}
            isNotHistory={!permissionsData.permissions.includes("ExtendedEditTransports")}
            parameter={'truck'}
            onClose={load}>
            <Icon name='plus' /> {t("add truck")}
        </TransportFormModal> : null;

    return (
        <LazyLoadDictionary
            apiGetList={`${parameter}/GetList`}
            apiChangeActivity={`${parameter}/ChangeActivity`}
            className='container-margin-top-bottom'
            newModal={parameter === 'trailer' ? newModalTrailer : newModalTractor}
            headerRow={headerRow}>
            {(list = [], load, toggleIsActive) =>
                list.map(transport =>
                    <Table.Row key={transport.id}>
                        <Table.Cell>{transport.model}</Table.Cell>
                        <Table.Cell>{transport.number}</Table.Cell>
                        <Table.Cell>{transport.description}</Table.Cell>
                        <Table.Cell>{transport.companies && transport.companies.map((permission, i) => <Label
                            key={i}>{permission.name}</Label>)}</Table.Cell>
                        {parameter !== 'trailer' ? null : <Table.Cell>{transport.types && transport.types.map((x, index) => <Label key={index}>{x.name}</Label>)}</Table.Cell>}
                        <Table.Cell>
                            {
                                (transport.documents && transport.documents.length > 0) ?
                                    <Icon name="paperclip" />
                                    : null
                            }
                        </Table.Cell>
                        <Table.Cell>{transport.securityComment}</Table.Cell>
                        <Table.Cell textAlign='center'>
                            <Checkbox toggle itemID={transport.id} checked={transport.isActive} onChange={toggleIsActive} disabled={!permissionsData.permissions.includes("DeactivateTransports")} />
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            {parameter === 'trailer' ?
                                <TransportFormModal
                                    isDisabled={!(permissionsData.permissions.includes("EditTransports") || permissionsData.permissions.includes("ExtendedEditTransports"))}
                                    size="small"
                                    title={t('editing trailer')}
                                    actionButton={t('Save changes')}
                                    id={transport.id}
                                    isNotHistory={!permissionsData.permissions.includes("ExtendedEditTransports")}
                                    parameter={parameter}
                                    onClose={load}>
                                    <Icon name='edit' />{` ${t('Edit')}`}
                                </TransportFormModal> :
                                <TransportFormModal
                                    isDisabled={!(permissionsData.permissions.includes("EditTransports") || permissionsData.permissions.includes("ExtendedEditTransports"))}
                                    size="small"
                                    title={t('edit truck')}
                                    actionButton={t('Save changes')}
                                    id={transport.id}
                                    isNotHistory={!permissionsData.permissions.includes("ExtendedEditTransports")}
                                    parameter={parameter}
                                    onClose={load}>
                                    <Icon name='edit' />{` ${t('Edit')}`}
                                </TransportFormModal>
                            }
                        </Table.Cell>
                    </Table.Row>
                )}
        </LazyLoadDictionary>
    )
}
