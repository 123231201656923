import React from "react";
import {Button, Table, Checkbox, Icon, Label, Container, Grid} from "semantic-ui-react";
import RoleEditModal from "./components/RoleEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function Roles() {

    const newModal = (load) =>
        <RoleEditModal
            title={'Role creation'}
            actionButton={'Create role'}
            id={null}
            onClose={load}>
            <Icon name='plus'/>{` ${t('Create role')}`}
        </RoleEditModal>;

    const headerRow = <Table.Row>
        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
        <Table.HeaderCell>{t('users')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Permissions')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Highlighting visits')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Task highlight')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Orders module highlight')}</Table.HeaderCell>
        <Table.HeaderCell textAlign='center'></Table.HeaderCell>
        <Table.HeaderCell/>
    </Table.Row>;
    return (
        <LazyLoadDictionary
            apiGetList="roles/GetList"
            apiChangeActivity="roles/ChangeActivity"
            name={t("roles")}
            className='container-margin-top-bottom'
            newModal={newModal}
            headerRow={headerRow}>
            {(roles, load, toggleIsActive) =>
                roles.map(role => {
                    return (
                        <Table.Row verticalAlign='top' key={role.id}>
                            <Table.Cell>{role.name}</Table.Cell>
                            <Table.Cell>{role.userCount}</Table.Cell>
                            <Table.Cell>{role.permissions.map((permission, i) => <Label key={i}
                                                                                        className='label-margin'>{permission}</Label>)}</Table.Cell>
                            <Table.Cell>{role.visitBacklights.map((backlight, i) => <Label key={i}
                                                                                           color={backlight.color.toLowerCase()}
                                                                                           className='label-margin'>{backlight.name}</Label>)}</Table.Cell>
                            <Table.Cell>{role.taskBacklights.map((backlight, i) => <Label key={i}
                                                                                          color={backlight.color.toLowerCase()}
                                className='label-margin'>{backlight.name}</Label>)}</Table.Cell>
                            <Table.Cell>{role.ordersModuleBacklights.map((backlight, i) => <Label key={i}
                                color={backlight.color.toLowerCase()}
                                className='label-margin'>{backlight.name}</Label>)}</Table.Cell>
                            <Table.Cell textAlign='center'>
                                <Checkbox toggle itemID={role.id} checked={role.isActive} onChange={toggleIsActive}/>
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <RoleEditModal
                                    size="small"
                                    title={t('Role editing')}
                                    actionButton={t('Save role')}
                                    id={role.id}
                                    onClose={load}>
                                    <Icon name='edit'/>{` ${t('Edit')}`}
                                </RoleEditModal>
                            </Table.Cell>
                        </Table.Row>
                    );
                })
            }
        </LazyLoadDictionary>
    );
}
