import React from "react";
import { Dropdown } from "semantic-ui-react";
import { UserPermissionsContext } from "../Contexts";

export default class ActivityDropdown extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        value: this.context.permissionsData.currentActivityName,
        items: []
    }

    onOpen = () => {
        if (!this.state.items || this.state.items.length === 0) {
            callApi("UserSettings/GetActivities")
                .then(arr => {
                    arr.length > 1 && arr.unshift({name: t('All activities'), id: ''});
                    this.setState({
                        items: arr || []
                    });
                });
        }
    }

    onChange = (e, { value }) => {
        this.setState({ value: this.state.items.find(x => x.id === value).name });
        callApi("UserSettings/ChangeCurrentActivity", value)
            .then(() => {
                this.context.loadPermissionsData();
                if (this.props.onChange !== undefined)
                    this.props.onChange(null, value);
            });
    }

    render() {
        return (
            <Dropdown
                text={this.state.value}
                selection
                onOpen={this.onOpen}
                className='activity-dropdown'
            >
                <Dropdown.Menu>
                    {this.state.items.length > 1 && <>
                        <Dropdown.Item key={''} onClick={this.onChange} value={''}>{t('All activities')}</Dropdown.Item>
                        <Dropdown.Divider />
                    </>}

                    {this.state.items
						.filter(x=>x.id !== '')
						.sort((a,b)=> a.name.localeCompare(b.name))
						.map(x => (
                        	<Dropdown.Item key={x.id} onClick={this.onChange} value={x.id}>{x.name}</Dropdown.Item>
                    	))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
};