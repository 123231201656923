import React from "react";

import StateFacet from "./state/Facet";
import StateValue from "./state/Value";
import StateEdit from "./state/Edit";

import DriverValue from "./driver/Value";
import DriverEdit from "./driver/Edit";
import DriverFacet from "./driver/Facet";

import TransportValue from "./transport/Value";
import TransportEdit from "./transport/Edit";

import TrailerEdit from './transport/trailer/Edit';
import TrailerValue from './transport/trailer/Value';
import TrailerFacet from "./transport/trailer/Facet";

import TruckEdit from './transport/truck/Edit';
import TruckValue from './transport/truck/Value';
import TruckFacet from "./transport/truck/Facet";

import TasksFacet from "./tasks/Facet";
import TasksValue from "./tasks/Value";
import TasksEdit from "./tasks/Edit";

import TextFacet from "./text/Facet";
import TextValue from "./text/Value";
import TextEdit from "./text/Edit";

import TextareaEdit from "./textarea/Edit";

import NumberFacet from "./number/Facet";
import NumberValue from "./number/Value";
import NumberEdit from "./number/Edit";

import DateFacet from "./date/Facet";
import DateEdit from "./date/Edit";

import DateTimeEdit from "./datetime/Edit";

import TimeSlotEdit from "./timeslot/Edit";
import TimeSlotValue from "./timeslot/Value";

import SelectFacet from "./select/Facet";
import SelectValue from "./select/Value";
import SelectEdit from "./select/Edit";

import BooleanEdit from "./boolean/Edit";
import BooleanEditOther from "./boolean/EditOther";
import BooleanValue from "./boolean/Value";

import GateEdit from "./gate/Edit";
import VisitValue from "./visit/VisitValue";
import Values from "./select/Values";

const GridColumnDispatcher = (props) => {
    const isFacet = props.isFacet;
    const isEdit = props.isEdit;

    let field = getColumnControl(props);
    const controlProps = getColumnControlProps(props);
    let control = field[1];
    if (isFacet) control = field[0];
    if (isEdit) control = field[2];

    if (control)
        return React.cloneElement(control, controlProps);
    return null;
};
export default GridColumnDispatcher;

const controls = {
    "Text": { renders: [<TextFacet />, <TextValue />, <TextEdit />] },
    "Textarea": { renders: [<TextFacet />, <TextValue />, <TextareaEdit />] },
    "Number": { renders: [<NumberFacet />, <NumberValue />, <NumberEdit />] },
    "Date": { renders: [<DateFacet />, <TextValue />, <DateEdit />] },
    "DateTime": { renders: [<DateFacet />, <TextValue />, <DateTimeEdit />] },
    "Select": { renders: [<SelectFacet />, <SelectValue />, <SelectEdit />] },
    "MultiSelect": { renders: [<SelectFacet />, <Values />, <SelectEdit multiple />] },
    "TranslatedSelect": { renders: [<SelectFacet />, <SelectValue />, <SelectEdit />] },
    "State": { renders: [<StateFacet />, <StateValue />, <StateEdit />] },
    "Transport": { renders: [<SelectFacet />, <TransportValue />, <SelectEdit />] },
    "Trailer": { renders: [<TrailerFacet />, <TrailerValue />, <TrailerEdit/>] },
    "Truck": { renders: [<TruckFacet />, <TruckValue />, <TruckEdit/>] },
    "Driver": { renders: [<DriverFacet />, <DriverValue />, <DriverEdit />] },
    "Gate": { renders: [<SelectFacet visibleCountAll/>, <SelectValue />, <GateEdit />] },
    "Platform": { renders: [<SelectFacet />, <SelectValue />, <SelectEdit />] },
    "Activity": { renders: [<SelectFacet />, <SelectValue />, <SelectEdit />] },
    "Tasks": { renders: [<TasksFacet />, <TasksValue />, <TasksEdit />] },
    "Company": { renders: [<SelectFacet />, <SelectValue />, <SelectEdit />] },
    "TimeSlot": {renders: [<SelectFacet disabled />, <TimeSlotValue />, <TimeSlotEdit />]},
    "Boolean": {renders: [<BooleanValue/> , <BooleanValue/>, <BooleanEditOther/>]},
    "VisitNumber": { renders: [<NumberFacet />, <VisitValue />, <NumberEdit />] },
    "TaskNumber": { renders: [<NumberFacet />, <VisitValue isTask />, <NumberEdit />] },
};

const getColumnControl = (props) => {
    let filter = controls[props.column.type];
    if (!filter) {
        console.log(` not found. Using String . props.column.type:'${props.column.type}'`);
        filter = controls['Text'];
    }
    return filter.renders;
};

const getColumnControlProps = (props) => {
    let controlProps = {};
    controlProps.isLoading = props.isLoading || false;
    controlProps.value = props.value;
    controlProps.name = props.column.name;
    controlProps.isClearable = props.column.isClearable;
    if (props.isFacet) {
        controlProps.onChange = props.onChange;
        controlProps.setSort = props.setSort;
        controlProps.value = props.filters[props.column.name] || null;
        if (props.sort && props.sort.name === props.column.name)
            controlProps.sort = props.sort.desc ? "desc" : "asc";
    }
    if (props.isEdit) {
        controlProps.onChange = props.onChange;
        controlProps.isDisabled = props.isDisabled;
        controlProps.reloadFields = props.reloadFields;
        controlProps.noLabel = props.noLabel;
        controlProps.isVisitChange = props.isVisitChange;
        controlProps.onSaveVisit = props.onSaveVisit;

    }
    if (props.valuesList && props.valuesList.length > 0) {
        if (props.column.type.includes("Translated"))
            controlProps.valuesList = props.valuesList.map(x => ({ name: t(x.name), value: x.value, isActive: x.isActive }));
        else
            controlProps.valuesList = props.valuesList;
    }

    if (props.column.name.includes("State"))
        controlProps.stateColors = props.stateColors;

    controlProps.task = props.item || props.task;
    controlProps.visit = props.item || props.visit;
    controlProps.visitDate = props.visitDate || "";
    controlProps.loadTimeSlot = props.loadTimeSlot;
    controlProps.showPopup = props.showPopup;
    controlProps.isShow = props.isShow;
    controlProps.className = props.className;
    controlProps.states = props.states;
    controlProps.maxLength = props.column.maxLength || null;

    return controlProps;
};
