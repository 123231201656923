import React from "react";
import {Form, Dropdown, Icon} from "semantic-ui-react";
import TransportFormModal from "../../../../pages/transports/components/TransportEditModal";
import { UserPermissionsContext } from "../../../../Contexts";
import DriverEditModal from "../../../../pages/drivers/components/DriverEditModal";


class Edit extends React.Component {
    state = {
        items: [],
        searchQuery: '',
        isHandlePressEnter: false
    }

    loadList = (afterCall, search = '', selectedId = '') => {
        if (this.props.isCase) {
            callApi("case/GetTrailersForEdit", {search, selectedId: selectedId}).then(
                (items) =>
                    this.setState({
                        items: items.map(x => ({...x,  disabled: !x.isActive}))
                    }, afterCall)
            );
        } else {
            callApi("CreateUpdateVisit/GetTrailersForEdit", {
                selectedId: selectedId,
                search: search,
                platformId: this.context.permissionsData.currentPlatformId,
                activityIds: this.props.visit.activities || []
            }).then(
                (items) =>
                    this.setState({
                        items: items.map(x => ({...x,  disabled: !x.isActive}))
                    }, afterCall)
            );
        }
    }
    componentDidMount = () => {
        if (!!this.props.value) {
            this.loadList(()=> {}, '', this.props.value);
        } else
            this.loadList();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {isHandlePressEnter} = this.state;
        if (prevProps.value !== this.props.value && !isHandlePressEnter && this.state.searchQuery.length !==1) {
            setTimeout(()=>this.setState({
                searchQuery: ''
            }),100)
        }
        if (prevState.searchQuery !== this.state.searchQuery && !!this.state.searchQuery) {
            const {onChange, name} = this.props;
            onChange && onChange('',{name, value: ''});
        }
    }

    handleKeyPress = (e) => {
        if (e.keyCode == 13) {
            this.setState({
                isHandlePressEnter: true
            },() => this.loadList(()=> {}, this.state.searchQuery))
        }
    }

    handleOnChange = (e, {name, value}) => {
        const {onChange} = this.props;
        onChange && onChange(e, {name, value});
    }

    handleSearchChange = (e, { name, searchQuery }) => {
        if (searchQuery.includes(' ')) {
            this.loadList(()=> {}, searchQuery.slice(0,-1))
        }
        return this.setState({ searchQuery })
    }

    onFocus = () => {
        if (!!this.props.value) {
            this.loadList(()=> {}, '', this.props.value);
        } else
            this.loadList(() => {}, this.state.searchQuery);
    }

    onClick =() => {
        this.setState({
            isHandlePressEnter: false
        })
    }

    getDescriptionWithBlockedActivities = (description, blockedActivities = []) => {
        const textAtBeginning = blockedActivities.length > 1 ? t("Activities blocked") : t("Activity blocked");
        const textAboutActivity = blockedActivities.join(', ')
        const textResult = `${textAtBeginning}: ${textAboutActivity}`
        return <div>{description}
            <p class={"dropdown-driver-decription"}>{blockedActivities.length ? textResult : ''}</p></div>
    }

    render() {
        const {name, value, isDisabled, onChange, reloadFields, valuesList, noLabel, isClearable, isNotHistory, isEdit, className} =  this.props;
        const { permissionsData } = this.context;
        const {searchQuery} = this.state;
        //const inputEl = useRef(null);

        /*const items = (valuesList || [])
            .sort((a, b) => ((b.isActive - a.isActive) - 2 * (a.value === value)))
            .sort((a, b) => ( a.value === value ? -1 : b.value === value ? 1 : 0))
            .map(x =>
                ({
                    key: x.value,
                    value: x.value,
                    text: x.name,
                    disabled: !x.isActive,
                    description: !x.isActive && !!x.items ? getDescriptionWithBlockedActivities(x.description, x.items) : x.description
                }));*/
        let items = this.state.items;
        delete items.forEach(i => delete i.isActive);

        const onTransportSave = (e, { value,parameter }) => {
            this.loadList(()=>{
                if (reloadFields !== undefined)
                    reloadFields(e, {name, value, parameter });
                onChange(e, { name, value, parameter });
            }, '', value);
        };

        const search = (options, queryStr) => {
            const query = queryStr.replace(/\s+/g, ' ').trim();
            return items.filter(opt => new RegExp(query, "i").test(opt.text) || (opt.description && new RegExp(query, "i").test(opt.description))).slice(0, 20);
        };

        return (
            <Form.Field>
                {name && <label className={ isDisabled ? "label-disabled" : null}>{t(name) + (this.props.isCase ? ' *' : '')}</label>}
                    <div className={`dropbox-button ${(value && isEdit) ? "dropbox-button_2" : ''}`}>
                        <Dropdown
                            key={'dropdown-edit-trailer'}
                            name={name}
                            onKeyDown={this.handleKeyPress}
                            value={value}
                            onChange={this.handleOnChange}
                            onFocus={this.onFocus}
                            onMouseDown={this.onClick}
                            searchQuery={searchQuery}
                            onSearchChange={this.handleSearchChange}
                            disabled={isDisabled || false}
                            search={search}
                            selection
                            //closeOnEscape={false}
                            selectOnBlur={false}
                            selectOnNavigation={false}
                            clearable={isClearable}
                            className={className}
                            options={items}
                            onBlur={()=>this.setState({searchQuery: ''})}
                        />
                        <TransportFormModal
                            isDisabled={!permissionsData.permissions.includes("CreateTransports")}
                            title={t("adding trailer")}
                            actionButton={t("add trailer")}
                            id={null}
                            isNotHistory={isNotHistory}
                            parameter={'trailer'}
                            onSave={onTransportSave}>
                            <Icon name='plus'/>
                        </TransportFormModal>
                        {value && isEdit && <TransportFormModal
                            size="small"
                            title={t('edit trailer')}
                            actionButton={t('Save changes')}
                            id={value}
                            buttonClassName={'edit-btn'}
                            isNotHistory={isNotHistory}
                            parameter={'trailer'}
                            onSave={onTransportSave}
                        >
                            <Icon name='edit' />
                        </TransportFormModal>
                        }
                    </div>
            </Form.Field>

        );
    }
}

Edit.contextType = UserPermissionsContext;
export default Edit;
