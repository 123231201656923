import React from "react";
import {Popup, Button, Grid, Message} from "semantic-ui-react";
import InputRegExp from "../../../shared/columns/number/inputNumber";

const initState = {
    interval: '',
    isOpen: false,
    textError: ''
};

export default class BeatTimeSlot extends React.Component {

    state = {
        ...initState
    };

    componentDidUpdate(prevProps, prevState) {
        const {interval} = this.state;
        if (prevState.interval !== interval) {
            this.setState({
                textError: ''
            })

        }
    }

    handleOpen = () => {
        const {onOpen} = this.props;
        this.setState({
            isOpen: true
        });
        onOpen && onOpen()
    };

    handleClose = () => {
        const {onClose} = this.props;
        onClose && onClose();
    };

    changeIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    };

    addBeatTimeSlot = () => {
        const {interval} = this.state;
        const {intervalDivideScheduleStandard: {timeStart = '', timeEnd = ''}} = this.props;
        const timeStartInterval = timeStart;
        const timeEndInterval = timeEnd;
        const timeStartDate = new Date(2020, 0, 1, timeStartInterval.slice(0, 2), timeStartInterval.slice(3, 5));
        const timeEndDate = new Date(2020, 0, 1, timeEndInterval.slice(0, 2), timeEndInterval.slice(3, 5));
        const timeDiffMs = Math.abs(timeEndDate.getTime() - timeStartDate.getTime());
        const minDiff = Math.floor((timeDiffMs / 1000 / 60));

        if (!this.isValid()) {
            return this.setState({
                textError: `${t('Fill in the fields')}`
            });
        }
        if (minDiff < interval || Number(interval) === 0) {
            return this.setState({
                textError: t('Interval cannot be broken')
            });
        } else {
            this.setState({
                textError: t('')
            });
        }
        if (this.isValid()) {
            this.setState({
                ...initState
            });
            this.props.onAdd && this.props.onAdd({interval});
            this.changeIsOpen()
        }
    };

    saveBeatTimeSlot = () => {
        const {date, timeStart, timeEnd, isLoad, isUnload, isMoving} = this.state;
        if (!this.isValid()) {
            this.setState({
                textError: `${t('Fill in the fields')}`
            });
            return
        }
        this.setState({
            textError: ''
        });
        this.props.onChange && this.props.onChange({date, timeStart, timeEnd, isLoad, isUnload, isMoving})
        this.changeIsOpen()
    };

    cancelBeatTimeSlot = () => {
        const {onCancel} = this.props;
        this.setState({
            ...initState
        });
        onCancel && onCancel()
    };

    isValid = () => {
        const {interval} = this.state;
        return !!interval;
    };

    getTextInfo = () => {
        const {intervalDivideScheduleStandard: {timeStart = '', timeEnd = ''}} = this.props;
        return `${t('TimeSlot')} '${t('with')} ${timeStart.slice(0, 5)} ${t('before')} ${timeEnd.slice(0, 5)}' ${t('will be split into an interval')}, ${t('size below')}:`
    };

    render() {
        const {disabled, contentButton} = this.props;
        const {interval} = this.state;

        let content = <div onClick={e => {
            e.stopPropagation();
        }}>
            <Grid columns="equal" className=" ui form padding-bottom-0" style={{width: "400px"}}>
                <Grid.Row>
                    <Grid.Column>
                        <Message info>
                            <Message.Header>{`${t('Enter the time slot splitting interval')}.`}</Message.Header>
                            <p>{this.getTextInfo()}</p>
                        </Message>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <InputRegExp label={`${t('Interval')}(${t('min')})`} name="interval"
                                     value={interval}
                                     reg={'[0-9]+'}
                                     onChange={(e, {value}) => this.handleChange('interval', value)}/>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="padding-margin-0">
                    <Grid.Column>
                        {this.state.textError &&
                        <Message negative>
                            <p>{this.state.textError}</p>
                        </Message>}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign={"center"}>
                        <Button size="small" onClick={this.addBeatTimeSlot}>{t('Beat time slot')}</Button>
                        <Button size="small" onClick={this.cancelBeatTimeSlot}>{t('Cancel')}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>;
        return (
            <Popup
                trigger={<Button style={{fontSize: "10px"}} content={contentButton}/>}
                content={content}
                on='click'
                size={'huge'}
                disabled={disabled}
                open={this.state.isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                position='top right'
            />
        )
    }

}
