import React, {useContext, useEffect, useState} from "react";
import {Button, Dropdown, Form, Message, Modal} from "semantic-ui-react";
import VisitTasksModal from "./visitTaskModal/VisitTasksModal";
import {toast} from "react-toastify";
import {UserPermissionsContext} from "../../Contexts";

const InputDropdown = ({options = [], onChange, name, onSearch, value, isDisabled}) => {
    const [searchQuery, setSearchQuery] = useState("");
    useEffect(() => onSearch && onSearch(searchQuery), [searchQuery, value]);

    return <Form.Input label={t(name)}>
        <Dropdown
            disabled={isDisabled}
            value={value}
            clearable
            name={name}
            search={!!onSearch}
            fluid
            selection
            options={options.map(o => {
                return {text: o.number, value: o.id};
            })}
            searchQuery={searchQuery}
            onSearchChange={(e) => setSearchQuery(e.target.value)}
            onChange={(e, {value, name}) => {
                setSearchQuery("");
                onChange(e, {value, name});
            }}
        />
    </Form.Input>;
};

export const GroupTaskModal = ({children, rows, onClick, rowsWithTask}) => {
    const visitTaskType = 'VisitTaskType';

    const [modalOpen, setModalOpen] = useState(false);
    const [form, setForm] = useState({});
    const [data, setData] = useState({});
    const [platformVisible, setPlatformVisible] = useState(false);

    const onChange = (e, {value, name}) => {
        let values = {[name]: value};
        if (name === 'TaskNumber') values['VisitNumber'] = data.taskNumbers && data.taskNumbers.find(vN => vN.id === value) && data.taskNumbers.find(vN => vN.id === value).visitId;
        if (name === 'VisitNumber' && form['TaskNumber']) values['TaskNumber'] = null;

        setForm(f => {
            return {
                ...f,
                ...values
            };
        });
    };

    const onClose = () => {
        setForm({});
        modalOpen && setModalOpen(false);
        onClick && onClick(false);
        platformVisible && setPlatformVisible(false);
    };

    const onOpen = () => {
        if ((rowsWithTask || []).length) {
            let errors = [];
            rowsWithTask.map(r => errors.push(`${t('order_no')}${r.number} ${t('group_task_state_error')}${r.taskNumber}`))
            toast.error(errors.join(';\n'));
        }

        const canOpen = rows.every(row => row.transportCompany === rows[0].transportCompany
            && row.plannedDate === rows[0].plannedDate
            && row.operationType === rows[0].operationType
            && row.activityId === rows[0].activityId);
        if (canOpen) {
            onClick && onClick(true);
            setModalOpen(true);
        }
        else toast.error(t("group_task_invalid_data"));
    };

    const searchByVisitTaskNumbers = (visitNumber, taskNumber) => {
        callApi(`OrdersModule/SearchByVisitTaskNumbers`,
            {
                visitNumber: visitNumber || (data.visitNumbers && data.visitNumbers.find(vN => vN.id === form["VisitNumber"]) && data.visitNumbers.find(vN => vN.id === form["VisitNumber"]).number),
                taskNumber: taskNumber || (data.taskNumbers && data.taskNumbers.find(vN => vN.id === form["TaskNumber"]) && data.taskNumbers.find(vN => vN.id === form["TaskNumber"]).number)
            }
        ).then(data => {
            if (!data.error) {
                setData((prevState => {
                    return {
                        ...prevState,
                        visitNumbers: data.visitNumbers,
                        taskNumbers: data.taskNumbers
                    };
                }));
            }
        });
    };

    const getPlatforms = () => {
        callApi("UserSettings/GetPlatforms")
            .then(arr => {
                setData(prevState => {
                    return {
                        ...prevState,
                        platforms: arr.map(p => {
                            return {
                                number: p.name,
                                id: p.id
                            }
                        })
                    };
                });
            }
        );
    };

    const getOperationTypes = () => {
        callApi("OrdersModule/GetVisitTaskType")
            .then(arr => {
                setData(prevState => {
                    return {
                        ...prevState,
                        visitTaskTypes: arr.map(p => {
                            return {
                                number: t(p),
                                id: p
                            };
                        })
                    };
                });
            }
        );
    };

    useEffect(() => {
        platformVisible && getPlatforms();
    }, [platformVisible]);

    useEffect(() => {
        modalOpen && getOperationTypes();
    }, [modalOpen]);

    useEffect(() => {
        if (!form.visitTaskType && data.visitTaskTypes && data.visitTaskTypes.length) {
            onChange(null, {value: rows[0].operationType || 'Unloading', name: visitTaskType});
        }
    }, [data.visitTaskTypes]);

    const {permissionsData} = useContext(UserPermissionsContext);
    const currentPlatformIsNull = !permissionsData.currentPlatformId;

    return (
        <Modal
            trigger={children}
            open={modalOpen}
            onOpen={onOpen}
            onClose={onClose}
            closeOnEscape
            closeOnDimmerClick={false}
            closeIcon
        >
            <Modal.Header>{t("group_into_a_task")}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Group widths="equal">
                        <InputDropdown
                            value={form["VisitNumber"]}
                            name={"VisitNumber"}
                            options={data.visitNumbers}
                            onSearch={(search) => searchByVisitTaskNumbers(search)}
                            onChange={onChange}
                        />
                        <InputDropdown
                            value={form["TaskNumber"]}
                            name={"TaskNumber"}
                            options={data.taskNumbers}
                            onSearch={(search) => searchByVisitTaskNumbers(null, search)}
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Form.Group widths="2">
                        <InputDropdown
                            isDisabled={rows[0].operationType}
                            value={form[visitTaskType]}
                            name={visitTaskType}
                            options={data.visitTaskTypes}
                            onChange={onChange}
                        />
                        {platformVisible && !form["VisitNumber"] && !form["TaskNumber"] && <InputDropdown
                            name={"Platform"}
                            options={data.platforms}
                            onChange={onChange}
                        />}
                    </Form.Group>
                </Form>

                {platformVisible && !form["VisitNumber"] && !form["TaskNumber"] && !form["Platform"] && <Form widths="equal">
                    <Message negative>
                        {t('select_platform')}
                    </Message>
                </Form>}
            </Modal.Content>
            <Modal.Actions>
                <Button content={t("CancelButton")} onClick={onClose}/>
                {(currentPlatformIsNull && !form["VisitNumber"] && !form["TaskNumber"] && !form["Platform"]) ? <Button color="blue" content={t("Save")} onClick={()=>setPlatformVisible(true)}/> :
                    <VisitTasksModal
                        isOrderModule
                        platformId={platformVisible ? form["Platform"] : null}
                        onClose={onClose}
                        newOrdersIds={rows}
                        visitId={form["VisitNumber"]}
                        visitTaskType={form[visitTaskType]}
                        key={"vtm" + form["VisitNumber"]}
                        taskId={form["TaskNumber"]}
                        defaultActiveIndex={1}
                        title={form["VisitNumber"] ? t("edit_visit_modal_label") : t("create_visit_modal_label")}
                    >
                        <Button color="blue" content={t("Save")}/>
                    </VisitTasksModal>}
            </Modal.Actions>
        </Modal>
    );

};
