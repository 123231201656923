import React from "react";
import { Button, Icon } from "semantic-ui-react";
import DocView from "./components/DocView";

class DocumentListViewCompact extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            countPerPage: props.countPerPage || 2,
            disabledLeft: false,
            disabledRight: false

        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps.documents) !== JSON.stringify(this.props.documents))
        {
            this.handleDisabledButton();
        }
    }
    handleDisabledButton = () => {
        this.setState({
            disabledLeft: this.state.page === 1,
            disabledRight: !(this.state.page * this.state.countPerPage <  this.props.documents.length)
        })
    };

    goLeft = () => {
        this.setState(prevState => {
            return { page: prevState.page > 1 ? --prevState.page : 1,
                disabledLeft: this.state.page === 1,
                disabledRight: !(this.state.page * this.state.countPerPage <  this.props.documents.length)
            };
        });
    };

    goRight = () => {
        this.setState(prevState => {
            let length = this.props.documents.length;
            return {
                page: prevState.page * prevState.countPerPage < length ? ++prevState.page : prevState.page,
                disabledLeft: this.state.page === 1,
                disabledRight: !(this.state.page * this.state.countPerPage <  this.props.documents.length)
            }
        });
    };

    render() {
        const { page, countPerPage, disabledLeft, disabledRight } = this.state;
        const documents = this.props.documents.slice((page - 1) * countPerPage, page * countPerPage);
        if (documents.length > 0)
            return (
                <div className="flex-container flex-container-justify">
                    <Button icon className="document-list-button" onClick={this.goLeft} disabled={disabledLeft}><Icon name="chevron left" /></Button>
                    {documents.map((document, index) => (
                        <DocView key={index} document={document} onClick={this.props.onClick} />
                    ))}
                    <Button icon className="document-list-button" onClick={this.goRight} disabled={disabledRight}><Icon name="chevron right" /></Button>
                </div>
            );
        else return null;
    }
}

export default DocumentListViewCompact;
