import React, {useState} from "react";
import ControlledModal from "../../shared/modals/ControlledModal";
import {Dropdown, Form} from "semantic-ui-react";

const AccessToInvitationsModal = ({id, children, size, title, actionButton, onClose: onCloseInit, onSave}) => {
    const initFormData = {
        isActive: true
    };
    const [formData, setFormData] = useState(initFormData);
    const [errorText, setErrorText] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const onClose = () => {
        onCloseInit && onCloseInit();
        setFormData(initFormData);
        setErrorText(null);
        setIsChanged(false);
    };

    const load = () => {
        id && callApi("accessInvitations/GetFor", id).then(data => {
            (!data.error || data.error !== "") && setFormData(data);
        });

        callApi("Platforms/GetList").then(platforms => {
            setPlatforms(platforms.map(c => ({key: c.id, value: c.id, text: c.name})));
        });
    };

    const save = () => {
        setIsLoading(true)
        return callApi("accessInvitations/SaveOrCreate", formData)
            .then(data => {
                if (data.error !== "") {
                    setErrorText("" + data.error);
                }
                onSave && onSave();
                return data;
            }).finally(() => {
                setIsLoading(false);
            });
    };

    const handleChange = (e, {name, value, checked}) => {
        setFormData((prevState) => {
            return {...prevState, [name]: checked !== undefined ? checked : value};
        });
        !isChanged && setIsChanged(true);
    };

    return (
        <ControlledModal
            saveButtonDisabled={isLoading}
            saveButtonLoading={isLoading}
            buttonName={children}
            buttonSize={size}
            title={title}
            onOpen={load}
            closeOnEscape={true}
            closeOnDimmerClick={false}
            onClose={onClose}
            error={errorText}
            saveButtonName={actionButton}
            onSave={save}
            size="small"
            isConfirmForCloseModal={isChanged}
        >
            <Form className="form-data" unstackable>
                <Form.Group widths={2}>
                    <Form.Input
                        width={12}
                        label={t("Email")}
                        type="email"
                        name="email"
                        value={formData.email || ""}
                        onChange={handleChange}
                    />
                    <Form.Checkbox
                        width={4}
                        label={t("Active")}
                        name="isActive"
                        checked={formData.isActive}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group widths={2}>
                    <Form.Field width={8}>
                        <label>{t("Platform")}</label>
                        <Dropdown
                            clearable
                            fluid
                            selection
                            search
                            name="platformId"
                            placeholder={t("Platform")}
                            value={formData.platformId}
                            options={platforms}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <label>{t("availablePlatforms")}</label>
                        <Dropdown
                            multiple
                            fluid
                            selection
                            search
                            name="platformIds"
                            placeholder={t("All platforms")}
                            value={formData.platformIds}
                            options={platforms}
                            onChange={handleChange}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </ControlledModal>
    );
};

export default AccessToInvitationsModal;