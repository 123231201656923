import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Papa from "papaparse";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import enGB from "date-fns/locale/en-GB";

//date-fns
registerLocale("ru", ru);
registerLocale("en", enGB);
let translateFileLink = document.getElementById("translateFileLink") || {};

Papa.parse(translateFileLink.href || "/assets/translate-file.csv", {
    download: true,
    complete: function (results) {
        for (let i = 0; i < results.data.length; i++) {
            window.translates[results.data[i][0]] = { ru: results.data[i][1], en: results.data[i][2] };
        }
        ReactDOM.render(
            <App />,
            document.getElementById("content")
        );
    }
});