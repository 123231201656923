import React from "react";
import { Modal, Form, Button, Input, Icon, Message, Tab, Checkbox, Dropdown, Table } from "semantic-ui-react";
import BacklightsEditor from "./BacklightsEditor";
import ControlledModal from "../../../shared/modals/ControlledModal";

export default class RoleEditModal extends React.Component {

    state = {
        modal: false,
        formData: {
            id: "",
            name: "",
            permissions: [],
            visitBacklights: [],
            taskBacklights: [],
            ordersModuleClasses: [],
            isForAllActivities: false,
            isForAllPlatforms: false,
        },
        allPermissions: [],
        backLightColors: [],
        visitClasses: [],
        taskClasses: [],
        ordersModuleClasses: [],
        errorText: "",
    };

    load = () => {
        return callApi("Definitions/GetBackLightInfo").then(backLightInfo => {
            callApi("Definitions/GetAllPermissions").then(allPermissions => {
                callApi("Roles/GetById", this.props.id).then(data => {
                    callApi("activities/getList").then(activities => {
                        callApi("Platforms/GetList").then(platforms => {
                            this.setState({
                                formData: data,
                                allPermissions: allPermissions,
                                platforms: platforms.map(x => { return { key: x.id, text: x.name, value: x.id }; }),
                                activities: activities.map(x => { return { key: x.id, text: x.name, value: x.id }; }),
                                backLightColors: backLightInfo.colors.map(x => { return { key: x, text: t(x), value: x }; }),
                                visitClasses: backLightInfo.visitClasses.map(x => { return { key: x, text: x, value: x }; }),
                                taskClasses: backLightInfo.taskClasses.map(x => { return { key: x, text: x, value: x }; }),
                                ordersModuleClasses: backLightInfo.ordersModuleClasses.map(x => { return { key: x, text: x, value: x }; }),
                            });
                        });
                    });
                });
            });
        });
    }

    deleteNewIds = (arr) => {
        return (arr || []).map(v => !v.isNew ? {...v, isNew: null} : {...v, id: null, isNew: null});
    }

    save = () => {
        let formData = {
            ...this.state.formData,
            visitBacklights: this.deleteNewIds(this.state.formData.visitBacklights),
            taskBacklights: this.deleteNewIds(this.state.formData.taskBacklights),
            ordersModuleBacklights: this.deleteNewIds(this.state.formData.ordersModuleBacklights)
        };
        return callApi("Roles/SaveOrCreate", formData)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            });
    }

    removeBacklight = (backlight) => {
        let formData = this.state.formData;
        formData.visitBacklights = formData.visitBacklights.filter(x => x.id !== backlight.id);
        this.setState({ formData: formData });
    }

    changeFormData = (changer) => {
        let formData = this.state.formData;
        changer(formData);
        this.setState({ formData: formData });
    }

    addOrRemovePermission = (permission) => {
        let formData = this.state.formData;
        let permissions = formData.permissions;
        let thisDependedOf = this.state.allPermissions.filter(x=>x.name === permission)[0].dependedOf;
        let otherDependedOf = this.state.allPermissions.filter(x=>x.dependedOf === permission);
        if (formData.permissions.includes(permission)) {
            permissions = permissions.filter(x => x !== permission);
            if(thisDependedOf == null){
                permissions = permissions
                    .filter(x => !(otherDependedOf.filter(op=>op.name === x).length > 0));
            }
        } else {
            permissions.push(permission);
            if(thisDependedOf !== null){
                permissions.push(thisDependedOf);
            }
        }
        formData.permissions = permissions;
        this.setState({ formData: formData });
    }

    render() {
        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={t(this.props.title)}
                onOpen={this.load}
                onClose={this.props.onClose}
                error={this.state.errorText}
                saveButtonName={t(this.props.actionButton)}
                onSave={this.save}>
                <Form>
                    <Form.Field>
                        <Form.Input label={t('Name')} value={this.state.formData.name}
                            onChange={(e) => { this.changeFormData((fd) => { fd.name = e.target.value; }); }}
                        />
                    </Form.Field>
                    <Tab panes={[
                        {
                            menuItem: t('Permissions'), render: () =>
                                <Tab.Pane>{this.state.allPermissions.map((permission, index) =>
                                {return permission.dependedOf === null ? <><Form.Field key={index}>
                                    <Form.Checkbox
                                        label={permission.name}
                                        checked={this.state.formData.permissions.includes(permission.name)}
                                        onChange={(e) => {
                                            this.addOrRemovePermission(permission.name, e.target.value);
                                        }}/>
                                </Form.Field>
                                {this.state.allPermissions.filter(x=>x.dependedOf === permission.name).map((subPermission, index) => {
                                    return <Form.Field className={'sub-permission'} key={"sub_" + index}>
                                        <Form.Checkbox
                                            label={subPermission.name}
                                            checked={this.state.formData.permissions.includes(subPermission.name)}
                                            onChange={(e) => {
                                                this.addOrRemovePermission(subPermission.name, e.target.value);
                                            }}/>
                                    </Form.Field>;
                                })}
                                </>: ''})}</Tab.Pane>
                        },
                        {
                            menuItem: t('Backlight'), render: () => <Tab.Pane>
                                <label>{t('Visits')}</label>
                                <BacklightsEditor value={this.state.formData.visitBacklights}
                                    backLightColors={this.state.backLightColors}
                                    activities={this.state.activities}
                                    platforms={this.state.platforms}
                                    classes={this.state.visitClasses}
                                    onChange={(value) => {
                                        this.changeFormData((fd) => { fd.visitBacklights = value; });
                                    }} />
                                <label>{t('Tasks')}</label>
                                <BacklightsEditor value={this.state.formData.taskBacklights}
                                    backLightColors={this.state.backLightColors}
                                    activities={this.state.activities}
                                    platforms={this.state.platforms}
                                    classes={this.state.taskClasses}
                                    onChange={(value) => {
                                        this.changeFormData((fd) => { fd.taskBacklights = value; });
                                    }} />
                                <label>{t('OrdersModule')}</label>
                                <BacklightsEditor value={this.state.formData.ordersModuleBacklights}
                                                  backLightColors={this.state.backLightColors}
                                                  activities={this.state.activities}
                                                  platforms={this.state.platforms}
                                                  classes={this.state.ordersModuleClasses}
                                                  onChange={(value) => {
                                        this.changeFormData((fd) => { fd.ordersModuleBacklights = value; });
                                    }} />
                            </Tab.Pane>
                        },
                    ]}/>
                </Form>
            </ControlledModal>
        );
    }
}
