import React, {useContext} from "react";
import {Checkbox, Grid, Segment, Form, Icon} from "semantic-ui-react";
import TextEdit from "../../../../shared/columns/text/Edit";
import SelectEdit from "../../../../shared/columns/select/Edit";
import CompanyEditModal from "../../../companies/components/CompanyEditModal";
import {UserPermissionsContext} from "../../../../Contexts";

export default function ClientInfo ({isView, formData, companyList, loadFields, handleChange}) {
    const { permissionsData } = useContext(UserPermissionsContext);

    return (<Segment>
        <Grid className="margin-bottom-10">
            <Grid.Row className="padding-bottom-0">
                <Grid.Column width={3}>
                    <Form.Field>
                        <Checkbox
                            radio
                            label="FTL"
                            name="clientInfo"
                            disabled={isView}
                            checked={formData.isFtl}
                            onChange={(e, { checked }) => {
                                handleChange("isFtl", checked);
                            }}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width={3}>
                    <Form.Field>
                        <Checkbox
                            radio
                            label="LTL"
                            name="checkboxRadioGroup"
                            disabled={isView}
                            checked={!formData.isFtl}
                            onChange={(e, { checked }) => {
                                handleChange("isFtl", !checked);
                            }}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            {
                formData.isFtl &&
                    <>
                        <Form.Field className="margin-bottom-0 margin-top-7">
                            <label className="margin-bottom-0">{t('Information about Customer')}</label>
                        </Form.Field>
                        <Grid.Row columns="equal" className="padding-bottom-0 padding-top-0">
                            <Grid.Column>
                                <TextEdit
                                    isDisabled={isView}
                                    value={formData.clientCompany}
                                    onChange={(e, { name, value }) =>
                                        handleChange("clientCompany", value)
                                    }
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </>
            }
            <Grid.Row className="padding-bottom-0">
                <Grid.Column>
                    <Checkbox
                        label={t('Expensive cargo (in accordance with the procedure)')}
                        disabled={isView}
                        checked={formData.isExpensiveCargo}
                        onChange={(e, {checked}) => handleChange("isExpensiveCargo", checked)}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>);
}
