import React, {useContext, useEffect, useState} from "react";
import {Button, Confirm, Dimmer, Loader, Modal} from "semantic-ui-react";
import GridColumnDispatcher from "../columns/GridColumnDispatcher";
import {toast} from "react-toastify";
import {UserPermissionsContext} from "../../Contexts";

export const FormModal = ({id, gridName, title, open, children, stateColors = [], onClose: onCloseModal, formData}) => {
    const state = 'New';
    const [modalOpen, setModalOpen] = useState(open);
    const [fields, setFields] = useState([]);
    const [form, setForm] = useState({state});
    const [oldForm, setOldForm] = useState({state});
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const {permissionsData} = useContext(UserPermissionsContext);

    useEffect(() => {
        id && modalOpen && loadData();
    }, [id, modalOpen]);

    useEffect(() => {
        if (formData) {
            setForm(prevState => {return {...formData, ...prevState};});
            setOldForm(prevState => {return {...formData, ...prevState};});
        }
    }, [formData]);

    const getColumns = (activity) => {
        setIsLoading(true);
        return callApi(`${gridName}Form/GetColumns`, {
            activityIds: activity ? [activity] : (form.activities || []),
            platformId: permissionsData.currentPlatformId
        }).then(data => {
            if (!data.error) {
                setIsLoading(false);
                setFields(data.columns);
            }
            return data;
        });
    };

    const onClose = () => {
        setModalOpen(false);
        setOldForm({state});
        setForm({state});
        setShowConfirmation(false);
        onCloseModal && onCloseModal();
    };

    const onOpen = () => {
        setModalOpen(true);
        !id && getColumns();
    };

    const onChange = (e, {value, name}) => {
        setForm(f => {
            setOldForm(f);
            return {
                ...f,
                [name.charAt(0).toLowerCase() + name.slice(1)]: value
            };
        });
    };

    const onChangeCustomField = (e, {value, name}) => {
        let customFields = form.customFields ? [...form.customFields] : [];
        const index = customFields.findIndex(v => v.name === name);
        index !== -1 ? customFields[index] = {name: name, value: value} : customFields.push({name: name, value: value});
        setForm(f => {
            setOldForm(f);
            return {
                ...f,
                customFields: customFields
            };
        });
    };

    const saveAndClose = () => {
        save().then(resp => {
            if (resp.successMessage) onClose();
        });
    };

    const loadData = () => {
        setIsLoading(true);
        return callApi(`${gridName}Form/GetFor`, id || form.id).then(data => {
            if (!data.error) {
                setForm(data);
                setOldForm(data);
                setIsLoading(false);
                getColumns(data.activity);
            }
            return data;
        });
    };

    const save = () => {
        setIsLoading(true);
        return callApi(`${gridName}Form/Save`, form).then(resp => {
            if (resp.successMessage !== null) toast.info(resp.successMessage);
            else toast.error(resp.errorMessage);
            setIsLoading(false);
            return resp;
        });
    };

    const formChanged = JSON.stringify(form) !== JSON.stringify(oldForm);

    const renderLoader = () => {
        return (
            <div className="fluid-for-loader">
                <Dimmer active inverted>
                    <Loader size="large" inverted content={`${t("Loading")}...`}/>
                </Dimmer>
            </div>
        );
    };

    return (
        <Modal
            dimmer="blurring"
            className="form-modal"
            trigger={children}
            open={modalOpen}
            onOpen={onOpen}
            onClose={() => formChanged ? setShowConfirmation(true) : onClose()}
            closeOnEscape
            closeOnDimmerClick={false}
            closeIcon
            size="fullscreen"
        >
            <Modal.Header>{id ? `${form.number || ''} - ${t(form.state) || ''}` : title}</Modal.Header>
            {isLoading && renderLoader()}
            <Modal.Content scrolling>
                <div className="ui form visit-edit-grid">
                    {fields.map(x => {
                        let a = x.fieldMatrixAccessTypes.find(x => x.state === form.state) || {};
                        let isDisabled = a.accessType === "Show";
                        let isHidden = a.accessType === "Hidden";
                        return (!isHidden && <GridColumnDispatcher
                            key={x.name}
                            column={x}
                            value={x.isCustom
                                ? (form.customFields && form.customFields.find(y => y.name === x.name) || {}).value
                                : form[x.name.charAt(0).toLowerCase() + x.name.slice(1)]
                            }
                            isEdit
                            onChange={x.isCustom ? onChangeCustomField : onChange}
                            valuesList={x.valuesForSelectType || []}
                            stateColors={stateColors}
                            isDisabled={isDisabled}
                            className={isDisabled ? "disabled" : ""}
                        />);
                    })}
                </div>
            </Modal.Content>
            <Modal.Actions>
                {formChanged && <Button className="save-changes-button" color="green" content={t("Save changes")}
                                        onClick={saveAndClose}/>}
            </Modal.Actions>
            <Confirm
                dimmer="blurring"
                open={showConfirmation}
                onCancel={() => onClose()}
                onConfirm={() => setShowConfirmation(false)}
                content={t("Your changes won't save. Are you sure to close the window?")}
                cancelButton={t("Close without saving")}
                confirmButton={t("CancelButton")}
            />
        </Modal>
    );

};
