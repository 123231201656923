import React from "react";
import DocView from "./components/DocView";

const DocumentListView = ({ documents }) => {
    return (
        <div className="flex-container">
            {documents.map((document, index) => (
                <DocView key={index} document={document} />
            ))}
        </div>
    );
};
export default DocumentListView;