import React from "react";
import {Input, Form} from "semantic-ui-react";

const Edit = ({value, name, onChange, isDisabled, noLabel, className, maxLength}) => {
    return (
        <Form.Field>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <Input
                className={className}
                type="number"
                disabled={isDisabled || false}
                name={name}
                value={value || ""}
                onChange={(e, {name, value}) => (!maxLength || value.toString().length <= maxLength) && onChange(e, {name, value})}
                autoComplete="off"
            />
        </Form.Field>
    );
};
export default Edit;