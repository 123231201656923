import React from "react";
import VisitTasksModal from "../../modals/visitTaskModal/VisitTasksModal";

const VisitValue = ({ value, isTask, visit = {} }) => {
    return (
        <div onClick={(e)=>e.stopPropagation()}>
            <VisitTasksModal
                key={"vtm" + visit.id}
                visitId={visit.visitId}
                taskId={isTask && visit.visitId ? visit.taskId : undefined}
                title={t('edit_visit_modal_label')}
            >
                <div>
                    {value}
                </div>
            </VisitTasksModal>
        </div>
    );
};
export default VisitValue;
