import React from 'react';
import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import CompanySchedule from './CompanySchedule';

export default class ReadOnlyActivity extends React.Component {
    renderActivityDropdown = (item, allActivities) => {
        return (allActivities.find((x) => x.id === item.activityId) || {}).name || item.activityId;
    };

    renderActivityButtons = (index, item) => {
        return (
            <>
                <Button size="small" onClick={() => this.props.editActivity(index)}>
                    <Icon name="edit" />
                </Button>
                <Button size="small" onClick={() => this.props.deleteActivity(index)}>
                    {' '}
                    <Icon name="delete" />
                </Button>
            </>
        );
    };

    render() {
        const { index, activity, allActivities, showActionButtons } = this.props;
        const schedules = activity.schedules;

        if (schedules.length === 0) {
            return (
                <Table.Row
                    key={`schedule-${index}-${0}`}
                    className={`schedule-activities-modal-item-${index}`}
                >
                    <Table.Cell>{this.renderActivityDropdown(activity, allActivities)}</Table.Cell>

                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>

                    <Table.Cell width={2} textAlign="center">
                        {showActionButtons && this.renderActivityButtons(index, activity)}
                    </Table.Cell>
                </Table.Row>
            );
        }

        return schedules.map((schedule, key) => {
            const isFirstRow = key === 0;

            return (
                <Table.Row
                    key={`schedule-${index}-${key}`}
                    className={`schedule-activities-modal-item-${index}`}
                >
                    {isFirstRow && (
                        <Table.Cell rowSpan={schedules.length}>
                            {this.renderActivityDropdown(activity, allActivities)}
                        </Table.Cell>
                    )}

                    <Table.Cell>
                        <CompanySchedule activityIndex={index} scheduleIndex={key} item={schedule} disabled />
                    </Table.Cell>

                    <Table.Cell>
                        <Checkbox disabled checked={schedule.isGreenCorridor} />
                    </Table.Cell>

                    <Table.Cell>{schedule.maxCountTaskOnTimeSlot}</Table.Cell>

                    {isFirstRow && (
                        <Table.Cell rowSpan={schedules.length} width={2} textAlign="center">
                            {showActionButtons && this.renderActivityButtons(index, activity)}
                        </Table.Cell>
                    )}
                </Table.Row>
            );
        });
    }
}
