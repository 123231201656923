import React from "react";
import {Form} from "semantic-ui-react";
import GridColumnDispatcher from "../../../shared/columns/GridColumnDispatcher";
import InputRegExp from "../../../shared/columns/number/inputNumber";
import {convertingStringToNumber} from "../../../const/functions";

const SpecificationsGate = ({keyV, column, value, onChange, isDisabled, className, noLabel, type, disabledAll}) => {
    const onBlurInput = (e, {value}) => {
        const valueNew = typeof value === "number" ? value : Number(convertingStringToNumber(value));
        onChange && onChange(e, {value: valueNew});
    };

    return type !== "Number" ? (
        <Form.Field>
            <GridColumnDispatcher
                key={`${keyV}-${column.name}`}
                column={column}
                isEdit
                onChange={onChange}
                isDisabled={isDisabled || disabledAll}
                className={className}
                noLabel={noLabel}
                value={value}
                valuesList={column.valuesForSelectType || []}/>
        </Form.Field>
    ) : (
        <InputRegExp
            label={t(column.name)}
            name="defaultValue"
            value={value}
            reg={"^[+-]?([0-9]+([.,][0-9]*)?|[.][0-9]+)$"}
            onBlur={(e) => onBlurInput(e, {value})}
            onChange={onChange}
            isDisabled={disabledAll}
        />
    );
};
export default SpecificationsGate;
