import React from "react";
import {Form, Dropdown, Icon} from "semantic-ui-react";
import DriverEditModal from "../../../pages/drivers/components/DriverEditModal";
import { UserPermissionsContext } from "../../../Contexts";
import { toast } from "react-toastify";

class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.inputEl = React.createRef()
    }
    state = {
        items: [],
        searchQuery: '',
        isOpen: false,
        searchValue: '',
        isHandlePressEnter: false,
    }

    loadList = (afterCall, search = '', selectedId = '') => {
        if (this.props.isCase) {
            callApi("case/GetDriversForEdit", {search, selectedId: selectedId}).then(
                (items) =>
                    this.setState({
                        items: items.map(x => ({
                            ...x,
                            disabled: !x.isActive,
                            description: !x.isActive && !!x.items ? this.getDescriptionWithBlockedActivities(x.description, x.items) : x.description
                        })),
                    }, afterCall)
            );
        } else {
            callApi("CreateUpdateVisit/GetDriversForEdit", {
                selectedId: selectedId,
                search: search,
                platformId: this.context.permissionsData.currentPlatformId,
                activityIds: this.props.visit.activities || []
            }).then(
                (items) =>
                    this.setState({
                        items: items.map(x => ({
                            ...x,
                            disabled: !x.isActive,
                            description: !x.isActive && !!x.items ? this.getDescriptionWithBlockedActivities(x.description, x.items) : x.description
                        }))
                    }, afterCall)
            );
        }
    }

    componentDidMount = () => {
        if (!!this.props.value) {
            this.loadList(()=> {}, '', this.props.value);
        } else
        this.loadList();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {isHandlePressEnter} = this.state;
        if (prevProps.value !== this.props.value && !isHandlePressEnter && this.state.searchQuery.length !==1) {
            setTimeout(()=>this.setState({
                searchQuery: ''
            }),100)
        }
        if (prevState.searchQuery !== this.state.searchQuery && !!this.state.searchQuery) {
            const {onChange, name} = this.props;
            onChange && onChange('',{name, value: ''});
        }
    }


    getDescriptionWithBlockedActivities = (description, blockedActivities = []) => {
        const textAtBeginning = blockedActivities.length > 1 ? t("Activities blocked") : t("Activity blocked");
        const textAboutActivity = blockedActivities.join(', ')
        const textResult = `${textAtBeginning}: ${textAboutActivity}`
        return <div>{description}
            <p class={"dropdown-driver-decription"}>{blockedActivities.length ? textResult : ''}</p></div>
    }

    handleKeyPress = (e) => {

        if (e.keyCode == 13) {
            this.setState({
                isHandlePressEnter: true
            },() => this.loadList(()=> {}, this.state.searchQuery))

        }
    }

    toastBlockedActivities = (value) => {
        let valueItem = this.state.items.find(item => item.value === value);
        let blockedAllActivities = valueItem && valueItem.blockedAllActivities;
        let blockedActivities = (valueItem && valueItem.blockedActivities && valueItem.blockedActivities.length) ? valueItem.blockedActivities.map(a=>a.name) : null;
        if (blockedAllActivities || blockedActivities) {
            let string = `${t(blockedAllActivities ? "all_blocked_activities_toast" : "blocked_activities_toast")} ${(blockedActivities && !blockedAllActivities) ? blockedActivities.join(', ') : ''}`;
            toast.error(string);
            blockedAllActivities && this.setState({searchQuery: ''});
            return !blockedAllActivities;
        }
        return true;
    }

    handleOnChange = (e, {name, value}) => {
        const {onChange} = this.props;
        onChange && this.toastBlockedActivities(value) && onChange(e, {name, value});
    }

    handleSearchChange = (e, { name, searchQuery }) => {
        if (searchQuery.includes(' ')) {
            this.loadList(()=> {}, searchQuery.slice(0,-1))
        }
        return this.setState({searchQuery});
    }

    onFocus = () => {
        if (!!this.props.value) {
            this.loadList(()=> {}, '', this.props.value);
        } else
            this.loadList(() => {}, this.state.searchQuery);
    }

    onClick =() => {
        this.setState({
            isHandlePressEnter: false
        })
    }

    render() {
        const { name, value, isDisabled, onChange, reloadFields, valuesList, isClearable, isNotHistory, width, className } =  this.props;
        const { permissionsData } = this.context;
        const {searchQuery, isOpen} = this.state;
        //const inputEl = useRef(null);

        /*const items = (valuesList || [])
            .sort((a, b) => ((b.isActive - a.isActive) - 2 * (a.value === value)))
            .sort((a, b) => ( a.value === value ? -1 : b.value === value ? 1 : 0))
            .map(x =>
                ({
                    key: x.value,
                    value: x.value,
                    text: x.name,
                    disabled: !x.isActive,
                    description: !x.isActive && !!x.items ? getDescriptionWithBlockedActivities(x.description, x.items) : x.description
                }));*/
        let items = this.state.items;
        delete items.forEach(i => delete i.isActive);

        const search = (options, queryStr) => {
            const query = queryStr.replace(/\s+/g, ' ').trim();
            return items.filter(opt => new RegExp(query, "i").test(opt.text) || (opt.description && new RegExp(query, "i").test(opt.description))).slice(0, 20);
        };

        const onDriverSave = (e, { value }) => {
            this.loadList(()=>{
                if (reloadFields !== undefined)
                    reloadFields();
                onChange(e, { name, value });

            }, '', value);
        };

        return (
            <Form.Field>
                <label className={ isDisabled ? "label-disabled" : null}>{t(name)}</label>
                {

                        <div className="dropbox-button">
                            <Dropdown
                                key={'dropdown-edit-driver'}
                                ref={this.inputEl}
                                name={name}
                                onKeyDown={this.handleKeyPress}
                                value={value}
                                onChange={this.handleOnChange}
                                onFocus={this.onFocus}
                                onMouseDown={this.onClick}
                                searchQuery={searchQuery}
                                onSearchChange={this.handleSearchChange}
                                disabled={isDisabled || false}
                                search={search}
                                selection
                                //closeOnEscape={false}
                                selectOnBlur={false}
                                selectOnNavigation={false}
                                //wrapSelection={false}
                                clearable={isClearable}
                                className={className}
                                options={items}
                                onBlur={()=>this.setState({searchQuery: ''})}
                            />
                            <DriverEditModal
                                isNotHistory={isNotHistory}
                                id={null}
                                isDisabled={isDisabled || !permissionsData.permissions.includes("CreateDrivers")}
                                onSave={onDriverSave}>
                                <Icon name='plus' />
                            </DriverEditModal>
                        </div>
                }
            </Form.Field>

        );
    }
}

Edit.contextType = UserPermissionsContext;
export default Edit;
