import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Checkbox } from "semantic-ui-react";

const Edit = ({ value }) => {
    let taskStates = [];
    let taskTypes = [];
    let content = (<div className="facet-filter-for-visit-tasks-wrapper">
        <div>
            <Input className="" icon='search' placeholder={t('Search')} />
        </div>
        {taskTypes.map(x => {
            return (<div>
                <Checkbox label='' />
                <span>
                    {x.name} <b>({10})</b>
                </span>
            </div>);
        })}
        {taskStates.map(x => {
            return (<div>
                <Checkbox label='' />
                <Label color={x.stateColor}>
                    {x.name}
                </Label>
                <span>
                    <b>({10})</b>
                </span>
            </div>);
        })}
    </div>);

    return (<div>
        <Popup
            trigger={<Input className="facet-filter-for-visit-number" icon='search' placeholder={t("tasks")} />}
            content={content}
            on='click'
            hideOnScroll
            position='bottom left'
        />
    </div>);
};

export default Edit;
