import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Table } from "semantic-ui-react";

const Value = ({ value, name, valuesList }) => {
    valuesList = valuesList || [];
    return (
        <div>{value
            ? (valuesList.find(x => x.value === value) || {}).name || value
            : ""
        }</div>
    );
};
export default Value;
