/*
	Последняя редакция: 2021.09.22

[2021.09.22] К.Кузнец
- добавил сортировку Платформ
	.sort((a,b)=> a.name.localeCompare(b.name))

*/





import React from "react";
import { Dropdown } from "semantic-ui-react";
import { UserPermissionsContext } from "../Contexts";

export default class PlatformDropdown extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        value: this.context.permissionsData.currentPlatformName,
        items: []
    }

    onOpen = () => {
        if (!this.state.items || this.state.items.length === 0) {
            callApi("UserSettings/GetPlatforms")
                .then(arr => {
                    arr.length > 1 && arr.unshift({name: 'Все платформы', id: ''});
                    this.setState({ items: arr || [] });
                });
        }
    }

    onChange = (e, { value }) => {
        this.setState({ value: this.state.items.find(x => x.id === value).name });
        callApi("UserSettings/ChangePlatform", value)
            .then(() => {
                callApi("Identity/UserInfo").then(userData => {
                    this.context.loadPermissionsData();
                });
                if (this.props.onChange != undefined)
                    this.props.onChange(value);
            });
    }

    render() {
        return (
            <Dropdown
                text={this.state.value}
                icon='globe'
                labeled
                button
                className='icon'
                onOpen={this.onOpen}
            >
                <Dropdown.Menu>
                    {this.state.items.length > 1 && <>
                        <Dropdown.Item key={''} onClick={this.onChange} value={''}>{t('All platforms')}</Dropdown.Item>
                        <Dropdown.Divider />
                    </>}

                    {this.state.items
						.filter(x=>x.id !== '')
						.sort((a,b)=> a.name.localeCompare(b.name))
						.map(x => (
                        	<Dropdown.Item key={x.id} onClick={this.onChange} value={x.id}>{x.name}</Dropdown.Item>
                    	))}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
};