import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Form, TextArea } from "semantic-ui-react";

const Edit = ({ value, name, onChange, isDisabled, noLabel, className, rows=3 }) => {
    return (
        <Form.Field>
            {!noLabel ? <label className={ isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <TextArea rows={rows} className={className} autoHeight disabled={isDisabled || false} name={name} value={value || ""} onChange={onChange} />
        </Form.Field>
    );
};
export default Edit;