import React from "react";
import { Label, Icon } from "semantic-ui-react";

const CustomFieldList = ({ fields }) => {
    const icons = [
        { icon: "file", type: "Text" },
        { icon: "file", type: "Textarea" },
        { icon: "calendar", type: "Date" },
        { icon: "calendar", type: "DateTime" },
        { icon: "sort-numeric-asc", type: "Number" },
        { icon: "chevron-circle-down", type: "Select" },
        { icon: "chevron-circle-down", type: "MultiSelect" },
    ];
    return (
        <div>
            {fields.map((field, index) => {
                let iconName = (icons.find(i => i.type === field.type) || {}).icon;
                return (
                    <Label key={index} className={"label-margin" + (!field.isActive ? " opacity-half" : "")}>
                        <span className={"fa fa-" + iconName} /> {field.name}
                    </Label>
                );
            })}
        </div>
    );
};
export default CustomFieldList;