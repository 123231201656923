﻿export function saveAuth(token) {
    if (token)
        localStorage.setItem("Identity", JSON.stringify(token));
}

export function logout() {
    localStorage.removeItem("Identity");
}

export function getName() {
    let token = JSON.parse(localStorage.getItem("Identity"));

    if (token) {
        return token.userName;
    }

    return null;
}

export function getRole() {
    let token = JSON.parse(localStorage.getItem("Identity"));

    if (token) {
        return token.userRole;
    }

    return null;
}

export function isLogged() {
    let token = localStorage.getItem("Identity");
    if (token) {
        return true;
    } else {
        return false;
    }
}

export function getToken() {
    let item = JSON.parse(localStorage.getItem("Identity"));

    if (item) {
        return item.access_token;
    }
    return null;
}

export function setCurrentUserData(userData) {
    if (userData) {
        localStorage.setItem("VisitFilters", userData.visitFilters);
        localStorage.setItem("TaskFilters", userData.taskFilters);
        localStorage.setItem("VisitSort", userData.visitSort);
        localStorage.setItem("TaskSort", userData.taskSort);
    }
}