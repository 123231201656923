import React, {useState} from "react";
import {Modal, Button, Message, Confirm, Dimmer, Loader} from "semantic-ui-react";
import {toast} from "react-toastify";

export default function ControlledModal({
                                            children,
                                            size,
                                            className,
                                            title,
                                            buttonSize,
                                            buttonName,
                                            buttonClassName,
                                            saveButtonName,
                                            saveButtonDisabled,
                                            onOpen,
                                            onClose,
                                            closeOnEscape = true,
                                            closeOnDimmerClick = false,
                                            onSave,
                                            error,
                                            dontCloseAfterSave,
                                            isEditActivity,
                                            noCancelButton,
                                            isDisabled,
                                            isConfirmForCloseModal,
                                            textForCloseModal,
                                            textForIsDoubleModal,
                                            textForCloseModalCancelButton,
                                            defaultOpen = false,
                                            isScrolling = false,
                                            isLoading = false,
                                            saveButtonLoading = false,
                                        }) {
    const [open, setOpen] = useState(defaultOpen);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [isDouble, setIsDouble] = useState(false);

    const handleOpen = () => {
        if (onOpen !== undefined) {
            onOpen();
        }
        
        setOpen(true);
    };

    const closeModal = () => {
        if (onClose !== undefined) {
            onClose();
        }

        setOpen(false);
    };

    const handleClose = (e) => {
        if (isEditActivity) return;

        if (isConfirmForCloseModal) {
            setIsOpenConfirm(true);
            return;
        }
        
        closeModal();
    };

    const handleSave = (isDouble = false) => {
        if (isEditActivity) return;

        if (onSave !== undefined)
            onSave(true, isDouble).then(x => {
                if (x && x.isSameCompanies) setIsDouble(true);
                else if ((!x || !x.error) && !dontCloseAfterSave) {
                    closeModal();
                }
            });
        else {
            closeModal();
        }
    };

    const button = <Button className={`add-btn ${buttonClassName || ""}`} size={buttonSize} disabled={isDisabled}>
        {buttonName}
    </Button>;

    const renderLoader = () => {
        return (
            <div className="fluid-for-loader">
                <Dimmer active inverted>
                    <Loader size='large' inverted content={`${t('Loading')}...`}/>
                </Dimmer>
            </div>
        );
    };

    return (
        <Modal dimmer="blurring"
               trigger={button}
               open={open}
               onOpen={handleOpen}
               onClose={handleClose}
               closeIcon
               closeOnEscape={closeOnEscape}
               closeOnDimmerClick={closeOnDimmerClick}
               className={className}
               size={size}>
            <Modal.Header>{title}</Modal.Header>
            {isLoading && renderLoader()}
            <Modal.Content scrolling={isScrolling}>
                {children}
                {error ? <Message error content={error}/> : null}
            </Modal.Content>
            <Modal.Actions>
                {noCancelButton ? null :
                    <Button negative={!isEditActivity} content={t("CancelButton")} onClick={handleClose}/>}
                <Button
                    positive={!isEditActivity}
                    icon='check'
                    labelPosition='right'
                    content={saveButtonName}
                    disabled={saveButtonDisabled}
                    loading={saveButtonLoading}
                    onClick={()=>handleSave()}
                />
            </Modal.Actions>
            <Confirm dimmer="blurring"
                     open={isOpenConfirm}
                     onCancel={() => {
                         closeModal();
                         setIsOpenConfirm(false);
                     }}
                     onConfirm={() => {
                         setIsOpenConfirm(false);
                     }}
                     content={textForCloseModal ? textForCloseModal : t("Your changes won't save. Are you sure to close the window?")}
                     cancelButton={textForCloseModalCancelButton ? textForCloseModalCancelButton : t("Close without saving")}
                     confirmButton={t("CancelButton")}
            />
            <Confirm dimmer="blurring"
                     open={isDouble}
                     onCancel={() => {
                         setIsDouble(false);
                         toast.error(t('object_exists_in_system'));
                     }}
                     onConfirm={() => {
                         setIsDouble(false);
                         handleSave(true);
                         closeModal();
                     }}
                     content={t(textForIsDoubleModal)}
                     cancelButton={t('Cancel')}
                     confirmButton={t("AddButton")}
            />
        </Modal>
    );
}
