import React from "react";
import { Form, Dropdown, Segment, TextArea, Icon, Loader, Popup } from "semantic-ui-react";

export default class Edit extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        let { task } = this.props;
        this.state = {
            activity: task.activity,
            gates: []
        };
        this.getGates();
    }

    getGates = () => {
        let { task, visitDate } = this.props;
        callApi("gates/getAvailable", {
            activityId: task.activity,
            platformId: task.platform,
            visitDate
        }).then(gates => {
            this.setState({
                activity: task.activity,
                gates: gates.map(gate =>{
                    let descriptionNew = t('no tasks');
                    if (gate.firstTask) {
                        const startTime = gate.firstTask.start ? `${t('with')} ${gate.firstTask.start} ` : ''
                        descriptionNew = `${gate.firstTask.date} ${startTime}${t('before')} ${gate.firstTask.end.slice(0,5)} ${t(gate.firstTask.taskType)}`
                    }
                    return { value: gate.value, text: gate.value, description: descriptionNew }})
            });
        });
    };

    render() {
        let { name, value, onChange, isDisabled, task, isClearable, description} = this.props;
        let items = [];
        if (task.activity !== this.state.activity) {
            this.getGates();
        } else {
            items = this.state.gates;
        }
        if (items.length === 0 && value !== null && value !== undefined) {
            //value = parseInt(value);
            items.push({ value: value, text: value});
        }
        const search = (options, query) => {
            return items.filter(opt => new RegExp(query, "i").test(opt.text) || (opt.description && new RegExp(query, "i").test(opt.description)));
        };

        return (
            <Form.Field>
                <label className={ isDisabled ? "label-disabled" : null}>{t(name)}</label>
                <Dropdown
                    className={'gate-input'}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={isDisabled || false}
                    search={search}
                    clearable={isClearable}
                    selection
                    options={items} />
            </Form.Field>
        );
    }
}
