import React from "react";
import {Button, Icon, Loader, Message} from "semantic-ui-react";


export default class Surface extends React.Component {
    surfaceStates = {
        "none":1, 
        "created":2, 
        "connected":3,
        "viewDriver": 4
    }
    
    state = {
        id: "",
        number: "",
        state: this.surfaceStates.none,
        driverId: '',
        timer: 0
    };

    componentDidMount() {
        callApi('Surface/Create').then((data) => {
            this.setState({
               id: data.id,
               number: data.number,
               state: this.surfaceStates.created
            });
            
           setInterval( () => {
               if(this.state.id !== ''){
                   callApi('Surface/GetContent?id=' + this.state.id).then((content) => {
                       if(this.state.state === this.surfaceStates.created && content.connectedUserCount > 0){
                           this.setState({
                               state: this.surfaceStates.connected
                           });
                           return;
                       }

                       if(this.state.state === this.surfaceStates.connected && content.driverId !== null){
                           
                           var loadingTask = pdfjsLib.getDocument(window.location.origin + '/api/dataProcessingAgreementForDriver/Pdf?driverId=' + content.driverId);
                           
                           loadingTask.promise.then(function(pdf) {
                               let renderPage = (pdf, pageNumber)=>{
                                   pdf.getPage(pageNumber).then(function(page) {
                                       let canvas = document.createElement('canvas');
                                       let scale = 1.5;
                                       let viewport = page.getViewport({ scale: scale, });
                                       let pagesElement = document.getElementById('surface-pdf-pages');

                                       pagesElement.append(canvas);

                                       let context = canvas.getContext('2d');
                                       canvas.height = viewport.height;
                                       canvas.width = viewport.width;

                                       var renderContext = {
                                           canvasContext: context,
                                           viewport: viewport
                                       };
                                       page.render(renderContext);
                                       renderPage(pdf, pageNumber + 1);
                                   }, function (reason) {
                                       console.error(reason);
                                   });                                   
                               };
                               renderPage(pdf, 1);
                           });
                           
                           this.setState({
                               driverId: content.driverId,
                               state: this.surfaceStates.viewDriver,
                               timer: 10 * 60 * 1000
                           });
                           return;
                       }

                       if(this.state.state === this.surfaceStates.viewDriver && content.driverId === null){
                           document.getElementById('surface-pdf-pages').innerHTML = "";
                           this.setState({
                               driverId: '',
                               state: this.surfaceStates.connected,
                               timer: 0
                           });
                           return;
                       }
                   });
               }
            }, 3000);
        });
        setInterval( this.tick, 1000);
    }

    tick = () => {
        if(this.state.timer > 0){
            this.setState({timer: this.state.timer - 1000})
        }else{
            if(this.state.driverId !== null && this.state.driverId !== ''){
                callApi('Surface/Clear?id=' + this.state.id).then((content)=>{
                    this.setState({
                        driverId: ''
                    });
                });
            }
        }
    }

    accept = () => {
        callApi('Surface/Accept?driverId=' + this.state.driverId).then((content)=>{
            document.getElementById('surface-pdf-pages').innerHTML = "";
            this.setState({
                driverId: '',
                state: this.surfaceStates.connected
            });
        });
    }
    
    render (){
        const getTimer = (millisec) => {
            let seconds = (millisec / 1000).toFixed(0);
            let minutes = Math.floor(seconds / 60);
            let hours = "";
            if (minutes > 59) {
                hours = Math.floor(minutes / 60);
                hours = (hours >= 10) ? hours : "0" + hours;
                minutes = minutes - (hours * 60);
                minutes = (minutes >= 10) ? minutes : "0" + minutes;
            }

            seconds = Math.floor(seconds % 60);
            seconds = (seconds >= 10) ? seconds : "0" + seconds;
            if (hours !== "") {
                return hours + ":" + minutes + ":" + seconds;
            }
            return `${minutes}м. ${seconds}с.`;
        };

        switch (this.state.state) {
            case this.surfaceStates.none:
                return (
                    <div className="surface">
                        <div className="surface-text">
                            <Loader />
                            <span className="surface-text-header">Инициализация удаленного планшета</span>
                        </div>
                    </div>
                );
            case this.surfaceStates.created:
                return (
                    <div className="surface">
                        <div className="surface-text">
                            <span className="surface-text-header">Удалённый планшет</span>
                            <span className="margin-bottom-15">Настройте удалённый планшет в меню "Работа с удалённым планшетом".</span>
                            <span className="margin-bottom-15">Введите Номер планшета в настройках удалённого плашнета в окне "Работа с удалённым планшетом".</span>
                            <span><span>Номер планшета:</span> <span className="surface-text-number">{this.state.number}</span></span>
                        </div>
                    </div>
                );
            case this.surfaceStates.connected:
                return (
                    <div className="surface">
                        <div>
                            <img src="/assets/img/logo.png" title="Fm logistic" alt="Fm logistic" />
                        </div>
                        <div className="surface-number">
                            <span>Номер планшета:</span>
                            <span className="surface-text-number">{this.state.number}</span>
                        </div>
                    </div>
                );
/*
                        <iframe 
                            src={window.location.origin + '/api/dataProcessingAgreementForDriver/Pdf?driverId=' + this.state.driverId} 
                            frameborder="0" 
                            height="100%" 
                            width="100%" />

 */
            case this.surfaceStates.viewDriver:
                return (
                    <div className="surface-pdf">
                        <div id="surface-pdf-pages">
                        </div>                         
                        <div className="surface-confirm">
                            <Message warning>
                                <Icon name="info circle"/>
                                Дата и время согласия будет сохранено.
                                Форма автоматически скроется через <span>{getTimer(this.state.timer)}</span>
                            </Message>
                            <Button onClick={this.accept}>Согласен</Button>
                        </div>
                    </div>
                );
            default:
                return null;
        }        
    }
    
};
