import React, {useState} from "react";
import {Button, Input, List, Modal} from "semantic-ui-react";

const ConnectSurfaceModal = ({children}) => {
    let [open, setOpen] = useState(false);
    let [code, setCode] = useState(null);
    let [isConnect, setIsConnect] = useState(false);

    const handleOpen = () => {
        callApi('Surface/IsConnected').then((data)=> {
            if(data.connected){
                setIsConnect(data.connected);
                setCode(data.number);
            }
            setOpen(true);
        });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConnect = () => {
        callApi('Surface/Connect?number=' + code).then(data => {
            if(data.error === false)
                setIsConnect(true);
        });
    };

    const handleDisconnect = () => {
        callApi('Surface/Disconnect?number=' + code).then(data => {
            if(data.error === false){
                setCode(null);
                setIsConnect(false);
            }
        });
    };

    const currentLocation = window.location;

    return (
        <Modal
            closeIcon
            dimmer="blurring"
            open={open}
            closeOnEscape
            closeOnDimmerClick={false}
            trigger={children}
            onOpen={handleOpen}
            onClose={handleClose}
            size={'mini'}
        >
            <Modal.Header>{t("Работа с удалённым планшетом")}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    {
                        !isConnect
                            ?
                            <>
                                <span className="margin-bottom-5">
                                    Подключите удалённый планшет для вывода формы согласия с обработкой персональных данных, следуя шагам:
                                </span>
                                <List bulleted>
                                    <List.Item className="margin-left-15">Откройте на планшете в браузере <b>{currentLocation.origin + '/surface'}</b></List.Item>
                                    <List.Item className="margin-left-15">Введите номер планшета для подключения</List.Item>
                                </List>
                                <div className="margin-top-20">
                                    <Input
                                        value={code}
                                        onChange={(e, {value}) => setCode(value)}
                                    />
                                    <Button primary className="margin-left-15"
                                            onClick={handleConnect}>Подключить</Button>
                                </div>
                            </>
                            :
                            <>
                                <span className="margin-bottom-15">
                                    Ваш удалённый планшет подключен. Для показа формы согласия на планшете используйте:
                                </span>
                                <List bulleted>
                                    <List.Item className="margin-left-15">Форму редактирования водителя из справочника <b>Водители</b></List.Item>
                                    <List.Item className="margin-left-15">Форму редактирования визита</List.Item>
                                </List>
                                <Button color='orange'
                                        onClick={handleDisconnect}>Отключить планшет {code}</Button>
                            </>
                    }

                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>{t("CloseButton")}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ConnectSurfaceModal;
