import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { hot } from "react-hot-loader/root";
import PrivateRoute from "./utils/PrivateRoute";

import Login from "./pages/login/Login";
import Users from "./pages/users/Users";
import Visits from "./pages/visits/Visits";
import Tasks from "./pages/tasks/Tasks";
import SecurityCheck from "./pages/securityCheck/SecurityCheck";
import SecurityService from "./pages/securityService/SecurityService";
import FieldMatrix from "./pages/fieldMatrix/FieldMatrix";
import Drivers from "./pages/drivers/Drivers";
import Activities from "./pages/activities/Activities";
import Roles from "./pages/roles/Roles";
import Transports from "./pages/transports/Transports";
import DocumentTypes from "./pages/documentTypes/DocumentTypes";
import Platforms from "./pages/platforms/Platforms";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import GlobalSettings from "./pages/globalSettings/GlobalSettings";
import Companies from "./pages/companies/Companies";
import TransportTypes from "./pages/transportTypes/TransportTypes";
import GateCharacteristics from "./pages/gateCharacteristics/GateCharacteristics";

import * as AuthHelper from "./utils/authHelper";
import * as StateHelper from "./utils/stateHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-datepicker/dist/react-datepicker.min.css";
import Header from "./layout/Header";
import { UserPermissionsContext } from "./Contexts";
import TruckTypes from "./pages/truckType/TruckType";
import Surface from "./pages/surface/Surface";
import CreateVisitFromBJ from "./pages/createVisitFromBJ/CreateVisitFromBJ";
import Requests from "./pages/requests/requestee/Requests";
import OrdersModule from "./pages/ordersModule/OrdersModule";
import AccessToInvitations from "./pages/accessToInvitations/AccessToInvitations";

class App extends React.Component {
    state = {}

    constructor(props) {
        super(props);
        this.loadPermissionsData();
    }

    loadPermissionsData = () => {
        return callApi("Identity/UserInfo").then(userInfo => {
            AuthHelper.setCurrentUserData(userInfo);
            this.setState(userInfo);
        });
    }

    render() {
        const userInfo = this.state.permissionsData;
        if (!userInfo) return null;

        let nextPage = "/login";
        if (userInfo.isLogged) {
            const pages = StateHelper.getPages();
            for (let i = 0; i < pages.length; i++) {
                if (userInfo.permissions.includes(pages[i].permission)) {
                    nextPage = "/" + pages[i].name;
                    break;
                }
            }
        }

        return (
            <Router>
                <article>
                    <UserPermissionsContext.Provider value={{
                        permissionsData: userInfo,
                        loadPermissionsData: this.loadPermissionsData
                    }} >
                        <Header />
                        <Switch>
                            <PrivateRoute exact path="/" component={() => <Redirect to={nextPage} />} />
                            <PrivateRoute exact  path="/visits" component={(props) => <Visits {...props}/>} />
                            <PrivateRoute path="/visits/:id" component={(props) => <Visits {...props}/>} />
                            <PrivateRoute path="/tasks" component={() => <Tasks />} />
                            <PrivateRoute path="/ordersModule" component={(props) => <OrdersModule {...props}/>} />

                            {/* Предыдущий способ указания вызывал unmounting родительского компонента при изменениях в дочернем */}
                            {/* Другие не переделывал, чтобы ничего не поломать */}
                            <PrivateRoute path="/invitations" component={Requests} />
                            <PrivateRoute path="/securityCheck" component={() => <SecurityCheck />} />
                            <PrivateRoute path="/securityService" component={() => <SecurityService />} />
                            <PrivateRoute path="/fieldMatrix" component={() => <FieldMatrix />} />
                            <PrivateRoute path="/users" component={() => <Users />} />
                            <PrivateRoute path="/drivers" component={() => <Drivers />} />
                            <PrivateRoute path="/activities" component={() => <Activities />} />
                            <PrivateRoute path="/roles" component={() => <Roles />} />
                            <PrivateRoute path="/transports" component={() => <Transports />} />
                            <PrivateRoute path="/documentTypes" component={() => <DocumentTypes />} />
                            <PrivateRoute path="/trailerTypes" component={() => <TransportTypes />} />
                            <PrivateRoute path="/truckTypes" component={() => <TruckTypes />} />
                            <PrivateRoute path="/platforms" component={() => <Platforms />} />
                            <PrivateRoute path="/accessToInvitations" component={() => <AccessToInvitations />} />
                            <PrivateRoute path="/globalSettings" component={() => <GlobalSettings />} />
                            <PrivateRoute path="/companies" component={() => <Companies />} />
                            <PrivateRoute path="/gateCharacteristics" component={() => <GateCharacteristics/>} />

                            <Route path="/createInvitation" component={() => <Login viewCreateInvitation />} />
                            <Route path="/createVisitFromBJ" component={CreateVisitFromBJ} />
                            <Route path="/surface" component={Surface} />
                            <Route path="/resetPassword/:resetToken" component={(props) => <ResetPassword {...props} />} />
                            <Route path="/login" component={() => <Login />} />
                        </Switch>
                    </UserPermissionsContext.Provider>
                </article>
                <ToastContainer
                    closeOnClick={false}
                    draggable={false}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={20000}
                    hideProgressBar={true}
                />
            </Router>
        );
    }
}
export default hot(App);
