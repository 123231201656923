import React from "react";
import {Grid, Segment} from "semantic-ui-react";
import TextEdit from "../../../../shared/columns/text/Edit";
import DatePickerLabel from '../../../../shared/columns/date/Edit'
import DateTimePickerLabel from '../../../../shared/columns/datetime/Edit'

export default function RouteInfo ({isView, formData, handleChange}) {
    return (<Segment>
        <Grid className="margin-bottom-10">
            <Grid.Row className="padding-bottom-0">
                <Grid.Column width={8}>
                    <TextEdit
                        isDisabled={isView}
                        name={t('Trip number')}
                        value={formData.tripNumber}
                        onChange={(e, { name, value }) =>
                            handleChange("tripNumber", value)
                        }
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column>
                    <TextEdit
                        isDisabled={isView}
                        name={`${t('Loading city')} *`}
                        value={formData.loadingCity}
                        onChange={(e, { name, value }) =>
                            handleChange("loadingCity", value)
                        }
                    />
                </Grid.Column>
                <Grid.Column>
                    <TextEdit
                        isDisabled={isView}
                        name={`${t('City of unloading')} *`}
                        value={formData.unloadingCity}
                        onChange={(e, { name, value }) =>
                            handleChange("unloadingCity", value)
                        }
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column className="router-into-datepicker">
                    <DateTimePickerLabel
                        name={`${t('date of download')} *`}
                        popperPlacement="top-end"
                        value={formData.loadingDate}
                        isDisabled={isView}
                        onChange={(e, { name, value }) =>
                            handleChange("loadingDate", value)}
                    />
                </Grid.Column>
                <Grid.Column className="router-into-datepicker">
                    <DateTimePickerLabel
                        name={`${t('Unload Date')} *`}
                        popperPlacement="top-end"
                        value={formData.unloadingDate}
                        isDisabled={isView}
                        onChange={(e, { name, value }) =>
                            handleChange("unloadingDate", value)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>);
}
