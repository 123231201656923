import React, { useContext } from "react";
import { Modal, Button, Grid, Label, Select, Tab, Input, Icon, Form } from "semantic-ui-react";
import * as StateHelper from "../../../../../utils/stateHelper";
import { UserPermissionsContext } from "../../../../../Contexts";

import Task from "./Task";

export default function Tasks(
    { driverId,
        tasks,
        onFieldChange,
        onCustomFieldChange,
        onAction,
        addTask,
        taskId,
        getIsLoading,
        visitDate,
        isVisitChange,
        onSaveVisit,
        onTaskDocumentsChange,
        loadData,
        ordersOpen,
        setNewOrders
    }) {
    const { permissionsData } = useContext(UserPermissionsContext);
    let taskTabs = [];

    for (let i = 0; i < tasks.length; i++) {
        let task = tasks[i];

        const stateColors = StateHelper.taskStateColors();

        let state = task.state;
        taskTabs.push({
            menuItem: { key: i, content: `${task.number} (${t(task.type)}/${t(task.state)})` }, render: () => {
                return (<Task
                    ordersOpen={ordersOpen}
                    driverId={driverId}
                    state={state}
                    visitDate={visitDate}
                    isVisitChange={isVisitChange}
                    task={task}
                    getIsLoading={getIsLoading}
                    stateColors={stateColors}
                    onCustomFieldChange={onCustomFieldChange}
                    onFieldChange={onFieldChange}
                    onAction={onAction}
                    onTaskDocumentsChange={onTaskDocumentsChange}
                    onSaveVisit={onSaveVisit}
                    loadData={loadData}
                    setNewOrders={setNewOrders}
                /> );
            }

        });
    }

    if (permissionsData.permissions.includes("CreateTasks"))
        taskTabs.push({
            menuItem: {
                className: "add-button-tab-item",
                key: "new",
                content: <Button className="add-task-button" onClick={addTask} size='small'>{t("Add task")}</Button>
            }
        });

    let activeIndex = tasks.findIndex(x => x.id === taskId);
    activeIndex = (activeIndex > -1 || tasks.length === 0) ? activeIndex : 0;

    return (<div>
        <Tab
            key={taskId ? activeIndex : undefined}
            className="all-tabs"
            panes={taskTabs}
            defaultActiveIndex={activeIndex}
            menu={{ fluid: true, vertical: true }}
            menuPosition='left'
        />
    </div>);
};
