import React, {useRef, useState} from "react";
import {Button, Form} from "semantic-ui-react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";

export function EditableField(props) {

    const {currentValue, onSave, requestId, noValueText} = props;

    const [isEdit, setIsEdit] = useState(false);

    const newValueRef = useRef(currentValue);

    const save = () => {
        onSave(requestId, newValueRef.current.value, () => setIsEdit(false));
    };

    return (
        <Form>
            <Form.Field>
                <Form.Group style={{ display:"flex", flexDirection:"row", alignItems:"center" }}>
                    {!isEdit &&
                        <>
                            <Form.Field width={13}>
                                {currentValue ? currentValue : (noValueText ? noValueText : t("noValue"))}
                            </Form.Field>
                            
                            <Button
                                width={3}
                                style={{marginLeft: "3px"}}
                                icon='edit'
                                onClick={() => setIsEdit(true)}
                            />
                        </>
                    }

                    {isEdit === true &&
                        <>
                            <input width={13} ref={newValueRef} defaultValue={currentValue}/>

                            <Button
                                style={{marginLeft: "3px"}}
                                width={1}
                                icon='check'
                                onClick={() => save()}
                            />

                            <Button
                                width={1}
                                icon='remove'
                                onClick={() => setIsEdit(false)}
                            />
                        </>
                    }
                </Form.Group>
            </Form.Field>

        </Form>
    );
}