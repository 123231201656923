export const securityCheckModel = {
    driverId: null,
    driverState: null,
    loadingCity: null,
    loadingDate: null,
    tripNumber: null,
    unloadingCity: null,
    unloadingDate: null,
    transportCompanyId: null,
    transportCompanyInn: null,
    transportCompanySearchName: null,
    isAttracted: false,
    attractedCompanyAtiCode: null,
    subcontractorPhoneNumber: null,
    subcontractorInn: null,
    transportId: null,
    isFtl: true,
    clientCompany: null,
    isExpensiveCargo: false,
    comment: null,
    documents: null,
    truckId: null,
    truckState: null,
    truckTypeId: null,
    trailerId: null,
    trailerState: null,
    trailerTypeId: null,
    driverNotes: null,
    trailerNotes: null,
    truckNotes: null,
    driverDocuments: null,
    truckDocuments: null,
    trailerDocuments: null,
    snils: null,
    driverInn: null
};

export const MODAL_NAME = {
    PLATFORM: "platformModal",
    SCHEDULE: "sheduleModal"
};