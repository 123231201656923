import "./login.css";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Button, Grid, Popup, Icon, Modal, Label, Select, Input, Tab, Dropdown } from "semantic-ui-react";
import * as AuthHelper from "../../utils/authHelper";
import * as StateHelper from "../../utils/stateHelper";
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import RequestsModal from "../requests/requester/RequestsModal";
import { UserPermissionsContext } from "../../Contexts";

export default class Login extends React.Component {
    static contextType = UserPermissionsContext;
    
    constructor(props) {
        super(props);
    }
    
    state = {
        login: "",
        password: "",
        error: "",
        modalOpen: false
    };

    handleChangeEmail = (e) => {
        this.setState({ login: e.target.value });
    }

    handleChangePassword = (e) => {
        this.setState({ password: e.target.value });
    }

    responseGoogle = (response) => {
        console.log(response);
    }

    login = (history) => {
        const pages = StateHelper.getPages();

        callApi("Identity/Login",
            {
                login: this.state.login,
                password: this.state.password
            })
            .then(resp => {
                if (resp) {
                    if (!resp.error) {
                        AuthHelper.saveAuth(resp);
                        this.context.loadPermissionsData().then(() => {
                            if(window.location.search.indexOf('?backUrl') == 0){
                                history.push(window.location.search.replace('?backUrl=', ''));
                            }else{
                                history.push("/");
                            }
                        });
                    }
                    else {
                        this.setState({ error: resp.error });
                    }
                }
            })
            .catch((ex) => {
                this.setState({ error: ex.message });
            });
    }

    toggleModalForm = () => {
        this.setState({
            modalOpen: true
        });
    }

    handleChangeLang = (e, value) => {
        localStorage.setItem("currentCulture", value.value);
        window.location = "/login";
    }

    onKeyDown = (e, history) => {
        if (e.keyCode == 13)
            this.login(history);
    }

    render() {
        const langOptions = [
            {
                text: "Русский",
                value: "ru",
                flag: "ru"
            },
            {
                text: "English",
                value: "en",
                flag: "gb"
            }
        ];
        const currentLangValue = localStorage.getItem("currentCulture");
        return (
            <>
                <div className="centered-div">
                    <ForgotPasswordModal open={this.state.modalOpen} toggleModal={() => this.setState({ modalOpen: !this.state.modalOpen })} />
                    <Grid className="login-form-wrapper">
                        <Grid.Row>
                            <Grid.Column className="login-form-description">
                                <div>
                                    <img src="/assets/img/logo.png" title="Fm logistic" alt="Fm logistic" />
                                </div>
                                <div>
                                    <p>{t("login_welcome")}</p>
                                    <p>{t("login_support")}: <a href="tel:84957373955">+7(495)737-39-55</a></p>
                                </div>
                            </Grid.Column>
                            <Route render={({ history }) => (
                                <Grid.Column className="login-form-input-wrapper">
                                    <div className="margin-bottom-10">
                                        <Input onChange={this.handleChangeEmail} icon='user' iconPosition='left' placeholder='Email' /></div>
                                    <div className="margin-bottom-10">
                                        <Input type="password" onChange={this.handleChangePassword} onKeyDown={(e) => this.onKeyDown(e, history)} icon='lock' iconPosition='left' placeholder={t("password_placeholder")} />
                                    </div>
                                    <div>
                                        <a className="login-form-forgot-pass" onClick={() => this.toggleModalForm()}>
                                            {t("forgot_pass")}
                                        </a>
                                        <Button floated='right' onClick={() => { this.login(history); }}>{t("enter_btn")}</Button>
                                    </div>
                                    <div className="error">
                                        {this.state.error}
                                    </div>
                                </Grid.Column>
                            )} />
                        </Grid.Row>
                    </Grid>
                </div>
                <div className="language-switcher">
                    <i className={`${langOptions[(currentLangValue === "ru" ? 0 : 1)].flag} flag`}></i>
                    <span>{t("Language")}:</span> <Dropdown inline
                        options={langOptions}
                        onChange={this.handleChangeLang}
                        defaultValue={langOptions[(currentLangValue === "ru" ? 0 : 1)].value}
                    ></Dropdown>
                </div>
                <RequestsModal defaultOpen={this.props.viewCreateInvitation} />
            </>
        );
    }
};
