import React from "react";
import {Button, Table, Checkbox, Icon, Label, Container, Grid} from "semantic-ui-react";
import DocumentTypeFormModal from "./components/DocumentTypeEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function DocumentTypes() {

    const newModal = (load) =>
        <DocumentTypeFormModal
            title={t('Adding a document type')}
            actionButton={t('Add document type')}
            id={null}
            onClose={load}>
            <Icon name='plus'/>{` ${t('Add document type')}`}
        </DocumentTypeFormModal>;
    const headerRow = <Table.Row>
        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
        <Table.HeaderCell>{t('For entity')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Has an expiring date')}</Table.HeaderCell>
        <Table.HeaderCell/>
    </Table.Row>;
    return (
        <LazyLoadDictionary
            apiGetList="DocumentTypes/GetList"
            name={t("documentTypes")}
            className='container-margin-top-bottom'
            newModal={newModal}
            headerRow={headerRow}>
            {(documentTypes, load) =>
                documentTypes.map(documentType => {
                    return (
                        <Table.Row key={documentType.id}>
                            <Table.Cell>{documentType.name}</Table.Cell>
                            <Table.Cell>{documentType.forType}</Table.Cell>
                            <Table.Cell>{documentType.isForExpiringDate ? t('Yes') : t('No')}</Table.Cell>
                            <Table.Cell textAlign='center'>
                                <DocumentTypeFormModal
                                    size="small"
                                    title={t('Editing a document type')}
                                    actionButton={t('Save changes')}
                                    id={documentType.id}
                                    onClose={load}>
                                    <Icon name='edit'/>{` ${t('Edit')}`}
                                </DocumentTypeFormModal>
                            </Table.Cell>
                        </Table.Row>
                    );
                })
            }
        </LazyLoadDictionary>
    );
}
