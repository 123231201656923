import React from "react";
import EditCreateActivityModal from "./components/ActivityEditModal";
import {Grid, Icon, Label, Table, Container, Checkbox} from "semantic-ui-react";
import CustomFieldsList from "../../shared/customFieldsEditor/CustomFieldsList";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function Activities() {

    const newModal = (load) =>
        <EditCreateActivityModal
            title={t('Activity Creation')}
            actionButton={t('Create Activity')}
            id={null}
            onClose={load}>
            <Icon name='plus'/>{` ${t('Add Activity')}`}
        </EditCreateActivityModal>;

    const headerRow =
        <Table.Row>
            <Table.HeaderCell>
                {t('Name')}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t('client')}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t('platforms')}
            </Table.HeaderCell>
        {/*    <Table.HeaderCell width={2}>
                {t('Maximum number of tasks per time slot')}
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>
                {t('Green corridor')}
            </Table.HeaderCell>*/}
            <Table.HeaderCell>
                {t('Custom visit fields')}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t('Custom task fields')}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t('Custom order fields')}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t('Custom ordersModule fields')}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>;

    return (
        <LazyLoadDictionary
            apiGetList="activities/getList"
            name={t("activities")}
            className='container-margin-top-bottom'
            newModal={newModal}
            headerRow={headerRow}>
            {(activities, load) =>
                activities.map((activity, index) =>
                    <Table.Row key={index}>
                                <Table.Cell>
                                    {activity.name}
                                </Table.Cell>
                                <Table.Cell>
                                    {activity.clientName}
                                </Table.Cell>
                                <Table.Cell>
                                    {(activity.platformNames || []).map(name => (
                                        <Label>{name}</Label>
                                    ))}
                                </Table.Cell>
                             {/*   <Table.Cell>
                                    {activity.maxCountTaskOnTimeSlot }
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    <Checkbox disabled checked={activity.isGreenCorridor}/>
                                </Table.Cell>*/}
                                <Table.Cell>
                                    <CustomFieldsList fields={activity.customFieldsForVisit}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <CustomFieldsList fields={activity.customFieldsForTask}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <CustomFieldsList fields={activity.customFieldsForOrder}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <CustomFieldsList fields={activity.customFieldsForOrdersModule}/>
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    <EditCreateActivityModal
                                        size="small"
                                        title={t('Activity change')}
                                        actionButton={t('SaveButton')}
                                        id={activity.id}
                                        onClose={load}>
                                        <Icon name='edit'/>{` ${t('Edit')}`}
                                    </EditCreateActivityModal>
                                </Table.Cell>
                            </Table.Row>)
                        }
        </LazyLoadDictionary>
    );
}
