import React from "react";
import {UserPermissionsContext} from "../../../Contexts";
import {Button, Dimmer, Loader, Modal, Segment, Tab, Icon} from "semantic-ui-react";
import SecurityCheckInfo from "../../securityCheck/components/SecurityCheckInfo";
import SecurityDecisionModal from "./SecurityDecisionModal";
import History from "../../../shared/History";
import HistoryByDriver from "./HistoryByDriver";
import {securityCheckModel} from "../../../const";

const getStatusMap = ({driverState, trailerState, truckState}) => {
    const getIcon = (status) => {
        switch (status) {
            case 0:
            case 1:
                return <Icon name="minus"/>;
            case 2:
                return <Icon name="check" color="green"/>;
            case 3:
                return <Icon name="close" color="red"/>;

        }
    };

    return <div>
        <span>Водитель {getIcon(driverState)}</span>
        <span className="pl-5">Транспортное средство {getIcon(truckState)}</span>
        <span className="pl-5">Прицеп {getIcon(trailerState)}</span>
    </div>;
};

const initState = {
    statuses: {},
    modalOpen: false,
    formData: {...securityCheckModel},
    state: null,
    columns: [],
    isConfirmSuccess: false,
    isReturnToWork: false,
    closeOnEscape: true,
    closeOnDimmerClick: false,
    driverHistories: [],
    number: null,
    isLoadedData: {
        driverInfo: false,
        truckInfo: false,
        trailerInfo: false
    }

};

export default class SecurityServiceModal extends React.Component {
    static contextType = UserPermissionsContext;
    initScrollContainer = React.createRef();

    state = {
        ...initState
    };

    changeFormData = (name = "", value) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    onClose = () => {
        this.props.runTimer && this.props.runTimer();
        this.props.onLoad && this.props.onLoad();
        this.setState({...initState});
    };

    closeAnyway = (field) => {
        this.setState({[field]: false});
    };

    onOpen = () => {
        if (this.props.stopTimer !== undefined)
            this.props.stopTimer();
        this.load(this.props.caseId)
            .then(() => {
                this.loadFields().then(
                    () => {
                        this.setState({modalOpen: true});
                    }
                );
            });
    };

    load = (caseId) => {
        return callApi("case/GetForSecurityService", caseId).then(result => {
            let form = {};

            Object.keys(securityCheckModel).forEach(key => {
                form = {
                    ...form,
                    [key]: result[key]
                };
            });


            this.setState(prevState => ({
                formData: {
                    ...prevState.formData,
                    ...form
                },
                statuses: {
                    driverState: result.driverState,
                    trailerState: result.trailerState,
                    truckState: result.truckState,
                },
                history: result.histories,
                number: result.number,
                driverHistories: result.driverHistories,
                isResponsible: result.isResponsible,
                state: result.state || null,
                isView: true
            }));
        });
    };

    loadFields = () => {
        const currentPlatformId = this.context.permissionsData.currentPlatformId;
        return callApi("case/GetCaseColumns", {
            platformId: currentPlatformId
        }).then(caseColumnsData => {
            this.setState({columns: caseColumnsData.columns});
        });
    };

    inWork = () => {
        const {caseId} = this.props;

        return callApi("case/InWork", caseId).then(data => {
            if (data.error !== "") {
                this.setState({
                    errorText: data.error
                });
            }
            this.load(caseId);
            return data;
        });
    };

    inSuccess = () => {
        this.setState({isConfirmSuccess: true});
    };

    inReturnToWork = () => {
        this.setState({isReturnToWork: true});
    };

    inReturn = () => {

    };

    inReject = () => {
        this.setState({isConfirmReject: true});
    };

    confirmReject = () => {

    };

    getActions = () => {
        const {state, columns, isResponsible, formData} = this.state;

        const {
            caseId
        } = this.props;

        if (state === "New" || (!isResponsible && state === "InWork")) {
            return (<Button positive content={t('Take over')} onClick={this.inWork}/>);
        } else if (state === "InWork") {
            return (
                <>
                    <SecurityDecisionModal
                        isConfirm
                        title={t('the confirmation')}
                        caseId={caseId}
                        driverId={formData.driverId}
                        columns={columns}
                        onClose={this.onClose}
                    >
                        <Button positive content={`${t('Ok')} (${t('Confirm')})`} onClick={this.inSuccess}/>
                    </SecurityDecisionModal>

                    {formData.driverState !== 2 || formData.truckState !== 2 || formData.trailerState !== 2 ? <SecurityDecisionModal
                        isReject
                        title={t('Rejection reason')}
                        caseId={caseId}
                        driverId={formData.driverId}
                        columns={columns}
                        onClose={this.onClose}
                        driverState={formData.driverState}
                        truckState={formData.truckState}
                        trailerState={formData.trailerState}
                    >
                        <Button negative content={t('Rejected')} onClick={this.inReject}/>
                    </SecurityDecisionModal>: ''}

                    {formData.driverState !== 2 || formData.truckState !== 2 || formData.trailerState !== 2 ?<SecurityDecisionModal
                        isReturn
                        title={t('Reason for return')}
                        caseId={caseId}
                        columns={columns}
                        onClose={this.onClose}
                        driverState={formData.driverState}
                        truckState={formData.truckState}
                        trailerState={formData.trailerState}
                    >
                        <Button color="yellow" content={t('Return to initiator')} onClick={this.inReturn}/>
                    </SecurityDecisionModal>: ''}
                </>
            );
        } else if (state === "ProcessingCompleted") {
            return (
                <SecurityDecisionModal
                    isReturnToWork
                    title={t('the return to work')}
                    caseId={caseId}
                    driverId={formData.driverId}
                    columns={columns}
                    onClose={this.onClose}
                    driverState={formData.driverState}
                    truckState={formData.truckState}
                    trailerState={formData.trailerState}
                >
                    <Button positive content={t('Return to work')} onClick={this.inReturnToWork}/>
                </SecurityDecisionModal>
            );
        }
    };

    setIsLoaded = ({name, isLoaded}) => {
        const {isLoadedData} = this.state;

        if (!name) {
            this.setState(prevState => {

                prevState.isLoadedData.driverInfo = false;
                prevState.isLoadedData.truckInfo = false;
                prevState.isLoadedData.trailerInfo = false;

                return prevState;
            });

            return;
        }

        isLoadedData[name] = isLoaded;

        this.setState({
            isLoadedData
        });
    };

    tabChange = () => {
        if (this.initScrollContainer.current) {
            this.setState({tabHeight: `${this.initScrollContainer.current.getBoundingClientRect().height}px`});
        }
    };

    render() {
        const {caseId, children, title} = this.props;
        const {modalOpen, columns, formData, isView, history, closeOnEscape, closeOnDimmerClick, driverHistories, number, state, isLoadedData, statuses} = this.state;

        return (<Modal dimmer="blurring"
                       trigger={children}
                       open={modalOpen}
                       size="large"
                       onOpen={this.onOpen}
                       onClose={this.onClose}
                       closeOnEscape={closeOnEscape}
                       closeOnDimmerClick={closeOnDimmerClick}
                       closeIcon>
            <Modal.Header>
                <div className="modal-headers-status">
                    <div>
                        {`${t('View Case')} №${number} (${t(state)})`}
                    </div>
                    <div>
                        {getStatusMap(statuses)}
                    </div>
                </div>
            </Modal.Header>
            <Modal.Content scrolling>
                <Tab onTabChange={this.tabChange} panes={[
                    {
                        menuItem: t('Information'),
                        key: "1",
                        render: () => {
                            return <Tab.Pane>
                                <div ref={this.initScrollContainer} className="init-scroll">
                                    {isLoadedData.driverInfo === false || (isLoadedData.truckInfo === false || isLoadedData.trailerInfo === false) ?
                                        <div className="fluid-for-loader">
                                            <Dimmer active inverted>
                                                <Loader size='large' inverted content={`${t('Loading')}...`}/>
                                            </Dimmer>
                                        </div>
                                        : null
                                    }
                                    <SecurityCheckInfo
                                        setIsLoaded={this.setIsLoaded}
                                        columns={columns}
                                        isView={isView}
                                        formData={formData}
                                        isEntityStateEdit={state === "InWork"}
                                        changeFormData={this.changeFormData}
                                    />
                                </div>
                            </Tab.Pane>;
                        }
                    },
                    {
                        menuItem: t('Case history'),
                        key: "2",
                        render: () => {
                            return (<Tab.Pane>
                                <div style={{height: this.state.tabHeight || ""}} className="init-scroll fixed-height">
                                    <Segment>
                                        <History items={history} isLocalDate/>
                                    </Segment>
                                </div>
                            </Tab.Pane>);
                        }
                    },
                    {
                        menuItem: t('Driver story'),
                        key: "2",
                        render: () => {
                            return <Tab.Pane>
                                <div style={{height: this.state.tabHeight || ""}} className="init-scroll fixed-height">
                                    <HistoryByDriver items={driverHistories}/></div>
                            </Tab.Pane>;
                        }
                    }]}/>
            </Modal.Content>
            <Modal.Actions>
                {this.getActions()}
            </Modal.Actions>

        </Modal>);
    }
}
