/*
Последняя редакция: 2021.08.12


[2021.08.12]
- добавил параметры на вход функции VisitGridHeader
	popup_links_arr
	popup_onopen
- реализовал формирование перечня ссылок из параметра
	popup_links_arr
- добавил обработку события открытия перечня ссылок путем вызова
	popup_onopen

*/





import React from "react";
import { Grid, Button, Popup, Select, Tab, Input, Icon } from "semantic-ui-react";
import Search from "../../Search";
import PlatformDropdown from "../../PlatformDropdown";

export const VisitGridHeader = ({
	createButton,
	searchValue,
	searchOnChange,
	counter,
	loadColumns,
	clearFilter,
	isFilters,
	// [2021.08.12] К.Кузнец, добавил параметры
	popup_links_arr,
	popup_onopen
}) => {


	// [2021.08.12] К.Кузнец, собственный перечень ссылок,
	// формирую HTML из параметра popup_links_arr
	/* БЫЛО
		<div className="change-view-variant-wrapper">
			<div><a href="#"><Icon name='list ul' /> {t("list")}</a></div>
			<div><a href="#"><Icon name='calendar' /> {t("workload")}</a></div>
		</div>
	*/
	// https://medium.com/javascript-in-plain-english/how-to-loop-through-arrays-in-react-3eaa8a14445
	// https://sentry.io/answers/unique-key-prop/
	// key="uniqueId1"
	// СХЕМА ОБЪЕКТА - [{href:'#', target:'_blank', content:'Настройка'}..{}]
	var popup_links_html
	=
	popup_links_arr
	.map((x,i)=>
		<div key={x.key}><a href={x.href} target={x.target}><Icon name='list ul'/>{x.content}</a></div>
	);



    return (
        <Grid className="visits-header-panel">
            <Grid.Row>
                <Grid.Column width={10}>
                    {createButton}
                    <Search value={searchValue} onChange={searchOnChange} />
                    <span className="records-counter"><b>{counter}</b> {t("records")}</span>
                </Grid.Column>
                <Grid.Column width={6} className="visit-right-elements">
                    <PlatformDropdown onChange={loadColumns} />
                    <Button color={isFilters ? 'orange' : null} onClick={clearFilter}>
                        {t("reset_filters")}
                    </Button>
                    <Popup
                        trigger={<Button icon='bars' />}
                        content={
							<div className="change-view-variant-wrapper">
								{popup_links_html}
							</div>
						}
                        on='click'
                        position='bottom right'
						onOpen={popup_onopen}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );

};
