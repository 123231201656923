import React from "react";
import { Button, Popup, Checkbox, Label, Icon, Form, Input } from "semantic-ui-react";

export default class Facet extends React.Component {
    state = {items:[]}

    setFilter = (e, {value}) => {
        this.setState({filter: value.toLowerCase()});
    }

    clearFilter = () => {
        this.setState({ filter: "" });
    }

    loadList = (search = '') => {
        callApi("drivers/get", {search: search}).then(
            (items) =>
                this.setState({
                    items,
                    isOpen: true
                })
        );
    }

    handleOpen = () => {
        this.loadList();
    }

    handleKeyPress = (e) => {
        if (e.keyCode == 13) {
            this.loadList(this.state.filter)
        }
    }

    render() {
        const { name, value, onChange, sort, setSort, isClearable, visibleCountAll } = this.props;

        const valuesList = this.state.items;

        const visibleCount = visibleCountAll ? valuesList.length : 20;
        let values = value
            ? value.split(",")
            : [];

        let items = (valuesList || []);
      /*  items = items.length > visibleCount
            ? items.sort((a, b) => (b.isActive - a.isActive - values.includes(a.value) + (a.value < b.value) * 2))
            : items.sort((a, b) => (b.isActive - a.isActive + (a.value < b.value) * 2));*/
        items = items.map((x) => {
            return {
                value: x.value,
                name: x.text,
                isActive: x.isActive
            };
        });
        if (isClearable)
            items.unshift({
                value: emptyObjectId,
                name: t('Not completed'),
                isActive: true
            });

       /* if (this.state.filter)
            items = items.filter(x => values.includes(x.value) || x.name.toLowerCase().includes(this.state.filter));
*/
        items = visibleCountAll ? items : items.slice(0, values.length + 20);

        const toggle = (e, { value }) => {
            if (values.some(x => x === value)) {
                values.splice(values.indexOf(value), 1);
            } else {
                values.push(value);
            }
            if (onChange !== undefined)
                onChange(e, { name: name, value: values.join(",") });
        };

        let content = (
            <Form>
                <label className="label-in-popup">{t(name)}</label>
                <div>
                    <Input fluid onKeyDown={this.handleKeyPress} size="mini" icon='search' value={this.state.filter} onChange={this.setFilter}/>
                </div>
                <div className="select-facet-values">
                    {items.map(x => {
                        let label = (
                            <label className={!x.isActive ? "colorGrey" : ""}>
                                {`${x.name} ${x.description ? x.description : ''}`}
                            </label>
                        );
                        return (<Form.Field key={x.value}>
                            <Checkbox value={x.value} checked={values.includes(x.value)} onChange={toggle} label={label} />
                        </Form.Field>);
                    })}
                </div>
            </Form>
        );

        return (
            <div className="facet-input">
                <Popup
                    trigger={
                        <Input
                            fluid
                            label={{ basic: true, content: "" }}
                            labelPosition='right'
                            onKeyPress={(e) => { e.preventDefault(); }}
                            placeholder={
                                values.length > 0
                                    ? values.length + " " + t("selected")
                                    : t(name)
                            }
                        />
                    }
                    content={content}
                    on='click'
                    hoverable
                    position='bottom left'
                    onClose={this.clearFilter}
                    onOpen={this.handleOpen}
                />
                <Button className={`sort-button sort-button-up ${sort === "asc" ? "sort-button-active" : ""}`} name={name} value="asc" onClick={setSort}>
                    <Icon name="caret up" />
                </Button>
                <Button className={`sort-button sort-button-down ${sort === "desc" ? "sort-button-active" : ""}`} name={name} value="desc" onClick={setSort}>
                    <Icon name="caret down" />
                </Button>
                <div className={"hidden-resize"}>{t(name) + "..............."}</div>
            </div>
        );
    }
}
