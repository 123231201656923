import React from "react";
import { Button, Table, Checkbox, Icon, Label, Container, Grid } from "semantic-ui-react";
import BaseDictionary from "../../shared/BaseDictionary";
import GateCharacteristicsEditModal from "./components/GateCharacteristicsEditModal"
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function GateCharacteristics() {

    const newModal = (load) =>
        <GateCharacteristicsEditModal
            title={t('Adding Gate Features')}
            actionButton={t('Add gate feature')}
            id={null}
            onClose={load}>
            <Icon name='plus' />{` ${t('Add gate feature')}`}
        </GateCharacteristicsEditModal>;
            const headerRow =  <Table.Row>
                <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Type')}</Table.HeaderCell>
                <Table.HeaderCell>{t('Default value')}</Table.HeaderCell>
                <Table.HeaderCell />
            </Table.Row>;

    return (
        <LazyLoadDictionary
            apiGetList="GateCustomFields/Get"
            name={t("gateCharacteristics")}
            className='container-margin-top-bottom'
            newModal={newModal}
            headerRow={headerRow}>
            {(roles, load) =>
                roles.map(role =>
                                <Table.Row verticalAlign='top' key={role.id}>
                                    <Table.Cell>{role.name}</Table.Cell>
                                    <Table.Cell>{role.type}</Table.Cell>
                                    <Table.Cell>{role.type === 'Boolean' ? role.defaultValue ? t('Yes') : t('No'): role.defaultValue}</Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        <GateCharacteristicsEditModal
                                            size="small"
                                            title={t('Edit Gate Features')}
                                            actionButton={t('Save changes')}
                                            id={role.id}
                                            onClose={load}>
                                            <Icon name='edit' />{` ${t('Edit')}`}
                                        </GateCharacteristicsEditModal>
                                    </Table.Cell>
                                </Table.Row>
                            )
            }
        </LazyLoadDictionary>
    );
}
