import moment from "moment";

export const formatDate = (date) => {

    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    let mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    const yy = date.getFullYear();

    return dd + '.' + mm + '.' + yy;
};

export const parseDate = (date) => {
    if (!date) {
        return null;
    }

    const value = moment(date, "DD.MM.YYYY");
    if (!value.isValid()) {
        return null;
    }
    
    return value.toDate();
};

export const parseTime = (time) => {
    if (!time) {
        return null;
    }

    const value = moment(time, "HH:mm");
    if (!value.isValid()) {
        return null;
    }
    
    return value.toDate();
}

export const formatTime = (date) => {

    let hh = date.getHours();
    if (hh < 10) hh = '0' + hh;

    let mm = date.getMinutes();
    if (mm < 10) mm = '0' + mm;

    return hh + ':' + mm;
};

export const formatDateTime = (date) => {
    return `${formatDate(date)} ${formatTime(date)}`
};

export const convertingStringToNumber = (str) => {
    if (str === '') return null;
    return parseFloat(str.replace(',', '.').replace(' ', ''))
}
