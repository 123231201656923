import React from "react";
import { Checkbox, Form } from "semantic-ui-react";

const Edit = ({ value, name, onChange, isDisabled, noLabel, className }) => {
    return (
        <Form.Field>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <Checkbox toggle className={className} disabled={isDisabled || false} name={name} checked={value || false} onChange={(e, {name, checked}) => onChange(e, {name, value: checked })}  />
        </Form.Field>
    );
};
export default Edit;
