import React from "react";
import {Modal, Form, Button, Grid, Icon, Message, TextArea, Segment, Tab, Popup} from "semantic-ui-react";
import DocumentListEdit from "../../../shared/documents/DocumentListEdit";
import DatePicker from "react-datepicker";
import History from "../../../shared/History";

import ControlledModal from "../../../shared/modals/ControlledModal";
import {UserPermissionsContext} from "../../../Contexts";

export default class DataProcessingAgreementForDriver extends React.Component {
    static contextType = UserPermissionsContext;
    state = this.initialState;

    timer = null;

    get initialState() {
        return {
            id: null,
            number: null,
            isSendToSurface: false,
            isMeShow: false
        };
    }    
    
    load = () =>{
        callApi('Surface/IsConnected').then((data)=> {
            if(this.state.isSendToSurface && data.driverId === null){
                this.props.onAccept();
            }
            
            this.setState({
                id: data.id,
                number: data.number,
                isSendToSurface: data.driverId !== null && data.driverId !== '',
                isShowThisDriver: data.driverId === this.props.driverId,
                isMeShow: data.isMeShow
            })
        });
    }
    
    componentDidMount() {
        if(this.context.permissionsData.permissions.includes("AvailableOpenDataProcessingAgreementForDriver")){
            this.load();
            this.timer = setInterval(this.load, 3000);
        }
    }
    
    componentWillUnmount() {
        if(this.timer != null){
            clearInterval(this.timer);
        }
        if(this.state.isSendToSurface)
            this.stopViewOnSurface();
    }

    sendToSurface = (e) => {
        callApi('Surface/ViewForDriver?driverId=' + this.props.driverId).then(()=>{
            this.setState({isSendToSurface: true});
        });
    }

    stopViewOnSurface = (e) => {
        callApi('Surface/StopViewForDriver?driverId=' + this.props.driverId).then(()=>{
            this.setState({isSendToSurface: false});
        });
    }

    onClickOpenDataProcessingAgreementForDriver = (e) => {
        e.stopPropagation();
        window.open(window.location.origin + '/api/dataProcessingAgreementForDriver/Pdf?driverId=' + this.props.driverId);
    }

    render() {
        return (
            <Form.Field>
                <label>Инструменты</label>
                <div>
                    <Popup content={t('OpenDataProcessingAgreementForDriver')} trigger={<Button onClick={this.onClickOpenDataProcessingAgreementForDriver} icon>
                        <Icon name='external' />
                    </Button>}/>
                    {this.state.id !== null && this.state.number !== null && !this.state.isSendToSurface &&
                    <Popup 
                        content={<span>Показать форму согласия на планшете <b>{this.state.number}</b></span>} 
                        trigger={
                            <Button disabled={this.state.isSendToSurface} onClick={this.sendToSurface} icon>
                        <Icon name='tablet alternate' />
                    </Button>}/>}
                    {this.state.id !== null && this.state.isSendToSurface && this.state.isMeShow &&
                    <Button onClick={this.stopViewOnSurface} icon>
                        <Icon name='stop' /> Остановить показ
                    </Button>}
                    {this.state.id !== null && this.state.isSendToSurface && !this.state.isMeShow &&
                    <Popup
                        content={<span>Планшет занят другим оператором</span>}
                        trigger={
                            <Button icon>
                                <Icon name='ban' />
                            </Button>}/>}
                </div>
            </Form.Field>
        );
    }
}