export default result => {
    const { data, headers } = result;
    let headerLine = headers['content-disposition'] || '';
    let startFileNameIndex = headerLine.indexOf('filename=') + 10;
    let endFileNameIndex = headerLine.lastIndexOf(';') - 1;
    let name = headerLine.match(/filename[^;\n]*=(UTF-\d['"]*)((['"]).*?[.]$\2|[^;\n]*)?/); //поиск в content-disposition строки, содержащей название файла в UTF-8
    let filename = name ?
        decodeURI(name[0].replace("filename*=UTF-8''", '')) //из найденного выше значения удаляется `filename*=UTF-8''` и название файла декодируется
        : headerLine.substring(startFileNameIndex, endFileNameIndex);

    const link = document.createElement('a');
    link.href = URL.createObjectURL(new Blob([data], { type: data.type }));
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export const downloadBase64File = (contentBase64, fileName, type) => {
    const linkSource = `data:${type};base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
}

export function callApiDownload(endpoint, data, isFormData) {
    const BASE_URL = '/api/';

    let config = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
    };

    if (localStorage.Identity !== undefined) {
        let token = JSON.parse(localStorage.Identity).access_token || null;
        if (token)
            config.headers["Authorization"] = 'Bearer ' + token;
        else {
            throw "No token saved!";
        }
    }

    if (data)
        if (!isFormData) {
            config.body = JSON.stringify(data);
        } else {
            config.body = data;
            delete config.headers["Content-Type"];
        }

    return fetch(BASE_URL + endpoint, config).then(response =>
        response.blob().then(res =>
            {
                return {
                    data: res,
                    headers: {"content-disposition": response.headers.get('content-disposition')}
                };
            }
        ));

}