import React, {useEffect, useState} from "react";
import DriverEdit from "../../../shared/columns/driver/Edit";
import {Dimmer, Form, Loader, Segment} from "semantic-ui-react";
import DriverInfo from "./CheckBlocks/DriverInfo";
import ClientInfo from "./CheckBlocks/ClientInfo";
import RouteInfo from "./CheckBlocks/RouteInfo";
import DocumentListEdit from "../../../shared/documents/DocumentListEdit";
import TextareaEdit from "../../../shared/columns/textarea/Edit";
import DocumentListView from "../../../shared/documents/DocumentListView";
import CompanyInfo from "./CheckBlocks/CompanyInfo";
import TransportInfo from "./CheckBlocks/TransportInfo";

export default function SecurityCheckInfo(props) {
    const { columns, isView, isEntityStateEdit, formData, loadFields, setIsLoaded } = props;
    const {driverId, truckId, trailerId} = formData;
    const driverField = columns.find(item => item.type === "Driver") || null;
    const companyList = columns.find(item => "Company" === item.type) && columns.find(item => "Company" === item.type).valuesForSelectType;
    const transportList = columns.find(item => "Transport" === item.type) && columns.find(item => "Transport" === item.type).valuesForSelectType;
    const trailerList = columns.find(item => "Trailer" === item.type) && columns.find(item => "Trailer" === item.type).valuesForSelectType;
    const truckList = columns.find(item => "Truck" === item.type) && columns.find(item => "Truck" === item.type).valuesForSelectType;

    let [driverInfo, setDriverInfo] = useState({});
    let [documentTruckInfo, setDocumentTruckInfo] = useState([]);
    let [documentTrailerInfo, setDocumentTrailerInfo] = useState([]);
    //let [isLoadedData, setIsLoadedData] = useState({});

    useEffect(() => load(driverId), [driverId]);

    useEffect(() => getDocumentsTransport(truckId,trailerId), [trailerId, truckId]);

    useEffect(() => {
        return function cleanup() {
            setIsLoaded({ name: null});
        };
    }, []);

    const handleChange = (name, value) => {
        props.changeFormData(name, value);
    };

    const companyHandleChange = (name, value) => {
        if (props.changeFormData !== undefined) {
            props.changeFormData(name, value);
        }
        if (name == 'transportCompanyId') {
            loadTransportCompany(value);
        }
    };

    const load = driverId => {
        if (driverId)
                callApi("Drivers/GetById", driverId).then(data => {
                    setDriverInfo(data);
                }).then(() => {
                    setIsLoaded({ name: "driverInfo", isLoaded: true});
                });

    };

    const loadTruck = id => {
        if (id)
            callApi("truck/GetById", id).then(data => {
                setDocumentTruckInfo(data.documents );
            });
    };
 const loadTrailer = id => {
        if (id)
            callApi("trailer/GetById", id).then(data => {
                setDocumentTrailerInfo(data.documents );
            });
    }
 const loadTransportCompany = id => {
        if (id)
            callApi("companies/Get", id).then(data => {
                handleChange(`transportCompanyInn`, data.inn);
            });
    }

    const getDocumentsTransport = (truckId, trailerId) => {
        if (truckId)
            callApi("truck/GetById", truckId).then(data => {
                setDocumentTruckInfo(data.documents);
            }).then(() => {
                setIsLoaded({ name: "truckInfo", isLoaded: true});
            });

        if (trailerId)
            callApi("trailer/GetById", trailerId).then(data => {
                setDocumentTrailerInfo(data.documents);
            }).then(() => {
                setIsLoaded({ name: "trailerInfo", isLoaded: true});
            });
    };

    const onSaveDriver = (e, {name, value}) => {
        load(value);
        loadFields()
            .then(() => {
                handleChange("driverId", value);
            });

    };

    const onSaveCompany = (e, {name, value}) => {
        loadFields()
            .then(() => {
                handleChange("transportCompanyId", value);
                loadTransportCompany(value);
            });
    };

    const onSaveTransport = (e, {name, value, parameter}) => {
        loadFields()
            .then(() => {
                handleChange(`${parameter}Id`, value);
                if (parameter === 'trailer') {
                    loadTrailer(value)
                } else  loadTruck(value)
            });

    };
    
    return (
        <Form>
            <div className="ui form security-check">
                {driverField && (
                    <DriverEdit
                        name={driverField.name}
                        isNotHistory
                        value={driverId}
                        isCase
                        isDisabled={isView}
                        valuesList={driverField.valuesForSelectType}
                        reloadFields={loadFields}
                        onChange={(e, {name, value}) => handleChange("driverId", value)}
                    />
                )}

                <DriverInfo 
                    {...props}
                    formData={formData}
                    driverInfo={driverInfo} 
                    driverId={driverId} 
                    onSaveDriver={onSaveDriver} 
                    driverState={formData.driverState}
                    isStateEdit={isEntityStateEdit}
                    handleChangeDriverState={(value) => {handleChange("driverState", value)}}
                />

                
                <TransportInfo
                    {...props}
                    documentTrailerInfo={documentTrailerInfo}
                    documentTruckInfo={documentTruckInfo}
                    trailerList={trailerList}
                    formData={formData}
                    isStateEdit={isEntityStateEdit}
                    truckList={truckList}
                    handleChange={handleChange}
                    onSaveTransport={onSaveTransport}
                />
                <Form.Field className="margin-bottom-0">
                    <label className="margin-bottom-0">{t('TransportCompany')}</label>
                </Form.Field>
                <CompanyInfo {...props} companyList={companyList} handleChange={companyHandleChange} load={loadFields} onSaveCompany={onSaveCompany}/>


                <Form.Field className="margin-bottom-0">
                    <label className="margin-bottom-0">{t('Customer information')}</label>
                </Form.Field>
                <ClientInfo {...props} companyList={companyList} handleChange={handleChange} load={loadFields}/>


                <Form.Field className="margin-bottom-0">
                    <label className="margin-bottom-0">{t('Route')}</label>
                </Form.Field>
                <RouteInfo {...props} handleChange={handleChange}/>


                {isView
                    ? <>
                        <Form.Field className="margin-bottom-0">
                            <label className="margin-bottom-0">{t('Additional documents')}</label>
                        </Form.Field>
                        {formData.documents && formData.documents.length ?
                            <DocumentListView documents={formData.documents || []}/> :
                            <label>{t('No stuck documents')}</label>}
                    </>
                    : <>
                        <Form.Field className="margin-bottom-0">
                            <label className="margin-bottom-0">{t('Attach additional documents')}</label>
                        </Form.Field>
                        <DocumentListEdit
                            documents={formData.documents || []}
                            onDocumentsChange={(documents) => handleChange("documents", documents)}
                            typesApiUrl="DocumentTypes/GetDocumentTypeList"
                            type="SecurityCheck"/>
                    </>
                }


                <Form.Field className="margin-bottom-0">
                    <label className="margin-bottom-5 margin-top-7">{t('Comment')}</label>
                </Form.Field>
                <TextareaEdit
                    rows={6}
                    value={formData.comment}
                    noLabel
                    onChange={(e, {value}) => {
                        if(isView)
                             return;
                        handleChange("comment", value);
                    }}/>
            </div>
        </Form>
    );
}
