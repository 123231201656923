import React, {Fragment, useContext} from "react";
import EditCreatePlatformModal from "./components/PlatformEditModal";
import {Icon, Label, Table, Popup} from "semantic-ui-react";
import CustomFieldsList from "../../shared/customFieldsEditor/CustomFieldsList";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";
import {UserPermissionsContext} from "../../Contexts";

export default function Platforms() {
    const {permissionsData} = useContext(UserPermissionsContext);

    const newModal = (load) =>
        <EditCreatePlatformModal
            title={t("Platform creation")}
            actionButton={t("Add platform")}
            id={null}
            onClose={load}>
            <Icon name="plus"/>{` ${t("Add platform")}`}
        </EditCreatePlatformModal>;
    const headerRow =
        <Table.Row>
            <Table.HeaderCell>
                {t("Name")}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t("Address")}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t("Timezone")}
            </Table.HeaderCell>
            <Table.HeaderCell>
                {t("Gate")}
            </Table.HeaderCell>
            {!permissionsData.isAdminKU && permissionsData.permissions.includes("ExcludeFromAvailableRoles") && <Fragment>
                <Table.HeaderCell>
                    {t("Custom visit fields")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                    {t("Custom task fields")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                    {t("Custom order fields")}
                </Table.HeaderCell>
            </Fragment>}
            <Table.HeaderCell/>
        </Table.Row>;

    return (
        <LazyLoadDictionary
            apiGetList="Platforms/GetList"
            name={t("platforms")}
            className="container-margin-top-bottom"
            newModal={permissionsData.permissions.includes("EditPlatforms") && newModal}
            headerRow={headerRow}>
            {(platforms = [], load) =>
                platforms.map((platform, index) => {
                    return (<Table.Row key={index}>
                        <Table.Cell>
                            {platform.name}
                        </Table.Cell>
                        <Table.Cell>
                            {platform.address}
                        </Table.Cell>
                        <Table.Cell>
                            {platform.timeZoneName}
                        </Table.Cell>
                        <Table.Cell>
                            {platform.gates.map((gate, index) => <Popup key={index}
                                                                        trigger={<Label>{gate.number}</Label>}
                                                                        content={gate.activities.map(x => x.text).join(", ")}/>)}
                        </Table.Cell>
                        {!permissionsData.isAdminKU && permissionsData.permissions.includes("ExcludeFromAvailableRoles") && <Fragment>
                            <Table.Cell>
                                <CustomFieldsList fields={platform.customFieldsForVisit}/>
                            </Table.Cell>
                            <Table.Cell>
                                <CustomFieldsList fields={platform.customFieldsForTask}/>
                            </Table.Cell>
                            <Table.Cell>
                                <CustomFieldsList fields={platform.customFieldsForOrder}/>
                            </Table.Cell>
                        </Fragment>}
                        <Table.Cell textAlign="center">
                            <EditCreatePlatformModal
                                size="small"
                                title={t("Platform editing")}
                                actionButton={t("SaveButton")}
                                id={platform.id}
                                onClose={load}>
                                <Icon name="edit"/>{` ${t("Edit")}`}
                            </EditCreatePlatformModal>
                        </Table.Cell>
                    </Table.Row>);
                })
            }
        </LazyLoadDictionary>
    );
}
