import React, {useState, useEffect} from "react";
import {Table} from "semantic-ui-react";
import {dateFormat} from "../../../utils/dateFormat";
import SecurityServiceModal from "./SecurityServiceModal";
import StatusValue from "../../../shared/columns/status/Value";
import {securityServiceStatusColor} from "../../../utils/stateHelper";

export default function HistoryByDriver(props) {
    const {items = []} = props;
    const ListTable = (list = []) =>
        <div style={{position: "relative"}} className=".init-scroll">
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Date_Time_Created')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('State')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Surname L.P. the driver')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Surname L.P. initiator')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('TransportCompany')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Result of checking')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {list.map(item => {
                        return (
                            <SecurityServiceModal caseId={item.id}
                                                  key={item.id} isCheck>
                                <Table.Row key={item.id} className="security-service-history-driver-table">
                                    <Table.Cell>{dateFormat(item.creationDate)}</Table.Cell>
                                    <Table.Cell>{t(item.state)}</Table.Cell>
                                    <Table.Cell>{item.driverName}</Table.Cell>
                                    <Table.Cell>{item.initiatorName}</Table.Cell>
                                    <Table.Cell>{item.transportCompanyName}</Table.Cell>
                                    <Table.Cell>{item.state ==="Completed" ? <StatusValue value={item.result}
                                                                                                              stateColors={securityServiceStatusColor()}
                                                                                                              text={t(item.result)}/> : null}</Table.Cell>

                                </Table.Row>
                            </SecurityServiceModal>
                        );
                    })}
                </Table.Body>
            </Table>
        </div>;
    return(
        <div>{ListTable(items)}</div>
    )
};
