import React from "react";
import {Label} from "semantic-ui-react";
import Value from "./Value";
import {multiSelectValues} from "../../../utils/valueFormat";

const Values = ({ value, name, valuesList }) => {
    let values = multiSelectValues(value);
    return (
        values.map(v => <Label className='label-margin'>
            <Value
                value={v}
                name={name}
                valuesList={valuesList}
            />
        </Label>)
    );
};

export default Values;