import "./header.css";
import React from "react";
import { Route, Link } from "react-router-dom";
import { Menu, Container, Segment, Dropdown } from "semantic-ui-react";
import * as AuthHelper from "../utils/authHelper";
import * as StateHelper from "../utils/stateHelper";
import FieldsConfigModal from "../shared/modals/FieldsConfigModal";
import ProfileModal from "./components/ProfileModal";
import { UserPermissionsContext } from "../Contexts";
import ConnectSurfaceModal from "../shared/modals/ConnectSurfaceModal";
import ChangePasswordModal from "./components/ChangePasswordModal";

export default class Header extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        activeItem: location.pathname,
        mainTabs: StateHelper.getMainPages(),
        secTabs: StateHelper.getSecondaryPages(),
        isLoaded: false
    }

    componentWillMount() {
        if (!this.context.permissionsData &&  window.location.pathname !== "/createInvitation") {
            window.location.href = "/login";
            this.setState({ isLoaded: false });
        } else {
            this.setState({
                isLoaded: true,
                activeItem: location.pathname,
            });
        }
    }

    setTabPermissions = (permissions, tabs) => {
        return tabs.filter(t => this.isTabPermitted(permissions, t.permission, t.adminKu));
    }

    isTabPermitted = (permissions, name, isAdminKu) => {
        const { permissionsData } = this.context;
        return permissions.some(permission => permission === name || (isAdminKu && permissionsData.isAdminKU));
    }

    isTabSelected = (name) => {
        return this.state.activeItem.indexOf(name) > -1;
    }

    changeTab = (e, { name }) => {
        this.setState({ activeItem: name });
    }

    logout = (history) => {
        AuthHelper.logout();
        this.context.loadPermissionsData().then(() => {
            this.setState({
                activeItem: "/",
            });
            history.push("/login");
        });
    }

    render() {
        const { permissionsData } = this.context;
        if (window.location.pathname === "/surface" || window.location.pathname.indexOf("/createVisitFromBJ") === 0 || window.location.pathname === "/createInvitation") {
            return null;
        }
        if (permissionsData.isLogged) {
            const { permissions } = permissionsData,
                mainTabs = this.setTabPermissions(permissions, this.state.mainTabs),
                secTabs = this.setTabPermissions(permissions, this.state.secTabs);

            let { activeItem } = this.state;
            activeItem = activeItem === "/"
                ? (mainTabs[0] || {}).name || secTabs[0].name
                : activeItem;

            return (
                <header>
                    <div>
                        <Menu pointing secondary>
                            <Menu.Item as={Link} to="/" header>
                                <img src="/assets/img/fmlogo2.png" title="Fm logistic" alt="Fm logistic" />
                            </Menu.Item>
                            {
                                mainTabs.map((tab, index) => {
                                    return (
                                        <Menu.Item className="large" key={index} as={Link} to={`/${tab.name}`} name={tab.name} active={activeItem.indexOf(tab.name) > -1} onClick={this.changeTab}>
                                            {t(tab.name)}
                                        </Menu.Item>
                                    );
                                })
                            }
                            {
                                secTabs.length > 0 ?
                                    <Dropdown item className={`icon ${secTabs.some(x => activeItem.indexOf(x.name) > -1) && "superActive"}`} icon='cog'>
                                        <Dropdown.Menu>
                                            {
                                                secTabs.map((tab, index) => {
                                                    return (
                                                        <Dropdown.Item key={index} as={Link} to={`/${tab.name}`} name={tab.name} active={this.isTabSelected(tab.name)} onClick={this.changeTab}>
                                                            {t(tab.name)}
                                                        </Dropdown.Item>
                                                    );
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    : null
                            }
                            <Menu.Menu position='right'>
                                <Dropdown text={`${AuthHelper.getName()} (${AuthHelper.getRole()})`} item>
                                    <Dropdown.Menu>
                                        <ProfileModal>
                                            <Dropdown.Item>{t("profile")}</Dropdown.Item>
                                        </ProfileModal>
                                        {permissions.includes("ViewVisits") || permissions.includes("ViewTasks")
                                            ? <FieldsConfigModal>
                                                <Dropdown.Item>{t("fields_settings")}</Dropdown.Item>
                                            </FieldsConfigModal>
                                            : null
                                        }
                                        {permissions.includes("AvailableOpenDataProcessingAgreementForDriver")
                                            ? <ConnectSurfaceModal>
                                                <Dropdown.Item>
                                                    {t("Work with the consent form for the processing of personal data")}
                                                </Dropdown.Item>
                                            </ConnectSurfaceModal>
                                            : null
                                        }
                                        <Route render={({ history }) => (<Dropdown.Item onClick={() => { this.logout(history); }}>{
                                            t("exit")}</Dropdown.Item>)} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        </Menu>
                    </div>
                    <ChangePasswordModal open={!permissionsData.passwordChanged}/>
                </header>
            );
        }
        else return null;
    }
};
