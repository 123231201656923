import React from "react";
import { Button, Form, Label, Dropdown, Input, Popup, Icon } from "semantic-ui-react";

const Edit = ({ value, name, isDisabled, onChange, stateColors, valuesList, className }) => {
    const state = stateColors.find(x => x.value === value),
          color = state ? state.color : "grey",
          inverted = state ? state.inverted : false;

    const items = (valuesList || []).map((x) => {
        return {
            key: x.name,
            value: x.name,
            text: t(x.name)
        };
    });

    const labelClassname = isDisabled ? "label-disabled" : null;

    if (!isDisabled)
        return (
            <Form.Field>
                <label className={labelClassname}>{t(name)}</label>
                <Dropdown
                    className={className}
                    selection
                    search
                    disabled={isDisabled || false}
                    name={name}
                    value={value}
                    fluid
                    options={items}
                    onChange={onChange}
                />
            </Form.Field>
        );
    else
        return (
            <Form.Field>
                <label className={labelClassname}>{t(name)}</label>
                <div className="semantic-ui-div state-edit-field">
                    <Icon color={color} inverted={inverted} name="circle" />
                    {t(value)}
                </div>
            </Form.Field>
        );
};
export default Edit;