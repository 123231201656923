import React from "react";
import { Modal, Button, Grid, Label, Select, Tab, Input, Icon, Table, Dropdown, Form, Message, Popup, TextArea } from "semantic-ui-react";
import CustomFieldsEditor from "./CustomFieldsEditor";

export default class CustomFieldsTable extends React.Component {

    getCountVisits = (fields) => {
        return fields.length === 0 ? "" : `(${fields.length})`;
    }

    saveCustomField = (customField, forEntity) => {
        let { customFields } = this.props;
        let fields;
        switch (forEntity) {
            case "visit":
                fields = customFields.customFieldsForVisit;
                break;
            case "task":
                fields = customFields.customFieldsForTask;
                break;
            case "order":
                fields = customFields.customFieldsForOrder;
                break;
            case "ordersModule":
                fields = customFields.customFieldsForOrdersModule;
                break;
        }

        let newField = {
            id: customField.id,
            name: customField.name,
            type: customField.type,
            isActive: customField.isActive,
            valuesForSelectTypeAsArray: customField.valuesForSelectTypeAsArray
        };

        let i = fields.findIndex(x => x.id == customField.id);
        if (i > -1)
            fields[i] = newField;
        else
            fields.push(newField);

        switch (forEntity) {
            case "visit":
                customFields.customFieldsForVisit = fields;
                break;
            case "task":
                customFields.customFieldsForTask = fields;
                break;
            case "order":
                customFields.customFieldsForOrder = fields;
                break;
            case "ordersModule":
                customFields.customFieldsForOrdersModule = fields;
                break;
        }
        this.props.onChangeCustomFields({ customFields });
    }

    render() {
        let panes = [
            {
                menuItem: `${t('Visits')} ${this.getCountVisits(this.props.customFields.customFieldsForVisit)}`,
                key: "1",
                render: () => {
                    return <Tab.Pane>
                        <CustomFieldsEditor
                            fields={this.props.customFields.customFieldsForVisit}
                            onSave={(customField) => { this.saveCustomField(customField, "visit"); }} />
                    </Tab.Pane>;
                }
            },
            {
                menuItem: `${t('Tasks')} ${this.getCountVisits(this.props.customFields.customFieldsForTask)}`,
                key: "2",
                render: () => {
                    return <Tab.Pane>
                        <CustomFieldsEditor
                            fields={this.props.customFields.customFieldsForTask}
                            onSave={(customField) => { this.saveCustomField(customField, "task"); }} />
                    </Tab.Pane>;
                }
            },
            {
                menuItem: `${t('Orders')} ${this.getCountVisits(this.props.customFields.customFieldsForOrder)}`,
                key: "3",
                render: () => {
                    return <Tab.Pane>
                        <CustomFieldsEditor
                            fields={this.props.customFields.customFieldsForOrder}
                            onSave={(customField) => { this.saveCustomField(customField, "order"); }} />
                    </Tab.Pane>;
                }
            },
        ];

        !this.props.isPlatform && panes.push({
            menuItem: `${t('ordersModule')} ${this.getCountVisits(this.props.customFields.customFieldsForOrdersModule)}`,
            key: "4",
            render: () => {
                return <Tab.Pane>
                    <CustomFieldsEditor
                        fields={this.props.customFields.customFieldsForOrdersModule}
                        onSave={(customField) => { this.saveCustomField(customField, "ordersModule"); }} />
                </Tab.Pane>;
            }
        });

        return (
            <div>
                <Message
                    success
                    header={t('Custom fields')}
                    content={t('Custom_fields_will_be_displayed')}
                />
                <div>
                    <Tab panes={panes} />
                </div>
            </div>
        );
    }
};
