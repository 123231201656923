import React from "react";
import ControlledModal from "../../../shared/modals/ControlledModal";
import {Table, Grid, Dropdown, Button, Icon, Confirm, Form, Input, Checkbox, Message} from "semantic-ui-react";
import TextEdit from '../../../shared/columns/text/Edit';
import inputTime from '../../../shared/columns/time/inputTime';
import InputTime from '../../../shared/columns/time/inputTime';
import ScheduleExceptions from './ScheduleException';
import BeatTimeSlot from './BeatTimeSlot';
import {Tab} from "semantic-ui-react/dist/commonjs/modules/Tab/Tab";
import SpecificationsGate from "./SpecificationsGate";

const dayOfWeeksStub = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
];

const taskStandardSchedule = {
    isLoad: `${t('Loading')}`,
    isUnload: `${t('Unloading')}`,
    isMoving: `${t('Sorting')}`
};

const columnStabNumber = {
    "name": "Number",
    "type": "Number",
    "valuesForSelectType": []
};

const columnStabText = {
    "name": "Text",
    "type": "Text",
    "valuesForSelectType": []
};

const columnStabBoolean = {
    "name": "Boolean",
    "type": "Boolean",
    "valuesForSelectType": []
};

const columnStabSelect = {
    "name": "Select",
    "type": "Select",
    "valuesForSelectType": [{
        "name": "sdfdf",
        "isActive": true,
        "value": "5d663dd3fb12271758af4439"
    }]
};

const SpecificationsListStab = [columnStabNumber, columnStabText, columnStabSelect, columnStabBoolean]

const initState = {
    modalOpen: false,
    closeOnEscape: true,
    closeOnDimmerClick: false,
    nameGate: '',
    specifications: {
        number: 1,
        boolean: true
    },
    editGate: {
        activityId: ''
    },
    editGateOld: {},
    editScheduleExceptionIndex: null,
    editIndex: null,
    errorText: '',
    isValidScheduleEdit: true,
    formData: [],
    oldFormData: null,
    formDataNew: {
        activityId: '',
        schedules: [],
        scheduleExceptions: []
    },
    textErrorItem: '',
    confirmation: {
        open: false,
        content: '',
        onConfirm: () => {
        }
    },
    indexDivideScheduleStandard: null,
    indexOpenBeatTimeSlot: null,
    specificationsList: []
};

export default class ScheduleModal extends React.Component {

    state = {
        ...initState
    };

    componentDidMount() {
        const {error} = this.props;

        this.setState({
            errorText: error
        });
    };

    componentDidUpdate(prevProps, prevState) {
        const {nameGate, errorText, editGate, editIndex} = this.state;
        if (!prevState.nameGate && nameGate) {
            this.setState({
                errorText: ''
            })

        }
        if (!!prevState.editGate.schedules && JSON.stringify(prevState.editGate.schedules !== JSON.stringify(editGate.schedules))) {
            const indexNew = editIndex || prevState.editIndex
            this.renderHeightScheduleItem(indexNew)

        }

    }

    load = () => {
        const {id, formData, specificationsList = []} = this.props;
        const specificationsListNew = specificationsList.map(item => ({
            ...item,
            valuesForSelectType: (item.valuesForSelectType || []).map(x => ({name: x, value: x, isActive: true}))
        }));
        this.setState({
            nameGate: id,
            oldFormData: formData,
            formData: formData,
            specificationsList: specificationsListNew

        });
    };

    editGate = (gateNumber) => {
        let gate = this.props.gates.filter((gate) => {
            return gate.number === gateNumber;
        })[0];
        this.setState({
            editGateNumber: gateNumber,
            editActivities: gate.activities.map(x => x.id)
        });
    };

    isScheduleFormChanged = () => {
        if (this.state.oldFormData === null) return false;

        return JSON.stringify(this.state.formData) !== JSON.stringify(this.state.oldFormData);
    };

    onClose = () => {
        const {onClose, onLoad} = this.props;

        this.setState({
            ...initState
        });

        onClose && onClose();
        onLoad && onLoad();
    };

    save = (e) => {
        const {formData, nameGate, editIndex, oldFormData, specificationsList} = this.state;
        const {allNameGates, isHasTasks, isActive, availableForEdit} = this.props;

        if (this.props.getOldFormData !== undefined) {
            this.props.getOldFormData({gate: {number: nameGate, schedules: oldFormData}, index: this.props.index});
        }

        this.setState({
            oldFormData: null
        });

        if (editIndex !== null && editIndex >= 0) {
            this.setState({
                errorText: t('Save activity graph settings')
            });
            return Promise.reject(false)
        }

        if (allNameGates && allNameGates.length > 0 && allNameGates.includes(nameGate)) {
            this.setState({
                errorText: t('A gate with the same name already exists on this platform')
            });
            return Promise.reject(false)
        }
        if (!nameGate) {
            this.setState({
                errorText: t('Field "Gate Name" is empty')
            });
            return Promise.reject(false)
        } else {
            let specificationsListNew = [...specificationsList]
            specificationsListNew.forEach((x, index) => {
                if (x.valuesForSelectType.length > 0) {
                    specificationsListNew[index].valuesForSelectType = specificationsListNew[index].valuesForSelectType.map(i => i.value)
                }
            })
            return this.props.onSave && this.props.onSave({
                gate: {
                    number: nameGate,
                    schedules: formData,
                    customFields: specificationsListNew,
                    isHasTasks,
                    isActive,
                    availableForEdit
                }, index: this.props.index
            });

        }
    };

    isNotFilledScheduleItem = (schedules = []) => {
        if (schedules.find(item =>
            !item.dayOfWeeks.length ||
            !item.timeStart.length ||
            item.timeStart.length < 5 ||
            item.timeEnd.length < 5 ||
            !item.timeEnd.length ||
            !(item.isLoad || item.isUnload || item.isMoving))) {
            return true
        }
        return false
    };

    saveForm = (item) => {
        const {editGate, formData, textErrorItem, isValidScheduleEdit, editIndex} = this.state;

        let editGateNew = Object.assign({}, editGate);

        if (!editGateNew.activityId.length) {
            return
        }

        if (this.isNotFilledScheduleItem(editGateNew.schedules) || !isValidScheduleEdit) {
            this.setState({
                textErrorItem: t('Fill in the schedule and select tasks')
            });
            return
        }

        editGate.schedules.sort((a, b) => b.dayOfWeeks > a.dayOfWeeks)
        let formDataNew = formData.concat();
        if (formDataNew[this.state.editIndex])
            formDataNew[this.state.editIndex] = editGate;
        else
            formDataNew.push(editGate);

        this.setState({
            errorText: null,
            textErrorItem: '',
            editGate: {},
            editIndex: null,
            formData: formDataNew
        }, () => {
        });
    };

    cancelForm = (item) => {
        const {formData, editIndex, editGateOld} = this.state;

        let formDataOld = [...formData];
        if (Object.keys(editGateOld).length !== 0) {
            formDataOld.splice(editIndex, 1, editGateOld);
        } else formDataOld.splice(editIndex, 1);
        this.setState({
            editGate: {},
            errorText: '',
            textErrorItem: '',
            isValidScheduleEdit: true,
            editGateOld: {},
            editIndex: null,
            formData: formDataOld
        });
    };

    handleChangeActivities = (e, obj) => {
        this.setState(prevState => {
            return {
                editGate: {...prevState.editGate, activityId: obj.value}
            };
        });
    };

    handleChangeSpecificationsGate = (e, {name, value, id}) => {
        const {specificationsList} = this.state;
        let specificationsListNew = specificationsList.concat();
        const index = specificationsListNew.findIndex(item => item.id === id)
        specificationsListNew[index] = {...specificationsListNew[index], value: value !== '' ? value : null}
        this.setState({
            specificationsList: specificationsListNew
        });
    };

    handleChangeScheduleException = (value, index) => {
        let scheduleExceptionsNew = this.state.editGate.scheduleExceptions.concat();
        scheduleExceptionsNew[index] = value;
        this.setState(prevState => {
            return {
                editScheduleExceptionIndex: null,
                editGate: {...prevState.editGate, scheduleExceptions: scheduleExceptionsNew}
            };
        });
    };

    handleChangeStandardSchedule = (name, value, index) => {
        let schedulesNew = this.state.editGate.schedules.concat();
        schedulesNew[index][name] = value;
        this.setState(prevState => {
            return {
                editGate: {...prevState.editGate, schedules: schedulesNew}
            };
        });
    };

    onOpenScheduleException = (index) => {
        this.setState({
            editScheduleExceptionIndex: index
        });
    };

    addEditStandardSchedule = () => {
        let schedulesNew = this.state.editGate.schedules;

        if (schedulesNew.find(item => !item.dayOfWeeks.length || !item.timeStart.length || !item.timeEnd.length)) {
            return
        }
        schedulesNew.push({
            dayOfWeeks: [],
            timeStart: '',
            timeEnd: '',
            isLoad: false,
            isUnload: false,
            isMoving: false
        });
        this.setState(prevState => {
            return {
                editGate: {...prevState.editGate, schedules: schedulesNew}
            }
        })
    };

    addEditScheduleException = (value) => {
        let scheduleExceptionsNew = this.state.editGate.scheduleExceptions;
        scheduleExceptionsNew.push(value)
        this.setState(prevState => {
            return {
                editGate: {...prevState.editGate, scheduleExceptions: scheduleExceptionsNew}
            }
        })
    };

    addFormDataNew = () => {
        const {formData, formDataNew: formDataNewStab} = this.state;
        let formDataNew = [...formData];
        formDataNew.push(formDataNewStab);
        this.setState({
            formData: formDataNew,
            editIndex: formDataNew.length - 1,
            formDataOld: formData,
            editGate: JSON.parse(JSON.stringify(formDataNewStab))
        });
    };

    deleteItem = (index) => {
        const {formData} = this.state;
        let formDataNew = formData.concat();
        formDataNew.splice(index, 1);
        this.setState({
            editGate: {},
            indexDivideScheduleStandard: null,
            editIndex: null,
            confirmation: {
                open: true,
                content: t('Are you sure you want to delete the entire line with activity?'),
                onConfirm: () => {
                    this.setState({
                        formData: formDataNew,
                        confirmation: {
                            open: false
                        }
                    })
                }
            }
        });

    };

    getCorrectTimeValue = (value) => {
        if (`${value}`.length === 1) {
            return `0${value}`
        }
        return value
    };

    isOpenBeatTimeSlot = (value) => {
        this.setState({
            indexOpenBeatTimeSlot: value
        })
    };

    divideStandardSchedule = (index, {interval}) => {

        const {editGate} = this.state;
        let editGateNew = Object.assign({}, editGate);
        const intervalDivideScheduleStandard = interval;
        if (!intervalDivideScheduleStandard) {
            return this.setState({
                textErrorItem: t('Fill in the interval')
            });
        }
        const timeStartInterval = editGateNew.schedules[index].timeStart;
        const timeEndInterval = editGateNew.schedules[index].timeEnd;
        const timeStartDate = new Date(2020, 0, 1, timeStartInterval.slice(0, 2), timeStartInterval.slice(3, 5));
        const timeEndDate = new Date(2020, 0, 1, timeEndInterval.slice(0, 2), timeEndInterval.slice(3, 5));

        if (intervalDivideScheduleStandard) {

            let dateTimeStartInterval = timeStartDate;
            let dateTimeEndInterval = timeEndDate;
            let scheduleNewList = [];

            while (Date.parse(dateTimeStartInterval) < Date.parse(dateTimeEndInterval)) {
                const hh = this.getCorrectTimeValue(dateTimeStartInterval.getHours());
                const mm = this.getCorrectTimeValue(dateTimeStartInterval.getMinutes());
                const ss = this.getCorrectTimeValue(dateTimeStartInterval.getSeconds());
                let newTimeStart = `${hh}:${mm}:${ss}`;

                dateTimeStartInterval.setMinutes(dateTimeStartInterval.getMinutes() + Number(intervalDivideScheduleStandard));

                let newTimeEnd = `${this.getCorrectTimeValue(dateTimeStartInterval.getHours())}:${this.getCorrectTimeValue(dateTimeStartInterval.getMinutes())}:${this.getCorrectTimeValue(dateTimeStartInterval.getSeconds())}`;

                if (dateTimeStartInterval <= Date.parse(dateTimeEndInterval)) {
                    scheduleNewList.push({
                        ...editGateNew.schedules[index],
                        timeStart: newTimeStart,
                        timeEnd: newTimeEnd
                    });
                } else {
                    scheduleNewList.push({
                        ...editGateNew.schedules[index],
                        timeStart: newTimeStart,
                        timeEnd: timeEndInterval
                    });
                }
            }

            let editGateNewSchedules = scheduleNewList;
            if (editGateNew.schedules.length > 1) {
                editGateNew.schedules.splice(index, 1);
                editGateNewSchedules = scheduleNewList.concat(editGateNew.schedules);
            }

            this.setState((prevState) => {
                return {
                    editGate: {
                        ...prevState.editGate,
                        schedules: editGateNewSchedules
                    },
                    indexDivideScheduleStandard: null,
                    indexOpenBeatTimeSlot: null
                }
            });
        }
    };

    deleteStandardSchedule = (index) => {
        const {schedules} = this.state.editGate;
        let schedulesNew = schedules.concat();
        schedulesNew.splice(index, 1);
        this.setState({
            confirmation: {
                open: true,
                content: t('Are you sure you want to delete the schedule for activity?'),
                onConfirm: () => {
                    this.setState(prevState => {
                        return {
                            confirmation: {
                                open: false
                            },

                            editGate: {...prevState.editGate, schedules: schedulesNew},
                            indexDivideScheduleStandard: null
                        }
                    });
                }
            }
        })
    };

    deleteScheduleException = (index) => {
        const {scheduleExceptions} = this.state.editGate;
        let scheduleExceptionsNew = scheduleExceptions.concat();
        scheduleExceptionsNew.splice(index, 1);
        this.setState({
            confirmation: {
                open: true,
                content: t('Are you sure you want to remove the exception?'),
                onConfirm: () => {
                    this.setState(prevState => {
                        return {
                            confirmation: {
                                open: false
                            },

                            editGate: {...prevState.editGate, scheduleExceptions: scheduleExceptionsNew}
                        }
                    });
                }
            }
        })
    };

    getNameTask = (obj) => {
        let renderTaskText = []

        for (let key in obj) {
            if (obj[key]) {
                renderTaskText.push(taskStandardSchedule[key])
            }

        }
        return t(`${renderTaskText.join(', ')}`)
    };

    validEditForm = (value) => {
        this.setState({
            isValidScheduleEdit: value
        })
    };

    getActivities = (index) => {
        const {onlyCurrentUserActivities} = this.props;
        const {formData} = this.state;

        return onlyCurrentUserActivities
            .filter((item) => {
                let a = []
                formData.forEach(i => {
                    if (i.activityId === item.id) {
                        a.push(true)
                    }
                })
                if (item.id === formData[index].activityId) {
                    a = []
                }
                return !a.length
            })
            .map((row) => {
                return {key: row.id, text: row.name, value: row.id};
            })
    };

    getHeightTaskSchedule = (index, key) => {
        const standart = document.getElementsByClassName(`schedule-modal-item-standard-days-${index}-${key}`);
        const heightNew = !!standart[0] && !!standart[0].clientHeight ? standart[0].clientHeight : 50
        return {height: `${heightNew}px`}
    };

    renderHeightScheduleItem = (editIndex) => {
        const {editGate} = this.state;
        if (!Object.keys(editGate).length) {
            return;
        }
        editGate.schedules && editGate.schedules.length > 0 && editGate.schedules.forEach((item, index) => {
            const load = document.getElementsByClassName(`schedule-modal-item-task-schedule-load-${editIndex}-${index}`);
            const unload = document.getElementsByClassName(`schedule-modal-item-task-schedule-unload-${editIndex}-${index}`);
            const moving = document.getElementsByClassName(`schedule-modal-item-task-schedule-moving-${editIndex}-${index}`);
            const schedule = document.getElementsByClassName(`schedule-modal-item-standard-days-${editIndex}-${index}`);
            const heightNew = !!schedule[0] && !!schedule[0].clientHeight ? schedule[0].clientHeight : 50
            if (load && unload && moving && load[0] && load[0].style && unload[0] && unload[0].style && moving[0] && moving[0].style) {
                load[0].style.height = `${heightNew}px`
                unload[0].style.height = `${heightNew}px`
                moving[0].style.height = `${heightNew}px`

            }
        })
    };

    isUpward = (index) => {
        const table = document.getElementsByClassName("schedule-modal-table");
        const el = document.getElementsByClassName(`schedule-modal-item-${index}`)
        return (table[0].offsetHeight > 300 && el[0] && el[0].offsetTop > 220) || (table[0].offsetHeight > 250 && !el[0])
    };

    render() {
        const {children, title = '', size = 'large', isDisabled, actionButton, editPlatformPermission} = this.props;
        const {errorText, nameGate, formData, confirmation, indexOpenBeatTimeSlot} = this.state;

        return (
            <ControlledModal
                size="fullscreen"
                buttonName={children}
                buttonSize={size}
                title={title}
                onOpen={this.load}
                onSave={this.save}
                onClose={this.onClose}
                isDisabled={isDisabled}
                closeOnEscape={true}
                closeOnDimmerClick={false}
                isEditActivity={typeof this.state.editIndex === "number"}
                saveButtonName={actionButton}
                isConfirmForCloseModal={this.isScheduleFormChanged()}
            >
                <div className=" ui form margin-bottom-10">
                    <Grid>
                        {errorText &&
                        <Grid.Row columns="equal" className="margin-bottom-10">
                            <Grid.Column>
                                <Message negative>
                                    <p>{errorText}</p>
                                </Message>
                            </Grid.Column>
                        </Grid.Row>}
                        <div className={"schedule-modal-block"}>
                            <Grid.Row columns="equal" className="margin-bottom-15">
                                <Grid.Column width={4} className={"no-fluid-input"}>
                                    <TextEdit
                                        className="no-fluid-input"
                                        name={t('Gate Name')}
                                        value={nameGate}
                                        onChange={(e) => {
                                            this.setState({nameGate: e.target.value})
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns="equal" className="margin-bottom-10">
                                <Grid.Column width={4}>
                                    {t('Specifications')}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns="equal">
                                <div className={"specifications-gate-block"}>
                                    {this.state.specificationsList.map((item, v) =>
                                        <SpecificationsGate
                                            disabledAll={!editPlatformPermission}
                                            key={v}
                                            keyV={v}
                                            column={{...item, isClearable: true}}
                                            value={item.value}
                                            type={item.type}
                                            name={item.name}
                                            onChange={(e, {name, value}) => this.handleChangeSpecificationsGate(e, {
                                                name,
                                                value,
                                                id: item.id
                                            })}
                                        />
                                    )}
                                </div>
                            </Grid.Row>
                            <Grid.Row columns="equal" className="margin-bottom-10">
                                <Grid.Column width={4}>
                                    {t('Schedule')}
                                </Grid.Column>
                            </Grid.Row>
                            <div className={"init-scroll shedule-modal-table"}>
                                <Grid.Row columns="equal" className={"padding-margin-0 schedule-modal-table"}>
                                    <Grid.Column>
                                        <Table celled compact>
                                            <Table.Header>
                                                <tr>
                                                    <Table.HeaderCell width={2}>
                                                        {t('Activity')}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        {t('Standard schedule')}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>
                                                        {t('Loading')}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>
                                                        {t('Unloading')}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={1}>
                                                        {t('Sorting')}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        {t('Exceptions')}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={1}/>
                                                </tr>
                                            </Table.Header>
                                            <Table.Body>
                                                {formData.map((item, index) =>
                                                    <Table.Row key={`table-row-${index}`}
                                                               className={`schedule-modal-item-${index}`}>
                                                        <Table.Cell>
                                                            {this.state.editIndex == index ?
                                                                <Dropdown placeholder={`${t('Select')}...`}
                                                                          fluid
                                                                          error={!this.state.editGate.activityId.length}
                                                                          selection
                                                                          upward={this.isUpward(index)}
                                                                          value={this.state.editGate.activityId}
                                                                          onChange={this.handleChangeActivities}
                                                                          options={this.getActivities(index)}
                                                                /> :
                                                                (this.props.allActivities.find(x => x.id === item.activityId) || {}).name || item.activityId
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell width={5}>
                                                            {this.state.editIndex == index ?
                                                                this.state.editGate.schedules.map((schedule, key) =>
                                                                    <Grid columns="equal" key={`standard-days-${key}`}
                                                                          className={`clear-margin-grid schedule-modal-item-standard-days-${index}-${key}`}>
                                                                        <Grid.Row columns="equal"
                                                                                  className="padding-bottom-0">
                                                                            <Grid.Column width={6}>
                                                                                <Dropdown
                                                                                    placeholder={t('Choose a day')}
                                                                                    fluid
                                                                                    error={!this.state.editGate.schedules[key].dayOfWeeks.length}
                                                                                    multiple
                                                                                    upward={this.isUpward(index)}
                                                                                    selection
                                                                                    className='circular'
                                                                                    value={this.state.editGate.schedules[key].dayOfWeeks}
                                                                                    onChange={(e, {value}) =>
                                                                                        this.handleChangeStandardSchedule("dayOfWeeks", value, key)}
                                                                                    options={dayOfWeeksStub.map(item => ({
                                                                                        key: item,
                                                                                        text: t(item),
                                                                                        value: item
                                                                                    }))}
                                                                                />
                                                                            </Grid.Column>
                                                                            <Grid.Column width={3}>
                                                                                <InputTime
                                                                                    value={this.state.editGate.schedules[key].timeStart}
                                                                                    placeholder={"HH:mm"}
                                                                                    validation={(value) => this.validEditForm(value)}
                                                                                    max={this.state.editGate.schedules[key].timeEnd}
                                                                                    onChange={(e, {value}) =>
                                                                                        this.handleChangeStandardSchedule("timeStart", value, key)}
                                                                                />
                                                                            </Grid.Column>
                                                                            <Grid.Column width={3}>
                                                                                <InputTime
                                                                                    value={this.state.editGate.schedules[key].timeEnd}
                                                                                    placeholder={"HH:mm"}
                                                                                    validation={(value) => this.validEditForm(value)}
                                                                                    min={this.state.editGate.schedules[key].timeStart}
                                                                                    onChange={(e, {value}) =>
                                                                                        this.handleChangeStandardSchedule("timeEnd", value, key)}
                                                                                />
                                                                            </Grid.Column>
                                                                            {this.state.indexDivideScheduleStandard !== key &&
                                                                            <Grid.Column width={1}
                                                                                         className={"padding-0"}>
                                                                                <Button style={{fontSize: "10px"}}
                                                                                        className="margin-top-for-delete"
                                                                                        size="small" onClick={() => {
                                                                                    this.deleteStandardSchedule(key);
                                                                                }}> <Icon name='delete'/></Button>
                                                                            </Grid.Column>
                                                                            }
                                                                            <Grid.Column width={2}>
                                                                                {this.state.indexDivideScheduleStandard !== key &&
                                                                                <BeatTimeSlot
                                                                                    disabled={!!(/*this.isNotFilledScheduleItem([this.state.editGate.schedules[key]]) &&*/ indexOpenBeatTimeSlot !== null && indexOpenBeatTimeSlot !== key)}
                                                                                    contentButton={t('Beat time slot')}
                                                                                    intervalDivideScheduleStandard={{
                                                                                        timeStart: this.state.editGate.schedules[key].timeStart,
                                                                                        timeEnd: this.state.editGate.schedules[key].timeEnd
                                                                                    }}
                                                                                    onAdd={(value) =>
                                                                                        this.divideStandardSchedule(key, value)}
                                                                                    onOpen={() => this.isOpenBeatTimeSlot(key)}
                                                                                    onCancel={() => this.setState({
                                                                                        indexOpenBeatTimeSlot: null
                                                                                    })}
                                                                                />
                                                                                }
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>) :
                                                                item.schedules && item.schedules.map((schedule, key) =>
                                                                    <Grid columns="equal"
                                                                          key={`standard-days-${key}`}
                                                                          className={`clear-margin-grid schedule-modal-item-standard-days-${index}-${key}`}>
                                                                        <Grid.Row verticalAlign="middle" columns="equal"
                                                                                  className="schedule-modal-task__block padding-bottom-0">
                                                                            <Grid.Column width={7}>
                                                                                {this.state.formData[index].schedules[key].dayOfWeeks.length < dayOfWeeksStub.length ?
                                                                                    this.state.formData[index].schedules[key].dayOfWeeks
                                                                                        .sort((a, b) => {
                                                                                            return <div> dayOfWeeksStub.indexOf(a)
                                                                                                -
                                                                                                dayOfWeeksStub.indexOf(b) </div>;
                                                                                        })
                                                                                        .map(x => t(x)).join(', ') :
                                                                                    <div>{t('All days')}</div>
                                                                                }
                                                                            </Grid.Column>
                                                                            <Grid.Column width={4}>
                                                                                <InputTime
                                                                                    value={this.state.formData[index].schedules[key].timeStart}
                                                                                    placeholder={"HH:mm"}
                                                                                    disabled
                                                                                />
                                                                            </Grid.Column>
                                                                            <Grid.Column width={4}>
                                                                                <InputTime
                                                                                    value={this.state.formData[index].schedules[key].timeEnd}
                                                                                    placeholder={"HH:mm"}
                                                                                    disabled
                                                                                />
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>)
                                                            }
                                                            {this.state.editIndex == index &&
                                                            <Grid columns="equal" className="clear-margin-grid ">
                                                                <Grid.Row columns="equal">
                                                                    <Grid.Column>
                                                                        <Button size="small"
                                                                                onClick={this.addEditStandardSchedule}>{t('AddButton')}</Button>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell verticalAlign='top'>
                                                            {this.state.editIndex == index ?
                                                                this.state.editGate.schedules.map((taskScheduleEdit, k) => (
                                                                    <Grid columns="equal"
                                                                          key={`standard-isLoad-${k}`}
                                                                          style={this.getHeightTaskSchedule(index, k)}
                                                                          className={`clear-margin-grid margin-bottom-10 schedule-modal-item-task-schedule-load-${index}-${k}`}>
                                                                        <Grid.Row verticalAlign="top" columns="equal"
                                                                                  className="padding-bottom-0">
                                                                            <Grid.Column>
                                                                                <Checkbox
                                                                                    className={(!taskScheduleEdit.isLoad && !taskScheduleEdit.isUnload && !taskScheduleEdit.isMoving) ? "checkbox-red-border" : null}
                                                                                    toggle
                                                                                    checked={taskScheduleEdit.isLoad}
                                                                                    onChange={(e, {name, checked}) =>
                                                                                        this.handleChangeStandardSchedule("isLoad", checked, k)}/>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>))
                                                                :
                                                                item.schedules && item.schedules.map((taskSchedule, key) => (
                                                                    <Grid columns="equal"
                                                                          key={`standard-isLoad-${key}`}
                                                                          className={`clear-margin-grid margin-bottom-10 schedule-modal-item-task-schedule-load-${index}-${key}`}>
                                                                        <Grid.Row columns="equal"
                                                                                  className="schedule-modal-task__block padding-bottom-0">
                                                                            <Grid.Column>
                                                                                <div
                                                                                    className={"schedule-modal-task-check"}>
                                                                                    {taskSchedule.isLoad &&
                                                                                    <div><Icon name='check'/></div>}
                                                                                </div>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell verticalAlign='top'>
                                                            {this.state.editIndex == index ?
                                                                this.state.editGate.schedules.map((taskScheduleEdit, k) => (
                                                                    <Grid columns="equal"
                                                                          key={`standard-isUnload-${k}`}
                                                                          style={this.getHeightTaskSchedule(index, k)}
                                                                          className={`clear-margin-grid margin-bottom-10 schedule-modal-item-task-schedule-unload-${index}-${k}`}>
                                                                        <Grid.Row verticalAlign="top" columns="equal"
                                                                                  className="padding-bottom-0">
                                                                            <Grid.Column>
                                                                                <Checkbox
                                                                                    className={(!taskScheduleEdit.isLoad && !taskScheduleEdit.isUnload && !taskScheduleEdit.isMoving) ? "checkbox-red-border" : null}
                                                                                    toggle
                                                                                    checked={taskScheduleEdit.isUnload}
                                                                                    onChange={(e, {name, checked}) =>
                                                                                        this.handleChangeStandardSchedule("isUnload", checked, k)}/>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>))
                                                                :
                                                                item.schedules && item.schedules.map((taskSchedule, key) => (
                                                                    <Grid
                                                                        columns="equal"
                                                                        key={`standard-isUnload-${key}`}
                                                                        className={`clear-margin-grid margin-bottom-10 schedule-modal-item-task-schedule-unload-${index}-${key}`}>
                                                                        <Grid.Row verticalAlign="top" columns="equal"
                                                                                  className="schedule-modal-task__block padding-bottom-0">
                                                                            <Grid.Column>
                                                                                <div
                                                                                    className={"schedule-modal-task-check"}>
                                                                                    {taskSchedule.isUnload &&
                                                                                    <div><Icon name='check'/></div>}
                                                                                </div>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                ))}
                                                        </Table.Cell>
                                                        <Table.Cell verticalAlign='top'>
                                                            {this.state.editIndex == index ?
                                                                this.state.editGate.schedules.map((taskScheduleEdit, k) => (
                                                                    <Grid columns="equal"
                                                                          key={`standard-isMoving-${k}`}
                                                                          style={this.getHeightTaskSchedule(index, k)}
                                                                          className={`clear-margin-grid margin-bottom-10 schedule-modal-item-task-schedule-moving-${index}-${k}`}>
                                                                        <Grid.Row verticalAlign="top" columns="equal"
                                                                                  className="padding-bottom-0">
                                                                            <Grid.Column>
                                                                                <Checkbox
                                                                                    className={(!taskScheduleEdit.isLoad && !taskScheduleEdit.isUnload && !taskScheduleEdit.isMoving) ? "checkbox-red-border" : null}
                                                                                    toggle
                                                                                    checked={taskScheduleEdit.isMoving}
                                                                                    onChange={(e, {name, checked}) =>
                                                                                        this.handleChangeStandardSchedule("isMoving", checked, k)}/>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>))
                                                                :
                                                                item.schedules && item.schedules.map((taskSchedule, key) => (
                                                                    <Grid columns="equal"
                                                                          key={`standard-isMoving-${key}`}
                                                                          className={`clear-margin-grid margin-bottom-10 schedule-modal-item-task-schedule-moving-${index}-${key}`}>
                                                                        <Grid.Row verticalAlign="top" columns="equal"
                                                                                  className="schedule-modal-task__block padding-bottom-0">
                                                                            <Grid.Column>
                                                                                <div
                                                                                    className={"schedule-modal-task-check"}>
                                                                                    {taskSchedule.isMoving &&
                                                                                    <div><Icon name='check'/></div>}
                                                                                </div>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                ))}
                                                        </Table.Cell>
                                                        <Table.Cell width={4}>
                                                            {this.state.editIndex == index ?
                                                                this.state.editGate.scheduleExceptions.map((scheduleException, index) =>
                                                                    <Grid verticalAlign="middle" columns="equal"
                                                                          key={`schedule-exception-${index}`}
                                                                          className={"clear-margin-grid margin-top-0"}>
                                                                        <Grid.Row verticalAlign="middle" columns="equal"
                                                                                  className="padding-bottom-0 padding-right-15">
                                                                            <Grid.Column verticalAlign="middle"
                                                                                         width={14}>
                                                                                <ScheduleExceptions
                                                                                    contentButton={`${scheduleException.date} ${t('with')} ${scheduleException.timeStart.slice(0, 5)} ${t('before')} ${scheduleException.timeEnd.slice(0, 5)} ${this.getNameTask(
                                                                                        {
                                                                                            isLoad: scheduleException.isLoad,
                                                                                            isUnload: scheduleException.isUnload,
                                                                                            isMoving: scheduleException.isMoving
                                                                                        })}`}
                                                                                    scheduleException={scheduleException}
                                                                                    onOpen={() => this.onOpenScheduleException(index)}
                                                                                    onCancel={() => this.setState({
                                                                                        editScheduleExceptionIndex: null
                                                                                    })}
                                                                                    disabled={!!(this.state.editScheduleExceptionIndex !== index) && !!(this.state.editScheduleExceptionIndex !== null)}
                                                                                    onChange={(value) =>
                                                                                        this.handleChangeScheduleException(value, index)}
                                                                                />
                                                                            </Grid.Column>
                                                                            <Grid.Column width={1}
                                                                                         className={"padding-0"}>
                                                                                <Button
                                                                                    disabled={this.state.editScheduleExceptionIndex !== null && this.state.editScheduleExceptionIndex >= 0}
                                                                                    style={{fontSize: "10px"}}
                                                                                    size="small" onClick={() => {
                                                                                    this.deleteScheduleException(index)
                                                                                }}> <Icon name='delete'/></Button>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>)
                                                                :
                                                                item.scheduleExceptions && item.scheduleExceptions.map((scheduleException, index) =>
                                                                    <Grid columns="equal"
                                                                          key={`schedule-exception-${index}`}
                                                                          className={"clear-margin-grid margin-top-0"}>
                                                                        <Grid.Row verticalAlign="middle" columns="equal"
                                                                                  className="padding-bottom-0 padding-right-15">
                                                                            <Grid.Column>
                                                                                {scheduleException &&
                                                                                <ScheduleExceptions disabled
                                                                                                    contentButton={`${scheduleException.date} ${t('with')} ${scheduleException.timeStart.slice(0, 5)} ${t('before')} ${scheduleException.timeEnd.slice(0, 5)} ${this.getNameTask(
                                                                                                        {
                                                                                                            isLoad: scheduleException.isLoad,
                                                                                                            isUnload: scheduleException.isUnload,
                                                                                                            isMoving: scheduleException.isMoving
                                                                                                        })}`}
                                                                                                    scheduleException={scheduleException}/>
                                                                                }
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                )
                                                            }
                                                            {this.state.editIndex == index &&
                                                            <Grid columns="equal" className="clear-margin-grid ">
                                                                <Grid.Row columns="equal" className="padding-bottom-0">
                                                                    <Grid.Column>
                                                                        <ScheduleExceptions
                                                                            disabled={this.state.editScheduleExceptionIndex !== null && this.state.editScheduleExceptionIndex >= 0}
                                                                            contentButton={t('Add exception')}
                                                                            onAdd={(value) =>
                                                                                this.addEditScheduleException(value)}
                                                                        />
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                            }
                                                        </Table.Cell>
                                                        {this.state.editIndex == index ?
                                                            <Table.Cell style={{textAlign: "center"}}>
                                                                {this.state.textErrorItem &&
                                                                <Message negative>
                                                                    <p>{this.state.textErrorItem}</p>
                                                                </Message>
                                                                }
                                                                <Button positive size="small" onClick={() => {
                                                                    this.saveForm(item);
                                                                }}>{t('SaveButton')}</Button>
                                                                <Button size="small" onClick={() => {
                                                                    this.cancelForm(item);
                                                                }}>{t('Cancel')}</Button>
                                                            </Table.Cell> :

                                                            <Table.Cell textAlign='center' collapsing>
                                                                {this.state.editIndex !== index && this.state.editIndex === null && <>
                                                                    <Button disabled={!item.isEditGraph} size="small" onClick={() => {
                                                                        this.setState({
                                                                            editIndex: index,
                                                                            editGateOld: JSON.parse(JSON.stringify(item)),
                                                                            editGate: JSON.parse(JSON.stringify(item))
                                                                        });
                                                                    }}><Icon name='edit'/></Button>
                                                                    <Button disabled={!item.isEditGraph} size="small" onClick={() => {
                                                                        this.deleteItem(index)
                                                                    }}> <Icon name='delete'/></Button>
                                                                </>}
                                                            </Table.Cell>
                                                        }
                                                    </Table.Row>
                                                )}
                                            </Table.Body>
                                        </Table>
                                    </Grid.Column>
                                </Grid.Row>
                            </div>
                        </div>
                        {(this.state.editIndex === null && this.state.nameGate && this.props.platformId) &&
                        <Grid.Row columns="equal">
                            <Grid.Column>
                                <Button size="small" onClick={this.addFormDataNew}>{t('Add Activity')}</Button>
                            </Grid.Column>
                        </Grid.Row>
                        }
                        <Confirm dimmer="blurring"
                                 size="small"
                                 open={confirmation.open}
                                 onCancel={() => this.setState({confirmation: {open: false}})}
                                 onConfirm={confirmation.onConfirm}
                                 header={t('the confirmation')}
                                 content={confirmation.content}
                                 cancelButton={t('No')}
                                 confirmButton={t('Yes')}
                        />
                    </Grid>
                </div>
            </ControlledModal>
        )
    }
}
