import React, {useContext} from "react";
import {securityServiceStatusColor} from "../../utils/stateHelper";
import SecurityCheckEditModal from "./components/SecurityCheckEditModal";
import {Button, Label, Table} from "semantic-ui-react";
import {dateFormat} from "../../utils/dateFormat";
import StatusValue from "../../shared/columns/status/Value";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function SecurityCheck() {

    const newModal = (load) =>
        <SecurityCheckEditModal
            title={t('New case')}
            onLoad={load}
        >
            <Button color='blue' className="create-button">{t('Create a case for verification')}</Button>
        </SecurityCheckEditModal>;

    const headerRow = <Table.Row>
        <Table.HeaderCell>{t('Number')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Date_Time_Created')}</Table.HeaderCell>
        <Table.HeaderCell>{t('State')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('Driver')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Initiator')}</Table.HeaderCell>
        <Table.HeaderCell>{t('TransportCompany')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Trip number')}</Table.HeaderCell>
        <Table.HeaderCell>{t('Validation Results')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('Activities blocked')}</Table.HeaderCell>
    </Table.Row>;


    return <LazyLoadDictionary
        className="wider container-margin-top-bottom"
        apiGetList="case/GetListForUser"
        isAll
        newModal={newModal}
        headerRow={headerRow}>
        {(list = [], load) => {
            return list.map(item => {
                return (
                    <SecurityCheckEditModal title={`${t('View Case')} №${item.number} (${t(item.state)})`}
                                            caseId={item.id}
                                            key={item.id}
                                            onLoad={load}
                    >
                        <Table.Row verticalAlign='top' key={item.id} className="security-check-table">
                            <Table.Cell>{item.number}</Table.Cell>
                            <Table.Cell>{dateFormat(item.creationDate)}</Table.Cell>
                           {/* <Table.Cell><StateValue value={item.result || item.state}
                                                    stateColors={securityCheckStateColor()}
                                                    text={t(item.state)}/></Table.Cell>*/}
                            <Table.Cell>{t(item.state)}</Table.Cell>
                            <Table.Cell>{item.driverName}</Table.Cell>
                            <Table.Cell>{item.initiatorName}</Table.Cell>
                            <Table.Cell>{item.transportCompanyName}</Table.Cell>
                            <Table.Cell>{item.tripNumber}</Table.Cell>
                            <Table.Cell>{item.state !=="InWork" && item.state !=="New" ? <StatusValue value={item.result}
                                                       stateColors={securityServiceStatusColor()}
                                                       text={t(item.result)}/> : null}</Table.Cell>
                            <Table.Cell>{item.blockedActivities && item.blockedActivities.map((item, i) =><Label key={i}>{item}</Label>)}</Table.Cell>
                        </Table.Row>
                    </SecurityCheckEditModal>
                );
            });
        }}
    </LazyLoadDictionary>;
}
