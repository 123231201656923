import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {Grid, Icon, Label, Table, Container, Checkbox} from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import Search from "./Search";
import InfiniteScrollTable from "./InfiniteScrollTable";

export default function LazyLoadDictionary(
    { apiGetList, apiChangeActivity, newModal, children, name, className, isVerticalScroll, headerRow, noFilter, isAll, filtersAndSort, clearSelectedAll }) {
    let timerId = null;
    let [list, setList] = useState([]);
    let [filter, setFilter] = useState("");
    let [timerLoad, setTimerLoad] = useState(null);
    let [all, setAll] = useState(false);
    let [page, setPage] = useState(0);
    const isFilterChanged = useRef(false);

    const [filters, setFilters] = useState({});

    const [sort, setSort] = useState({});

    useEffect(() => {
        clearSelectedAll && clearSelectedAll();
    }, [filters]);

    const clearFilters = () => {
        return Object.keys(filters).length || Object.keys(sort).length ? () => {
            setFilters({});
            setSort({});
            setPage(0);
        } : null;
    };

    const setFilterValue = (e, {name, value}) => {
        setFilters((filters) => {
            if (value)
                filters = { ...filters, [name]: value };
            else if (filters[name]) {
                filters = Object.assign({}, filters);
                delete filters[name];
            }
            return filters;
        });
        setPage(0);
    };

    const setSortValue = (e, { name, value }) => {
        if (sort.name === name && (sort.desc === (value === 'desc'))) {
            setSort({});
        } else setSort({name, desc: value === 'desc'});
        setPage(0);
    };

    const load = (isLazy) => {
        const filtersData = filtersAndSort ? {
                filters, sort
            } : {};
        callApi(apiGetList, {
            search: filter,
            all: isAll ? all : null,
            page: isLazy ? page + 1 : page,
            loadPrevious: !isLazy,
            ...filtersData
        })
            .then(newList => {
                if (newList.error) {
                        toast.error(t("Lost connection with server"));
                        throw (t("Lost connection with server"));
                }

                if (!isLazy)
                    setList(newList);
                else if (newList && newList.length > 0) {
                setList(list.concat(
                    newList.filter(x => !list.some(y => y.id === x.id)))
                );
                setPage(page + 1);
            }
        }
        )
    };
    const setTimerAndLoad = () => {
        clearTimeout(timerId);
        load();
        timerId = setTimeout(() => {setTimerLoad(timerLoad + 1)}, 6000000);
    };
    useEffect(setTimerAndLoad, [timerLoad]);
    useEffect(load, [all, apiGetList, filters, sort]);

    const onSearchChange = (e, { value }) => {
        setFilter(value);
        isFilterChanged.current = true;
        setPage(0);
    };

    const onAllChange = (e, {checked}) => {
        setAll(checked);
    };

    const debounceSearchChange = onSearchChange;

    const toggleIsActive = (e, { itemID }) => {
        setList(list.map(el => (el.id === itemID ? Object.assign(el, { isActive: !el.isActive }) : el)));
        callApi(apiChangeActivity, itemID)
            .then(result => {
                if (result.error) {
                    toast.error(result.error);
                    console.log(result.error);
                }
                load();
            });
    };
   /* const [valueTimeSlot, setValueTimeSlot] = useState({date: '07.08.2019', timeStart: '09:00', timeEnd: '09:15'})
    const onChangeTimeSlotValue = (value) => {
        setValueTimeSlot(value)
    };*/

    return (
        <Container className={className}>
            <h2>{name}</h2>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={7}>
                        {!noFilter ? <Search isAuto value={filter} onKeyPressEnter={load} onChange={debounceSearchChange}/> : null}
                        {isAll
                            ? <div className="grid-check-all">
                                <Checkbox checked={all} label={t('show all')} onChange={onAllChange}/>
                            </div>
                            : null}
                    </Grid.Column>
                    <Grid.Column width={9} textAlign='right'>
                        {newModal != undefined ? newModal(load, clearFilters()) : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <InfiniteScrollTable isVerticalScroll={isVerticalScroll} onBottomVisible={() => load(true)}
                headerRow={filtersAndSort ? headerRow(filters, setFilterValue, sort, setSortValue) : headerRow}>
                {children !== undefined ? children(list, load, toggleIsActive) : null}
            </InfiniteScrollTable>
        </Container>
    );
}
