import React, {useRef, useState} from "react";
import {Grid, Button, Popup, Select, Tab, Input, Icon, Confirm} from "semantic-ui-react";
import Search from "../../Search";
import ActivityDropdown from "../../ActivityDropdown";
import {toast} from "react-toastify";

export const GridHeader = ({
	createButton,
	searchValue,
	searchOnChange,
	counter,
	loadColumns,
	clearFilter,
	isFilters,
    isCheckedRows = [],
    gridName,
    isModal,
    onExport,
    editPermission = false,
    deletePermission = false,
    uploadingOrderInformationPermission = false,
    activity
}) => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const fileUploader = useRef(null);
    const fileOrderInformationUploader = useRef(null);

    const uploadExcel = () => {
        fileUploader && fileUploader.current.click();
    };
    const fileOrderInformationUpload = () => {
        fileOrderInformationUploader && fileOrderInformationUploader.current.click();
    };

    const downloadExcel = () => {
        onExport && onExport();
    };

    const deleteRows = () => {
        callApi(`${gridName}/DeleteByIds`, isCheckedRows).then(resp => {
            if (resp.successMessage !== null) toast.info(resp.successMessage);
            else if (resp.errorMessage !== null) toast.error(resp.errorMessage);
            if (!resp.error) {
                setShowConfirmation(false);
                loadColumns();
            }
        });
    };

    const onFilePicked = e => {
        const file = e.target.files[0];
        if (file && file.size){
            const data = new FormData();
            data.append('file', file);

            callApi(`${gridName}/ImportFromExcel`, data, true).then(resp => {
                    if (resp.successMessage !== null) toast.info(resp.successMessage);
                    else if (resp.errorMessage !== null) toast.error(resp.errorMessage);
                    if (!resp.error) {
                        loadColumns();
                    }
            });
        }

        fileUploader.current.value = null;
    };

    const onFileOrderInformationPicked = e => {
        const file = e.target.files[0];
        if (file && file.size) {
            if (file.size > 100000000) {
                toast.error('maxFileSizeExceeded');
            } else {
                const data = new FormData();
                data.append('file', file);
                data.append('activityId', activity);

                callApi(`${gridName}/LoadOrdersInfo`, data, true).then(resp => {
                    if (resp.successMessage !== null) toast.info(resp.successMessage);
                    else if (resp.errorMessage !== null) toast.error(resp.errorMessage);
                    if (!resp.error) {
                        loadColumns();
                    }
                });
            }
        }

        fileOrderInformationUploader.current.value = null;
    };

    return (
        <Grid className="visits-header-panel">
            <Grid.Row>
                <Grid.Column width={9}>
                    {!isModal && createButton}
                    <Search value={searchValue} onChange={searchOnChange} />
                    <span className="records-counter"><b>{counter}</b> {t("records")}</span>
                </Grid.Column>
                <Grid.Column width={7} className="visit-right-elements">
                    {!isModal && <>
                        {uploadingOrderInformationPermission && <Popup
                            trigger={<Button icon="boxes" onClick={fileOrderInformationUpload}/>}
                            content={t("uploadingOrderInformation")}
                            position="bottom right"
                        />}
                        {editPermission && <Popup
                            trigger={<Button icon="upload" onClick={uploadExcel}/>}
                            content={t("upload-excel")}
                            position="bottom right"
                        />}
                        <Popup
                            trigger={<Button icon="download" onClick={downloadExcel}/>}
                            content={t("download-excel")}
                            position="bottom right"
                        />
                        {deletePermission && <Popup
                            trigger={<Button icon="trash" onClick={() => setShowConfirmation(true)}
                                             disabled={!isCheckedRows.length}/>}
                            content={t("delete")}
                            position="bottom right"
                        />}
                    </>}
                    {!isModal && <ActivityDropdown onChange={loadColumns} isModal={isModal}/>}
                    <Button color={isFilters ? 'orange' : null} onClick={clearFilter}>
                        {t("reset_filters")}
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={onFilePicked}
            />
            <input
                type="file"
                ref={fileOrderInformationUploader}
                style={{ display: 'none' }}
                onChange={onFileOrderInformationPicked}
            />
            <Confirm
                dimmer="blurring"
                open={showConfirmation}
                onCancel={() => setShowConfirmation(false)}
                onConfirm={deleteRows}
                content={t("delete_order_module")}
                cancelButton={t("CancelButton")}
                confirmButton={t("Delete")}
            />
        </Grid>
    );

};
