import React from "react";
import {Grid, Modal, Form, Button, Label, Icon, Message, Dropdown, Checkbox} from "semantic-ui-react";
import ControlledModal from "../../../shared/modals/ControlledModal";
import EditOther from "../../../shared/columns/boolean/EditOther";
import InputRegExp from "../../../shared/columns/number/inputNumber";
import {convertingStringToNumber} from "../../../const/functions";

export default class GateCharacteristicsEditModal extends React.Component {
    state = this.initialState;

    get initialState() {
        return {
            modal: false,
            formData: {
            },
            errorText: "",
            options: []
        };
    }

    componentWillUnmount () {
        this.setState(this.initialState)
    }

    componentDidUpdate(prevProps, prevState) {
        const {formData} = this.state;
        if (prevState.formData.type !== formData.type && formData.type === 'Boolean' && !this.props.id) {
            this.setState(prevState => {
                return {formData: {...prevState.formData, defaultValue: false}}
            })
        }
    }

    load = () => {
        if (this.props.id)
            return callApi("GateCustomFields/GetById", this.props.id).then(data => {
                this.setState({
                    formData: data,
                    options: data.valuesForSelectType.map(value => ({text: value, value}))
                });
            });
    };

    save = () => {
        this.setState({isLoading: true});
        if (this.props.id)
            return callApi("GateCustomFields/Update", this.state.formData)
                .then(data => {
                    if (data.error !== "") {
                        this.setState({
                            errorText: data.error
                        });
                    }
                    else this.setState({
                        formData: {}
                    });
                    return data;
                }).finally(() => {
                    this.setState({isLoading: false});
                });
        return callApi("GateCustomFields/Create", this.state.formData)
            .then(data => {
                if (data.error !== "") {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            }).finally(() => {
                this.setState({isLoading: false});
            });
    };

    onClose = () => {
        if (this.props.onClose !== undefined)
            this.props.onClose();
        this.setState(this.initialState);
    };

    handleChange = (e, {name, value}) => {
        let defaultValueNew = this.state.formData.defaultValue;
        if (name === "type") {
            defaultValueNew = null
        }
        this.setState(prevState => {
            return {formData: {...prevState.formData, [name]: value, defaultValue: defaultValueNew}};
        });
    };

    handleChangeDefaultValue = (e, {value}) => {
        this.setState(prevState => {
            return {formData: {...prevState.formData, defaultValue: value}};
        });
    };

    onBlurNumberDefaultValue = (value) => {
        let valueNew = value ? convertingStringToNumber(value): 0
        this.setState(prevState => {
            return {formData: {...prevState.formData, defaultValue: Number(valueNew)}}
        });
    };


    handleChangeToggle = () => {
        this.setState(prevState => {
            return {formData: {...prevState.formData, defaultValue: !prevState.formData.defaultValue}};
        });
    };

    handleAddition = (e, {value}) => {
        this.setState({
            options: [{text: value, value}, ...this.state.options],
        });
    };

    handleChangeValuesForSelectType = (e, {value}) => this.setState(prevState => {
        return {formData: {...prevState.formData, valuesForSelectType: value}};
    });

    render() {
        const {formData, options} = this.state;
        return (
            <ControlledModal
                saveButtonDisabled={this.state.isLoading}
                saveButtonLoading={this.state.isLoading}
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                onClose={this.onClose}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                onSave={this.save}
                size="small">
                <Form>
                    <Grid columns='equal' className="margin-bottom-10">
                        <Grid.Row columns='equal'>
                            <Grid.Column>
                                <Form.Field>
                                    <Form.Input label={t('Name')} name="name" value={formData.name || ''}
                                                onChange={this.handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t('Type')}</label>
                                    <Dropdown
                                        fluid
                                        disabled={!!this.props.id && !formData.isFieldTypeEditable}
                                        selection
                                        name="type"
                                        value={formData.type}
                                        options={[
                                            {text: "Text", value: "Text"},
                                            {text: "Number", value: "Number"},
                                            {text: "Boolean", value: "Boolean"},
                                            {text: "Select", value: "Select"},
                                        ]} onChange={this.handleChange}/>
                                </Form.Field>
                                <Form.Field>
                                    {formData.type === 'Boolean' &&
                                    <EditOther
                                        name={t('Default value')}
                                        value={formData.defaultValue}
                                        onChange={this.handleChangeToggle}/>
                                    }
                                    {formData.type === 'Text' &&
                                    <Form.Input label={t('Default value')} name="defaultValue"
                                                value={formData.defaultValue || ''}
                                                onChange={this.handleChangeDefaultValue}
                                    />
                                    }
                                    {formData.type === 'Number' &&
                                    <>{/*<Form.Input label={t('Default value')} name="defaultValue"
                                                value={formData.defaultValue}
                                                type="number"
                                                onBlur={() => this.onBlurNumberDefaultValue(formData.defaultValue)}
                                                onChange={this.handleChangeDefaultValue}
                                    />*/}
                                    <InputRegExp label={t('Default value')} name="defaultValue"
                                        value={formData.defaultValue}
                                        reg={'^[+-]?([0-9]+([.,][0-9]*)?|[.][0-9]+)$'}
                                        onBlur={() => this.onBlurNumberDefaultValue(formData.defaultValue)}
                                        onChange={this.handleChangeDefaultValue}
                                        />
                                    </>
                                    }
                                    {formData.type === 'Select' &&
                                    <Form.Dropdown
                                        label={t('Default value')}
                                        name="defaultValue"
                                        options={formData.valuesForSelectType && formData.valuesForSelectType.length ? (formData.valuesForSelectType || []).map(item => ({
                                            text: item,
                                            value: item
                                        })) : []}
                                        noResultsMessage={`${t('Set a list of possible')} ->`}
                                        search
                                        placeholder={`${t('Set a list of possible')} ->`}
                                        selection
                                        fluid
                                        value={formData.defaultValue}
                                        onChange={this.handleChangeDefaultValue}
                                    />
                                    }
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Message
                                    success
                                    content={t('Define_gate_characteristics')}
                                />
                                {formData.type === 'Select' &&
                                <Form.Field className={"gate-characteristics_edit-modal"}>
                                    <Form.Dropdown
                                        label={t('Set value to select')}
                                        name="valuesForSelectType"
                                        options={options}
                                        placeholder={t('Values')}
                                        search
                                        selection
                                        fluid
                                        multiple
                                        allowAdditions
                                        value={formData.valuesForSelectType}
                                        onAddItem={this.handleAddition}
                                        onChange={this.handleChangeValuesForSelectType}
                                    />
                                </Form.Field>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </ControlledModal>
        );
    }
}
