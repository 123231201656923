/*

Последняя редакция: 2021.05.05


[2021.05.05]
- изменил сообщение об ошибке

*/

import React, {useState, useEffect} from "react";
import {Button, Icon, Message, Loader} from "semantic-ui-react";
import { Route, Redirect } from "react-router-dom";
import * as AuthHelper from "../../utils/authHelper";


export default class CreateVisitFromBJ extends React.Component {
    errorSourceDescriptionParams = 'Ошибка при обработке параметров'
    //errorSourceDescriptionServer = 'Ошибка при создании визита'
	errorSourceDescriptionServer = 'Статус'

    state = {
        params: [],
        error: null,
        errorSourceDescription: null,
    };

    componentDidMount() {
        if(AuthHelper.isLogged() === false){
            window.location = '/login?backUrl=' + encodeURI(window.location.href.replace(window.location.origin, ''));
            return;
        }

        callApi('CreateVisitFromBJ/GetParams').then((data) => {
            this.setState({
                params: data,
            }, ()=> {
                let urlParams = window.location.search
                    .substr(1, window.location.search.length - 1)
                    .split('&')
                    .map(x => x.split('='))
                    .filter(x=> x[0] !== "")
                    .map(x => {return {name: x[0], value: x[1]};})
                ;

                if(urlParams.length === 0){
                    this.setState({
                        error: 'Параметры не заданы',
                        errorSourceDescription: this.errorSourceDescriptionParams
                    })
                }

                for (let i = 0; i < urlParams.length; i++){
                    let urlParam = urlParams[i];
                    if(data.filter(x=>x === urlParam.name).length === 0){
                        this.setState({
                            error: 'Неизвестный параметр: ' + urlParam.name,
                            errorSourceDescription: this.errorSourceDescriptionParams
                        })
                        return;
                    }
                }

                for (let i = 0; i < data.length; i++){
                    let param = data[i];
                    if(urlParams.filter(x=>x.name === param).length === 0){
                        this.setState({
                            error: 'Нет обязательного параметра: ' + param,
                            errorSourceDescription: this.errorSourceDescriptionParams
                        });
                        return;
                    }
                    if(urlParams.filter(x=>x.name === param)[0].value === null){
                        this.setState({
                            error: 'Не задано значение обязательного параметра: ' + param,
                            errorSourceDescription: this.errorSourceDescriptionParams
                        });
                        return;
                    }
                }

                callApi('CreateVisitFromBJ/Create', urlParams).then(resp=> {
                    if(resp.error == null)
                        window.location  = window.location.origin + "/visits/" + resp.id;
                    else
                        this.setState({
                            error: resp.error,
                            errorSourceDescription: this.errorSourceDescriptionServer
                        });
                })
            });
        });
    }

    render (){
        return <><div className="centered-div">
            {this.state.error !== null ? <Message>
                <Message.Header>{this.state.errorSourceDescription}</Message.Header>
                <p>{this.state.error}</p>
            </Message> : <Loader active size='massive'/>}
        </div></>;
    }

};
