import React, { useContext, useState, useCallback} from "react";
import {Grid, Icon, Segment, Form, Checkbox} from "semantic-ui-react";
import TkCompanyTypeEdit from "./TkCompanyTypeEdit"
import TextEdit from "../../../../shared/columns/text/Edit";
import {UserPermissionsContext} from "../../../../Contexts";

export default function CompanyInfo({isView, formData, companyList = [], handleChange, loadFields, onSaveCompany}) {
    const {permissionsData} = useContext(UserPermissionsContext);

    const tcModeInitialValue = !!formData.transportCompanySearchName;
    const [tcMode, setTcMode] = useState(tcModeInitialValue); // Режим выбора ТК: false - из списка, true - ввод SearchName ТК
    const handleTcModeChange = useCallback((e, { value, checked }) => {
        setTcMode(value === 'tc' ? false : true)
        if (value === 'tc') {
            handleChange("transportCompanySearchName", null);
        }
        else {
            handleChange("transportCompanyId", null);
            handleChange("transportCompanyInn", null);
        }
    }, [handleChange]);

    return (<Segment>
        <Grid className="margin-bottom-10">
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column width={8}>
                    <Checkbox
                        radio
                        label={t('TC Choice') + (tcMode ? "" : " *")}
                        name="transportCompanyMode"
                        value="tc"
                        readOnly={isView}
                        checked={!tcMode}
                        onChange={handleTcModeChange}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Checkbox
                        radio
                        label={t('TC SearchName') + (tcMode ? " *" : "")}
                        name="transportCompanyMode"
                        value="searchname"
                        readOnly={isView}
                        checked={tcMode}
                        onChange={handleTcModeChange}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column width={8}>
                    <TkCompanyTypeEdit
                        value={formData.transportCompanyId}
                        isDisabled={isView || tcMode}
                        onChange={(e, {name, value, parameter}) =>  handleChange("transportCompanyId", value, parameter)}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <TextEdit
                        isDisabled={isView || !tcMode}
                        value={formData.transportCompanySearchName}
                        onChange={(e, { name, value }) => handleChange("transportCompanySearchName", value)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column width={8}>
                    <TextEdit
                        isDisabled={true}
                        name={t('inn')}
                        value={formData.transportCompanyInn}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column>
                    <Checkbox
                        label={t('Attracted transport')}
                        disabled={isView}
                        checked={formData.isAttracted}
                        onChange={(e, {checked}) => {
                            handleChange("isAttracted", checked);
                        }}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column width={8}>
                    <TextEdit
                        isDisabled
                        name={t('TIN of the subcontractor')}
                        value={formData.subcontractorInn}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <TextEdit
                        isDisabled
                        name={t('Subcontractor phone')}
                        value={formData.subcontractorPhoneNumber}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal" className="padding-bottom-0">
                <Grid.Column width={8}>
                    <TextEdit
                        isDisabled={isView}
                        name={t('comment_for_the_security_service')}
                        value={formData.attractedCompanyAtiCode}
                        onChange={(e, {name, value}) =>
                            handleChange("attractedCompanyAtiCode", value)
                        }
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>);
}