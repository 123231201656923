import React from "react";
import { Button, Grid, Popup, Icon, Modal, Label, Select, Input, Tab, Dropdown, Menu, Form, Checkbox } from "semantic-ui-react";
import * as StateHelper from "../../utils/stateHelper";
import { debounce } from "throttle-debounce";
import {toast} from "react-toastify";
import SelectFilter from "./selectFilter";
import {UserPermissionsContext} from "../../Contexts";

export default class FieldMatrix extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        activeItem: `${t('Visits')}`,
        platforms: [],
        activities: [],
        roles: [],
        states: StateHelper.visitStatesFieldMatrix(),
        taskStates: StateHelper.taskStatesFieldMatrix(),
        ordersModuleStates: StateHelper.ordersModuleStatesFieldMatrix(),
        visitFields: [],
        taskFields: [],
        orderFields: [],
        ordersmoduleFields: [],
        backlights: [],
        currentPlatform: null,
        currentActivity: null,
        currentRole: null,
        filter: ""
    };

    componentWillMount() {
        this.reload();
    }

    getCurrentEntity = () => {
        switch (this.state.activeItem) {
            case `${t('Visits')}`: return "Visit";
            case `${t('Tasks')}`: return "Task";
            case `${t('Orders')}`: return "Order";
            case `${t('OrdersModule')}`: return "OrdersModule";
        }
    }

    reload = (data = {}) => {
        const { permissionsData } = this.context;
        const isAdminKu = permissionsData.isAdminKU;

        const {savedField = null, afterSave = false} = data;
        let forEntity = this.getCurrentEntity();
        let variableName = forEntity.toLowerCase() + "Fields";
        callApi("fieldMatrix/getSelectors",
            {
                platformId: this.state.currentPlatform,
                activityId: this.state.currentActivity,
                roleId: this.state.currentRole,
            }).then(selectors => {
                if (selectors.error) {
                    toast.error(t("Lost connection with server"));
                    throw (t("Lost connection with server"));
                }
                callApi("fieldMatrix/getFor",
                    {
                        platformId: selectors.platformId,
                        activityId: selectors.activityId,
                        roleId: selectors.roleId,
                        forEntity: forEntity,
                        savedField,
                        afterSave
                    }).then(data => {
                        if (data.error) {
                            toast.error(t("Lost connection with server"));
                            throw (t("Lost connection with server"));}
                        this.setState({
                            currentPlatform: selectors.platformId,
                            currentActivity: selectors.activityId,
                            currentRole: selectors.roleId,

                            platforms: selectors.platforms,
                            activities: selectors.activities,
                            roles: selectors.roles,

                            [variableName]: data
                        });
                    });
            });
    }

    dropdown = (visitField, index) => {
        let value = (visitField.fieldMatrixAccessTypes[index] || {}).accessType;
        return <Dropdown className="normal-weight" onChange={(e, { value }) => { this.OnChangeAccessType(visitField, index, e, value); }} inline options={[{ text: t('Hidden'), value: "Hidden" }, { text: t('View'), value: "Show" }, { text: t('EditHeader'), value: "Edit" }]} value={value} />;
    }

    messageToast = (data) => {
        data.isError ? toast.error(data.error) : toast.info(data.error);
    }

    OnChangeAccessType = (visitField, index, e, value) => {
        let fieldMatrixAccessType = visitField.fieldMatrixAccessTypes[index];
        fieldMatrixAccessType.accessType = value;
        visitField.fieldMatrixAccessTypes = [fieldMatrixAccessType];
        callApi("fieldMatrix/save", {
            platformId: this.state.currentPlatform,
            activityId: this.state.currentActivity,
            roleId: this.state.currentRole,
            fields: [visitField],
            forEntity: this.getCurrentEntity()
        }).then((data)=> {
            this.messageToast(data);
            this.reload({savedField: visitField.name, afterSave: true});
        });
    }

    ChangeAccessTypeForRow = (visitField, accessType) => {
        for (let i = 0; i < visitField.fieldMatrixAccessTypes.length; i++) {
            let fieldMatrixAccessType = visitField.fieldMatrixAccessTypes[i];
            fieldMatrixAccessType.accessType = accessType;
        }

        callApi("fieldMatrix/save", {
            platformId: this.state.currentPlatform,
            activityId: this.state.currentActivity,
            roleId: this.state.currentRole,
            fields: [visitField],
            forEntity: this.getCurrentEntity()
        }).then((data)=> {
            this.messageToast(data);
            this.reload({savedField: visitField.name, afterSave: true});
        });
    }

    onChange = (e, { name, value }) => {
        this.setState({ [name]: value }, this.reload);
    }

    handleChangePlatform = (e, { value }) => { this.setState({ currentPlatform: value }, this.reload); }
    handleChangeActivity = (e, { value }) => { this.setState({ currentActivity: value }, this.reload); }
    handleChangeRole = (e, { value }) => { this.setState({ currentRole: value }, this.reload); }

    setFilter = (e, { value }) =>
        this.setState({ filter: value.toLowerCase() });

    debounceSetFilter = debounce(300, this.setFilter)

    FieldRow = (field, states) => {
        return <tr key={field.name}>
            <td><b>{field.name}</b>   <Popup
                trigger={<Button size='mini'>All</Button>}
                content={<Button.Group>
                    <Button size='mini' onClick={() => { this.ChangeAccessTypeForRow(field, "Hidden"); }}>Hide</Button>
                    <Button size='mini' onClick={() => { this.ChangeAccessTypeForRow(field, "Show"); }}>Show</Button>
                    <Button size='mini' onClick={() => { this.ChangeAccessTypeForRow(field, "Edit"); }}>Edit</Button>
                </Button.Group>}
                on='click'
                position='top right'
            /></td>
            {states.map((state, index) => <td key={state.text + index}>{this.dropdown(field, index)}</td>)}
        </tr>;
    }

    statesList = (item) => {
        switch (item) {
            case t('Visits'): return this.state.states;
            case t('OrdersModule'): return this.state.ordersModuleStates;
            default: return this.state.taskStates;
        }
    }

    render() {
        const { permissionsData } = this.context;
        const { permissions } = permissionsData;

        let { visitFields, taskFields, orderFields, ordersmoduleFields, filter } = this.state;
        if (filter) {
            visitFields = visitFields.filter(x => x.name.toLowerCase().includes(filter));
            taskFields = taskFields.filter(x => x.name.toLowerCase().includes(filter));
            orderFields = orderFields.filter(x => x.name.toLowerCase().includes(filter));
            ordersmoduleFields = ordersmoduleFields.filter(x => x.name.toLowerCase().includes(filter));
        }
        let fields = visitFields;
        switch (this.state.activeItem) {
            case `${t('Tasks')}`: fields = taskFields; break;
            case `${t('Orders')}`: fields = orderFields; break;
            case `${t('OrdersModule')}`: fields = ordersmoduleFields; break;
        }
        let states = this.statesList(this.state.activeItem);
        return (
            <>
                <div className="padding-top-20">
                    <Menu>
                        {permissions.includes("ViewVisits") && <Menu.Item key="Visit" active={this.state.activeItem === `${t("Visits")}`} onClick={() => {
                            this.setState({activeItem: `${t("Visits")}`, filter: ""}, () => {
                                this.reload();
                            });
                        }}>
                            {t("Visits")}
                        </Menu.Item>}
                        {permissions.includes("ViewTasks") && <Menu.Item key="Task" active={this.state.activeItem === `${t("Tasks")}`} onClick={() => {
                            this.setState({activeItem: `${t("Tasks")}`, filter: ""}, () => {
                                this.reload();
                            });
                        }}>
                            {t("Tasks")}
                        </Menu.Item>}
                        {permissions.includes("ViewTasks") && <Menu.Item key="Order" active={this.state.activeItem === `${t("Orders")}`} onClick={() => {
                            this.setState({activeItem: `${t("Orders")}`, filter: ""}, () => {
                                this.reload();
                            });
                        }}>
                            {t("Orders")}
                        </Menu.Item>}
                        {permissions.includes("ViewOrderModule") && <Menu.Item key="OrdersModule" active={this.state.activeItem === `${t("OrdersModule")}`}
                                    onClick={() => {
                                        this.setState({activeItem: `${t("OrdersModule")}`, filter: ""}, () => {
                                            this.reload();
                                        });
                                    }}>
                            {t("OrdersModule")}
                        </Menu.Item>}
                        <Menu.Item>
                            <SelectFilter placeholder={t('role')} selection options={this.state.roles.map(x => {
                                return { key: x.name, value: x.id, text: x.name };
                            })} onChange={this.handleChangeRole} value={this.state.currentRole} />
                        </Menu.Item>
                        {this.state.activeItem !== t('OrdersModule') && <Menu.Item>
                            <SelectFilter placeholder={t("Platform")} selection options={this.state.platforms.map(x => {
                                return {key: x.name, value: x.id, text: x.name};
                            })} onChange={this.handleChangePlatform} value={this.state.currentPlatform}/>
                        </Menu.Item>}
                        <Menu.Item>
                            <SelectFilter placeholder={t('Activity')} selection options={this.state.activities.map(x => {
                                return { key: x.name, value: x.id, text: x.name };
                            })} onChange={this.handleChangeActivity} value={this.state.currentActivity} />
                        </Menu.Item>
                    </Menu>
                </div>
                <div className="padding-20">
                    <table className="ui celled table">
                        <thead>
                            <tr>
                                <th>
                                    <Input placeholder={t('Search by field')} icon='search' onChange={this.debounceSetFilter} />
                                </th>
                                {states.map(state => <th key={state.text}><Label className="status-label-bottom" color={state.stateColor}>{state.text}</Label></th>)}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="ui ribbon label">{t('Main fields')}</div>
                                </td>
                                {states.map((state, i) => <td key={i}></td>)}
                            </tr>
                            {fields.filter(f => f.isCustom === false).map(field => {
                                return this.FieldRow(field, states);
                            })}
                            <tr>
                                <td>
                                    <div className="ui ribbon label">{t('Custom fields')}</div>
                                </td>
                                {states.map((state, i) => <td key={i}></td>)}
                            </tr>
                            {fields.filter(f => f.isCustom === true).map(field => {
                                return this.FieldRow(field, states);
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
};
