import React from "react";
import {Checkbox, Container, Grid, Label, Progress, Tab, Table, Visibility} from "semantic-ui-react";
import { toast } from "react-toastify";
import SecurityServiceList from "./SecurityServiceList";
import Search from "../../shared/Search";

const initState = {
    list: [],
    timerLoad: null,
    page: 0,
    all: true,
    filter: "",
    counterList: [],
    isCloseToExpired: false,
    isExpired: false,
    activeTab: "New",
    caseNumber: null
};


export default class SecurityService  extends React.Component {

    state = {
        ...initState
    };

    componentDidMount() {
        this.setTimerAndLoad();
    }

    componentDidUpdate(prevProps, prevState) {
        const {all, isExpired, isCloseToExpired, activeTab} = this.state;
        if (prevState.all !== all || prevState.isExpired !== isExpired || prevState.isCloseToExpired !== isCloseToExpired) {
            this.getFilter();
            this.load();
        }
        if (prevState.activeTab !== activeTab) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    setTimerAndLoad = () => {
        this.load();
        this.setTimer();
    };

    setTimer = () => {
        if (this.timerId !== undefined)
            clearInterval(this.timerId);
        this.timerId = setInterval(() => { this.load(); }, 600000);
    };

    clearTimer = () => {
        clearInterval(this.timerId);
    };

    checkboxTable = () => {
        const {all, isCloseToExpired, isExpired} = this.state;
        return (
            <div>
                <p>{all} {isCloseToExpired} {isExpired}</p>
                <Checkbox checked={all} label={t('show all')} onChange={this.onAllChange}/>
                <Checkbox className="color-orange" checked={isCloseToExpired} label={t('Close to delay')}
                          onChange={this.onIsCloseToExpiredChange}/>
                <Checkbox className="color-red" checked={isExpired} label={t('Overdue')} onChange={this.onIsExpiredChange}/>
            </div>
        );
    };

    load = (isLazy) => {
        const {activeTab, all, isCloseToExpired, isExpired, page, list, filter} = this.state;
        if (activeTab === "ProcessingCompleted") {
            this.setState({
                all: true,
                isCloseToExpired: false,
                isExpired: false
            });
        }
        callApi("case/GetListForSecurityService", {
            state: activeTab.toString(),
            all: all,
            search: filter,
            isCloseToExpired: isCloseToExpired,
            isExpired: isExpired,
            page: isLazy ? page + 1 : page,
            loadPrevious: !isLazy
        })
            .then(newList => {
                if (newList.error) {
                    toast.error(t("Lost connection with server"));
                    throw (t("Lost connection with server"));
                } else if (!isLazy)
                    this.setState({
                        list: newList
                    });
                else if (newList && newList.length > 0) {
                    this.setState({
                        list: list.concat(
                            newList.filter(x => !list.some(y => y.id === x.id))),
                        page: page + 1
                    });
                }
            })

        callApi("case/getcounters", { search: filter })
            .then(counterList => {
                if (counterList.error) {
                   return
                }
                this.setState({
                    counterList
                });
            })
    };

    handleTabChange = (e, ...props) => {
        const [item] = props;

        this.setState({
            activeTab: item.panes[item.activeIndex].key,
            list: []
        });
    };

    onAllChange = (e, {checked}) => {
        this.setState({
            all: checked
        });
        if (checked) {
            this.setState({
                isCloseToExpired: false,
                isExpired: false
            });
        }
    };

    onIsCloseToExpiredChange = (e, {checked}) => {
        this.setState({
            isCloseToExpired: checked
        });
        if (checked) {
            this.setState({
                all: false,
                isExpired: false
            });
        }
    };

    onIsExpiredChange = (e, {checked}) => {
        this.setState({
            isExpired: checked
        });
        if (checked) {
            this.setState({
                all: false,
                isCloseToExpired: false
            });
        }
    };

    getCounterRender = (name) => {
        const {counterList} = this.state;
        const counterTab = counterList.find((item) => item.state === name) || {};
        if (Object.keys(counterTab).length !== 0 && name !=="ProcessingCompleted") {
            return `(${counterTab.count}/${counterTab.isExpiredCount})`;
        }
        if (Object.keys(counterTab).length !== 0 && name ==="ProcessingCompleted") {
            return `(${counterTab.count})`;
        }
        return "(0/0)";
    };

    getFilter = () => {
        const {isExpired, isCloseToExpired} = this.state;

        if (!isExpired && !isCloseToExpired) {
            this.setState({
                all: true
            });
        }
    };

    onSearchChange = (e, { value }) => {
        this.setState({
            filter: value,
            page: 0,
        });
    };

    render () {
        const {activeTab, list, caseNumber, filter} = this.state;

        return (
            <Container className='wider container-margin-top-bottom'>
                <div style={{marginBottom: "12px"}}>
                    <Search isAuto value={filter} onKeyPressEnter={this.load} onChange={this.onSearchChange}/>
                </div>
                <Tab panes={[
                    {
                        menuItem: `${t('new_e')} ${this.getCounterRender("New")}`,
                        key: "New",
                        render: () =>
                            <Tab.Pane>
                                <div className="grid-check-all">
                                    {this.checkboxTable()}
                                </div>
                                <SecurityServiceList
                                    list={list}
                                    load={this.load}
                                    activeTab={activeTab}
                                    stopTimer={this.clearTimer}
                                    runTimer={this.setTimerAndLoad}
                                />
                            </Tab.Pane>
                    },
                    {
                        menuItem: `${t('Returned to the initiator')} ${this.getCounterRender("Returned")}`,
                        key: "Returned",
                        render: () => {
                            return <Tab.Pane>
                                <div className="grid-check-all">
                                    {this.checkboxTable()}
                                </div>
                                <SecurityServiceList
                                    list={list}
                                    load={this.load}
                                    activeTab={activeTab}
                                    stopTimer={this.clearTimer}
                                    runTimer={this.setTimerAndLoad}
                                />
                            </Tab.Pane>;
                        }
                    },
                    {
                        menuItem: `${t('InWork')} ${this.getCounterRender("InWork")}`,
                        key: "InWork",
                        render: () => {
                            return <Tab.Pane>
                                <div className="grid-check-all">
                                    {this.checkboxTable()}
                                </div>
                                <SecurityServiceList
                                    list={list}
                                    load={this.load}
                                    activeTab={activeTab}
                                    stopTimer={this.clearTimer}
                                    runTimer={this.setTimerAndLoad}
                                />
                            </Tab.Pane>;
                        }
                    },
                    {
                        menuItem: `${t('Spent')} ${this.getCounterRender("ProcessingCompleted")}`,
                        key: "ProcessingCompleted",
                        render: () => {
                            return <Tab.Pane>
                                <SecurityServiceList
                                    list={list}
                                    load={this.load}
                                    activeTab={activeTab}
                                    stopTimer={this.clearTimer}
                                    runTimer={this.setTimerAndLoad}
                                />
                            </Tab.Pane>;
                        }
                    }
                ]}
                     onTabChange={this.handleTabChange}
                />
            </Container>
        );
    }

}
