import React from "react";
import {Link} from "react-router-dom";
import {Form, Message, Button, Header, Icon, Modal, Divider} from "semantic-ui-react";
import {toast} from "react-toastify";

class PasswordField extends React.Component {
    state = {
        type: "password",
    };


    handleClick = () => this.setState(({type}) => ({
        type: type === "text" ? "password" : "text"
    }));


    render() {
        const {label} = this.props;

        return (
            <Form.Field className={"password-with-eye"}>
                <Icon name={this.state.type === "text" ? "hide" : "eye"} onClick={this.handleClick}/>
                <Form.Input label={label} iconPosition="left">
                    <Icon name="lock"/>
                    <input type={this.state.type} onChange={this.props.onChange}/>
                </Form.Input>
            </Form.Field>
        );
    }
}

export default class ChangePasswordModal extends React.Component {

    resetToken;
    state = this.initialState;

    get initialState() {
        return {
            oldPassword: null,
            password: null,
            passwordConfirm: null,
            errorText: null,
            modalOpen: false,
        };
    }

    onOpen = () => {
        this.setState({
            modalOpen: true,
        });
    };

    onClose = () => {
        this.setState(this.initialState);
    };

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value
        });
    };

    handleOldPasswordChange = (e) => {
        this.setState({
            oldPassword: e.target.value
        });
    };

    handleConfirmPasswordChange = (e) => {
        this.setState({
            passwordConfirm: e.target.value
        });
    };

    okBtnClick = () => {
        if (!(this.validateLength() && this.validateDigital() && this.validateRegistry()))
            return;

        callApi(`Profile/ResetPassword`, {
            oldPassword: this.state.oldPassword,
            newPassword: this.state.password
        }, false)
            .then((result) => {
                if (result.isError)
                    this.setState({errorText: result.error});
                else {
                    this.onClose();
                    toast.info(t("passwordIsReset"));
                }
            })
            .catch(err => {
                this.setState({errorText: err.message});
            });
    };

    validateLength = () => {
        if (!this.validateEq()) return false;
        let password = this.state.password;
        return password.length >= 6 && password.length <= 32;
    };

    validateDigital = () => {
        if (!this.validateEq()) return false;
        return /^.*(?=.*\d).*$/.test(this.state.password);
    };

    validateRegistry = () => {
        if (!this.validateEq()) return false;
        return /^.*(?=.*[a-z]).*$/.test(this.state.password) && /^.*(?=.*[A-Z]).*$/.test(this.state.password) && /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/.test(this.state.password);
    };

    validateEq = () => {
        if (this.state.password !== null)
            return this.state.password === this.state.passwordConfirm;
        return false;
    };

    componentDidMount() {
        this.props.open && this.onOpen();
    }

    render() {
        return (
            <Modal
                dimmer="blurring"
                id="fieldModal"
                className="profile-modal"
                trigger={this.props.children}
                open={this.state.modalOpen}
                onOpen={this.onOpen}
                size={"tiny"}
                onClose={this.onClose}
                closeOnEscape={!this.props.open}
                closeOnDimmerClick={false}
                closeIcon={!this.props.open}
            >
                <Modal.Content>
                    <Form error>
                        <Form.Field>
                            <Header as="h2">
                                {t("change_pass")}
                            </Header>
                        </Form.Field>
                        <PasswordField label={t("oldPassword")} onChange={this.handleOldPasswordChange}/>
                        {this.state.errorText ? <Message error={true}>{t(this.state.errorText)}
                        </Message> : ""}

                        <Divider/>
                        <PasswordField label={t("new_pass")} onChange={this.handlePasswordChange}/>
                        <PasswordField label={t("retype_pass")} onChange={this.handleConfirmPasswordChange}/>
                        <Message>
                            <div><Icon
                                name={"check" + (this.validateEq() ? " green" : "")}/> {t("Passwords must match")}</div>
                            <div><Icon
                                name={"check" + (this.validateLength() ? " green" : "")}/> {t("Password must be between 6 and 32 characters")}
                            </div>
                            <div><Icon
                                name={"check" + (this.validateDigital() ? " green" : "")}/> {t("Password must contain numbers")}
                            </div>
                            <div><Icon
                                name={"check" + (this.validateRegistry() ? " green" : "")}/> {t("Password must contain latin letters in different case")}
                            </div>
                        </Message>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Modal dimmer="blurring" size="mini" open={this.state.modalOpen}>
                        <Modal.Content>
                            <p>{t("new_pass_set")}</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Link to="/">
                                <Button icon="check" content={t("Ok")}/>
                            </Link>
                        </Modal.Actions>
                    </Modal>
                    <Button
                        disabled={!(this.validateLength() && this.validateDigital() && this.validateRegistry())}
                        positive
                        content={t("setup_pass")}
                        onClick={this.okBtnClick}/>
                </Modal.Actions>
            </Modal>
        );
    }
};
