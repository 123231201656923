import React from "react";
import {UserPermissionsContext} from "../../../Contexts";
import {Button, Message, Modal, Loader, Dimmer, Confirm} from "semantic-ui-react";
import SecurityCheckInfo from "./SecurityCheckInfo";
import {toast} from "react-toastify";
import {securityCheckModel} from "../../../const";

const initState = {
    errorText: "",
    columns: [],
    modalOpen: false,
    closeOnEscape: true,
    closeOnDimmerClick: false,
    card: {},
    isView: true,
    isConfirmReject: false,
    formData: {
        ...securityCheckModel
    },
    activeLoader: false,
    isLoadedData: {
        driverInfo: false,
        truckInfo: false,
        trailerInfo: false
    },
    isOpenConfirm: false
};

export default class SecurityCheckEditModal extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        ...initState
    };

    onClose = () => {
        this.props.onLoad();
        this.setState({...initState});
    };

    onOpen = () => {
        this.load(this.props.caseId).then(() => {
            this.loadFields().then(() => {
                this.setState({modalOpen: true, isCreateMode: true});
            });
        });
    };

    load = caseId => {
        return callApi("case/GetForUser", caseId).then(result => {
            let form = {};
            Object.keys(securityCheckModel).forEach(key => {
                form = {
                    ...form,
                    [key]: result[key]
                };
            });
            this.setState(prevState => ({
                formData: {
                    ...prevState.formData,
                    ...form
                },
                card: result,
                isView: !!result.state && result.state !== "Returned"
            }));
        });
    };

    loadFields = () => {
        const currentPlatformId = this.context.permissionsData.currentPlatformId;
        return callApi("case/GetCaseColumns", {
            platformId: currentPlatformId
        }).then(caseColumnsData => {
            return this.setState({columns: caseColumnsData.columns});
        });
    };

    save = () => {
        const currentPlatformId = this.context.permissionsData.currentPlatformId;
        this.setState({
            activeLoader: true
        });
        return callApi("case/create", this.state.formData).then(data => {
            if (data.error) {
                this.setState({
                    errorText: data.error,
                    activeLoader: false
                });
            } else {
                toast.info(`${t('Case')} ${data.id} ${t('created and sent to SB')}`);
                this.onClose();
            }
            return data;
        })
            .catch(error => {
                this.setState({
                    errorText: t('An error has occurred'),
                    activeLoader: false
                });
            });
    };

    resend = () => {
        const {caseId} = this.props;
        const currentPlatformId = this.context.permissionsData.currentPlatformId;

        return callApi("case/update", {
            ...this.state.formData,
            id: caseId
        }).then(data => {
            if (data.error) {
                this.setState({
                    errorText: data.error
                });
            } else {
                this.onClose();
            }
            return data;
        })
            .catch(error => {
                this.setState({
                    errorText: t('An error has occurred')
                });
            });
        ;
    };

    changeFormData = (name = "", value) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    renderLoader = () => {
        const {isLoadedData} = this.state;
        const {caseId} = this.props;

        if (!caseId) return null;

        if (isLoadedData.driverInfo === false || (isLoadedData.truckInfo === false || isLoadedData.trailerInfo === false)) {
            return (
                <div className="fluid-for-loader">
                    <Dimmer active inverted>
                        <Loader size='large' inverted content={`${t('Loading')}...`}/>
                    </Dimmer>
                </div>
            );
        } else {
            return null;
        }
    };

    getContentInfo = () => {
        const {columns = [], formData, isView} = this.state;

        return (<div className="init-scroll">
                {this.renderLoader()}
                <SecurityCheckInfo
                    setIsLoaded={this.setIsLoaded}
                    columns={columns}
                    isView={isView}
                    formData={formData}
                    isEntityStateEdit={false}
                    changeFormData={this.changeFormData}
                    loadFields={this.loadFields}
                />
            </div>
        );
    };

    setIsLoaded = ({name, isLoaded}) => {
        const {isLoadedData} = this.state;

        if (!name) {
            this.setState(prevState => {

                prevState.isLoadedData.driverInfo = false;
                prevState.isLoadedData.truckInfo = false;
                prevState.isLoadedData.trailerInfo = false;

                return prevState;
            });

            return;
        }

        isLoadedData[name] = isLoaded;

        this.setState({
            isLoadedData
        });
    };

    getSendActions = () => {
        const {card} = this.state;

        if (!card.state) {
            return (
                <>
                    {/*<Button negative content={t("CancelButton")} onClick={this.onClose} />*/}
                    <Button
                        positive
                        icon="check"
                        disabled={this.state.activeLoader}
                        labelPosition="right"
                        content={t('SendButton')}
                        onClick={this.save}
                    />
                </>
            );
        }

        if (card.state === "Returned") {
            return (
                <Button
                    positive
                    icon="check"
                    labelPosition="right"
                    content={t('resend')}
                    onClick={this.resend}
                />
            );
        }
    };

    delete = () => {
        callApi("case/delete", this.props.caseId).then(data => {
            if (data.error) {
                this.setState({
                    errorText: data.error
                });
            }

            return data;
        }).catch(error => {
            this.setState({
                errorText: t('An error has occurred')
            });
        });
    };

    render() {
        const {children, title} = this.props;
        const {modalOpen, errorText, card, closeOnEscape, closeOnDimmerClick, activeLoader, isLoadedData} = this.state;
        const {state, result, securityServiceComment} = card;

        return (
            <Modal dimmer="blurring"
                   trigger={children}
                   open={modalOpen}
                   size="large"
                   onOpen={this.onOpen}
                   onClose={this.onClose}
                   closeOnEscape={closeOnEscape}
                   closeOnDimmerClick={closeOnDimmerClick}
                   closeIcon
            >
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content scrolling>
                    {activeLoader && <Loader inverted size='medium' active={activeLoader} inline='centered'/>}
                    {["Returned", "Completed"].includes(state) &&
                    <div>
                        <span>{t('Result of checking')}:</span>
                        {state === "Returned" && <>
                            <Message color="yellow">{t(state)}</Message>
                            <p>
                                {t('SB comment on return')}: <b className="history-comment">{securityServiceComment}</b>
                            </p>
                        </>
                        }
                        {state === "Completed" && <>
                            <Message color={result === "Confirmed" ? "green" : "red"}>{t(result)}</Message>
                            {result === "PartialRejected" && <>
                                <p>
                                    {t('Partial Failure Security Council Comment')}: <b
                                    className="history-comment">{securityServiceComment}</b>
                                </p>
                            </>
                            }
                        </>
                        }
                    </div>
                    }
                    {errorText ? <Message error content={errorText}/> : null}
                    <div style={{marginTop: "12px"}}>
                        {this.getContentInfo()}
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    {card.isInitiator && card.state === "New" ?
                        <Button
                            negative
                            icon="delete"
                            disabled={this.state.activeLoader}
                            labelPosition="right"
                            content={t('Delete')}
                            onClick={() => {
                                this.setState({isOpenConfirm: true});
                            }}
                        /> : null}
                    {this.getSendActions()}
                </Modal.Actions>
                <Confirm dimmer="blurring"
                         open={this.state.isOpenConfirm}
                         onCancel={() => {
                             this.delete();
                             this.onClose();
                         }}
                         onConfirm={() => {
                             this.setState({isOpenConfirm: false});
                         }}
                         content={t('Are you sure you want to delete the case?')}
                         cancelButton={t('Delete')}
                         confirmButton={t('CancelButton')}
                />
            </Modal>);
    }
}
