import React from "react";
import {Button, Container, Form, Icon, Table} from "semantic-ui-react";
import { getRequestStates } from "../../../utils/stateHelper";
import ReadOnlyPlatforms from "../ReadOnlyPlatforms";
import {dateFormat} from "../../../utils/dateFormat";

export default function PreviousRequests({ requests, allPlatforms, deleteRequest }) {
    
    const states = getRequestStates();
    
    return (
        <Container className='container-margin-top-bottom'>
            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('requestState')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestVisitor')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestPhoneNumber')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestTruckNumber')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestPlatforms')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('requestDate')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Visit purpose')}</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>    
                </Table.Header>
                <Table.Body>
                    {requests.map(request => {
                        const stateInfo = states.find(elem => elem.key === request.state);
                        const startDate = dateFormat(request.startDate, "DD.MM.YYYY", true);
                        const finishDate = dateFormat(request.finishDate, "DD.MM.YYYY", true);
                        const dateInfo = startDate === finishDate ? startDate : startDate + " - " + finishDate;
                        return (
                            <Table.Row key={request.id}>
                                <Table.Cell>
                                {stateInfo && 
                                    <>
                                        <Icon color={stateInfo.color} name="circle"/>{t(stateInfo.value)}
                                    </>                                    
                                }
                                </Table.Cell>
                                <Table.Cell>{request.visitorName}</Table.Cell>
                                <Table.Cell>{request.requesterPhoneNumber}</Table.Cell>
                                <Table.Cell>{request.truckNumber}</Table.Cell>
                                
                                <Table.Cell>
                                    <ReadOnlyPlatforms requestPlatforms={request.platforms} allPlatforms={allPlatforms} />
                                </Table.Cell>

                                <Table.Cell>{dateInfo}</Table.Cell>
                                <Table.Cell>{request.purpose}</Table.Cell>
                                <Table.Cell>
                                    {request.state === 0 && 
                                        <Button
                                            width={1}
                                            icon='delete'
                                            onClick={() => deleteRequest(request.id)}
                                        />
                                    }
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
        </Container>
    );
};