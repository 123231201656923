import React from "react";
import { Button, Icon, Label, TextArea, Input, Popup, Form, Segment, Loader, Grid } from "semantic-ui-react";
import DocumentListViewCompact from "../../documents/DocumentListViewCompact";
import DriverEditModal from "../../../pages/drivers/components/DriverEditModal";
import { UserPermissionsContext } from "../../../Contexts";

export default class Value extends React.Component {
    static contextType = UserPermissionsContext;
    state = {
        isOpen: false,
        driver: null,
        preventClose: false,
        mouseLeaveDelay: 200,
        mouseEnterDelay: 400
    }

    handleOpen = () => {
        if (this.props.value) {
            /*if (!this.state.driver) {*/
            callApi("visits/getdriverbyid", this.props.value.value).then(
                (driver) =>
                    this.setState({
                        driver,
                        isOpen: true
                    })
            );
            /*}
            else this.setState({
                isOpen: true
            })*/
        }
    }

    handleClose = () => {
        this.setState({ mouseLeaveDelay: 100 });
        if (!this.state.preventClose)
            this.setState({ isOpen: false });
    }

    allowClose = () => {
        this.setState({
            preventClose: false,
            isOpen: false
        });
    }

    render() {
        const driver = this.state.driver;

        let content = driver
            ? (
                <Form size="mini" className="driver-popup" onClick={e => { e.stopPropagation(); }} >
                    <Grid columns="equal">
                        <Grid.Column>
                            <Form.Input disabled label={t("Phone")} name='ph' value={driver.phone || ""} />
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "right" }}>
                            {
                                this.context.permissionsData.permissions.includes("DriversForSelfCompany")
                                    ? <div className="small-add-btn-block" onClick={e => {  this.setState({ preventClose: true }); }}>
                                        <DriverEditModal
                                            id={this.props.value.value}
                                            onClose={this.allowClose}>
                                            <Icon name='edit' />
                                        </DriverEditModal>
                                    </div>
                                    : null
                            }
                        </Grid.Column>
                    </Grid>
                    <Form.Field className="no-bottom-margin">
                        <label>{t("Passport")}</label>
                    </Form.Field>
                    <Segment color='yellow' className="no-top-margin" size="mini">
                        <Form.Group>
                            <Form.Input disabled label={t("SeriesAndNumber")} name='passportSeriesAndNumber' value={driver.passportSeriesAndNumber} width={9} />
                            <Form.Input disabled label={t("Date of issue")} name='passportDate' value={driver.passportDate} width={5} />
                            <Form.Input disabled label={t("Code")} name='passportDepartmentCode' value={driver.passportDepartmentCode} width={4} />
                        </Form.Group>
                        <Form.Input
                            disabled
                            label={t("Issuer")}
                            value={driver.passportDepartment}
                            name='passportDepartment'
                        />
                    </Segment>
                    <DocumentListViewCompact documents={driver.documents || []}
                        onClick={e => { this.setState({ mouseLeaveDelay: 99999 }); }}
                    />

                </Form>
            )
            : (
                <Loader active inline='centered' />
            );

        return (
            <div>
                <Popup wide='very'
                    hoverable
                    style={{ "zIndex": "999" }}
                    trigger={<div>{this.props.value ? this.props.value.text : ''}</div>}
                    content={content}
                    on='hover'
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position='right center'
                    mouseLeaveDelay={this.state.mouseLeaveDelay}
                    mouseEnterDelay={this.state.mouseEnterDelay}
                />
            </div>
        );
    }
}
