import React from "react";
import { Grid, Header, Segment, Input } from "semantic-ui-react";

const source = [
];

export default class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    handleKeyPress = (e) => {
        const {onKeyPressEnter} = this.props;
        if (e.keyCode == 13) {
            this.props.onChange(e, {value: this.state.value});
            onKeyPressEnter && onKeyPressEnter()
        }
    }

    change = (e, { value }) => {
        const { isAuto } = this.props;
        this.setState({ value });
        if (isAuto || !value)
            this.props.onChange(e, { value });
    }

    render() {
        return (<Input
            placeholder={this.props.placeholder}
            icon="search"
            className="search-input"
            onKeyDown={this.handleKeyPress}
            onChange={this.change}
            value={this.state.value || ""}
        />);
    }
};
