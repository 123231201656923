import React from 'react';
import { Button, Checkbox, Dropdown, Grid, Message, Table } from 'semantic-ui-react';
import { parseTime, formatTime } from '../../../const/functions';
import InputRegExp from '../../../shared/columns/number/inputNumber';
import CompanySchedule from './CompanySchedule';

export default class EditedActivity extends React.Component {
    state = {
        activity: this.props.activity,
        textErrorItem: '',
        isSent: false
    };

    isUpward = (index) => {
        const table = document.getElementsByClassName('schedule-activities-modal-table');
        const el = document.getElementsByClassName(`schedule-activities-modal-item-${index}`);
        return (
            (table[0].offsetHeight > 300 && el[0] && el[0].offsetTop > 220) ||
            (table[0].offsetHeight > 250 && !el[0])
        );
    };

    areSchedulesFilled = (schedules = []) => {
        if (
            schedules.find(
                (schedule) =>
                    !schedule.dayOfWeeks.length ||
                    !schedule.timeStart.length ||
                    schedule.timeStart.length < 5 ||
                    schedule.timeEnd.length < 5 ||
                    !schedule.timeEnd.length,
            )
        ) {
            return false;
        }

        return true;
    };

    checkSchedulesIntersection = (schedules = []) => {
        for (var i = 0; i < schedules.length; i++) {
            const currentSchedule = schedules[i];
            const currentScheduleTimeStart = parseTime(currentSchedule.timeStart);
            const currentScheduleTimeEnd = parseTime(currentSchedule.timeEnd);

            const scheduleWithIntersection = schedules
                .filter((x, j) => j > i)
                .find(
                    (x) =>
                        parseTime(x.timeStart) < currentScheduleTimeEnd &&
                        parseTime(x.timeEnd) > currentScheduleTimeStart &&
                        x.isGreenCorridor === currentSchedule.isGreenCorridor &&
                        x.dayOfWeeks.find((y) => currentSchedule.dayOfWeeks.includes(y)),
                );

            if (scheduleWithIntersection) {
                const currentScheduleTimeInfo = `${formatTime(currentScheduleTimeStart,)}-${formatTime(currentScheduleTimeEnd)}`;
                const scheduleWithIntersectionTimeInfo = `${formatTime(parseTime(scheduleWithIntersection.timeStart),)}-${formatTime(parseTime(scheduleWithIntersection.timeEnd))}`;

                const intersectedDaysOfWeek = currentSchedule.dayOfWeeks
                    .filter((x) => scheduleWithIntersection.dayOfWeeks.includes(x))
                    .map((x) => t(x))
                    .join(', ');

                return (
                    t('companySchedulesIntersectPart1') +
                    `${intersectedDaysOfWeek} ${currentScheduleTimeInfo}` +
                    t('companySchedulesIntersectPart2') +
                    `${intersectedDaysOfWeek} ${scheduleWithIntersectionTimeInfo}` +
                    (currentSchedule.isGreenCorridor
                        ? t('companySchedulesIntersectPart3WithCorridor')
                        : t('companySchedulesIntersectPart3WithoutCorridor'))
                );
            }
        }

        return null;
    };

    saveEditedActivity = (index, activity) => {
        this.setState({isSent: true});
        let newActivity = Object.assign({}, activity);

        if (!newActivity.activityId.length) {
            this.setState({
                textErrorItem: t('Fill in the fields'),
            });

            return;
        }

        if (!this.areSchedulesFilled(newActivity.schedules)) {
            this.setState({
                textErrorItem: t('Fill in the schedule'),
            });

            return;
        }

        const schedulesIntersection = this.checkSchedulesIntersection(newActivity.schedules);
        if (schedulesIntersection) {
            this.setState({
                textErrorItem: schedulesIntersection,
            });

            return;
        }

        const { handleEditedActivitySave } = this.props;
        handleEditedActivitySave && handleEditedActivitySave(index, newActivity);
    };

    createSchedule = () => {
        let schedulesNew = this.state.activity.schedules;

        if (
            schedulesNew.find(
                (item) => !item.dayOfWeeks.length || !item.timeStart.length || !item.timeEnd.length,
            )
        ) {
            return;
        }

        schedulesNew.push({
            dayOfWeeks: [],
            timeStart: '',
            timeEnd: '',
            maxCountTaskOnTimeSlot: 0,
            isGreenCorridor: false,
        });

        this.setState((prevState) => {
            return { activity: { ...prevState.activity, schedules: schedulesNew } };
        });
    };

    deleteSchedule = (index) => {
        const { schedules } = this.state.activity;
        let newSchedules = schedules.concat();
        newSchedules.splice(index, 1);

        this.props.showConfirmation(
            t('Are you sure you want to delete the schedule for activity?'),
            () => {
                this.setState((prevState) => {
                    return { activity: { ...prevState.activity, schedules: newSchedules } };
                });
            },
        );
    };

    handleChange = (e, { name, value }) => {
        this.setState((prevState) => {
            return {
                activity: { ...prevState.activity, [name]: value },
            };
        });
    };

    handleChangeSchedule = (name, value, index) => {
        let newSchedules = this.state.activity.schedules.concat();
        newSchedules[index][name] = value;
        this.setState((prevState) => {
            return { activity: { ...prevState.activity, schedules: newSchedules } };
        });
    };

    handleChangeMaxCountTaskOnTimeSlot = (value, index) => {
        const count = value === '' || value === '0' ? 0 : Number(value);
        this.handleChangeSchedule('maxCountTaskOnTimeSlot', count, index);
    };

    renderActivityDropdown = (activity, index, options) => {
        return (
            <Dropdown
                placeholder={`${t('Select')}...`}
                fluid
                error={!activity.activityId.length && this.state.isSent}
                selection
                search
                name={'activityId'}
                upward={this.isUpward(index)}
                value={activity.activityId}
                onChange={this.handleChange}
                options={options}
            />
        );
    };

    renderAddScheduleButton = () => {
        return (
            <Grid columns="equal" className="clear-margin-grid ">
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Button size="small" onClick={this.createSchedule}>
                            {t('AddButton')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    renderActivityButtons = (index, activity) => {
        return (
            <>
                <Button
                    positive
                    size="small"
                    onClick={() => this.saveEditedActivity(index, activity)}
                >
                    {t('SaveButton')}
                </Button>
                <Button size="small" onClick={() => this.props.onDiscardChanges(activity)}>
                    {t('Cancel')}
                </Button>
            </>
        );
    };

    renderError = (index) => {
        return (
            <Table.Row key={`schedule-error`} className={`schedule-activities-modal-item-${index}`}>
                <Table.Cell colSpan={5}>
                    <Message negative>
                        <p>{this.state.textErrorItem}</p>
                    </Message>
                </Table.Cell>
            </Table.Row>
        );
    };

    render() {
        const { activity } = this.state;
        const { index, allActivities } = this.props;
        const schedules = activity.schedules;

        if (schedules.length === 0) {
            return (
                <>
                    <Table.Row
                        key={`schedule-${index}-${0}`}
                        className={`schedule-activities-modal-item-${index}`}
                    >
                        <Table.Cell>
                            {this.renderActivityDropdown(activity, index, allActivities)}
                        </Table.Cell>

                        <Table.Cell>{this.renderAddScheduleButton()}</Table.Cell>

                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>

                        <Table.Cell width={2} textAlign="center">
                            {this.renderActivityButtons(index, activity)}
                        </Table.Cell>
                    </Table.Row>

                    {this.state.textErrorItem ? this.renderError(index) : null}
                </>
            );
        }

        var renderedActivity = schedules.map((schedule, key) => {
            const isFirstRow = key === 0;
            const isLastRow = key === schedules.length - 1;

            return (
                <Table.Row
                    key={`schedule-${index}-${key}`}
                    className={`schedule-activities-modal-item-${index}`}
                >
                    {isFirstRow && (
                        <Table.Cell rowSpan={schedules.length}>
                            {this.renderActivityDropdown(activity, index, allActivities)}
                        </Table.Cell>
                    )}

                    <Table.Cell>
                        <CompanySchedule
                            activityIndex={index}
                            scheduleIndex={key}
                            item={schedule}
                            onDelete={() => this.deleteSchedule(key)}
                            onChange={(e, { name, value }) =>
                                this.handleChangeSchedule(name, value, key)
                            }
                            onChangeIsValid={(value) =>
                                this.setState({ isValidScheduleEdit: value })
                            }
                        />

                        {isLastRow && this.renderAddScheduleButton()}
                    </Table.Cell>

                    <Table.Cell>
                        <Checkbox
                            name="isGreenCorridor"
                            checked={schedule.isGreenCorridor}
                            onChange={(e, { name, checked }) =>
                                this.handleChangeSchedule(name, checked, key)
                            }
                        />
                    </Table.Cell>

                    <Table.Cell>
                        <InputRegExp
                            name="maxCountTaskOnTimeSlot"
                            value={schedule.maxCountTaskOnTimeSlot}
                            reg={'^[0-9]*$'}
                            onBlur={(e) =>
                                this.handleChangeMaxCountTaskOnTimeSlot(
                                    schedule.maxCountTaskOnTimeSlot,
                                    key,
                                )
                            }
                            onChange={(e, { name, value }) =>
                                this.handleChangeSchedule(name, value, key)
                            }
                        />
                    </Table.Cell>

                    {isFirstRow && (
                        <Table.Cell rowSpan={schedules.length} width={2} textAlign="center">
                            {this.renderActivityButtons(index, activity)}
                        </Table.Cell>
                    )}
                </Table.Row>
            );
        });

        return (
            <>
                {renderedActivity}
                {this.state.textErrorItem && this.renderError(index)}
            </>
        );
    }
}
