import React from "react";
import { Form } from "semantic-ui-react";

const PlatformEditForm = ({ formData, handleChange, timeZoneList, editPermission }) => {
    return (<Form size="small">
        <Form.Field>
            <Form.Input className='without-background' disabled={!editPermission} label={t('Name')} name="name" value={formData.name || ''}
                onChange={handleChange} />
        </Form.Field>
        <Form.Field>
            <Form.Input className='without-background' disabled={!editPermission} label={t('Address')} name="address" value={formData.address || ''}
                onChange={handleChange} />
        </Form.Field>
        <Form.Field className='without-background' disabled={!editPermission}>
            <label>{t('Timezone')}</label>
            <Form.Dropdown disabled={!editPermission} fluid search selection name="timeZoneId" value={formData.timeZoneId} options={timeZoneList}
                onChange={handleChange} />
        </Form.Field>
    </Form>);
};
export default PlatformEditForm;
