import React from "react";
import { Button, Table, Checkbox, Icon, Container, Grid, Label } from "semantic-ui-react";
import UserEditModal from "./components/UserEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function Users() {

    const newModal = (load) =>
        <UserEditModal
            title={t('User Creation')}
            actionButton={t('Create user')}
            id={null}
            onClose={load}>
            <Icon name='plus' />{` ${t('Add user')}`}
        </UserEditModal>;

    const headerRow =
        <Table.Row>
            <Table.HeaderCell>{t('First name')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Email')}</Table.HeaderCell>
            <Table.HeaderCell>{t('role')}</Table.HeaderCell>
            <Table.HeaderCell>{t('TransportCompanies')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Providers')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Consignees')}</Table.HeaderCell>
            <Table.HeaderCell>{t('platforms')}</Table.HeaderCell>
            <Table.HeaderCell>{t('activities')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>{t('Active')}</Table.HeaderCell>
            <Table.HeaderCell />
        </Table.Row>;

    return (
        <LazyLoadDictionary
            apiGetList="users/GetList"
            apiChangeActivity="Users/ChangeActivity"
            name={t("users")}
            className='container-margin-top-bottom width-auto padding-l2-r2'
            newModal={newModal}
            headerRow={headerRow}
            isVerticalScroll
        >
            {(users, load, toggleIsActive) =>
                users.map(u =>
                    <Table.Row key={u.id}>
                        <Table.Cell>{u.name}</Table.Cell>
                        <Table.Cell>{u.email}</Table.Cell>
                        <Table.Cell>{u.role}</Table.Cell>
                        <Table.Cell>{!u.companyNames ? t('All companies') : u.companyNames.map((n, i) => <Label key={i} className='label-margin'>{n}</Label>)}</Table.Cell>
                        <Table.Cell>{!u.providerNames ? t('All companies') : u.providerNames.map((n, i) => <Label key={i} className='label-margin'>{n}</Label>)}</Table.Cell>
                        <Table.Cell>{!u.consigneeNames ? t('All companies') : u.consigneeNames.map((n, i) => <Label key={i} className='label-margin'>{n}</Label>)}</Table.Cell>
                        <Table.Cell>{!u.platformNames ? t('All platforms') : u.platformNames.map((n, i) => <Label key={i} className='label-margin'>{n}</Label>)}</Table.Cell>
                        <Table.Cell>{!u.activityNames ? t('All activities'): u.activityNames.map((n, i) => <Label key={i} className='label-margin'>{n}</Label>)}</Table.Cell>
                        <Table.Cell textAlign='center'>
                            <Checkbox toggle itemID={u.id} checked={u.isActive} onChange={toggleIsActive} />
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            <UserEditModal
                                size="small"
                                title={t('Change')}
                                actionButton={t('SaveButton')}
                                id={u.id}
                                onClose={load}>
                                <Icon name='edit' />{` ${t('Edit')}`}
                                </UserEditModal>
                        </Table.Cell>
                    </Table.Row>
                )}
        </LazyLoadDictionary>
    );
}
