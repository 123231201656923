import React, {useState, useEffect} from "react";
import {
    Form,
    Dropdown,
    Modal,
    TextArea,
    Button,
    Radio,
    Segment,
    Message, Dimmer, Loader
} from "semantic-ui-react";
import {Grid} from "semantic-ui-react/dist/commonjs/collections/Grid/Grid";

export default function SecurityDecisionModal({
                                              children,
                                              caseId,
                                              driverId,
                                              title,
                                              onClose,
                                              isReject,
                                              isReturn,
                                              isConfirm,
                                              isReturnToWork,
                                              columns = [],
                                              driverState,
                                              truckState,
                                              trailerState
                                              }) {
    let [openModal, setOpenModal] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    let [comment, setComment] = useState("");
    let [rejectType, setRejectType] = useState(isReject ? "FullReject" : null);
    let [activities, setActivities] = useState([]);
    let [reasonId, setReasonId] = useState(null);
    let [errorText, setErrorText] = useState("");
    let [driverBlockedActivities, setDriverBlockedActivities] = useState([]);

    const handleOpen = () => {
        if (driverId) {
            load(driverId);
        }
        setOpenModal(true);
    };

    const handleClose = () => {
        setErrorText(null);
        setOpenModal(false);
    };

    const load = driverId => {
        if (driverId)
            callApi("Drivers/GetById", driverId).then(data => {
                setDriverBlockedActivities(data.blockedActivities);
            });
    };

    const handleChangeRejectType = (e, {value}) => {
        setRejectType(value);
    };

    const handleReject = () => {
        setIsLoading(true);
        
        let params = {
            id: caseId,
            comment: comment,
            driverState: driverState,
            truckState: truckState,
            trailerState: trailerState
        };

        if (rejectType === "PartialReject") {
            params = {
                ...params,
                activitiesIds: activities
            };
        }

        if (rejectType !== "TemporaryReject") {
            params = {
                ...params,
                reasonId: reasonId
            };
        }

        return callApi(`case/${rejectType}`, params).then(data => handleCaseApiResponse(data));
    };

    const handleReturn = () => {
        setIsLoading(true);
        
        return callApi("case/Return", {
            id: caseId,
            reasonId: reasonId,
            comment: comment,
            driverState: driverState,
            truckState: truckState,
            trailerState: trailerState
        }).then(data => handleCaseApiResponse(data));
    };

    const handleConfirm = () => {
        setIsLoading(true);

        return callApi("case/Confirm", caseId).then(data => handleCaseApiResponse(data));
    };

    const handleReturnToWork = () => {
        setIsLoading(true);

        return callApi("case/returnToWork", caseId).then(data => handleCaseApiResponse(data));
    };
    
    const handleCaseApiResponse = (data) => {
        setIsLoading(false);
        
        if (data.error) {
            setErrorText(data.error);
        } else {
            handleClose();
            onClose();
        }
        
        return data;
    };
    
    const activityList =
        columns.find(item => item.type === "Activity") && driverId &&
        columns
            .find(item => item.type === "Activity")
            .valuesForSelectType
            .filter(i => {
                let mas = []
                driverBlockedActivities.forEach(val => {
                    if (val.id === i.value) {
                        mas.push(true)
                    }
                });
                return !mas.length
            })

            .map(item => {
                return {
                    value: item.value,
                    key: item.name,
                    text: item.name
                }


            });

    const reasonList =
        columns.find(
            item => (isReject ? `${rejectType}Reason` : "ReturnReason") === item.type
        ) &&
        columns
            .find(
                item =>
                    (isReject ? `${rejectType}Reason` : "ReturnReason") === item.type
            )
            .valuesForSelectType.map(item => ({
            value: item.value,
            key: item.name,
            text: item.name
        }));

    useEffect(
        () => {
            setReasonId(null);
            setComment("");
        },
        [rejectType]
    );

    const renderLoader = () => {
        return (
            <div className="fluid-for-loader">
                <Dimmer active inverted>
                    <Loader size='large' inverted content={`${t('Loading')}...`}/>
                </Dimmer>
            </div>
        );
    };
    
    const renderRejectContent = () => {
        return (
            <>
                <Form.Field>
                    <Radio
                        label={t('Complete rejection (blacklist)')}
                        name="rejectType"
                        value="FullReject"
                        checked={rejectType === "FullReject"}
                        onChange={handleChangeRejectType}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={t('Partial Failure (for activity)')}
                        name="rejectType"
                        value="PartialReject"
                        checked={rejectType === "PartialReject"}
                        onChange={handleChangeRejectType}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        label={t('TemporaryRejected')}
                        name="rejectType"
                        value="TemporaryReject"
                        checked={rejectType === "TemporaryReject"}
                        onChange={handleChangeRejectType}
                    />
                </Form.Field>
                {rejectType === "PartialReject" ? (
                    <Form.Field>
                        <Form.Dropdown
                            fluid
                            multiple
                            search
                            selection
                            name="activities"
                            placeholder={t('activities')}
                            value={activities}
                            options={activityList}
                            onChange={(e, {value}) => setActivities(value)}
                        />
                    </Form.Field>
                ) : null}
            </>
        );
    };

    const renderReasonWithComment = () => {
        return (
            <>
                {rejectType !== "TemporaryReject" ? (
                    <Form.Field>
                        <Dropdown
                            name="reason"
                            value={reasonId}
                            onChange={(e, {value}) => setReasonId(value)}
                            search
                            placeholder={isReject ? t('Rejection reason') : t('Reason for return')}
                            selection
                            options={reasonList}/>
                    </Form.Field>
                ) : null}

                <Form.Field>
                    <TextArea
                        rows={2}
                        placeholder={t('Your comment')}
                        onChange={(e, {value}) => setComment(value)}
                    />
                </Form.Field>
            </>
        );
    };
    
    const renderConfirmContent = () => {
        return (<span>{t('Are you sure you want to confirm?')}</span>);
    };

    const renderReturnToWorkContent = () => {
        return (<span>{t('Are you sure you want to return to work?')}</span>);
    };
    
    return (
        <Modal
            dimmer="blurring"
            trigger={children}
            open={openModal}
            closeIcon
            closeOnEscape
            closeOnDimmerClick={false}
            onOpen={handleOpen}
            onClose={handleClose}
        >
            <Modal.Header>{title}</Modal.Header>
            {isLoading && renderLoader()}
            <Modal.Content>
                <Form>
                    {isReject && renderRejectContent()}                    
                    {(isReject || isReturn) && renderReasonWithComment()}
                    {isConfirm && renderConfirmContent()}
                    {isReturnToWork && renderReturnToWorkContent()}
                </Form>
                {errorText ? <Message error content={errorText}/> : null}
            </Modal.Content>
            <Modal.Actions>
                {isReject && (
                    <Button negative onClick={handleReject}>
                        {t('Rejected')}
                    </Button>
                )}
                {isReturn && (
                    <Button color="yellow" onClick={handleReturn}>
                        {t('Return to initiator')}
                    </Button>
                )}
                {isConfirm && (
                    <>
                        <Button color="grey" onClick={handleClose}>
                            {t('No')}
                        </Button>
                        <Button color="blue" onClick={handleConfirm}>
                            {t('Yes')}
                        </Button>
                    </>
                )}
                {isReturnToWork && (
                    <>
                        <Button color="grey" onClick={handleClose}>
                            {t('No')}
                        </Button>
                        <Button color="blue" onClick={handleReturnToWork}>
                            {t('Yes')}
                        </Button>
                    </>
                )}
            </Modal.Actions>
        </Modal>
    );
}
