import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Checkbox, Message, Table } from "semantic-ui-react";
import VisitTasksModal from "../../modals/visitTaskModal/VisitTasksModal";
import GridColumnDispatcher from "../../columns/GridColumnDispatcher";
import {FormModal} from "../../modals/FormModal";

function ModalComponent({gridName, ...props}) {
    switch (gridName) {
        case 'ordersModule': return <FormModal gridName={gridName} {...props}/>;
        default: return <VisitTasksModal {...props}/>;
    }
}

export default class Results extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        return this.props.rows !== nextProps.rows || this.props.selectedRows !== nextProps.selectedRows;
    }

    rowsSort(rows, selectedIds) {
        let rowsIsChecked = [];
        let rowsDontChecked = [];
        rows.forEach(row => selectedIds.includes(row.id) ? rowsIsChecked.push(row) : rowsDontChecked.push(row));
        return [...rowsIsChecked, ...rowsDontChecked];
    }

    render() {
        const { columns, rows, actionHandler, runTimer, stopTimer, stateColors, selectRow, filters, gridName, titleModal, checkedToTop, onCloseModal, selectedRows}  = this.props;

        return (
            (checkedToTop ? this.rowsSort(rows, selectedRows) : rows).map((row) => (
                <ModalComponent
                    gridName={gridName}
                    key={"vtm" + row.id}
                    visitId={row.visitId || row.id}
                    taskId={row.visitId ? row.id : undefined}
                    title={t(titleModal)}
                    runTimer={runTimer}
                    stopTimer={stopTimer}

                    id={row.id}
                    stateColors={stateColors}
                    onClose={onCloseModal}
                >
                    <Table.Row key={row.id} className={"visit-row " + row.color.toLowerCase()} data-visit-id={row.id}>
                        <Table.Cell />
                        <Table.Cell key={row.id + "checkbox"} className="small-column" onClick={(e) => { e.stopPropagation(); }}>
                            {Object.keys(filters).length === 0 && !checkedToTop ? <Popup wide='very'
                                                                        hoverable
                                                                        hideOnScroll
                                                                        trigger={<Checkbox
                                                                            onChange={(event, data) => { selectRow(event, data, row.id); }}
                                                                            checked={selectedRows.includes(row.id)}
                                                                            disabled
                                                                        />}
                                                                        content={
                                                                            <div>{t("Set at least one filter for bulk operations")}</div>}
                                                                        on='hover'
                                                                        position='right center'
                            /> : <Checkbox
                                onChange={(event, data) => { selectRow(event, data, row.id); }}
                                checked={selectedRows.includes(row.id)}
                            />}
                        </Table.Cell>
                        {columns.map((column, i) => {
                            return (
                                <Table.Cell key={"cell" + row.id + column.name + i}>
                                    {(column.fieldMatrixAccessTypes.find(x =>
                                        x.state === row.state && 
                                        (x.platformIds && x.platformIds.length > 0 ? x.platformIds.includes(row.platform) : true) &&
                                        ((row.activity && x.activityIds.filter(y=>y === row.activity).length > 0)  || 
                                            (row.activities && row.activities.filter(z=>x.activityIds.includes(z)).length > 0))
                                    ) || {}).accessType !== "Hidden"
                                        ? <GridColumnDispatcher
                                            key={row.id + column.name}
                                            column={column}
                                            isValue
                                            value={column.isCustom
                                                ? (row.customFields.filter(y => y.name === column.name)[0] || {}).value
                                                : row[column.name.charAt(0).toLowerCase() + column.name.slice(1)]}
                                            item={row}
                                            valuesList={column.valuesForSelectType}
                                            stateColors={stateColors} />
                                        : null
                                    }
                                </Table.Cell>
                            );
                        })}
                        <Table.HeaderCell className="actions-column" onClick={(e) => { e.stopPropagation(); }}>
                            {row.actions.map(action =>
                                (<Button
                                    key={row.id + action.name}
                                    color={action.color.charAt(0).toLowerCase() + action.color.slice(1)}
                                    actionname={action.name}
                                    actionbuttonname={action.buttonName}
                                    rowid={row.id}
                                    onClick={actionHandler}
                                    size='mini'>
                                    {t(action.buttonName)}
                                </Button>))}
                        </Table.HeaderCell>
                    </Table.Row>
                </ModalComponent>
            ))
        );
    }
}
