import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Icon } from "semantic-ui-react";

const Value = ({ props }) => {

    let tasks = props.value;
    let content = (<div className="popover-task-content">
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <b>Задача</b>
                </Grid.Column>
                <Grid.Column width={8}>
                    027-027-00000185
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <b>Тип</b>
                </Grid.Column>
                <Grid.Column width={8}>
                    Погрузка <Icon name="arrow up" />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <b>Дата</b>
                </Grid.Column>
                <Grid.Column width={8}>
                    13.02.2018 12:00
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <b>Статус</b>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Label color='blue'>
                        Обрабатывается складом
                        </Label>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <b>Партнёр</b>
                </Grid.Column>
                <Grid.Column width={8}>
                    Микояновский МК
                    </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <b>Склад</b>
                </Grid.Column>
                <Grid.Column width={8}>
                    Ашан Шушары
                    </Grid.Column>
            </Grid.Row>
        </Grid>
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Button color='red'>Отменить</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Button color='green'>Завершить</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>);
    return (
        <div>
            {tasks.length}:&nbsp;
                    {tasks.map(task => (<Popup
                trigger={<Button onClick={e => e.stopPropagation()} icon color={task.stateColor}><Icon name={task.typeIcon} /></Button>}
                content={content}
                on='click'
                hideOnScroll
                position='bottom left'
            />))}
        </div>
    );
};

export default Value;