import React from "react";
import { Button, Popup, Checkbox, Icon, Form } from "semantic-ui-react";

const Facet = ({ name, value, values, onChange, stateColors, sort, setSort, isSortingEnabled = true, allItemsSelectedText = t("all"), states, valuesList }) => {
    let selectedValues = value
        ? value.split(",")
        : (values ? values : []);

    const toggle = (e, { value }) => {
        if (selectedValues.some(x => x === value)) {
            selectedValues.splice(selectedValues.indexOf(value), 1);
        } else {
            selectedValues.push(value);
        }
        if (onChange !== undefined)
            onChange(e, { name: name || "State", value: selectedValues.join(","), values: selectedValues });
    };

    let content = (
        <Form>
            {(stateColors || valuesList).map(x => {
                let isVisible = stateColors ? (!states.length || states.includes(x.value)) : true;
                let label = (
                    <label>
                        {stateColors && <Icon color={x.color} inverted={x.inverted} name="circle"/>}
                        {t(`${x.name || x.value}`)}
                    </label>
                );
                return (isVisible ? <Form.Field key={x.value}>
                    <Checkbox value={x.value} checked={selectedValues.includes(x.value)} onChange={toggle} label={label} />
                </Form.Field> : null);
            })}
        </Form>
    );

    return (
        <div className="facet-sortable facet-input">
            <Popup
                trigger={
                    <Button
                        size="small"
                        style={{ lineHeight: "1.1rem" }}
                        fluid>
                        {
                            selectedValues.length > 0
                                ? selectedValues.length + " " + t("selected")
                                : allItemsSelectedText
                        }
                    </Button>
                }
                content={content}
                on='click'
                hideOnScroll
                position='bottom left'
            />
            
            {isSortingEnabled &&
                <>
                    <Button className={`sort-button sort-button-up ${sort === "asc" ? "sort-button-active" : ""}`} name={name || "State"} value="asc" onClick={setSort}>
                        <Icon name="caret up" />
                    </Button>
                    <Button className={`sort-button sort-button-down ${sort === "desc" ? "sort-button-active" : ""}`} name={name || "State"} value="desc" onClick={setSort}>
                        <Icon name="caret down" />
                    </Button>
                </>
            }

        </div >
    );

};
export default Facet;