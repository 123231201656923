import React from "react";
import DocumentListEdit from "../../../../documents/DocumentListEdit";
import DocumentListView from "../../../../documents/DocumentListView";
import { UserPermissionsContext } from "../../../../../Contexts";

export default class Documents extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        documents: [],
    };

    render() {
        let { visit, taskDocuments, onDocumentsChange, onSaveForm} = this.props;

        return (
            <div>
                <div>
                    <label>{t("Visit documents")}</label>
                    <DocumentListEdit
                        documents={visit.visitDocuments}
                        onDocumentsChange={onDocumentsChange}
                        typesApiUrl="DocumentTypes/GetDocumentTypeList"
                        type="Visit"
                        onSaveForm={onSaveForm}
                    />
                </div>

                {visit.transport &&
                    ((visit.transportDocuments && visit.transportDocuments.length > 0)
                        || this.context.permissionsData.permissions.includes("EditTransports"))
                    ? <div>
                        <label>{t("Transport documents")}</label>
                        {this.context.permissionsData.permissions.includes("EditTransports")
                            ? <DocumentListEdit
                                documents={visit.transportDocuments}
                                onDocumentsChange={onDocumentsChange}
                                typesApiUrl="DocumentTypes/GetDocumentTypeList"
                                type="Transport"
                                onSaveForm={onSaveForm}
                            />
                            : <DocumentListView documents={visit.transportDocuments} />}
                    </div>
                    : null
                }
                {visit.driver &&
                    ((visit.driverDocuments && visit.driverDocuments.length > 0)
                        || this.context.permissionsData.permissions.includes("DriversForSelfCompany"))
                    ? <div>
                        <label>{t("Driver documents")}</label>
                        {this.context.permissionsData.permissions.includes("DriversForSelfCompany")
                            ? <DocumentListEdit
                                documents={visit.driverDocuments}
                                onDocumentsChange={onDocumentsChange}
                                typesApiUrl="DocumentTypes/GetDocumentTypeList"
                                type="Driver"
                                onSaveForm={onSaveForm}
                            />
                            : <DocumentListView documents={visit.driverDocuments} />}
                    </div>
                    : null
                }
                {taskDocuments.length > 0
                    ? <div>
                        <label>{t("Task documents")}</label>
                        <DocumentListView documents={taskDocuments} />
                    </div>
                    : null
                }
                {visit.truck &&
                ((visit.truckDocuments && visit.truckDocuments.length > 0)
                    || this.context.permissionsData.permissions.includes("EditTransports"))
                    ? <div>
                        <label>{t("Truck documents")}</label>
                        {this.context.permissionsData.permissions.includes("EditTransports")
                            ? <DocumentListEdit
                                documents={visit.truckDocuments}
                                onDocumentsChange={onDocumentsChange}
                                typesApiUrl="DocumentTypes/GetDocumentTypeList"
                                type="Transport"
                                onSaveForm={onSaveForm}
                            />
                            : <DocumentListView documents={visit.truckDocuments} />}
                    </div>
                    : null
                }
                {visit.trailer &&
                ((visit.trailerDocuments && visit.trailerDocuments.length > 0)
                    || this.context.permissionsData.permissions.includes("EditTransports"))
                    ? <div>
                        <label>{t("Trailer documents")}</label>
                        {this.context.permissionsData.permissions.includes("EditTransports")
                            ? <DocumentListEdit
                                documents={visit.trailerDocuments}
                                onDocumentsChange={onDocumentsChange}
                                typesApiUrl="DocumentTypes/GetDocumentTypeList"
                                type="Transport"
                                onSaveForm={onSaveForm}
                            />
                            : <DocumentListView documents={visit.trailerDocuments} />}
                    </div>
                    : null
                }
            </div>);
    }
};
