import React from "react";
import {Modal, Button, Confirm, Tab, Icon, Message, Dimmer, Loader} from "semantic-ui-react";
import Information from "./components/Visit/Information";
import Documents from "./components/Visit/Documents";
import History from "../../History";
import Tasks from "./components/Tasks/Tasks";
import {toast} from "react-toastify";
import * as StateHelper from "../../../utils/stateHelper";
import CloneDeep from "clone-deep";
import {debounce} from "throttle-debounce";
import {UserPermissionsContext} from "../../../Contexts";
import GridColumnDispatcher from "../../columns/GridColumnDispatcher";
import { Route, Redirect } from "react-router-dom";

export default class VisitTasksModal extends React.Component {
    componentDidMount() {
        if(this.props.isOpen)
            this.onOpen()
    }

    static contextType = UserPermissionsContext;
    state = this.initialState;

    get initialState() {
        return {
            modalOpen: this.props.isOpen === undefined ? false: this.props.isOpen,
            showConfirmation: false,
            oldVisit: {},
            visit: {
                activities: [],
                driverDocuments: [],
                transportDocuments: [],
                visitDocuments: [],
                truckDocuments: [],
                trailerDocuments: [],
                customFields: [],
                actions: [],
                histories: [],
                tasks: [],
                visitDate: ''
            },
            visitColumns: [],
            taskColumns: [],
            orderColumns: [],
            availableActivities: null,
            actionConfirmation: {},
            saveButton: false,
            error: null,
            isLoading: false
        };
    }

    onOpen = () => {
        //this.setState({ modalOpen: true });
        if (this.props.stopTimer !== undefined)
            this.props.stopTimer();
        this.loadData().then(visit => {
            this.loadVisitFields(visit).then(() => {
                const { newOrdersIds, taskId } = this.props;
                if (newOrdersIds) {
                    if (taskId) {
                        const visitNew = this.setNewOrders(newOrdersIds, CloneDeep(visit), taskId, true);
                        if (visitNew) this.setState({visit: visitNew});
                        else return;
                    }
                    else this.addTask(newOrdersIds);
                }
                this.setState({modalOpen: true});
            });
        }).then(
            () => {
                if (!this.props.visitId && this.state.visit.id)
                    toast.info(`${t("Created visit draft number")} ${this.state.visit.number}`);
            }
        );
    }

    isVisitChanged = () => {
        return JSON.stringify(this.state.visit) !== JSON.stringify(this.state.oldVisit);
    }

    onClose = (e, history) => {
        if (this.isVisitChanged()) {
            this.setState({showConfirmation: true});
        }
        else
            this.closeAnyway(history);
    }

    deleteVisitAndTask = () => {
        const {visitId} = this.props;
        const {visit, newOrderId} = this.state;
        if (!visitId) {
            callApi("Visits/DeleteById", visit.id)
                .then(resp => {
                    if (resp.successMessage !== null) toast.info(resp.successMessage);
                    else toast.error(resp.errorMessage);
                });
        }
        if (visitId && newOrderId) {
            callApi("Tasks/DeleteById", newOrderId)
                .then(resp => {
                    if (resp.successMessage !== null) toast.info(resp.successMessage);
                    else toast.error(resp.errorMessage);
                });
        }
    }

    closeAnyway = (history) => {
        (this.props.newOrdersIds && this.state.saveButton && !this.state.saveClick) && this.deleteVisitAndTask();
        if (this.props.runTimer !== undefined)
            this.props.runTimer();
        let initialState = this.initialState;
        initialState.modalOpen = false;
        this.setState(initialState);
        if(this.props.isOpen){
            history.push("/visits");
        }
        this.props.onClose && this.props.onClose();
    }

    closeConfirmation = () => {
        this.setState({showConfirmation: false});
    }

    loadVisitFields = (visit) => {
        visit = typeof visit === 'object' ? visit : this.state.visit;
        
        this.setState({
            isLoading: true
        });
        
        return callApi("CreateUpdateVisit/GetVisitColumnsForEdit", {
            platformId: visit.platform,
            activityIds: visit.activities,
            driverId: visit.driver || null
        })
            .then(visitColumnsData => {
                this.setState({
                    visitColumns: StateHelper.sortColumns(
                        visitColumnsData.columns,
                        visit.platform,
                        "visit",
                        this.context.permissionsData.fieldsConfig),
                    availableActivities: visitColumnsData.availableActivities,
                    isLoading: false
                });
            })
            .catch(() => this.setState({
                isLoading: false
            }));
    }

    setNewOrders = (newOrdersIds, data, taskId, activityTest) => {
        let newData = CloneDeep(data);

        if (taskId) {
            let tasks = newData.tasks || [];
            let task = tasks.find(tsk => tsk.id === taskId);
                //добавление заказов из модуля заказов
            callApi("OrdersModule/OrdersModuleByTask", {
                ids: newOrdersIds,
                activityId: activityTest && task.activity
            })
                .then(res => {
                    if (!res.isError) {
                        const data = res.data;
                        const order = data[0] || {};
                        const {orderFieldForTask, orderFieldForVisit, customFieldsForVisit} = order;

                        if (!this.props.taskId) {
                            for (let key in orderFieldForTask) {
                                if (key === 'activityId') {
                                    task.activity = orderFieldForTask[key];
                                }
                                else {
                                    task[key] = orderFieldForTask[key];
                                }
                            }
                        }

                        if (!this.props.visitId) {
                            for (let key in orderFieldForVisit) {
                                this.onFieldChange(null, {
                                    name: key,
                                    value: orderFieldForVisit[key]
                                });
                            }
                            this.onFieldChange(null, {
                                name: "customFields",
                                value: customFieldsForVisit
                            });
                        }

                        task.orders.push(...data.map(newOrder => {
                            return {
                                ...newOrder,
                                id: StateHelper.newMongoObjectId(),
                            };
                        }));
                        if (this.props.visitTaskType) task.type = this.props.visitTaskType;
                    } else {
                        toast.error(t(data.error));
                        this.props.onClose && this.props.onClose();
                    }
                });
                newData.tasks = tasks;
        }
        return newData;
    }

    loadData = () => {
        this.setState({
            isLoading: true
        });
        return callApi("CreateUpdateVisit/getformodule", {visitid: this.state.visit.id || this.props.visitId, platformid: this.props.platformId})
            .then(data => {
                this.setState({visit: data, oldVisit: CloneDeep(data), isLoading: false});
                return data;
            });
    };

    action = (action, id, type, history) => {
        let url = type === "task" ? "tasks/action" : "visits/action";
        this.showActionConfirmation(`${t("Are you sure to complete")} "${t(action.buttonName)}"?`, (event) => {
            event.target.classList.add("loading");
            event.target.disabled = true;
            if (this.isVisitChanged())
                this.save().then(() => {
                    this.makeAction(url, action.name, id, history);
                });
            else
                this.makeAction(url, action.name, id, history);
        });
    };

    makeAction = (url, name, id, history) => {
        return callApi(url, {name, id}).then(resp => {
            this.closeActionConfirmation();
            if (resp.successMessage) {
                this.closeAnyway(history);
                if (resp.successMessage !== "") toast.info(resp.successMessage);
            }
            else if (resp.errorMessage !== "") toast.error(resp.errorMessage);

            //this.loadData();
            //this.debounceShowOrHideSaveButton();
        });
    };

    save = (notLoad = false) => {
        this.setState({
            isLoading: true
        });
        return callApi("CreateUpdateVisit/SaveVisit", this.state.visit).then(resp => {

            if (resp.successMessage !== null)
                toast.info(resp.successMessage);
            else
                toast.error(resp.errorMessage);
            this.setState({
                isLoading: false,
                saveClick: true
            });
            !notLoad && this.loadData();
            this.debounceShowOrHideSaveButton();
            return resp;
        });
    };

    saveAndClose = (history) => {
        this.save(true).then(resp => {
            if (resp.successMessage)
                this.closeAnyway(history);
        });
    };

    onCustomFieldChange = (e, {name, value}) => {
        this.setState(prevState => {
            let index = prevState.visit.customFields.findIndex(x => x.name === name);
            if (prevState.visit.customFields[index])
                prevState.visit.customFields[index].value = value;
            else
                prevState.visit.customFields.push({name, value});
            return prevState;
        }, this.debounceShowOrHideSaveButton);
    }

    onFieldChange = (e, {name, value}) => {
        this.setState(prevState => {
            this.state.visit[name.charAt(0).toLowerCase() + name.slice(1)] = value;
            return this.state.visit;
        }, this.debounceShowOrHideSaveButton);
        if (name === "Driver") {
            this.setState({
                visit: {
                    ...this.state.visit,
                    driver: value
                }
            });
            let visit = this.state.visit;
            if (value) {
                callApi("CreateUpdateVisit/GetDriverDocuments", value)
                    .then(data => {
                        visit.driverDocuments = data;
                        this.setState({visit});
                    });
                const currentPlatformId = this.context.permissionsData.currentPlatformId;
                this.setState({
                    isLoading: true
                });
                callApi("CreateUpdateVisit/GetVisitColumnsForEdit", {
                    platformId: currentPlatformId,
                    activityIds: visit.activities,
                    driverId: value
                })
                    .then(visitColumnsData => {
                        this.setState({
                            visitColumns: StateHelper.sortColumns(
                                visitColumnsData.columns,
                                currentPlatformId,
                                "visit",
                                this.context.permissionsData.fieldsConfig),
                            availableActivities: visitColumnsData.availableActivities,
                            isLoading: false
                        });
                    });
            }
            else {
                visit.driverDocuments = [];
                this.setState({visit});
            }
        }
        else {
            if (name === "Transport") {
                let visit = this.state.visit;
                if (value)
                    callApi("CreateUpdateVisit/GetTransportDocuments", value)
                        .then(data => {

                            visit.transportDocuments = data;
                            this.setState({visit});
                        });
                else {
                    visit.transportDocuments = [];
                    this.setState({visit});
                }
            }
            if (name === "Truck") {
                let visit = this.state.visit;
                if (value)
                    callApi("truck/GetDocuments", value)
                        .then(data => {

                            visit.truckDocuments = data;
                            this.setState({visit});
                        });
                else {
                    visit.truckDocuments = [];
                    this.setState({visit});
                }
            }
            if (name === "Trailer") {
                let visit = this.state.visit;
                if (value)
                    callApi("trailer/GetDocuments", value)
                        .then(data => {

                            visit.trailerDocuments = data;
                            this.setState({visit});
                        });
                else {
                    visit.trailerDocuments = [];
                    this.setState({visit});
                }
            }
            if (name === "VisitDate") {
                this.setState(prevState => {
                    if (prevState.visit.tasks.length > 0) {
                        prevState.visit.tasks.map(value => {
                            if (value.timeSlot) {
                                toast.info(`${t('At task')}\"${value.number}\"  ${t('the timeslot was reset')}.`);
                            }

                            value.timeSlot = null;

                            return value;
                        });
                    }

                    prevState.visit.visitDate = value;

                    return prevState;
                });
            }
        }
    };

    onTaskCustomFieldChange = taskId => (e, {name, value}) => {
        this.setState(prevState => {
            let taskIndex = prevState.visit.tasks.findIndex(x => x.id === taskId);
            let index = prevState.visit.tasks[taskIndex].customFields.findIndex(x => x.name === name);
            if (prevState.visit.tasks[taskIndex].customFields[index])
                prevState.visit.tasks[taskIndex].customFields[index].value = value;
            else
                prevState.visit.tasks[taskIndex].customFields.push({name, value});
            return prevState;
        }, this.debounceShowOrHideSaveButton);
    }

    onTaskFieldChange = taskId => (e, {name, value}) => {
        this.setState(prevState => {
            let taskIndex = prevState.visit.tasks.findIndex(x => x.id === taskId);

            if (name === "Gate" && prevState.visit.tasks[taskIndex].gate !== value) {
                prevState.visit.tasks[taskIndex].timeSlot = null;
            }

            prevState.visit.tasks[taskIndex][name.charAt(0).toLowerCase() + name.slice(1)] = value;

            if (name === "Activity") {
                prevState.visit.activities = (prevState.visit.tasks || []).map(x => x.activity).filter((v, i, a) => a.indexOf(v) === i);
                prevState.visit.tasks[taskIndex].timeSlot = null;
                this.loadVisitFields(prevState.visit);
            }

            if (name === "TimeSlot") {
                if (!value) {
                    prevState.visit.tasks[taskIndex].timeSlot = null;

                    return prevState;
                }

                prevState.visit.tasks[taskIndex].timeSlot = {
                    date: value.date,
                    start: value.start,
                    end: value.end,
                    rollingDate: value.rollingDate,
                };

                if (value.date !== value.visitDate) {
                    prevState.visit.visitDate = value.date;

                    prevState.visit.tasks.map((value, index) => {
                        if (index !== taskIndex && value.timeSlot) {
                            toast.info(`${t('At task')} \"${value.number}\" ${t('the timeslot was reset')} ${t('Check that the timeslot dates for all tasks match')}`);
                            value.timeSlot = null;
                        }

                        return value;
                    });

                    return prevState;
                }

                return prevState;
            }

            if (name === "Type") {
                prevState.visit.tasks[taskIndex].timeSlot = null;
            }
            return prevState;
        }, this.debounceShowOrHideSaveButton);
    };

    addTask = (newOrdersFromModule) => {
        if (!this.state.availableActivities || this.state.availableActivities.length === 0) {
            this.setState({error: t("You can't create a new task because of lack of available activities")});

            return;
        }
        callApi("tasks/save", {
            activity: Array.isArray(newOrdersFromModule) ? null : (this.state.availableActivities || [])[0] || null,
            platform: this.state.visit.platform,
            visit: this.state.visit.id,
            state: "Draft",
            type: "Loading",
            isFromOrderModule: this.props.isOrderModule
        }).then(data => {
            this.setState(prevState => {
                prevState.visit.tasks.push({
                    activity: Array.isArray(newOrdersFromModule) ? null : (prevState.availableActivities || [])[0] || null,
                    platform: this.state.visit.platform,
                    customFields: [],
                    id: data.id,
                    orders: [],
                    state: "Draft",
                    type: "Loading",
                    number: t("New task"),
                    consignee: "",
                    provider: "",
                    transportCompany: "",
                    gate: "",
                    initialGate: "",
                    timeSlot: null
                });
                prevState.visit.activities = (prevState.visit.tasks || []).map(x => x.activity).filter((v, i, a) => a.indexOf(v) === i);
                const newData = Array.isArray(newOrdersFromModule) ? {visit: this.setNewOrders(newOrdersFromModule, CloneDeep(prevState.visit), data.id), newOrderId: data.id} : {visit: prevState.visit};
                this.loadVisitFields(newData.visit);
                return newData;
            }, this.debounceShowOrHideSaveButton);
        });
    };

    onDocumentsChange = (documents, type) => {
        this.setState(prevState => {
            switch (type) {
                case "Visit":
                    prevState.visit.visitDocuments = documents;
                    break;
                case "Driver":
                    prevState.visit.driverDocuments = documents;
                    break;
                case "Transport":
                    prevState.visit.transportDocuments = documents;
                    break;
                case "Truck":
                    prevState.visit.truckDocuments = documents;
                    break;
                case "Trailer":
                    prevState.visit.trailerDocuments = documents;
                    break;
            }
            return {visit: prevState.visit};
        }, this.debounceShowOrHideSaveButton);
    }

    onTaskDocumentsChange = taskId => (documents) => {
        this.setState(prevState => {
            let taskIndex = prevState.visit.tasks.findIndex(x => x.id === taskId);
            prevState.visit.tasks[taskIndex].documents = documents;
            return prevState;
        }, this.debounceShowOrHideSaveButton);
    }

    getIsLoading = value => {
        this.setState({
            isLoading: value
        })
    }

    closeActionConfirmation = () => {
        this.setState({actionConfirmation: {open: false}});
    }

    showActionConfirmation = (content, onConfirm) => {
        this.setState({
            actionConfirmation: {
                open: true,
                onConfirm,
                content
            }
        });
    };


    renderLoader = () => {
        return (
            <div className="fluid-for-loader">
                <Dimmer active inverted>
                    <Loader size='large' inverted content={`${t('Loading')}...`}/>
                </Dimmer>
            </div>
        );
    };

    showOrHideSaveButton = () => {
        this.setState(() => {
            return {
                saveButton: this.isVisitChanged(),
            };
        });
    }

    debounceShowOrHideSaveButton = debounce(300, this.showOrHideSaveButton);

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { visit, oldVisit } = this.state;
        if (visit !== prevState.visit || oldVisit !== prevState.oldVisit) {
            this.debounceShowOrHideSaveButton();
        }
    }

    setVisit = (newOrdersIds, taskId) => {
        this.setState(
            prevState => {
                return {
                    visit: this.setNewOrders(newOrdersIds, CloneDeep(prevState.visit), taskId || this.props.taskId, false),
                    oldVisit: prevState
                };
            });
    }

    render() {
        const {taskId, children, title, newOrdersIds, defaultActiveIndex} = this.props;
        const {visit, visitColumns, availableActivities, modalOpen, error, saveButton, showConfirmation, actionConfirmation} = this.state;

        let filteredTasks = [];
        let taskDocuments = [];
        let panes = [];
        let trip = '';
        if (modalOpen) {
            /* this.setState({
                 filteredTasks: !availableActivities
                     ? visit.tasks
                     : visit.tasks.filter(x => availableActivities.includes(x.activity));
             })*/
            taskDocuments = filteredTasks.map(x => x.documents);
            taskDocuments = taskDocuments && taskDocuments[0] && taskDocuments.some(y => y && y.length > 0)
                ? taskDocuments.reduce(function (a, b) {
                    return a.concat(b);
                })
                : [];
            let visitPanes = this.context.permissionsData.permissions.includes("ViewVisits") || this.context.permissionsData.permissions.includes("ViewTasks") ? [
                {
                    menuItem: t("Information"),
                    render: () => <Tab.Pane>
                        <Information
                            isVisitChange={saveButton}
                            visit={visit}
                            columns={visitColumns}
                            onFieldChange={this.onFieldChange}
                            onCustomFieldChange={this.onCustomFieldChange}
                            reloadFields={this.loadVisitFields}/>
                    </Tab.Pane>
                },
                {
                    menuItem: `${t("Documents")} (${visit.driverDocuments.length + visit.visitDocuments.length + visit.trailerDocuments.length + visit.truckDocuments.length + taskDocuments.length})`,
                    render: () => <Tab.Pane>
                        <Documents
                            visit={visit}
                            onDocumentsChange={this.onDocumentsChange}
                            taskDocuments={taskDocuments}
                            onSaveForm={this.save}
                        />
                    </Tab.Pane>
                }
            ] : [];

            if (this.context.permissionsData.permissions.includes("ViewVisitHistory")|| this.context.permissionsData.permissions.includes("ViewTaskHistory") || this.context.permissionsData.permissions.includes("35") )
                visitPanes.push(
                    {
                        menuItem: t("History"),
                        render: () => <Tab.Pane>
                            <History items={visit.histories}/>
                        </Tab.Pane>
                    });

            if (this.context.permissionsData.permissions.includes("ViewTaskHistory") || this.context.permissionsData.permissions.includes("ViewTasks") || this.context.permissionsData.permissions.includes("35") || this.context.permissionsData.permissions.includes("ViewVisits"))
                panes.push({
                    menuItem: t("Visit"),
                    render: () => <Tab.Pane>
                        <Tab menu={{pointing: true}} panes={visitPanes}/>
                    </Tab.Pane>
                });

            if (this.context.permissionsData.permissions.includes("ViewTasks") || this.context.permissionsData.permissions.includes("ViewVisits"))
                panes.push({
                    menuItem: t("Tasks"),
                    render: () => {
                        filteredTasks = !availableActivities
                            ? visit.tasks
                            : visit.tasks.filter(x => availableActivities.includes(x.activity));

                        return (<Tab.Pane>
                            <Tasks
                                ordersOpen={!!newOrdersIds}
                                driverId={visit.driver || null}
                                visitDate={visit.visitDate || null}
                                taskId={taskId || (!!newOrdersIds && this.state.newOrderId)}
                                isVisitChange={saveButton}
                                tasks={filteredTasks}
                                getIsLoading={this.getIsLoading}
                                onCustomFieldChange={this.onTaskCustomFieldChange}
                                onFieldChange={this.onTaskFieldChange}
                                addTask={this.addTask}
                                onAction={(action, id, type) => this.action(action, id, type, history)}
                                onTaskDocumentsChange={this.onTaskDocumentsChange}
                                onSaveVisit={this.save}
                                loadData={this.loadData}
                                setNewOrders={(d, task) => this.setVisit(d, task)}
                            />
                        </Tab.Pane>)
                    }
                });


            if (visit && visit.tasks && visit.tasks[0] && visit.tasks[0].customFields) {
                trip = visit.tasks[0].customFields.filter(({name})=>name.indexOf("_dis_t_trip") > -1).find(({value})=>value);
                trip = trip ? `(${trip.value})` : '';
            }


        }


        return (<Route render={({ history }) => (<Modal dimmer="blurring"
                       className="visit-modal"
                       trigger={children}
                       open={modalOpen}
                       onOpen={this.onOpen}
                       onClose={(e) => this.onClose(e, history)}
                       closeOnEscape
                       closeOnDimmerClick={false}
                       closeIcon
                       size='fullscreen'>
            <Modal.Header>{title} № {this.state.visit.number || this.props.number} {this.props.visitDate || this.state.visit.visitDate ? `${t('from')} ${this.state.visit.visitDate || this.props.visitDate}` : ''} {trip}</Modal.Header>
            {this.state.isLoading && this.renderLoader()}
            <Modal.Content>
                <Modal.Description>
                    <Tab panes={panes} defaultActiveIndex={taskId && (this.context.permissionsData.permissions.includes("ViewTaskHistory") || this.context.permissionsData.permissions.includes("35") || this.context.permissionsData.permissions.includes("ViewVisits") || this.context.permissionsData.permissions.includes("ViewTasks")) ? 1 : (defaultActiveIndex || 0)}/>
                </Modal.Description>
                {error
                    ? <Message negative>
                        <Message.Header>{t('Mistake')}</Message.Header>
                        <p>{error}</p>
                    </Message>
                    : null}
            </Modal.Content>
            <Modal.Actions>
                {visit.actions.map(action => <Button key={action.name}
                                                     color={action.color.charAt(0).toLowerCase() + action.color.slice(1) === 'default' ? 'blue' : action.color.charAt(0).toLowerCase() + action.color.slice(1) || 'blue'}
                                                     content={t(action.buttonName)} onClick={() => {
                    this.action(action, visit.id, 'visit', history);
                }}/>)}
                {
                    (newOrdersIds && saveButton && !this.state.saveClick) && <Button className="save-changes-button" content={t("group_cancel")}
                                  onClick={(e)=>this.onClose(e, history)}/>
                }
                {
                    saveButton
                        ? <Button className="save-changes-button" color='green' content={t("Save changes")}
                                  onClick={(e) => newOrdersIds ? this.save() : this.saveAndClose(e, history)}/>
                        : null
                }
            </Modal.Actions>
            <Confirm dimmer="blurring"
                     open={showConfirmation}
                     onCancel={() => this.closeAnyway(history)}
                     onConfirm={this.closeConfirmation}
                     content={t("Your changes won't save. Are you sure to close the window?")}
                     cancelButton={t("Close without saving")}
                     confirmButton={t("CancelButton")}
            />
            <Confirm dimmer="blurring"
                     open={actionConfirmation.open}
                     onCancel={this.closeActionConfirmation}
                     onConfirm={actionConfirmation.onConfirm}
                     content={actionConfirmation.content}
            />
        </Modal>)} />);
    }
};

