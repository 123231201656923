/*
Последняя редакция: 2021.08.12


[2020.11.18] К.Кузнец
- реализовал загрузку перечня внешних ссылок
	https://stackoverflow.com/questions/50539442/change-content-on-click-and-hover-in-react-semantic-ui-popup-element
	https://react.semantic-ui.com/modules/popup/#usage-controlled
	добавил массив ссылок 'popup_links_arr' в состояние 'state', определил обработчик 'popup_onopen' ДО вызова 'render()',
	добавил параметр 'popup_links_arr' в вызове 'render()' const,
	пробросил параметры 'popup_links_arr' и 'popup_onopen' в элемент <VisitGridHeader> как свойства тега,
	изменил файл компонента VisitGridHeader.jsx

[2021.08.12]
- восстановил наш функционал перечня ссылок

[2021.08.12]
- добавил параметры на вход функции VisitGridHeader
	popup_links_arr
	popup_onopen
- реализовал формирование перечня ссылок из параметра
	popup_links_arr
- добавил обработку события открытия перечня ссылок путем вызова
	popup_onopen

*/





import "babel-polyfill";
import React from "react";
import { toast } from "react-toastify";
import { Button, Checkbox, Confirm, Grid, Label, Popup } from "semantic-ui-react";
import { debounce } from "throttle-debounce";
import { UserPermissionsContext } from "../../Contexts";
import downloadFile, { callApiDownload } from "../../utils/downloadFile";
import * as StateHelper from "../../utils/stateHelper";
import InfiniteScrollTable from "../InfiniteScrollTable";
import { FormModal } from "../modals/FormModal";
import { GroupTaskModal } from "../modals/GroupTaskModal";
import VisitTasksModal from "../modals/visitTaskModal/VisitTasksModal";
import Filter from "./components/Filter";
import { GridHeader } from "./components/GridHeader";
import Results from "./components/Results";
import { VisitGridHeader } from "./components/VisitGridHeader";

class SuperGrid extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        rows: [],
        columns: [],
        backLights: [],
        filters: this.getFiltersFromStorage,
        sort: this.getSortFromStorage,
        backLightsFilter: [],
        backLightsFilterId: [],
        fullText: null,
        confirmation: {},
        page: 0,
        count: 0,
        selectedRowListId: [],
        heightFooter: 0,
		// [2020.11.18] К.Кузнец, добавил свойство в состояние
		popup_links_arr: []
    };

    get getSortFromStorage() {
        return JSON.parse(localStorage.getItem(this.props.storageSortItem)) || {};
    }

    get getFiltersFromStorage() {
        return JSON.parse(localStorage.getItem(this.props.storageFilterItem)) || {};
    }

    setHeightFooter = () => this.setState({heightFooter: this.footer.clientHeight});

    componentDidMount() {
        this.setTimerAndLoad();
        window.addEventListener("resize", this.setHeightFooter);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.backLights !== prevState.backLights) this.setHeightFooter();

        if ((JSON.stringify(prevState.filters) !== JSON.stringify(this.state.filters))) {
                this.setState({
                    selectedRowListId: []
                })
        }

        if (this.state.rows !== prevState.rows && this.props.onCheck) {
            this.props.onCheck(this.state.selectedRowListId);
        }

        if ((this.state.selectedRowListId !== prevState.selectedRowListId) && this.props.gridName) {
            callApi(`${this.props.gridName}/getActions`, this.state.selectedRowListId).then(data => {
                this.setState({actions: data});
            });
        }

        if (this.props.autoLoadOff !== prevProps.autoLoadOff) {
            this.props.autoLoadOff ? this.clearTimer() : this.setTimerAndLoad();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
        window.removeEventListener("resize", this.setHeightFooter);
    }

    setTimerAndLoad = () => {
        let { autoLoadOff } = this.props;
        this.loadColumns();
        !autoLoadOff && this.setTimer();
    };

    setTimer = () => {
        if (this.timerId !== undefined)
            clearInterval(this.timerId);
        this.timerId = setInterval(() => { this.loadColumns(); }, 500000000);
    };

    clearTimer = () => {
        clearInterval(this.timerId);
    };

    loadColumns = (currentPlatformId, currentActivityId) => {
        currentPlatformId = currentPlatformId || this.context.permissionsData.currentPlatformId;
        callApi(this.props.apiGetColumns, currentPlatformId)
            .then(x => {
                if (x.error) {
                    toast.error(t("Lost connection with server"));
                    throw (t("Lost connection with server"));
                }
                if (x.columns) {
                    x.columns = StateHelper.sortColumns(
                        x.columns,
                        currentPlatformId,
                        this.props.userColumnsName,
                        this.context.permissionsData.fieldsConfig);
                }
                return x;
            })
            .then(currentActivityId ? () => this.loadList(null, null, null, currentActivityId) : this.loadList);
    };

    getParams = (gridInit, currentPlatformId, concat, currentActivityId) => {
        let { columns } = this.state;
        const { page, filters, backLightsFilter, backLightsFilterId, sort, fullText } = this.state;

        let { notDisplayedIds = [] } = this.props;

        currentPlatformId = currentPlatformId || this.context.permissionsData.currentPlatformId;
        currentActivityId = this.props.activityId ? this.props.activityId : (currentActivityId || this.context.permissionsData.currentActivityId);
        columns = (gridInit || {}).columns || columns;

        const filtersMap = Object.keys(filters).map(function (k) {
            let columnInfo = columns.find(x => x.name === k) || {};
            return {
                name: k,
                value: filters[k],
                type: columnInfo.type,
                isCustom: columnInfo.isCustom,
            };
        });

        const data = {
            platformId: currentPlatformId,
            columns: filtersMap,
            backLights: backLightsFilter,
            backLightsId: backLightsFilterId,
            sort: sort,
            fullText: fullText,
            page: page,
            loadPrevious: !concat,
            activityId: currentActivityId,
            excludeOrdersModuleIds: notDisplayedIds
        };

        return {...data, actionsMapping: filters && Object.keys(filters).length};
    }

    loadList = (gridInit, currentPlatformId, concat, currentActivityId, isExport = false) => {
        const { filters, selectedRowListId } = this.state;
        let { page } = this.state;
        let { autoLoadOff } = this.props;

        if (concat) {
            page++;
            !autoLoadOff && this.clearTimer();
        }

        const { states = [] } = this.props;

        if (states && states.length) {
            filters['State'] = (filters['State'] ? filters['State'].split(',').filter(s => states.includes(s)) : states).toString();
        }

        if (isExport) {
            return callApiDownload(`${this.props.gridName}/ExportToExcel`, {
                ...this.getParams(gridInit, currentPlatformId, concat, currentActivityId),
                ordersModuleIds: selectedRowListId.length ? selectedRowListId : null
            }, null, true)
                .then(x => {
                    downloadFile(x);
                });
        }

        return callApi(this.props.apiGetList, this.getParams(gridInit, currentPlatformId, concat, currentActivityId))
            .then(x => {
                if (x.error) {
                    toast.error(t("Lost connection with server"));
                    throw (t("Lost connection with server"));
                }
                if (x.notifications) {
                    x.notifications.forEach(item => toast.info(`${item}`))

                }
                return x;
            })
            .then(rows => {
                this.setState(prevState => {
                    const _rows = this.state.rows;
                    const { selectedRowListId } = this.state;
                    const newRows = rows.items;
                    let state = {...prevState, selectedRowListId: selectedRowListId}

                    if (_rows.length > 0) {
                        newRows.map(value => {
                           const currentRow = state.selectedRowListId.find(item => item === value.id);
                           if (currentRow) {
                               value.isChecked = true
                           }
                        });
                    }
                    state = !concat
                        ? {
                            ...state,
                            rows: rows.items,
                            count: rows.count
                        } : {
                            ...state,
                            rows: state.rows.concat(rows.items.filter(x => !prevState.rows.some(y => y.id === x.id))),
                            page: rows.items.length > 0 ? page : --page
                        };
                    if (gridInit) {
                        state = {
                            ...state,
                            columns: gridInit.columns,
                            backLights: gridInit.backLights
                        };
                    }
                    return state;
                }, () => {
                    if (concat)
                        !autoLoadOff && this.setTimer();
                });
            });
    };

    loadAndResetContainerScroll = () => {
        this.loadList().then(
            () => { if (this.container) {
                this.container.scrollTop = 0;
            } }
        );
    };

    setFilter = (e, { name, value }) => {
        this.setState((prevState) => {
            let { filters } = prevState;
            if (value)
                filters = { ...filters, [name]: value };
            else if (filters[name]) {
                filters = Object.assign({}, filters);
                delete filters[name];
            }
            return { ...prevState, filters, page: 0 };
        }, this.debounceSetFilterApiAndLoadList);
    };

    setFilterApiAndLoadList = () => {
        const filtersJson = JSON.stringify(this.state.filters);
        localStorage.setItem(this.props.storageFilterItem, filtersJson);
        callApi("UserSettings/SaveUserParameter", { name: this.props.storageFilterItem, value: filtersJson });
        this.loadAndResetContainerScroll();
    };
    debounceSetFilterApiAndLoadList = debounce(300, this.setFilterApiAndLoadList)

    setSort = (e, { name, value }) => {
        const columns = this.state.columns;
        const columnInfo = columns.find(x => x.name === name);
        const isDesc = value === "desc";
        const sort = (this.state.sort.name === name && this.state.sort.desc === isDesc)
            ? {} : {
                name,
                desc: isDesc,
                isCustom: columnInfo.isCustom
            };
        localStorage.setItem(this.props.storageSortItem, JSON.stringify(sort));
        callApi("UserSettings/SaveUserParameter", { name: this.props.storageSortItem, value: JSON.stringify(sort) });
        this.setState({
            sort,
            page: 0
        }, this.loadAndResetContainerScroll);
    };

    clearFilters = () => {
        this.setState({
            filters: {},
            backLightsFilter: [],
            backLightsFilterId: [],
            page: 0,
        }, this.loadAndResetContainerScroll);
        localStorage.setItem(this.props.storageFilterItem, JSON.stringify({}));
        callApi("UserSettings/SaveUserParameter", { name: this.props.storageFilterItem, value: JSON.stringify({}) });
    };

    backLightsFilterChange = (e, { backlight: value }) => {
        this.setState(prevState => {
            let bLF = prevState.backLightsFilter;
            let bLFId = prevState.backLightsFilterId;
            if (value.realizationClassName === "All") {
                if (bLF.length > 0) {
                    bLF = [];
                    bLFId = [];
                }
                else {
                    bLF = prevState.backLights.map((x) => (x.realizationClassName)).filter(x => x !== "All");
                    bLFId = prevState.backLights.filter(x => x.realizationClassName !== "All").map((x) => (x.id));
                }
                
            } else {
                if (bLF.includes(value.realizationClassName) || bLFId.includes(value.id)) {
                    bLF.splice(bLF.indexOf(value.realizationClassName), 1);
                    bLFId.splice(bLFId.indexOf(value.id), 1);
                }
                else {
                    bLF.push(value.realizationClassName);
                    bLFId.push(value.id);
                }
            }
            return { backLightsFilter: bLF, backLightsFilterId: bLFId, page: 0 };
        }, this.loadAndResetContainerScroll);
    };

    action = (e, { actionname, actionbuttonname, rowid }) => {
        e.stopPropagation();
        this.showConfirmation(`${t('Are you sure to complete')} "${t(actionbuttonname)}"?`, (event) => {
            event.target.classList.add("loading");
            event.target.disabled = true;
            callApi(this.props.apiAction, { name: actionname, id: rowid }).then(resp => {
                if (resp.successMessage && resp.successMessage.length)
                    toast.info(resp.successMessage || "Done");
                if (resp.errorMessage && resp.errorMessage.length)
                    toast.error(resp.errorMessage || "Error");
                this.loadList();
                this.closeConfirmation();
            });
        });
    };

    async consecutiveCallApiAction(idList = [], name) {
        for (let i = 0; i < idList.length; i++) {
            const id = idList[i];
            await callApi(this.props.apiAction, {name, id}).then(resp => {
                if (resp.successMessage && resp.successMessage.length)
                    toast.info(resp.successMessage || "Done");
                if (resp.errorMessage && resp.errorMessage.length)
                    toast.error(resp.errorMessage || "Error");
            })
        }
    };

    actionList = (e,{action, idList, count }) => {
        const {name, buttonName} = action;
        e.stopPropagation();
        this.showConfirmation(`${t('Are you sure to complete')} "${t(buttonName)}" ${count> 1 ? `${t('for')} ` + count: ''}?`, (event) => {
            event.target.classList.add("loading");
            event.target.disabled = true;

            this.consecutiveCallApiAction(idList, name);

            debounce(300,
                () => {
                this.loadList();
                this.closeConfirmation();})();

        });
    }

    changeFullTextFilter = (e, { value }) => {
        this.setState({ fullText: value, page: 0 }, this.loadAndResetContainerScroll);
    };

    closeConfirmation = () => {
        this.setState({ confirmation: { open: false } });
    };

    showConfirmation = (content, onConfirm) => {
        this.setState({
            confirmation: {
                open: true,
                onConfirm,
                content
            }
        });
    };

    selectRow = (event, data, id) => {
        const { rows, selectedRowListId: selectedRowListIdNew } = this.state;
        const _rows = JSON.parse(JSON.stringify(rows));
        const row = _rows.find(row => (row.id === id));

        row.isChecked = data.checked;
        if (data.checked && selectedRowListIdNew.indexOf(id) === -1) {
            selectedRowListIdNew.push(id)
        }
        else {selectedRowListIdNew.splice(selectedRowListIdNew.indexOf(id), 1);}

        this.setState({
            rows: _rows,
            selectedRowListId: [...selectedRowListIdNew]
        });
    };

    getRenderButtonsActions = () => {
        const {selectedRowListId, rows, actions} = this.state;
        
        const checkedRows = rows.filter(row => selectedRowListId.find(x => x === row.id));
        const buttonsList = [];
        checkedRows.forEach(item => {
            item.availableActions.forEach(action => {
                const indexButtonsList = buttonsList.findIndex(x => x.name === action.name)

                if (indexButtonsList !== -1) {
                    const countActions = buttonsList[indexButtonsList].id.length + 1
                    buttonsList[indexButtonsList] = {
                        id: [...buttonsList[indexButtonsList].id, item.id],
                        platform: item.platform,
                        name: action.name,
                        color: action.color,
                        buttonName: action.buttonName,
                        count: countActions
                    }
                } else buttonsList.push({
                    id: [item.id],
                    platform: item.platform,
                    name: action.name,
                    color: action.color,
                    buttonName: action.buttonName,
                    count: 1
                })
            })
        });

        const groupPermission = this.context.permissionsData.permissions.includes("GroupOrderModule")
            || this.context.permissionsData.permissions.includes("EditOrderModule");

        return (
            ([...buttonsList, ...(actions || [])]).map(item =>
               item.name === 'AddCheckedToTask'
                   ? (groupPermission && <GroupTaskModal
                        key={`actions-${item.name}`}
                        rows={item.ids}
                        onClick={this.props.setAutoLoadOff}
                   >
                       <Button
                           rowid={item.id}
                           size='mini'
                       >
                           {t(item.buttonName)} {item.count > 1 ? `(${item.count})` : null}
                       </Button>
                    </GroupTaskModal>)
                   : <span key={`actions-${item.name}`}>
                           <Button
                               color={item.color.charAt(0).toLowerCase() + item.color.slice(1)}
                               actionname={item.name}
                               actionbuttonname={item.buttonName}
                               rowid={item.id}
                               onClick={(e) => this.actionList(e, {action: {name: item.name, buttonName: item.buttonName}, idList: item.id, count: item.count})}
                               size='mini'>
                               {t(item.buttonName)} {item.count > 1 ? `(${item.count})` : null}
                           </Button>
                   </span>
           ));
    }

    setAll = () => {
        const {selectedRowListId, count} = this.state;
        const {gridName} = this.props;
        if (selectedRowListId.length !== count) {
            callApi(`${gridName}/ids`, {
                ...this.getParams()
            }).then((data) => {
                this.setState({selectedRowListId: data});
            });
        } else {
            this.setState({selectedRowListId: []});
        }
    }

	// [2020.11.18] К.Кузнец, обработчик клика
	popup_onopen = ()=>{

		//https://stackoverflow.com/questions/52436594/how-do-you-update-the-div-with-react
		//this.setState({
		//	popup_links_arr: [1,2,3]
		//});
		//currentPlatformId = currentPlatformId || this.context.permissionsData.currentPlatformId;
        //callApi(this.props.apiGetColumns, currentPlatformId)
		//console.log(this.context);
		//console.log(this.context.permissionsData);
		//this.context.permissionsData.currentPlatformId;
		//this.context.permissionsData.currentPlatformName;
		//console.log(this.props);

		var obj = {
			currentPlatformId: 		this.context.permissionsData.currentPlatformId,
			currentPlatformName:	this.context.permissionsData.currentPlatformName,

			// [2021.08.12] ТЕСТИРОВАНИЕ
			//context:				this.context,
			//props:				this.props
		};
		//console.log(obj);

		callApi("getLinks", obj).then(RESP=>{								// api/getLinks
			if(RESP.error || RESP['ошибка']){
				//toast.error("ОШИБКА! Перечень ссылок не загружен");
				//throw("ОШИБКА! Перечень ссылок не загружен");
				toast.error(RESP['причина'] || "ОШИБКА! Перечень не загружен");
			} else {
				var o = {
					// СХЕМА ОБЪЕКТА - {'ошибка':false, 'результат':[{href:'#', target:'_blank', content:''}..{}]}
					popup_links_arr:	RESP['результат']
				};
				this.setState(o);											// меняю состояние React
			};
		});

	}; // popup_onopen = ()=>{





    render() {
        const {
            storageSortItem,
            storageFilterItem,
            apiGetColumns,
            apiGetList,
            apiAction,
            userColumnsName,
            createButtonEnabled,
            createButtonPermission,
            createButtonName,
            createButtonModalTitle,
            editModalTitle,
            stateColors,
            gridName,
            checkedToTop,
            isModal = false,
            withAllCheckbox
        } = this.props;

        const {
            fullText,
            rows,
            columns,
            filters,
            sort,
            backLightsFilter,
            backLightsFilterId,
            confirmation,
            backLights,
            count,
            selectedRowListId,

			// [2020.11.18] К.Кузнец, параметр
			popup_links_arr

        } = this.state;

        let createButton = createButtonEnabled
        && this.context.permissionsData.permissions.some(x => Array.isArray(createButtonPermission) ? createButtonPermission.includes(x) : x === createButtonPermission)
            ? (gridName === 'ordersModule' ?
                <FormModal onClose={this.loadList} fields={columns} title={createButtonModalTitle} gridName={gridName} stateColors={stateColors} formData={{activity: this.context.permissionsData.currentActivityId || null}}>
                    <Button color='blue' className="create-button" >{createButtonName}</Button>
                </FormModal>
                : (this.context.permissionsData.currentPlatformName === "Все платформы" ?
                <Popup size='mini' position='bottom left' content='Выберите платформу' trigger={<Button style={{opacity: "0.5"}} color='blue' className="create-button" >{createButtonName}</Button>} />:
                <VisitTasksModal title={createButtonModalTitle} runTimer={this.setTimerAndLoad} stopTimer={this.clearTimer}>
                    <Button color='blue' className="create-button" >{createButtonName}</Button>
                </VisitTasksModal>))
            : null;
        

		// [2021.08.12] К.Кузнец, добавил параметры в элемент VisitGridHeader,
		// popup_links_arr={popup_links_arr}
		// popup_onopen={this.popup_onopen}
        return (<>
            <div className="visits container">
                {gridName !== 'ordersModule' && <VisitGridHeader
                    createButton={createButton}
                    isFilters={filters && Object.keys(filters).length}
                    searchValue={fullText}
                    searchOnChange={this.changeFullTextFilter}
                    counter={count}
                    loadColumns={this.loadColumns}
                    clearFilter={this.clearFilters}

                    popup_links_arr={popup_links_arr}
                    popup_onopen={this.popup_onopen}

                />}
                {gridName === 'ordersModule' && <GridHeader
                    activity={this.context.permissionsData.currentActivityId}
                    createButton={createButton}
                    isFilters={filters && Object.keys(filters).length}
                    searchValue={fullText}
                    searchOnChange={this.changeFullTextFilter}
                    counter={count}
                    loadColumns={this.loadColumns}
                    clearFilter={this.clearFilters}
                    isCheckedRows={selectedRowListId}
                    gridName={gridName}
                    isModal={isModal}
                    uploadingOrderInformationPermission={this.context.permissionsData.permissions.includes("LoadingTechnicalService")}
                    editPermission={this.context.permissionsData.permissions.includes("EditOrderModule") || this.context.permissionsData.permissions.includes("37")}
                    deletePermission={this.context.permissionsData.permissions.includes("DeleteOrderModule")}
                    onExport={()=>this.loadList(null, null, null, null, true)}
                />}
                <div className="scroll-grid-container"
                     style={{height: `calc(100vh - ${ this.state.heightFooter + (!isModal ? 97 : 250)}px)`}}
                     ref={instance => { this.container = instance; }}>
                    <InfiniteScrollTable
                        className="visits-table"
                        unstackable
                        striped
                        fixed
                        celled={false}
                        selectable={false}
                        headerRow={<Filter
                            states={this.props.states || []}
                            columns={columns}
                            filters={filters}
                            sort={sort}
                            setFilter={this.setFilter}
                            setSort={this.setSort}
                            stateColors={stateColors}
                            selectedRows={selectedRowListId}
                            count={count}
                            setAll={this.setAll}
                            withAllCheckbox={withAllCheckbox}
                        />}
                        context={this.container}
                        onBottomVisible={() => this.loadList(null, null, true)}
                    >
                        <Results
                            titleModal={editModalTitle || 'edit_visit_modal_label'}
                            columns={columns}
                            rows={rows}
                            filters={filters}
                            selectRow={this.selectRow}
                            actionHandler={this.action}
                            runTimer={this.setTimerAndLoad}
                            stopTimer={this.clearTimer}
                            stateColors={stateColors}
                            gridName={gridName}
                            checkedToTop={checkedToTop}
                            onCloseModal={this.loadList}
                            selectedRows={selectedRowListId}
                        />
                    </InfiniteScrollTable>
                </div>
                <div ref={(footer) => { this.footer = footer }}>
                    <Grid className="visits-footer-panel" style={{marginBottom: isModal ? '10px' : 'auto'}}>
                        {!isModal && <Grid.Row>
                            <Grid.Column width={8}>
                                {this.getRenderButtonsActions()}
                            </Grid.Column>
                            <Grid.Column width={8} className="visit-right-elements">
                                {backLights.map(a =>
                                    (<Label key={a.realizationClassName+a.id} color={a.color.toLowerCase()}>
                                        <Checkbox
                                            backlight={a || a.realizationClassName == "All"}
                                            label={t(a.name)}
                                            checked={a.realizationClassName === "All"
                                                ? backLightsFilter.length === 0
                                                : backLightsFilter.includes(a.realizationClassName)
                                            }
                                            onChange={this.backLightsFilterChange}/>
                                    </Label>))}
                            </Grid.Column>
                        </Grid.Row>}
                    </Grid>
                </div>

            </div>
            <Confirm dimmer="blurring"
                open={confirmation.open}
                onCancel={this.closeConfirmation}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
            <style>
                {"\
                body{\
                  overflow:hidden;\
                }\
            "}
            </style>
        </>);
    }
};
export default SuperGrid;
