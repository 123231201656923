import React from "react";
import { Popup, Checkbox, Form, Input } from "semantic-ui-react";

export default class SelectFilter extends React.Component {
    state = {items:[]}

    setFilter = (e, {value}) => {
        this.setState({filter: value.toLowerCase()});
    }

    clearFilter = () => {
        this.setState({ filter: "" });
    }

    loadList = (search = '') => {
        let items = this.props.options.filter(o=>o.text.toLowerCase().includes(search));
        this.setState({items});
    }

    handleOpen = () => {
        this.loadList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.filter !== prevState.filter) {
            this.loadList(this.state.filter);
        }
    }

    render() {
        const { onChange, value, placeholder, multi, options } = this.props;

        let values = value
            ? value.split(",")
            : [];

        let items = (this.state.items || []).map((x) => {
            return {
                value: x.value,
                name: x.text,
            };
        });

        const toggle = (e, { value }) => {
            if (multi) {
                if (values.some(x => x === value)) {
                    values.splice(values.indexOf(value), 1);
                } else {
                    values.push(value);
                }
                if (onChange)
                    onChange(e, {value: values.join(",")});
            } else onChange(e, {value});
        };

        let content = (
            <Form>
                <label className="label-in-popup">{placeholder}</label>
                <div>
                    <Input fluid size="mini" icon='search' value={this.state.filter || ''} onChange={this.setFilter}/>
                </div>
                <div className="select-facet-values">
                    {items.map(x => {
                        let label = (
                            <label>
                                {`${x.name} ${x.description ? x.description : ''}`}
                            </label>
                        );
                        return (<Form.Field key={x.value}>
                            <Checkbox value={x.value} checked={values.includes(x.value)} onChange={toggle} label={label} />
                        </Form.Field>);
                    })}
                </div>
            </Form>
        );

        return (
            <div className="facet-input">
                <Popup
                    trigger={
                        <Input
                            icon='caret down'
                            onKeyPress={(e) => { e.preventDefault(); }}
                            placeholder={
                                values.length > 0
                                    ? values.length + " " + t("selected")
                                    : placeholder
                            }
                            readOnly
                            value={(!multi && values.length && options.find(o=>o.value===values[0])) ? options.find(o=>o.value===values[0]).text : ''}
                        />
                    }
                    content={content}
                    on='click'
                    hoverable
                    position='bottom left'
                    onClose={this.clearFilter}
                    onOpen={this.handleOpen}
                />
            </div>
        );
    }
}
