import React from "react";
import {Form, Dropdown, Icon} from "semantic-ui-react";
import CompanyEditModal from "../../../companies/components/CompanyEditModal";
import { UserPermissionsContext } from "../../../../Contexts";


class Edit extends React.Component {
    state = {
        items: [],
        searchQuery: '',
        isHandlePressEnter: false
    }

    loadList = (afterCall, search = '', selectedId = '') => {
        callApi("case/GetTkCompanyForEdit", {search, selectedId: selectedId}).then(
            (items) =>{
                this.setState({
                    items: items.map(x => ({...x,  disabled: !x.isActive}))
                }, ()=>{
                    if(items.length === 1 && (search === '' || search === undefined)){
                        const {onChange} = this.props;
                        onChange && onChange(null, {name:'transportCompanyId', value: items[0].value});
                    }
                    afterCall&& afterCall();
                })
            }
        );
    }
    componentDidMount = () => {
        if (!!this.props.value) {
            this.loadList(()=> {}, '', this.props.value);
        } else
            this.loadList();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {isHandlePressEnter} = this.state;
        if (prevProps.value !== this.props.value && !isHandlePressEnter && this.state.searchQuery.length !==1) {
            setTimeout(()=>this.setState({
                searchQuery: ''
            }),100)
        }
        if (prevState.searchQuery !== this.state.searchQuery && !!this.state.searchQuery) {
            const {onChange, name} = this.props;
            console.log(name)
            onChange && onChange('',{name, value: ''});
        }
    }

    handleKeyPress = (e) => {
        if (e.keyCode == 13) {
            this.setState({
                isHandlePressEnter: true
            },() => this.loadList(()=> {}, this.state.searchQuery))
        }
    }

    handleOnChange = (e, {name, value}) => {
        const {onChange} = this.props;
        onChange && onChange(e, {name, value});
    }

    handleSearchChange = (e, { name, searchQuery }) => {
        if (searchQuery.includes(' ')) {
            this.loadList(()=> {}, searchQuery.slice(0,-1))
        }
        return this.setState({ searchQuery})
    }

    onFocus = () => {
        if (!!this.props.value) {
            this.loadList(()=> {}, '', this.props.value);
        } else
            this.loadList(() => {}, this.state.searchQuery);
    }

    onClick =() => {
        this.setState({
            isHandlePressEnter: false
        })
    }

    render() {
        const {name, value, isDisabled, onChange, reloadFields, valuesList, noLabel, isClearable, isNotHistory, isEdit, className} =  this.props;
        const { permissionsData } = this.context;
        const {searchQuery} = this.state;

        let items = this.state.items;
        delete items.forEach(i => delete i.isActive);

        const onSaveNew = (e, { value,parameter }) => {
            this.loadList(()=>{
                if (reloadFields !== undefined)
                    reloadFields(e, {name, value, parameter });
                onChange(e, { name, value, parameter });
            }, '', value);
        };

        const search = (options, queryStr) => {
            const query = queryStr.replace(/\s+/g, ' ').trim();
            return items.filter(opt => new RegExp(query, "i").test(opt.text) || (opt.description && new RegExp(query, "i").test(opt.description))).slice(0, 20);
        };

        return (
            <Form.Field>
                {<label className={isDisabled ? "label-disabled" : null}>{name && (t(name) + ' *')}</label>}
                {((permissionsData.permissions.includes("EditCompanies")) && !isDisabled)
                    ? <div className="dropbox-button">
                        <Dropdown
                            key={'gh7689067567456567789j=fghg-=\=fh'}
                            name={name}
                            onKeyDown={this.handleKeyPress}
                            value={value}
                            onChange={this.handleOnChange}
                            onFocus={this.onFocus}
                            onMouseDown={this.onClick}
                            searchQuery={searchQuery}
                            onSearchChange={this.handleSearchChange}
                            disabled={isDisabled || false}
                            search={search}
                            selection
                            //closeOnEscape={false}
                            selectOnBlur={false}
                            selectOnNavigation={false}
                            clearable={isClearable}
                            className={className}
                            options={items}
                        />
                        <CompanyEditModal
                            title={t("Adding a company")}
                            actionButton={t("Add company")}
                            id={null}
                            isNotHistory={isNotHistory}
                            onSave={onSaveNew}>
                            <Icon name='plus'/>
                        </CompanyEditModal>
                        {value && isEdit && <CompanyEditModal
                            size="small"
                            title={t('edit company')}
                            actionButton={t('Save changes')}
                            id={value}
                            buttonClassName={'edit-btn'}
                            isNotHistory={isNotHistory}
                            onSave={onSaveNew}
                        >
                            <Icon name='edit' />
                        </CompanyEditModal>
                        }
                    </div>
                    :
                    <Dropdown
                        key={'768867650879567867808790=dfgh789890fdrty'}
                        name={name}
                        onKeyDown={this.handleKeyPress}
                        value={value}
                        onChange={this.handleOnChange}
                        onFocus={this.onFocus}
                        onMouseDown={this.onClick}
                        searchQuery={searchQuery}
                        onSearchChange={this.handleSearchChange}
                        disabled={isDisabled || false}
                        search={search}
                        selection
                        selectOnBlur={false}
                        selectOnNavigation={false}
                        clearable={isClearable}
                        className={className}
                        options={items}
                    />
                }
            </Form.Field>

        );
    }
}

Edit.contextType = UserPermissionsContext;
export default Edit;
