﻿
export function getRequestStates() {
    return [
        { key: 0, value: "Awaited", color: "blue", inverted: false },
        { key: 1, value: "OnTheTerritory", color: "orange", inverted: false },
        { key: 2, value: "Departed", color: "green", inverted: false }
    ];
}

export function getPages() {
    return [
        { name: "visits", permission: "ViewVisits", main: true },
        { name: "tasks", permission: "ViewTasks", main: true },
        { name: "ordersModule", permission: "ViewOrderModule", main: true },
        { name: "ordersModule", permission: "36", main: true },
        { name: "invitations", permission: "ViewRequests", main: true },
        { name: "securityCheck", permission: "SendingСasesToSecurityService", main: true },
        { name: "securityService", permission: "SecurityService", main: true },
        { name: "fieldMatrix", permission: "FieldMatrix", main: true, adminKu: true },
        { name: "roles", permission: "EditRoles", main: true },
        { name: "users", permission: "EditUsers", main: true, adminKu: true },
        { name: "drivers", permission: "SettingDrivers" },
        { name: "transports", permission: "SettingTransports" },
        { name: "companies", permission: "EditCompanies" },
        { name: "documentTypes", permission: "EditDocumentTypes" },
        { name: "trailerTypes", permission: "EditTransportTypes" },
        { name: "truckTypes", permission: "EditTruckTypes" },
        { name: "gateCharacteristics", permission: "CharacteristicsGate" },
        { name: "activities", permission: "EditActivities" },
        { name: "platforms", permission: "EditPlatforms" },
        { name: "accessToInvitations", permission: "InvitationAccess" },
        { name: "globalSettings", permission: "EditGlobalSettings" },
    ];
}

export function getMainPages() {
    return getPages().filter(x => x.main);
}

export function getSecondaryPages() {
    return getPages().filter(x => !x.main);
}

export function visitStateColors() {
    return [
        { value: "Draft", color: "grey", inverted: true },
        { value: "Created", color: "grey", inverted: false },
        { value: "Waiting", color: "blue", inverted: true },
        { value: "Parking", color: "blue", inverted: false },
        { value: "InPlace", color: "yellow", inverted: false },
        { value: "Processing", color: "orange", inverted: false },
        { value: "ProcessingCompleted", color: "olive", inverted: true },
        { value: "Sent", color: "olive", inverted: false },
        { value: "Cancelled", color: "black", inverted: false },
    ];
}

export function taskStateColors() {
    return [
        { value: "Draft", color: "grey", inverted: true },
        { value: "Created", color: "grey", inverted: false },
        { value: "Waiting", color: "blue", inverted: true },
        { value: "Registered", color: "yellow", inverted: false },
        { value: "Processing", color: "orange", inverted: false },
        { value: "Completed", color: "olive", inverted: true },
        { value: "Finished", color: "green", inverted: true },
        { value: "Cancelled", color: "black", inverted: false },
    ];
}

export function ordersModuleStatesColors() {
    return [
        { value: "New", color: "blue", inverted: false },
        { value: "InVisit", color: "yellow", inverted: false },
        { value: "Closed", color: "black", inverted: false },
    ];
}

export function securityCheckStateColor() {
    return [
        { value: "New", color: "grey", inverted: false },
        { value: "InWork", color: "blue", inverted: false },
        { value: "Returned", color: "yellow", inverted: false },
        { value: "Confirmed", color: "green", inverted: true },
        { value: "Rejected", color: "red", inverted: true },
    ];
}

export function securityServiceStatusColor() {
    return [
        { value: "Returned", color: "yellow" },
        { value: "Confirmed", color: "green" },
        { value: "Rejected", color: "orange" },
        { value: "FullRejected", color: "orange" },
        { value: "PartialRejected", color: "orange" },
        { value: "TemporaryRejected", color: "orange" }
    ];
}

export function visitStatesFieldMatrix() {
    return [
        { key: "Draft", value: "Draft", text: t("Draft"), stateColor: "grey" },
        { key: "Created", value: "Created", text: t("Created"), stateColor: "grey" },
        { key: "Waiting", value: "Waiting", text: t("Waiting"), stateColor: "blue" },
        { key: "Parking", value: "Parking", text: t("Parking"), stateColor: "blue" },
        { key: "InPlace", value: "InPlace", text: t("InPlace"), stateColor: "yellow" },
        { key: "Processing", value: "Processing", text: t("Processing"), stateColor: "orange" },
        { key: "ProcessingCompleted", value: "ProcessingCompleted", text: t("ProcessingCompleted"), stateColor: "olive" },
        { key: "Sent", value: "Sent", text: t("Sent"), stateColor: "olive" },
        { key: "Cancelled", value: "Cancelled", text: t("Cancelled"), stateColor: "black" },
    ];
}

export function taskStatesFieldMatrix() {
    return [
        { key: "Draft", value: "Draft", text: t("Draft"), stateColor: "grey" },
        { key: "Created", value: "Created", text: t("Created"), stateColor: "grey" },
        { key: "Waiting", value: "Waiting", text: t("Waiting"), stateColor: "blue" },
        { key: "Registered", value: "Registered", text: t("Registered"), stateColor: "yellow" },
        { key: "Processing", value: "Processing", text: t("Processing"), stateColor: "orange" },
        { key: "Completed", value: "Completed", text: t("Completed"), stateColor: "olive" },
        { key: "Finished", value: "Finished", text: t("Finished"), stateColor: "green" },
        { key: "Cancelled", value: "Cancelled", text: t("Cancelled"), stateColor: "black" },
    ];
}

export function ordersModuleStatesFieldMatrix() {
    return [
        { key: "New", value: "New", text: t("New"), stateColor: "blue" },
        { key: "InVisit", value: "InVisit", text: t("InVisit"), stateColor: "yellow" },
        { key: "Closed", value: "Closed", text: t("Closed"), stateColor: "black" },
    ];
}

export function newMongoObjectId() {
    let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + "xxxxxxxxxxxxxxxx".replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

export function sortColumns(columns, currentPlatformId, type, fieldsConfig) {
    let myFilter = ((JSON.parse(fieldsConfig || "{}") || {})[currentPlatformId] || {})[type] || {};
    let hidden = myFilter.hidden || [];
    let order = myFilter.order || [];
    columns = columns.filter(x => !hidden.includes(x.name));
    let newColumns = [];
    for (let i = 0; i < order.length; i++) {
        let field = columns.find(x => x.name === order[i]);
        if (field)
            newColumns.push(field);
    }
    return newColumns.concat(
        columns.filter(x => !order.includes(x.name))
    );
}

/**
* Возвращает ключ локализации, или пустую строку,
* если для статуса не указана локализация
* @param {number} securityState
*/
export function getSecurityStateKeyLocalization(securityState) {
    switch (securityState) {
        case 0: return 'SecurityState_NotChecked'
        case 1: return 'SecurityState_Declined'
        case 2: return 'SecurityState_Approved'
        case 3: return 'SecurityState_Blocked'
        default: return ''
    }
}
