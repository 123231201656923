import React from "react";
import {Button, Form, Grid, Segment, TextArea} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {formatDate, parseDate} from "../../../const/functions";

export default function NewRequest({ allPlatforms, request, handleChange, isAllAvailable }) {
    
    const handleVisitorNameChange = (e, index, value) => {
        const names = [...request.visitorNames];
        names[index] = value;
        handleChange(e, { name: "visitorNames", value: names });
    };

    const handleAddNewVisitorName = (e) => {
        const names = [...request.visitorNames, ""];
        handleChange(e, { name: "visitorNames", value: names });
    };

    const handleDeleteVisitorName = (e, index) => {
        const names = [...request.visitorNames];
        names.splice(index, 1);
        handleChange(e, { name: "visitorNames", value: names });
    };
    
    return (
        <Form>
            <Grid columns='equal' className="margin-bottom-10">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label>{t("requestEmail") + " *"}</label>
                            <Form.Input disabled name="requesterEmail" value={request.requesterEmail} onChange={handleChange} />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label>{t("requestPhoneNumber")}</label>
                            <Form.Input name="requesterPhoneNumber" value={request.requesterPhoneNumber} onChange={handleChange} />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
    
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label>{t("requestTruckNumber")}</label>
                            <Form.Input name="truckNumber" value={request.truckNumber} onChange={handleChange} />
                        </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Form.Field>
                            <label>{t("requestVisitorCompanyName") + " *"}</label>
                            <Form.Input name="visitorCompanyName" value={request.visitorCompanyName} onChange={handleChange} />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
    
                <Grid.Row >
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("requestFullName") + " *"}</label>
                            <Segment color='yellow'>
                                {request.visitorNames.map((name, index) => {
                                    return (<Form.Group key={"fullName" + index}>
                                        <Form.Input
                                            width={15}
                                            name={"name" + index}
                                            value={name}
                                            onChange={(e, { value }) => handleVisitorNameChange(e, index, value)}
                                        />
    
                                        {index > 0 && 
                                            <Button
                                                width={1}
                                                icon='delete'
                                                onClick={e => handleDeleteVisitorName(e, index)}
                                            />
                                        }
                                    </Form.Group>);
                                })}
    
                                <Button
                                    width={8}
                                    icon='add'
                                    labelPosition='left'
                                    content={t("requestAddVisitor")}
                                    onClick={handleAddNewVisitorName}
                                />
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
    
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("requestPlatforms") + " *"}</label>
    
                            <Segment color='yellow'>
                                <Form.Group style={{height: "20px"}}>
                                    {isAllAvailable && <Form.Checkbox style={{ paddingTop: "10px" }}
                                        label={t("All platforms")}
                                        width={3}
                                        name="allPlatformsSelected"
                                        defaultChecked={request.platforms === null}
                                        onChange={(e, { checked }) => handleChange(e, { name: "platforms", value: checked ? null : [] })}
                                    />}
    
                                    {request.platforms !== null && 
                                        <Form.Dropdown
                                            width={13}
                                            fluid
                                            multiple
                                            search
                                            selection
                                            placeholder={t("requestSelectPlatformsPlaceholder")}
                                            name="platforms"
                                            value={request.platforms}
                                            options={allPlatforms}
                                            onChange={handleChange} />
                                    }
                                </Form.Group>
                            </Segment>
    
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
    
                <Grid.Row >
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("requestPeriodOfValidity")}</label>
                            <Segment color='yellow'>
                                <Form.Group>
                                    <Form.Field width={8}>
                                        <label>{t("requestPeriodStartDate") + " *"}</label>
                                        <DatePicker
                                            locale={localStorage.getItem("currentCulture")}
                                            selected={parseDate(request.startDate || "")}
                                            dateFormat="dd.MM.yyyy"
                                            onChange={(date, e) => { handleChange(e, { name: "startDate", value: date ? formatDate(date) : null }); }}
                                            onChangeRaw={(e) => handleChange(e, { name: "startDate", value: parseDate(e.target.value) ? e.target.value : null })}
                                        />
                                    </Form.Field>
    
                                    <Form.Field width={8}>
                                        <label>{t("requestPeriodFinishDate") + " *"}</label>
                                        <DatePicker
                                            locale={localStorage.getItem("currentCulture")}
                                            selected={parseDate(request.finishDate || "")}
                                            dateFormat="dd.MM.yyyy"
                                            onChange={(date, e) => { handleChange(e, { name: "finishDate", value: date ? formatDate(date) : null }); }}
                                            onChangeRaw={(e) => handleChange(e, { name: "finishDate", value: parseDate(e.target.value) ? e.target.value : null })}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Segment>
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
    
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <label>{t("Visit purpose") + " *"}</label>
                            <TextArea autoHeight name="purpose" value={request.purpose} onChange={handleChange} />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>);
};
