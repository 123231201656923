import React from 'react';
import { Form, Tab } from 'semantic-ui-react';
import ControlledModal from '../../../shared/modals/ControlledModal';
import Activities from './Activities';

const standardSchedule = [
    {
        dayOfWeeks: ['Tuesday', 'Wednesday', 'Friday'],
        timeEnd: '11:22',
        timeStart: '11:11',
    },
    {
        dayOfWeeks: ['Tuesday', 'Friday'],
        timeEnd: '22:22',
        timeStart: '11:11',
    },
];
const stab = [
    {
        activityId: '5dbae1d59c857c4790343600',
        activities: [...standardSchedule],
        maxCountTaskOnTimeSlot: 5,
        isGreenCorridor: false,
    },
    {
        activityId: '5dbae1d59c857c4790343600',
        activities: [...standardSchedule],
        maxCountTaskOnTimeSlot: 5,
        isGreenCorridor: true,
    },
];

export default class CompanyEditModal extends React.Component {
    state = this.initialState;

    get initialState() {
        return {
            modal: false,
            formData: {
                id: '',
                name: '',
                inn: '',
                activities: [],
            },
            errorText: null,
            allActivities: [],
        };
    }

    onOpen = () => {
        this.load();
        callApi('activities/getList', {
            all: true,
            loadPrevious: true,
            page: null,
            search: '',
        }).then((activities) => {
            this.setState({
                allActivities: activities,
            });
        });
    };

    load = () => {
        if (this.props.id)
            callApi('Companies/Get', this.props.id).then((data) => {
                this.setState({
                    formData: data,
                });
            });
    };

    onClose = () => {
        if (this.props.onClose !== undefined) this.props.onClose();
        this.setState(this.initialState);
    };

    save = (e) => {
        this.setState({isLoading: true});
        return callApi('Companies/Save', this.state.formData).then((data) => {
            if (data.error) {
                this.setState({
                    errorText: data.error,
                });
            }
            if (this.props.onSave !== undefined) this.props.onSave(e, { value: data.id });
            return data;
        }).finally(() => {
            this.setState({isLoading: false});
        });
    };

    handleChange = (e, { name, value, checked }) => {
        this.setState((prevState) => {
            return {
                formData: {
                    ...prevState.formData,
                    [name]: checked !== undefined ? checked : value,
                },
            };
        });
    };
    getPanes = () => {
        const { allActivities } = this.state;
        let pannes = [
            {
                menuItem: t('Information'),
                render: () => (
                    <Tab.Pane>
                        <Form>
                            <Form.Field>
                                <Form.Input
                                    label={t('Name')}
                                    name="name"
                                    value={this.state.formData.name}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    label={t('Inn')}
                                    name="inn"
                                    value={this.state.formData.inn}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        </Form>
                    </Tab.Pane>
                ),
            },
            {
                menuItem: t('activities'),
                render: () => (
                    <Tab.Pane>
                        <Activities
                            allActivities={allActivities}
                            activities={this.state.formData.activities}
                            onChange={this.onChangeActivities}
                            isEdit={this.onChangeIsEditActivities}
                        />
                    </Tab.Pane>
                ),
            },
        ];

        return pannes;
    };

    onChangeActivities = (activities = []) => {
        const { formData } = this.state;
        this.setState({
            formData: { ...formData, activities },
        });
    };

    onChangeIsEditActivities = (value) => {
        this.setState({
            isConfirmForCloseModal: value !== null,
        });
    };

    hasCompanyChanges = () => {
        const { isConfirmForCloseModal } = this.state;
        return isConfirmForCloseModal;
    };

    render() {
        return (
            <ControlledModal
                saveButtonDisabled={this.state.isLoading}
                saveButtonLoading={this.state.isLoading}
                buttonName={this.props.children}
                buttonSize={this.props.size}
                size="large"
                title={this.props.title}
                onOpen={this.onOpen}
                onClose={this.onClose}
                isDisabled={this.props.isDisabled}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                isEditActivity={this.hasCompanyChanges()}
                onSave={this.save}
            >
                <Tab panes={this.getPanes()} />
            </ControlledModal>
        );
    }
}
