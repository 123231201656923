export const multiSelectValues = (value, separator = ";") => {
    return (typeof value === 'string' ? value : '').split(separator).filter(v => v);
}

export function isLatinLettersAndNumbers(str) {
    for (let i = 0; i < str.length; i++) {
        let char = str.charCodeAt(i);
        if ((char < 48 || char > 57) && (char < 65 || char > 90) && (char < 97 || char > 122)) {
            return false;
        }
    }
    return true;
}