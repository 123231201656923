import React, {useEffect, useState} from "react";
import {  Container, Tab } from "semantic-ui-react";
import TransportList from "./TransportList"

export default function Transports() {

    let [activeTab, setActiveTab] = useState('truck')

    const handleTabChange = (e, ...props) => {
            const [item] = props;

           setActiveTab(item.panes[item.activeIndex].key)
    }

    return (
        <Container className='container-margin-top-bottom'>
            <h2>{t('Transport')}</h2>
            <Tab panes={[
                {
                    menuItem: t("truck"),
                    key: "truck",
                    render: () => {
                        return <Tab.Pane>
                            <TransportList key={"truck"} parameter={activeTab}/>
                        </Tab.Pane>
                    }
                },
                {
                    menuItem: t("trailer"),
                    key: "trailer",
                    render: () => {
                        return <Tab.Pane>
                            <TransportList key={"trailer"} parameter={activeTab}/>
                        </Tab.Pane>
                    }
                }
            ]}
                 onTabChange={handleTabChange}
            />
        </Container>
    );
}
