import React from "react";
import {Button, Grid, Label, Dropdown, Input, Popup, Icon} from "semantic-ui-react";
import {Form} from "semantic-ui-react/dist/commonjs/collections/Form/Form";
import TransportFormModal from "../../../pages/transports/components/TransportEditModal";

const Edit = ({name, value, isDisabled, onChange, valuesList, noLabel, isClearable }) => {

    const items = (valuesList || [])
        .sort((a, b) => ((b.isActive - a.isActive) - 2 * (a.value === value)))
        .sort((a, b) => ( a.value === value ? -1 : b.value === value ? 1 : 0))
        .map(x =>
            ({
                key: x.value,
                value: x.value,
                text: x.name,
                disabled: !x.isActive,
                description: x.description
            }));
    const TransportDropdown = () => (
        <Dropdown
            ref={inputEl}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled || false}
            search={search}
            selection
            clearable={isClearable}
            options={items.slice(0, 20)}
        />
    );
    const newModalTrailer = (load) =>
        <TransportFormModal
            title={t("adding trailer")}
            actionButton={t("add trailer")}
            id={null}
            parameter={'trailer'}
            onClose={load}>
            <Icon name='plus' /> {t("add trailer")}
        </TransportFormModal>;

    const newModalTractor = (load) =>
        <TransportFormModal
            title={t("adding tractor")}
            actionButton={t("add tractor")}
            id={null}
            parameter={'tractor'}
            onClose={load}>
            <Icon name='plus' /> {t("add tractor")}
        </TransportFormModal>;

    return (
        <Form.Field>
            {name && <label>{t(name)}</label>}
            <div className="dropbox-button">
                <TransportDropdown/>
                newModalTrailer()

            </div>
        </Form.Field>
    );
};
export default Edit;
