import React from "react";
import { Checkbox, Form, Button } from "semantic-ui-react";

const EditOther = ({ value, name, onChange, isDisabled, noLabel, className }) => {
    const onClickBtn = (e, {name, value}) => {
        let valueNew;
        if (typeof value === 'string') {
            valueNew = value === 'false' ? true : false
        } else valueNew = !value
        onChange(e, {name, value: valueNew})
    }

    return (
        <Form.Field>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <Button disabled={isDisabled} size="small" style={{width: "100%", height: "36px"}} basic color={value ? 'blue': null} onClick={(e) => onClickBtn(e, {name, value})}>{value ? t("Yes") : t("No")}</Button>
        </Form.Field>
    );
};
export default EditOther;
