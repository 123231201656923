import React from "react";
import { Modal, Form, Button, Label, Icon, Message } from "semantic-ui-react";

class PasswordField extends React.Component{
    state = {
        type: 'text',
    }


    handleClick = () => this.setState(({type}) => ({
        type: type === 'text' ? 'password' : 'text'
    }))


    render() {
        console.log(this.state);
        const { label } = this.props

        return (
            <label className="password">{label}
                <input type={this.state.type} className="password__input"/>
                <span className="password__show" onClick={this.handleClick}>{this.state.type === 'text' ? 'Hide' : 'Show'}</span>
            </label>
        )
    }
}


export default class ForgotPasswordModal extends React.Component {

    initialState = {
        email: "",
        isSended: false,
        error: null
    }
    constructor(props) {
        super(props);

        this.state = this.initialState;
        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        this.setState(this.initialState);
        this.props.toggleModal();
    }

    handleChangeEmail = (e) => {
        this.setState({ email: e.target.value });
    }
    
    send = (e)=>{
        callApi('ForgotPassword/CreateRequest', this.state.email).then((resp)=>{
            if(resp){
                this.setState({
                    isSended: !resp.isError,
                    error: resp.isError ? resp.error || t('An error has occurred') : null
                });
            }
        });
    }

    validateEmail = (email) => {
        if(this.state.email === '' || this.state.email === null)
            return false;
        
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        return (
            <Modal dimmer="blurring" size={"small"} open={this.props.open} onMount={() => this.setState({ email: "" })} onClose={this.onClose} >
                <Modal.Header>{t("pass_recover")}</Modal.Header>
                <Modal.Content>
                    {this.state.isSended ?
                        <Message positive>
                            {t("email_is_sent")}
                        </Message>
                        : <Form>
                            <Form.Field>
                                <Message>
                                    {t("forgot_pass_msg")}
                                </Message>
                            </Form.Field>
                            <Form.Field>
                                <Form.Input label='Email'  iconPosition='left'>
                                    <Icon name='mail' />
                                    <input value={this.state.email} onChange={this.handleChangeEmail} placeholder={'email@example.com'} />
                                </Form.Input>
                            </Form.Field>
                            {this.state.error && <Form.Field>
                                <Message negative>
                                    {t(this.state.error)}
                                </Message>
                            </Form.Field>}
                        </Form>}
                </Modal.Content>
                <Modal.Actions>
                    {this.state.isSended ? 
                        <Button onClick={this.onClose}>{t('close')}</Button> : 
                        <Button disabled={!this.validateEmail(this.state.email)} onClick={this.send}>{t('SendButton')}</Button>
                    }
                </Modal.Actions>
            </Modal>
        );
    }
}
