import React from "react";
import { Modal, Button, Grid, Label, Select, Tab, Input, Icon, Form } from "semantic-ui-react";
import * as StateHelper from "../../../../../utils/stateHelper";
import GridColumnDispatcher from "../../../../columns/GridColumnDispatcher";

export default class Information extends React.Component {

    render() {
        let visit = this.props.visit;
        let state = visit.state;
        let columns = this.props.columns || [];
        columns = columns.filter(field => { return (field.fieldMatrixAccessTypes.find(x => x.state === state) || {}).accessType !== "Hidden"; });

        const stateColors = StateHelper.visitStateColors();
        return (
            <div className="ui form visit-edit-grid">
                {
                    columns.map(field => {
                        let a = field.fieldMatrixAccessTypes.find(x => x.state === state) || {};
                        let isDisabled = a.accessType === "Show";
                        return (
                            <GridColumnDispatcher
                                key={field.name}
                                column={field}
                                className={isDisabled ? "disabled" : ""}
                                value={field.isCustom
                                    ? (visit.customFields.filter(y => y.name === field.name)[0] || {}).value
                                    : visit[field.name.charAt(0).toLowerCase() + field.name.slice(1)]
                                }
                                visit={visit}
                                isEdit
                                onChange={field.isCustom ? this.props.onCustomFieldChange : this.props.onFieldChange}
                                valuesList={field.valuesForSelectType}
                                stateColors={stateColors}
                                isDisabled={isDisabled}
                                reloadFields={this.props.reloadFields}
                            />
                        );
                    })}
            </div>
        );
    }
};
