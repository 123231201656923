import React from "react";
import CustomFieldsModal from "./components/GlobalSettingsEditModal";
import { Button, Grid, Popup, Icon, Modal, Label, Select, Input, Tab, Dropdown, Container, Table } from "semantic-ui-react";
import CustomFieldsList from "../../shared/customFieldsEditor/CustomFieldsList";
import BaseDictionary from "../../shared/BaseDictionary";

export default function GlobalSettings() {
    return (
        <BaseDictionary
            apiGetList="GlobalSettings/GetList"
            name={t("globalSettings")}
            className='container-margin-top-bottom wider'
            noFilter>
            {(list, load) => {
                const gs = list[0] || {};
                return <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {t('Name')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Custom visit fields')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Custom task fields')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Custom order fields')}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t('Custom ordersModule fields')}
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                {t('Global custom fields')}
                            </Table.Cell>
                            <Table.Cell>
                                <CustomFieldsList fields={gs.customFieldsForVisit || []} />
                            </Table.Cell>
                            <Table.Cell>
                                <CustomFieldsList fields={gs.customFieldsForTask || []} />
                            </Table.Cell>
                            <Table.Cell>
                                <CustomFieldsList fields={gs.customFieldsForOrder || []} />
                            </Table.Cell>
                            <Table.Cell>
                                <CustomFieldsList fields={gs.customFieldsForOrdersModule || []} />
                            </Table.Cell>
                            <Table.Cell textAlign='center'>
                                <CustomFieldsModal
                                    size="small"
                                    title={t('Change global fields')}
                                    actionButton={t('SaveButton')}
                                    getApiUrl='GlobalSettings/Get'
                                    saveApiUrl='GlobalSettings/Save'
                                    onClose={load}>
                                    <Icon name='edit' />{` ${t('Edit')}`}
                                </CustomFieldsModal>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>;
            }
            }
        </BaseDictionary>
    );
}
