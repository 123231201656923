import React, {useContext, useState} from "react";
import { Button, Table, Checkbox, Icon, Label, Container, Grid } from "semantic-ui-react";
import DriverFormModal from "./components/DriverEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";
import { UserPermissionsContext } from "../../Contexts";
import { getSecurityStateKeyLocalization } from "../../utils/stateHelper";

export default function Drivers() {
    const {permissionsData} = useContext(UserPermissionsContext);
    let [driverForEditId, setDriverForEditId]  = useState(null);
    const newModal = (load) =>
        permissionsData.permissions.includes("CreateDrivers") ? <DriverFormModal
            id={driverForEditId}
            onSave={(e, dataId) =>{setDriverForEditId(dataId.value)}}
            onClose={()=>{
                setDriverForEditId(null);
                load();
            }}>
            <Icon name='plus' />{` ${t('Add driver')}`}
        </DriverFormModal> : null;

    const headerRow =
        <Table.Row>
            <Table.HeaderCell>{t('Full name')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('Phone')}</Table.HeaderCell>
            <Table.HeaderCell>{t('passportSeriesAndNumber')}</Table.HeaderCell>
            <Table.HeaderCell>{t('passportDate')}</Table.HeaderCell>
            <Table.HeaderCell>{t('passportDepartment')}</Table.HeaderCell>
            <Table.HeaderCell>{t('driverLicenseSeries')}</Table.HeaderCell>
            <Table.HeaderCell>{t('driverLicenseValidDate')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('companies')}</Table.HeaderCell>
            <Table.HeaderCell width={4}>{t('Activities blocked')}</Table.HeaderCell>
            <Table.HeaderCell>{t('relevanceOfConsentToPDProcessing')}</Table.HeaderCell>
            <Table.HeaderCell>{t('Documents')}</Table.HeaderCell>
            <Table.HeaderCell>{t('SB comment')}</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>{t('Activity')}</Table.HeaderCell>
            <Table.HeaderCell width={3}/>
        </Table.Row>;

    return (
        <LazyLoadDictionary
            apiGetList="Drivers/GetList"
            apiChangeActivity="Drivers/ChangeActivity"
            name={t("drivers")}
            className='container-margin-top-bottom width-auto padding-l5-r5'
            isVerticalScroll
            newModal={newModal}
            headerRow={headerRow}>
            {(drivers, load, toggleIsActive) =>
                drivers.map(driver =>
                    <Table.Row key={driver.id}>
                        <Table.Cell>{`${driver.lastName} ${driver.firstName} ${driver.middleName}`}</Table.Cell>
                        <Table.Cell>{driver.phone}</Table.Cell>
                        <Table.Cell>{driver.passportSeriesAndNumber}</Table.Cell>
                        <Table.Cell>{driver.passportDate}</Table.Cell>
                        <Table.Cell>{driver.passportDepartment}</Table.Cell>
                        <Table.Cell>{driver.driverLicenseSeries}</Table.Cell>
                        <Table.Cell>{driver.driverLicenseValidDate}</Table.Cell>
                        <Table.Cell>{driver.companies && driver.companies.length > 5 ?  <>{driver.companies.splice(0, 5).map((permission, i) => <Label
                            key={i}>{permission.name}</Label>)} ... + {driver.companies.length}</> : driver.companies && driver.companies.map((permission, i) => <Label
                            key={i}>{permission.name}</Label>)}</Table.Cell>
                        <Table.Cell>{driver.blockedActivities.map((activitiy, i) =><Label key={i}>{activitiy.name}</Label>)}</Table.Cell>
                        <Table.Cell>{driver.acceptPersonalDataProcessingDate}</Table.Cell>
                        <Table.Cell>
                            {
                                (driver.documents && driver.documents.length > 0) ?
                                    <Icon name="paperclip" />
                                    : null
                            }
                        </Table.Cell>
                        <Table.Cell>{driver.securityComment}</Table.Cell>
                        <Table.Cell textAlign='center'>
                            <Checkbox
                                disabled={!permissionsData.permissions.includes("DeactivateDrivers")}
                                toggle
                                itemID={driver.id}
                                checked={driver.isActive}
                                onChange={toggleIsActive}
                            />
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            <DriverFormModal
                                isDisabled={!(permissionsData.permissions.includes("EditDrivers") || permissionsData.permissions.includes("ExtendEditDrivers"))}
                                size="small"
                                id={driver.id}
                                onClose={load}>
                                <Icon name='edit' />{` ${t('Edit')}`}
                            </DriverFormModal>
                        </Table.Cell>
                    </Table.Row>
                )
            }
        </LazyLoadDictionary>
    );
}
