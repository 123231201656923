import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {Grid, Icon, Label, Table, Container, Checkbox} from "semantic-ui-react";
import {debounce} from "throttle-debounce";
import Search from "./Search";
import LazyLoadDictionary from "./LazyLoadDictionary";

export default function BaseDictionary(
    {apiGetList, apiChangeActivity, newModal, children, name, className, noFilter, isAll}) {

    let [list, setList] = useState([]);
    let [filter, setFilter] = useState("");
    let [all, setAll] = useState(false);

    const load = () => {
        callApi(apiGetList, isAll ? {all} : null)
            .then((list) => {
                if (list.error) {
                    toast.error(t("Lost connection with server"));
                    throw (t("Lost connection with server"));
                }
                setList(list)});
    };

    useEffect(load, [all]);

    const onSearchChange = (e, {value}) => {
        setFilter(value);
    };

    const onAllChange = (e, {checked}) => {
        setAll(checked);
    };

    const debounceSearchChange = onSearchChange;

    const toggleIsActive = (e, {itemID}) => {
        setList(list.map(el => (el.id === itemID ? Object.assign(el, {isActive: !el.isActive}) : el)));
        callApi(apiChangeActivity, itemID)
            .then(result => {
                if (result.error) {
                    toast.error(result.error);
                    console.log(result.error);
                }
                load();
            });
    };

    if (filter) {
        filter = filter.toLowerCase();
        list = list.filter(x => Object.values(x).some(y => y && y.toString().toLowerCase().includes(filter)));
    }

    return (
        <Container className='container-margin-top-bottom'>
            <h2>{name}</h2>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={7}>
                        {!noFilter ? <Search isAuto value={filter} onKeyPressEnter={load} onChange={debounceSearchChange}/> : null}
                        {isAll
                            ? <div className="grid-check-all">
                                <Checkbox value={all} label={t('show all')} onChange={onAllChange}/>
                            </div>
                            : null}
                    </Grid.Column>
                    <Grid.Column width={9} textAlign='right'>
                        {newModal != undefined ? newModal(load) : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {children !== undefined ? children(list, load, toggleIsActive) : null}
        </Container>
    );
}
