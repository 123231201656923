import React from "react";
import {Modal, Button, Confirm, Tab, Message, Label} from "semantic-ui-react";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import {UserPermissionsContext} from "../../Contexts";

export default class FieldsConfigModal extends React.Component {
    static contextType = UserPermissionsContext;
    state = this.initialState;

    get initialState() {
        return {
            modalOpen: false,
            visitColumns: [],
            taskColumns: [],
            ordersModuleColumns: [],
            orderColumns: [],
            fieldsConfig: {},
            currentPlatformId: null
        };
    }

    onOpen = () => {
        const currentPlatformId = this.context.permissionsData.currentPlatformId;
        this.setState({
            modalOpen: true,
            fieldsConfig: (JSON.parse(this.context.permissionsData.fieldsConfig || "{}") || {})[currentPlatformId] || {},
            currentPlatformId
        });
        this.loadVisitAndTaskFields(currentPlatformId);
    }

    onClose = () => {
        this.setState(this.initialState);
    }

    loadVisitAndTaskFields = (currentPlatformId) => {
        const permissions = this.context.permissionsData.permissions;
        if (permissions.includes("ViewVisits"))
            callApi("visits/GetColumns", currentPlatformId)
                .then(visitColumnsData => {
                    this.setState({
                        visitColumns: visitColumnsData.columns
                    });
                });
        if (permissions.includes("ViewTasks"))
            callApi("tasks/GetColumns", currentPlatformId)
                .then(taskColumnsData => {
                    this.setState({
                        taskColumns: taskColumnsData.columns
                    });
                });
        if (permissions.includes("ViewTasks"))
            callApi("orders/GetColumns", currentPlatformId)
                .then(orderColumnsData => {
                    this.setState({
                        orderColumns: orderColumnsData.columns
                    });
                });
        if (permissions.includes("ViewOrderModule") || permissions.includes("36"))
            callApi("ordersModule/GetColumns", currentPlatformId)
                .then(ordersModuleColumnsData => {
                    this.setState({
                        ordersModuleColumns: ordersModuleColumnsData.columns,
                    });
                });
    }

    save = (hidden, order, type) => {
        let fieldsConfig = this.state.fieldsConfig;
        fieldsConfig[type] = {
            hidden,
            order
        };
        let user = this.context.permissionsData;
        let stringFields = JSON.parse(user.fieldsConfig || "{}") || {};
        stringFields[this.state.currentPlatformId] = fieldsConfig;
        let fieldsConfigString = JSON.stringify(stringFields);
        callApi("UserSettings/SaveFieldsConfig", fieldsConfigString).then(resp => {
            if (!resp.error) {
                user.fieldsConfig = fieldsConfigString;
                this.context.loadPermissionsData();
            }
        });
    }

    render() {

        const permissions = this.context.permissionsData.permissions;
        let panes = [];
        if (permissions.includes("ViewVisits"))
            panes.push({
                key: "fieldsTab1",
                menuItem: t("Visits"),
                render: () => <Tab.Pane key="tab1">
                    <DragAndDropFields
                        type="visit"
                        fieldsConfig={this.state.fieldsConfig.visit || {}}
                        fieldsList={this.state.visitColumns || []}
                        onChange={this.save}
                    />
                </Tab.Pane>
            });
        if (permissions.includes("ViewTasks"))
            panes.push({
                key: "fieldsTab2",
                menuItem: t("Tasks"),
                render: () => <Tab.Pane key="tab2">
                    <DragAndDropFields
                        type="task"
                        fieldsConfig={this.state.fieldsConfig.task || {}}
                        fieldsList={this.state.taskColumns || []}
                        onChange={this.save}
                    />
                </Tab.Pane>
            });
        if (permissions.includes("ViewTasks"))
            panes.push({
                key: "fieldsTab3",
                menuItem: t("Orders"),
                render: () => <Tab.Pane key="tab3">
                    <DragAndDropFields
                        type="order"
                        fieldsConfig={this.state.fieldsConfig.order || {}}
                        fieldsList={this.state.orderColumns || []}
                        onChange={this.save}
                    />
                </Tab.Pane>
            });
        if (permissions.includes("ViewOrderModule") || permissions.includes("36"))
            panes.push({
                key: "fieldsTab4",
                menuItem: t("OrdersModule"),
                render: () => <Tab.Pane key="tab4">
                    <DragAndDropFields
                        type="ordersModule"
                        fieldsConfig={this.state.fieldsConfig.ordersModule || {}}
                        fieldsList={this.state.ordersModuleColumns || []}
                        onChange={this.save}
                    />
                </Tab.Pane>
            });

        return (<Modal dimmer="blurring" id="fieldModal"
                       className="visit-modal"
                       trigger={this.props.children}
                       open={this.state.modalOpen}
                       onOpen={this.onOpen}
                       onClose={this.onClose}
                       closeOnEscape
                       closeOnDimmerClick={false}
                       closeIcon>
            <Modal.Header>{t("Fields configuration")}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Message
                        size='small'>{t("Your current platform")}: {this.context.permissionsData.currentPlatformName}</Message>
                    <Message size='small'>{t("FieldsConfigMessage")}</Message>
                    <Tab panes={panes}/>
                </Modal.Description>
            </Modal.Content>
        </Modal>);
    }
};

const DragAndDropFields = ({type, fieldsConfig, fieldsList, onChange}) => {
    let showed = (fieldsConfig.order || [])
        .filter(x => fieldsList.some(y => y.name === x)).concat(
            fieldsList.filter(x =>
                !(fieldsConfig.hidden || []).includes(x.name)
                && !(fieldsConfig.order || []).includes(x.name)).map(x => x.name)
        );
    return (
        <div className="flex-container-justify">
            {fieldsList && fieldsList.length > 0 ?
                <DnDList
                    key={"dnd" + type}
                    type={type}
                    left={fieldsConfig.hidden || []}
                    right={showed || []}
                    onChange={onChange}/>
                : null}
        </div>
    );
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
    const modalEl = document.getElementById("fieldModal");
    if (modalEl && /Chrome/.test(navigator.userAgent)) {
        let rect = modalEl.getBoundingClientRect();
        draggableStyle.top = draggableStyle.top - rect.top || 0;
        draggableStyle.left = draggableStyle.left - rect.left || 0;
    }
    return {
        userSelect: "none",
        padding: 5,
        ...draggableStyle
    };
};

class DnDList extends React.Component {
    state = {
        items: this.props.left.map(x => ({id: x, content: x})),
        selected: this.props.right.map(x => ({id: x, content: x}))
    };
    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: "items",
        droppable2: "selected"
    };

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const {source, destination} = result;
        let state = {
            items: this.state.items,
            selected: this.state.selected
        };
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );
            if (source.droppableId === "droppable2") {
                state.selected = items;
            } else {
                state.items = items;
            }
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );
            state = {
                items: result.droppable,
                selected: result.droppable2
            };
        }

        this.setState(state);
        this.props.onChange(
            state.items.map(x => x.id),
            state.selected.map(x => x.id),
            this.props.type
        );
    };

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <DroppableLabel items={this.state.items} droppableId="droppable" name={t("Hidden")}/>
                <DroppableLabel items={this.state.selected} droppableId="droppable2" name={t("Shown")}/>
            </DragDropContext>
        );
    }
}

const DroppableLabel = ({items, droppableId, name}) => (
    <div style={{width: "49%"}}>
        <p style={{textAlign: "center"}}>{name}</p>
        <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={{padding: 5, width: "100%", border: "1px solid #eee", height: 450, overflowY: "auto"}}>
                    {items.map((item, index) => (
                        <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}>
                                    <Label style={{width: "100%"}}>{t(item.content)}</Label>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </div>
);

