import React from "react";
import {Table, Checkbox} from "semantic-ui-react";
import GridColumnDispatcher from "../../columns/GridColumnDispatcher";

export default class Filter extends React.PureComponent {
    render() {
        const { columns, filters, sort, setFilter, setSort, stateColors, states, selectedRows, count, setAll, withAllCheckbox } = this.props;
        return (
            <Table.Row className="sticky-header">
                <Table.HeaderCell/>
                <Table.HeaderCell className="small-column">
                    {withAllCheckbox && <Checkbox
                        onChange={setAll}
                        checked={count && count === selectedRows.length}
                    />}
                </Table.HeaderCell>
                {columns.map((x, i) => {
                    return (
                        <Table.HeaderCell key={"th" + x.name + i} className={`column-facet column-${x.name.toLowerCase().replace(" ", "-")}-facet`}>
                            <GridColumnDispatcher
                                key={"facet" + x.name}
                                column={x}
                                isFacet
                                onChange={setFilter}
                                filters={filters}
                                sort={sort}
                                stateColors={stateColors}
                                setSort={setSort}
                                valuesList={x.valuesForSelectType}
                                states={states}
                            />
                        </Table.HeaderCell>
                    );
                })}
                <Table.HeaderCell className="actions-column" />
            </Table.Row>

        );
    }
}
