import React from "react";
import DocWithEditor from "./components/DocWithEditor";

const DocumentListEdit = ({ documents, onDocumentsChange, typesApiUrl, type, onSaveForm, notDelete }) => {

    const onDocumentEdit = (document, docIndex) => {
        if (docIndex !== null && docIndex < documents.length)
            documents[docIndex] = document;
        else
            documents.push(document);
        onDocumentsChange(documents, type);
    };

    const onDocumentDelete = (docIndex) => {
        callApi(`files/deleteFile?fileId=${documents[docIndex].fileId}`).then(
            () => {
                documents.splice(docIndex, 1);
                onDocumentsChange(documents, type);
                onSaveForm && onSaveForm();
            }
        );
    };

    return (
        <div className="flex-container">
            {documents.map((document, index) => (
                <DocWithEditor
                    key={document.fileId}
                    docIndex={index}
                    document={document}
                    onDocumentEdit={onDocumentEdit}
                    onDocumentDelete={onDocumentDelete}
                    titleText={t("Edit document")}
                    okButtonText={t("SaveButton")}
                    typesApiUrl={typesApiUrl}
                    type={type}
                    notDelete={notDelete}
                />
            ))}
            <DocWithEditor
                docIndex={documents.length}
                onDocumentEdit={onDocumentEdit}
                titleText={t("Add document")}
                okButtonText={t("AddButton")}
                typesApiUrl={typesApiUrl}
                type={type}
                notDelete={notDelete}
            />
        </div>
    );
};
export default DocumentListEdit;