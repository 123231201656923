import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Progress, Table, Icon } from "semantic-ui-react";

const Value = ({ value, stateColors, text }) => {
    const state = stateColors.find(x => x.value === value),
        color = state ? state.color : "grey",
        inverted = state ? state.inverted : false;
    return (
                <div>
            <Icon color={color} inverted={inverted} name="circle" />
            {text ? text : t(`${value}`)}
                </div>
            );
};
export default Value;
