import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {formatDate} from "../../../const/functions";

const Edit = ({ value, name, onChange, isDisabled, noLabel, required, placeholder, className }) => {

    const getClassNames = () => {
        const classNames = [];

        if (required && !value) {
            classNames.push("input-error");
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(" ");
    };

    return (
        <Form.Field>
            {!noLabel ? <label className={ isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <DatePicker
                disabled={isDisabled || false}
                placeholderText={placeholder}
                className={getClassNames()}
                isClearable={!(isDisabled || false)}
                selected={parseDate((value || "").substring(0, 10))}
                locale={localStorage.getItem("currentCulture")}
                dateFormat="dd.MM.yyyy"
                onChange={(date, e) => {
                    onChange(e, { name: name, value: date ? formatDate(date) : null });

                }}
            />
        </Form.Field>
    );
};
export default Edit;
