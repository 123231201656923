import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Progress, Table, Icon } from "semantic-ui-react";

const Value = ({ value, stateColors, text }) => {
    const state = stateColors.find(x => x.value === value),
        color = state ? state.color : "grey";
    return (
        <div className="status">
            <Label className="status-label-middle" color={color} horizontal>{text ? text : t(`${value}`)}</Label>
        </div>
    );
};
export default Value;
