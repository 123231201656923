import React, { useState } from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Modal, Form, Loader } from "semantic-ui-react";

export default function Value({ value, valuesList }) {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const handleOpen = () => {
        if (value) {
            if (!data) {
                callApi("visits/GetTransportById", value)
                    .then(transport => {
                        setData(transport);
                        setOpen(true);
                    });
            }
            else setOpen(true);
        }
    };

    const handleClose = () => setOpen(false);

    const transports = valuesList || [];

    const form = data
        ? (
            <Form size="small" onClick={e => { e.stopPropagation(); }} >
                <p>
                    {t("Description")}: <b>{data.description}</b>
                </p>
                {data.typeNames &&
                <div>
                    {t("Type")}: {data.typeNames.map(x => <Label key={x} size="small">{x}</Label>)}}
                </div>
                }
            </Form>
        )
        : null;

    return (
        <div>
            <Popup wide='very'
                hoverable
                hideOnScroll
                trigger={<div>{(transports.find(x => x.value === value) || {}).name || value}</div>}
                content={form}
                on='hover'
                open={open}
                onClose={handleClose}
                position='right center'
            />
        </div>
       /* <div>
            <Popup wide='very'
                hoverable
                hideOnScroll
                trigger={<div>{(transports.find(x => x.value === value) || {}).name || value}</div>}
                content={form}
                on='hover'
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                position='right center'
            />
        </div>*/
    );
}
