import {Button, Dropdown, Input, Table, Icon, Checkbox, Confirm, Grid} from "semantic-ui-react";
import React from "react";
import ScheduleModal from './ScheduleModal'
import {UserPermissionsContext} from "../../../Contexts";

export default class GateEditor extends React.Component {
    static contextType = UserPermissionsContext;

    state = {
        numberForAdd: "",
        editGateNumber: null,
        editActivities: [],
        confirmation: {
            open: false,
            content: '',
            onConfirm: () => {
            }
        }
    };

    editGate = (gateNumber) => {
        let gate = this.props.gates.filter((gate) => {
            return gate.number === gateNumber;
        })[0];
        this.setState({
            editGateNumber: gateNumber,
            editActivities: gate.activities.map(x => x.id)
        });
    };

    addGate = () => {
        this.props.changeFormData((fd) => {
            fd.gates.push({number: this.state.numberForAdd, activities: []});
            this.setState({numberForAdd: ""});
        });
    };

    saveGate = (editGateActivitiesValues) => {
        this.props.changeFormData((fd) => {
            let gate = fd.gates.filter((gate) => {
                return gate.number === this.state.editGateNumber;
            })[0];
            gate.activities = this.state.editActivities.map(x => {
                return {id: x, text: this.props.allActivities.filter(z => z.id === x)[0].name};
            });
            this.setState({editGateNumber: null});
        });
    };

    saveScheduleGate = (value) => {
        this.setState({editGateNumber: null});
        return this.props.changeFormData(value);
    };

    onCloseScheduleGate = () => {
        const {onCloseScheduleEdit} = this.props;
        onCloseScheduleEdit && onCloseScheduleEdit()
    };

    cancel = () => {
        this.setState({editGateNumber: null});
    };

    handleChangeActivities = (e, obj) => {
        this.setState({editActivities: obj.value});
    };

    handleInputChange = (e) => {
        this.setState({numberForAdd: e.target.value});
    };
    handleChangeToggleIsActive = (index, isHasTasks) => {
        const {gates, changeFormData} = this.props;
        const gateNew = {...gates[index], isActive: !gates[index].isActive};
        if (isHasTasks && gates[index].isActive) {
            this.setState({
                confirmation: {
                    open: true,
                    content: t('This gate is involved in tasks. Do you really want to deactivate them?'),
                    onConfirm: () => {
                        this.setState({
                            confirmation: {
                                open: false
                            }
                        });
                        changeFormData && changeFormData({gate: gateNew, index});
                    }
                }
            })
        } else {
            changeFormData && changeFormData({gate: gateNew, index});
        }
    };

    render() {
        let {gates = [], changeFormData} = this.props;
        const {confirmation} = this.state;

        const editPlatformPermission = this.context.permissionsData.permissions.includes("EditPlatforms");

        return <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('activities')}</Table.HeaderCell>
                    {editPlatformPermission && <Table.HeaderCell textAlign="center"></Table.HeaderCell>}
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {gates.map((gate, index) => {
                    return <Table.Row key={index}>
                        <Table.Cell>{gate.number}</Table.Cell>
                        <Table.Cell>{gate.schedules.map(x => this.props.allActivities.find(y => y.id === x.activityId) ? this.props.allActivities.find(y => y.id === x.activityId).name : '').join(", ")}</Table.Cell>
                        {editPlatformPermission && <Table.Cell textAlign="center">
                            <Checkbox toggle itemID={gate} checked={gate.isActive} disabled={!gate.availableForEdit}
                                      onChange={() => this.handleChangeToggleIsActive(index, gate.isHasTasks)}/>
                        </Table.Cell>}
                        <Table.Cell width={4} textAlign='center'>
                            <ScheduleModal
                                size="small"
                                title={`${t('Gate Editing')} "${gate.number}"`}
                                actionButton={t('SaveButton')}
                                id={gate.number}
                                isActive={gate.isActive}
                                isHasTasks={gate.isHasTasks}
                                index={index}
                                error={this.props.errorText}
                                allActivities={this.props.allActivities}
                                onlyCurrentUserActivities={this.props.onlyCurrentUserActivities}
                                allNameGates={gates.map(item => (item.number)).filter(x => x !== gate.number)}
                                formData={gate.schedules || []}
                                specificationsList={gate.customFields || []}
                                onSave={this.saveScheduleGate}
                                onClose={this.onCloseScheduleGate}
                                getOldFormData={this.props.getOldFormData}
                                platformId={this.props.platformId}
                                isDisabled={!gate.availableForEdit}
                                availableForEdit={gate.availableForEdit}
                                editPlatformPermission={editPlatformPermission}
                                /*onClose={load}*/
                            >
                                <Icon name='edit'/>{` ${t('Edit')}`}
                            </ScheduleModal>
                        </Table.Cell>
                    </Table.Row>;
                })}
            </Table.Body>
            <Confirm dimmer="blurring"
                     size="small"
                     open={confirmation.open}
                     onCancel={() => this.setState({confirmation: {open: false}})}
                     onConfirm={confirmation.onConfirm}
                     header={t('the confirmation')}
                     content={confirmation.content}
                     cancelButton={t('No')}
                     confirmButton={t('Yes')}
            />
        </Table>;
    }
}
