import React, {useState} from "react";
import * as StateHelper from "../../utils/stateHelper";
import SuperGrid from "../../shared/grid/SuperGrid";
import {Button, Modal} from "semantic-ui-react";

export default function OrdersModuleModal({children, open = false, task, setNewOrders}, props) {

    const [modalOpen, setModalOpen] = useState(open);
    const [checkedRows, setCheckedRows] = useState([]);

    const activityIdFromTask = () => task['activity'] || '';

    const save = () => {
        setNewOrders(checkedRows, task.id);
        setModalOpen(false);
    };

    return (<Modal
        dimmer="blurring"
        //className="visit-modal"
        trigger={children}
        open={modalOpen}
        onOpen={() => setModalOpen(true)}
        onClose={() => setModalOpen(false)}
        closeOnEscape
        closeOnDimmerClick={false}
        closeIcon
        size="fullscreen"
    >
        <Modal.Header>
            {t("ordersModule")}
        </Modal.Header>

        <SuperGrid
            gridName="ordersModule"
            storageSortItem="OrderSort"
            storageFilterItem="OrderFilters"
            apiGetColumns="ordersModule/getColumns"
            apiGetList="ordersModule/getList"
            apiAction="ordersModule/action"
            userColumnsName="ordersModule"
            createButtonEnabled
            createButtonPermission="CreateOrders"
            createButtonName={t("create_order_module_btn")}
            createButtonModalTitle={t("create_order_modal_label")}
            editModalTitle={t("edit_order_modal_label")}
            stateColors={StateHelper.ordersModuleStatesColors()}
            checkedToTop
            isModal
            activityId={activityIdFromTask()}
            onCheck={(rs)=>setCheckedRows(rs || [])}
            autoLoadOff
            notDisplayedIds={(task && task.orders) ? task.orders.map(o => o.orderId) : []}
            states={['New']}
            withAllCheckbox
        />

        <Modal.Actions>
            <Button color='red' onClick={() => setModalOpen(false)}>
                {t('CancelButton')}
            </Button>
            <Button color='green' onClick={save}>
                {t('AddButton')}
            </Button>
        </Modal.Actions>

    </Modal>);
}