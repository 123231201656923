import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Form } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {formatDateTime} from "../../../const/functions";
import {parseDateTime} from "../../../utils/dateFormat";

const Edit = ({ value, name, onChange, isDisabled, noLabel, popperPlacement, className }) => {
    return (
        <Form.Field className={noLabel ? "no-label-datepicker" : undefined}>
            {!noLabel ? <label className={ isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <DatePicker
                locale={localStorage.getItem("currentCulture")}
                disabled={isDisabled || false}
                isClearable={!(isDisabled || false)}
                selected={parseDateTime(value || "")}
                dateFormat="dd.MM.yyyy HH:mm:ss"
                className={className}
                showTimeSelect
                timeFormat="HH:mm:ss"
                timeIntervals={15}
                timeCaption={t("Time")}
                onChange={(date, e) => {
                    onChange(e, {
                        name: name,
                        value: date
                            ? formatDateTime(date)
                            : null
                    });
                }}
                popperPlacement={popperPlacement}
                onChangeRaw={(e) => onChange(e, { name, value: e.target.value })}
            />
        </Form.Field>
    );
};
export default Edit;
