import React from "react";
import {Modal, Button, Grid, Label, Select, Tab, Input, Icon, Form, Dimmer, Loader} from "semantic-ui-react";
import GridColumnDispatcher from "../../../../columns/GridColumnDispatcher";
import * as StateHelper from "../../../../../utils/stateHelper";
import Orders from "./Orders";
import DocumentListEdit from "../../../../documents/DocumentListEdit";
import { UserPermissionsContext } from "../../../../../Contexts";
import History from "../../../../History";

export default class Task extends React.Component {
    static contextType = UserPermissionsContext;
    state = {
        isShow: false,
        isLoading: false,
        isLoadingTimeSlot: false
    }
    componentWillMount() {
        this.loadColumns((this.props.task || {}).activity);
    }

    shouldComponentUpdate = (nextProps) => {
        if (nextProps.task.activity !== this.props.task.activity) {
            this.loadColumns(nextProps.task.activity);
            return false;
        }
        else
            return true;
    };

    loadColumns = (activity) => {
        const {driverId, visitDate,getIsLoading} = this.props;
        const currentPlatformId = this.context.permissionsData.currentPlatformId;
        this.setState({isLoading: true})
        getIsLoading && getIsLoading(true)
        return callApi("CreateUpdateVisit/GetTaskColumnsForEdit", { platformId: currentPlatformId, activityId: activity, driverId: driverId, visitDate })
            .then(taskColumnsData => {
                getIsLoading && getIsLoading(false)
                this.setState({
                    columns: StateHelper.sortColumns(
                        taskColumnsData.columns,
                        currentPlatformId,
                        "task",
                        this.context.permissionsData.fieldsConfig),
                    orderColumns: taskColumnsData.orderColumns,
                    isLoading: false
                });
            });
    };

    renderLoader = () => {
        return (
            <div className="fluid-for-loader">
                <Dimmer active inverted>
                    <Loader size='large' inverted content={`${t('Loading')}...`} />
                </Dimmer>
            </div>
        );
    }

    onFieldChange = taskId => (e, { name, value }) => {
        if (name === "Activity") {
            this.props.onFieldChange(taskId)(e, { name: "gate", value: "" });
            this.loadColumns(value);
        }
       /* if (name === "AvizationTimeInterval") {
            console.log('sdfsdf')
            this.props.onFieldChange(taskId)(e, { name: "visitDate", value: value && value.date });
        }*/

        this.props.onFieldChange(taskId)(e, { name, value });
    };

    loadTimeSlot = (e, {name, value}) => {
        if (!this.props.isVisitChange) {
            const {columns} = this.state;
            const visitDate = value;
            const activityId = this.props.task.activity;
            const taskType = this.props.task.type;
            // FM0005 GA TKV
            const providerId = this.props.task.provider;
            const visitTaskId = this.props.task.id;
            const gate = this.props.task.gate;
            const platformId = this.props.task.platform;
            const transportCompanyId = this.props.task.transportCompany

            const avizationColumn = columns.find(value => (value.name === name));

            if (!visitDate) {
                this.setState({
                    isShow: true
                });

                return;
            }

            if (!this.props.task.provider) {
                this.setState({
                    isShow: true
                });

                return;
            }

            
            this.setState({ isLoadingTimeSlot: true })
            // Golden Apple TKV
            callApi("avization/gettimeslots", { visitDate, activityId, taskType, providerId, transportCompanyId, visitTaskId, platformId, gate})
                .then(data => {
                    avizationColumn.valuesForSelectType = data;

                    this.setState({
                        columns,
                        isShow: true,
                        isLoadingTimeSlot: false
                    });
                });
        }
    };

    showPopup = (value) => {
        this.setState({
            isShow: value
        });
    };

    render() {
        const { state, task, stateColors, onCustomFieldChange, onFieldChange, onAction, onTaskDocumentsChange, loadData, onSaveVisit, setNewOrders } = this.props;
        let { columns, orderColumns } = this.state || {};
        columns = (columns || []).filter(field => {
            return (field.fieldMatrixAccessTypes.find(x => x.state === state) || {}).accessType !== "Hidden";
        });

        let info = (<Tab.Pane className="task-info-tab">
            <Modal.Content>
                <div className="ui form visit-edit-grid">
                    {columns.map(x => {
                        let isDisabled = (x.fieldMatrixAccessTypes.find(y => y.state === state) || {}).accessType === "Show";

                        return (<GridColumnDispatcher
                            key={x.name}
                            column={x}
                            value={x.isCustom
                                ? ((task.customFields.filter(y => y.name === x.name)[0] || {}).value || "")
                                : (x.name === "TimeSlot") ? task["timeSlot"] : task[x.name.charAt(0).toLowerCase() + x.name.slice(1)]}
                            task={task}
                            isEdit
                            onChange={x.isCustom
                                ? onCustomFieldChange(task.id)
                                : this.onFieldChange(task.id)}
                            valuesList={x.valuesForSelectType || []}
                            stateColors={stateColors}
                            visitDate={this.props.visitDate}
                            isVisitChange={this.props.isVisitChange}
                            isShow={this.state.isShow}
                            showPopup={this.showPopup}
                            loadTimeSlot={this.loadTimeSlot}
                            isDisabled={isDisabled}
                            className={isDisabled ? "disabled" : ""}
                            onSaveVisit={this.props.onSaveVisit}
                            isLoading={x.name === "TimeSlot" ? this.state.isLoadingTimeSlot : false}
                        />);
                    })}
                </div>
            </Modal.Content>
            <Modal.Actions>
                {(task.actions || []).map(action => (<Button size="tiny" color={action.color.charAt(0).toLowerCase() + action.color.slice(1)||'blue'} key={action.name} content={t(action.buttonName)} onClick={() => { onAction(action, task.id, "task"); }} />))}
            </Modal.Actions>
        </Tab.Pane>);

        let orders = orderColumns
            ? <Tab.Pane className="ui form task-info-tab">
                <div className="visit-edit-grid">
                    <Orders task={task} onFieldChange={onFieldChange} orderColumns={orderColumns} loadData={loadData} onSave={onSaveVisit} setNewOrders={setNewOrders}/>
                </div>
            </Tab.Pane>
            : null;

        let documents =
            <Tab.Pane>
                <div>
                    <DocumentListEdit
                        documents={task.documents || []}
                        onDocumentsChange={onTaskDocumentsChange(task.id)}
                        typesApiUrl="DocumentTypes/GetDocumentTypeList"
                        type="Task"
                    />
                </div>
            </Tab.Pane>;

        let panes = [
            {
                menuItem: t("Information"),
                render: () => info
            },
            {
                menuItem: t("Orders"),
                render: () => orders
            },
            {
                menuItem: `${t("Documents")} (${(task.documents || []).length})`,
                render: () => documents
            }
        ];

        return <Tab panes={panes} defaultActiveIndex={this.props.ordersOpen ? 1 : 0}/>;
    }
}
