import React from "react";
import { Form, Dropdown, Icon, Button } from "semantic-ui-react";
import {multiSelectValues} from "../../../utils/valueFormat";

const Edit = ({ name, value, isDisabled, onChange, valuesList, noLabel, isClearable, className, multiple = false }) => {

    const visibleCount = 20;

    let items = valuesList || [];
    items = items.length > visibleCount
        ? items.sort((a, b) => ((b.isActive - a.isActive) - 2 * (a.value === value)))
        : items.sort((a, b) => (b.isActive - a.isActive));

    items = items.map(x =>
        ({
            key: x.value,
            value: x.value,
            text: x.name,
            disabled: !x.isActive,
            description: x.description
        }));

    const multipleChange = (e, {value, name}) => {
      onChange(e, {name, value: value ? value.join(';') : null});
    };

    const search = (options, query) => {
        return items.filter(opt => new RegExp(query, "i").test(opt.text) || (opt.description && new RegExp(query, "i").test(opt.description))).slice(0, 20);
    };

    return (
        (!noLabel || multiple)
            ? <Form.Field>
                {(!noLabel && name) && <label className={ isDisabled ? "label-disabled" : null}>{t(name)}</label>}
                <Dropdown
                    multiple={multiple}
                    className={className}
                    name={name}
                    value={multiple ? multiSelectValues(value) : value}
                    onChange={multiple ? multipleChange : onChange}
                    disabled={isDisabled || false}
                    search={search}
                    selection
                    clearable={isClearable}
                    options={items} />
            </Form.Field>
            : <Form.Field>
                <div className="select-as-semantic">
                    <select className=""
                        onChange={e => { onChange(e, { name, value: e.target.value }); }}
                        value={value}
                        disabled={isDisabled || false}>
                        <option />
                        {items.map(item => (
                            <option key={item.value} value={item.value}>{item.text}</option>
                        ))}
                    </select>
                    {value && !isDisabled
                        ? <Button size="tiny" icon className="remove-select-button"
                            onClick={e => { onChange(e, { name, value: "" }); }}>
                            <Icon name="times" />
                        </Button>
                        : null
                    }
                </div>
            </Form.Field>
    );
};
export default Edit;
