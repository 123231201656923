import React, {Component} from "react";
import {Modal, Image, Container, Icon} from "semantic-ui-react";
import Viewer from "react-viewer";

export default class DocView extends Component {
    state = {modalOpen: false}

    handleOpen = () => {
        if (this.props.onClick != undefined)
            this.props.onClick();
        this.setState({modalOpen: true});
    }
    handleClose = () => this.setState({modalOpen: false});

    render() {
        const {document} = this.props;
        if (document && document.fileId) {
            const src = "/api/files/viewfile?fileId=" + document.fileId,
                name = document.name || "",
                extension = name.substr(name.lastIndexOf(".") + 1),
                isImage = ["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase());

            let image = isImage
                ? <div className="image-container" style={{background: `url(${src}) no-repeat center center`}}
                       onClick={this.handleOpen}/>
                : (
                    <div className="image-container">
                        <a target="_blanc" href={src}><Icon name='file outline'/></a>
                    </div>
                );
            let inner = (
                <div>
                    {image}

                    <div className="file-info">
                        <u>{document.documentTypeText && document.documentTypeText.length > 19 ? `${document.documentTypeText.slice(0, 15)}...` : document.documentTypeText}</u>
                        <div>{document.name && document.name.length > 15 ? `${document.name.slice(0, 12)}..${document.name.slice(-5, -1)}` : document.name}</div>
                        {document.isExpired
                            ? <div className="file-info-red">{document.date}<p>{t("Has expired")}</p></div>
                            : <div>{document.date}</div>}
                    </div>
                </div>
            );

            return (
                <div className="file-item">
                    <Modal
                        className="top-layer"
                        trigger={inner}
                        open={this.state.modalOpen}
                        onClose={this.handleClose}
                        closeOnEscape
                        closeOnDimmerClick={false}
                        basic>

                        <Modal.Content>
                            <Viewer
                                visible={true}
                                loop={false}
                                showTotal={false}
                                changeable={false}
                                scalable={false}
                                zoomSpeed={1}
                                onClose={this.handleClose}
                                images={[{src: src}]}
                            />
                        </Modal.Content>
                    </Modal>
                    {this.props.children}
                </div>
            );
        }
        return (
            <div className="file-item file-item-add">
                {this.props.children}
            </div>
        );
    }
}
