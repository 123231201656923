import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Table, Icon } from "semantic-ui-react";

const Facet = ({ name, value, onChange, sort, setSort }) => {
    const input = (
        <Input
            fluid
            name={name}
            value={value || ""}
            autoComplete="new-password"
            label={{ basic: true, content: "" }}
            labelPosition='right'
            placeholder={t(name)}
            onChange={onChange} />
    );

    return (
        <div className="facet-input">
            <Popup
                trigger={input}
                content={t(name)}
                on='focus'
            />
            <Button className={`sort-button sort-button-up ${sort === "asc" ? "sort-button-active" : ""}`} name={name} value="asc" onClick={setSort}>
                <Icon name="caret up" />
            </Button>
            <Button className={`sort-button sort-button-down ${sort === "desc" ? "sort-button-active" : ""}`} name={name} value="desc" onClick={setSort}>
                <Icon name="caret down" />
            </Button>
            <div className={"hidden-resize"} >{t(name) + "..............."}</div>
        </div>
    );

};
export default Facet;