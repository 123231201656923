import React, {Component} from "react";
import {Modal, Form, Button, Icon, Input} from "semantic-ui-react";
import FileUploader from "./FileUploader";
import DocView from "./DocView";
import DatePicker from "react-datepicker";
import WebCamUploader from "./WebCamUploader";
import FtpUploader from "./FtpUploader";

export default class DocWithEditor extends Component {
    state = {}

    constructor(props) {
        super(props);
        this.state = {
            types: [],
            modalOpen: false,
            docIndex: props.docIndex,
            document: Object.assign({}, props.document)
        };
    }

    handleOpen = () => {
        callApi(this.props.typesApiUrl, this.props.type)
            .then(types => {
                this.setState({
                    types: types.map((x) => ({
                        key: x.id,
                        value: x.id,
                        text: x.name,
                        date: x.isForExpiringDate.toString()
                    }))
                });
                if (!this.state.document || !this.state.document.documentType)
                    this.setState({
                        document: Object.assign(this.state.document, {
                            documentType: (types[0] || {}).id,
                            documentTypeText: (types[0] || {}).name
                        })
                    });
            });
        this.setState({
            modalOpen: true,
            document: Object.assign({}, this.props.document),
            docIndex: this.props.docIndex
        });
    }

    handleClose = (noDelete = false) => {
        if (this.state.document && !this.props.document && this.state.document.fileId && !noDelete) {
            callApi(`files/deleteFile?fileId=${this.state.document.fileId}`);
        }
        this.setState({modalOpen: false});
    }

    handleSave = () => {
        if (this.state.document && this.state.document.fileId) {
            this.props.onDocumentEdit(this.state.document, this.state.docIndex);
            this.handleClose(true);
        }
    }

    handleDelete = () =>
        this.props.onDocumentDelete(this.state.docIndex);

    addFile = (fileDto) => {
        if (fileDto)
            this.setState({
                document: {
                    ...this.state.document,
                    creationDate: fileDto.creationDate,
                    fileId: fileDto.fileId,
                    name: fileDto.name
                }
            });
    }

    handleFormChange = (e, {name, value}) => {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                document: {...prevState.document, [name]: value}
            };
        });
    }

    handleTypeChange = (e, {value}) => {
        this.setState({
            document: {
                ...this.state.document,
                documentType: value,
                documentTypeText: this.state.types.find(x => x.key === value).text,
                date: (this.state.types.find(x => x.key == value) || {}).date ? this.state.document.date : ""
            }
        });
    }

    getFileName = () => {
        if (!this.state.document || !this.state.document.name)
            return "";
        const name = this.state.document.name;
        return name.lastIndexOf(".") !== -1 ? name.substr(0, name.lastIndexOf(".")) : "";
    }

    saveFileName = (e, {value}) => {
        if (!value ||
            !this.state.document ||
            !this.state.document.name ||
            this.state.document.name.lastIndexOf(".") === -1)
            return;
        const currentName = this.state.document.name;
        const extension = currentName.substr(currentName.lastIndexOf("."));

        this.setState({"document": {...this.state.document, name: value + extension}});
    }

    render() {
        const {okButtonText, titleText, document} = this.props;
        const showDate = (this.state.types.find(x => x.key == this.state.document.documentType) || {}).date;
        return (
            <DocView document={document}>

                {document ? (
                    <div>
                        {!this.props.notDelete && <Icon name="times" className="uploaded-image-delete-button" onClick={this.handleDelete}/>}
                        <Icon name="pencil alternate" className="uploaded-image-edit-button" onClick={this.handleOpen}/>
                    </div>
                ) : (
                    <Icon name="plus" className="uploaded-image-add-button" onClick={this.handleOpen}/>
                )

                }
                <Modal
                    size='mini'
                    open={this.state.modalOpen}
                    closeOnEscape
                    closeOnDimmerClick={false}
                    onClose={this.handleClose}>
                    <Modal.Header>{titleText}</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <FileUploader document={this.state.document} onChange={this.addFile}/>
                            <WebCamUploader onChange={this.addFile}><Button size="small" floated='right' icon='photo'
                                                                            content='Webcam'/></WebCamUploader>
                            <FtpUploader onChange={this.addFile}><Button size="small" floated='right' icon='file'
                                                                            content='Ftp'/></FtpUploader>
                            <Form.Field>
                                <Form.Input label={t("Document name")} placeholder={t("Upload file")} name="name"
                                            value={this.getFileName()} onChange={this.saveFileName}/>
                            </Form.Field>
                            <Form.Field>
                                <Form.Dropdown label={t("Type")} fluid selection name="documentType"
                                               value={this.state.document.documentType} options={this.state.types}
                                               onChange={this.handleTypeChange}/>
                            </Form.Field>
                            {showDate
                                ? <Form.Field>
                                    <label>{t("Expiration Date")}</label>
                                    <DatePicker
                                        locale={localStorage.getItem("currentCulture")}
                                        selected={parseDate(this.state.document.date)}
                                        dateFormat="dd.MM.yyyy"
                                        onChange={(ev) => {
                                            this.setState({document: Object.assign(this.state.document, {date: dateToString(ev)})});
                                        }}
                                    />
                                </Form.Field>
                                : null
                            }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button icon='ban' content={t("CancelButton")} onClick={this.handleClose}/>
                        <Button icon='check' positive content={okButtonText} onClick={this.handleSave}/>
                    </Modal.Actions>
                </Modal>
            </DocView>
        );
    }
}
