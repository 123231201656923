import React from "react";
import {Table, Visibility} from "semantic-ui-react";
import {dateFormat} from "../../utils/dateFormat";
import SecurityServiceModal from "./components/SecurityServiceModal";
import {securityServiceStatusColor} from "../../utils/stateHelper";
import StatusValue from "../../shared/columns/status/Value";

export default function SecurityServiceList ({list = [], load, activeTab, stopTimer, runTimer}) {
    const classNameTableSecurityService = (IsCloseToExpired, IsExpired) => {
        let className = ['security-check-table']
        if (IsCloseToExpired && IsExpired) {
            className.push('security-table-color-red')
        }
        if (IsCloseToExpired && !IsExpired) {
            className.push('security-table-color-orange')
        }
        return className.join(' ')

    };
    return (<div style={{position: "relative"}}>
        <Table celled selectable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{t('Number')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Date_Time_Created')}</Table.HeaderCell>
                    {activeTab === "New" && <Table.HeaderCell>{t('Date_Time_Loading')}</Table.HeaderCell>}
                    <Table.HeaderCell>{t('Driver')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('Initiator')}</Table.HeaderCell>
                    {activeTab !== "New" ? <Table.HeaderCell>{t('Responsible')}</Table.HeaderCell> : null}
                    <Table.HeaderCell>{t('TransportCompany')}</Table.HeaderCell>
                    {activeTab === "Completed" ? <Table.HeaderCell>{t('Result of checking')}</Table.HeaderCell> : null}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {list.map(item => {
                    return (
                        <SecurityServiceModal caseId={item.id}
                                              key={item.id}
                                              onLoad={load}
                                              isCheck
                                              stopTimer={stopTimer}
                                              runTimer={runTimer}
                        >
                            <Table.Row key={item.id} className={classNameTableSecurityService(item.isCloseToExpired, item.isExpired)}>
                                <Table.Cell>{item.number}</Table.Cell>
                                <Table.Cell>{dateFormat(item.creationDate)}</Table.Cell>
                                {activeTab === "New" && <Table.Cell>{dateFormat(item.loadingDate, undefined, true)}</Table.Cell>}
                                <Table.Cell>{item.driverName}</Table.Cell>
                                <Table.Cell>{item.initiatorName}</Table.Cell>
                                {item.state !== "New" ? <Table.Cell>{item.responsibleName}</Table.Cell> : null}
                                <Table.Cell>{item.transportCompanyName}</Table.Cell>
                                {item.state === "Completed" ? <Table.Cell> <StatusValue value={item.result}
                                                                                        stateColors={securityServiceStatusColor()}
                                                                                        text={t(item.result)}/></Table.Cell> : null}
                            </Table.Row>
                        </SecurityServiceModal>
                    );
                })}
            </Table.Body>
        </Table>
        <Visibility
            continuous={false}
            once={false}
            onTopVisible={()=>{load(true);}}
            style={{position: "absolute", bottom: 0, left: 0, right: 0, height: "70vh", zIndex: -1}}/>
    </div>)
}
