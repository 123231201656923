import React, {useState, useEffect} from "react";
import {Form, Input, Popup, Grid, Icon, Button, Modal, Tab, Confirm} from "semantic-ui-react";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import {Message} from "semantic-ui-react/dist/commonjs/collections/Message/Message";
import {toast} from "react-toastify";

const Edit = ({task, name, placeholder, className, isDisabled, value = [], visitDate, valuesList, showPopup, isShow, onChange, loadTimeSlot, isVisitChange, onSaveVisit, isLoading}) => {
    const [valueInput, setValueInput] = useState("");
    const [valueResult, setValueResult] = useState(null);
    const [val, setValue] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSaveData, setIsSaveData] = useState(false);

    useEffect(() => {
        if (!value) {
            setValueInput("");
            setValueResult(null);

            return;
        }

        if (value.start && value.end && visitDate) {
            setValueInput(`${value.start.slice(0,5)} - ${value.end.slice(0,5)}`);
            setValueResult(value);
        } else {
            setValueInput("");
            setValueResult(null);
        }

    }, [value]);

    const onClickTime = (e, {name, value}) => {
        //onChange && onChange(e, {name, value}
        setValue(value);
        setValueInput(`${value.start.slice(0,5)} - ${value.end.slice(0,5)}`);
        handleClose(e, {
            name, value: { ...value }
        })
    };

    const isSelected = (item) => {
        if (!val) return;
        let selected;
        let valueRes = val;
        delete valueRes.visitDate;

        if (valueInput === null) {
            return false;
        }

        selected = JSON.stringify(valueRes) === JSON.stringify(item);

        return selected;

    };

    const itemTimeClassName = (item) => {
        const selected = isSelected(item);
        const className = ["c-time-slot-list__item_time"];

        if (selected) {
            className.push("c-time-slot-list__item_time_selected");
        }
        return className.join(" ");
    };

    const getLabelClass = isDisabled ? "label-disabled" : null;


    const onClickSaveChange = () => {
        onSaveVisit && onSaveVisit().then(resp => {
            if (resp.successMessage) {
                setIsSaveData(true);
                setIsModalOpen(false);
            }
        });
    };
    const onCloseModalSaveChange = () => {
        setIsModalOpen(false);
        setIsSaveData(false);
    };

    const ModalSaveChange = () => {
        return (
            <Modal dimmer="blurring"
                   //className="visit-modal"
                   open={isModalOpen}
                   onClose={onCloseModalSaveChange}
                   closeOnEscape
                   closeOnDimmerClick={false}
                   closeIcon
                   size='small'>
                <Modal.Header>{t('Data not saved')}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {t('To go to the time slot selection save the changes in Visits / Tasks')}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button size='small' color='grey' content={t("Cancel")} onClick={onCloseModalSaveChange}/>
                    <Button size='small' color='green' content={t("Save changes")} onClick={onClickSaveChange}/>
                </Modal.Actions>
            </Modal>
        )
    };

    const Content = () => {
        if (!visitDate) {
            return (
                (<Grid centered divided>
                    <Label className="avization-label"
                           color={"blue"}>{!visitDate ? t('No visit date selected') : ""}</Label>
                </Grid>)
            );
        }


        if (!task.provider) {
            return (
                (<Grid centered divided>
                    <Label className="avization-label"
                           color={"blue"}>{t('No provider selected')}</Label>
                </Grid>)
            );
        }

        if (valuesList && valuesList.length > 0) {
            return (
                <Grid centered divided columns={valuesList.length} className={"c-time-slot-list"}>
                    {valuesList.map((item, key) => (
                        <Grid.Column key={key} textAlign={"center"} className={"c-time-slot-list__item"}>
                            <div className={"c-time-slot-list__item_date"}>
                                {item.date}
                            </div>
                            {item.timeSlots.length === 0 ?
                                <Grid className="avization-empty" stretched centered>
                                    <Grid.Column verticalAlign="middle">
                                        <Label className="avization-label" color={"blue"}>{t('No data')}</Label>
                                    </Grid.Column>
                                </Grid>
                                :
                                item.timeSlots.map((time, index) => (
                                    <Grid.Row key={index}
                                              className={itemTimeClassName({
                                                  date: item.date,
                                                  start: time.start,
                                                  end: time.end,
                                                  rollingDate: time.rollingDate,
                                              })}
                                              onClick={(e) => onClickTime(e, {
                                                  name, value: {
                                                      date: item.date,
                                                      start: time.start,
                                                      end: time.end,
                                                      rollingDate: time.rollingDate,
                                                      visitDate
                                                  }
                                              })}>
                                        <Grid.Column>
                                            {time.start.slice(0, 5)} - {time.end.slice(0, 5)}
                                        </Grid.Column>
                                    </Grid.Row>
                                ))
                            }
                        </Grid.Column>
                    ))}
                </Grid>
            );
        } else {
            return (
                <Grid centered divided>
                    <Label className="avization-label" color={"blue"}>{t('No data temporarily')}</Label>
                </Grid>
            );
        }
    };

    const handleClose = (e, { name, value }) => {
        if (value.start && value.end) {
            onChange && onChange(e, {name, value });
        }
        showPopup(false);
        setValue(null);
    };

    const onClickIconClear = (e, {name, value}) => {
        e.stopPropagation();

        if (isOpen) setIsOpen(false);

        setValueInput("");
        setValueResult("");

        onChange && onChange(e, {name, value});
    };

    const loadTimes = (e, {name, value}) => {
        e.stopPropagation();

       loadTimeSlot(e, {name, value});
    };

    const onOpenTimeSlot = (e) => {
        if (isVisitChange) {
            setIsModalOpen(true);
        }
        loadTimes(e, {name, value: visitDate})
    };

    return (
        <>
            <ModalSaveChange/>
            <Form.Field>

                <label className={getLabelClass}>{t(name)}</label>
                <Popup
                    trigger={
                        <div className={"c-time-slot"}>
                            <Input className={`${className} c-time-slot_input`}
                                loading={isLoading}
                                   readOnly
                                   disabled={isDisabled || isLoading}
                                   placeholder={isLoading ? t('isLoading') :placeholder}
                                   value={valueInput}/>
                            {valueInput && <Icon name='delete' className={"c-time-slot__clear"} onClick={(e) => {
                                onClickIconClear(e, {name, value: null});
                            }}/>}
                        </div>}
                    content={<Content/>}
                    on={"click"}
                    open={isShow && !isModalOpen && !isVisitChange}
                    size={"large"}
                    disabled={isDisabled}
                    onClose={(e) => handleClose(e, {
                        name, value: {...val, visitDate}
                    })}
                    onOpen={(e) => onOpenTimeSlot(e)}
                    position={"right center"}
                />
            </Form.Field>
        </>
    );
};

export default Edit;
