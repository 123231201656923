import React from "react";
import {Modal, Button, Grid, Label, Select, Tab, Input, Icon} from "semantic-ui-react";
import {dateFormat} from "../utils/dateFormat";

const History = ({items, isLocalDate}) => {
    return (<div>
        <Grid>
            {(items || []).map((historyItem, i) => <Grid.Row key={i}>
                <Grid.Column width={5}>
                    <div>{isLocalDate ? dateFormat(historyItem.createAt) : historyItem.createAt}</div>
                    <div className="history-who">{historyItem.who}</div>
                </Grid.Column>
                <Grid.Column width={11}>
                    <div className="history-comment">
                        {historyItem.text}
                    </div>
                </Grid.Column>
            </Grid.Row>)}
        </Grid>
    </div>);
};
export default History;
