import React from "react";
import { Modal, Form, Input, Button, Label, Icon, Message, Grid, Segment, Tab } from "semantic-ui-react";
import ControlledModal from "../../../shared/modals/ControlledModal";
import { UserPermissionsContext } from "../../../Contexts";

export default class UserEditModal extends React.Component {
    state = this.initialState;
    static contextType = UserPermissionsContext;

    get initialState() {
        return {
            formData: {
                id: "",
                email: null,
                password: null,
                name: null,
                roleId: null,
                isActive: true,
                companies: null,
                providers: null,
                consignees: null,
                platforms: null,
                activities: null,
                adminKu: false,
                rolesKu: []
            },
            errorText: null,
            platformList: [],
            activityList: [],
            companyList: []
        };
    }

    load = () =>
        callApi("Platforms/GetList").then(platformList => {
            callApi("Activities/GetList").then(activityList => {
                callApi("Roles/GetList", { userId: this.props.id }).then(roles => {
                    callApi("Providers/GetAll").then(providers => {
                        callApi("Consignees/GetAll").then(consignees => {
                            callApi("TransportCompanies/GetAll").then(companies => {
                                callApi("Roles/GetList", { isRolesKu: true }).then(rolesKu => {
                                    this.setState({
                                        roles: roles.map((x) => ({ key: x.id, value: x.id, text: x.name })),
                                        rolesKuList: rolesKu.map((x) => ({ key: x.id, value: x.id, text: x.name })),
                                        companyList: companies.map((x) => ({ key: x.id, value: x.id, text: x.name })),
                                        providerList: providers.map((x) => ({ key: x.id, value: x.id, text: x.name })),
                                        consigneeList: consignees.map((x) => ({ key: x.id, value: x.id, text: x.name })),
                                        platformList: platformList.map((x) => ({ key: x.id, value: x.id, text: x.name })),
                                        activityList: activityList.map((x) => ({
                                            key: x.id,
                                            value: x.id,
                                            text: x.name,
                                            platforms: x.platforms
                                        })),
                                    });
                                    if (this.props.id) {
                                        callApi("Users/GetById", this.props.id)
                                            .then(user => {
                                                this.setState({
                                                    formData: user
                                                });
                                            });
                                    } else {
                                        callApi("users/GetCheckBoxValueForCreate")
                                            .then(settings => {
                                                this.changeFormData((fd) => {
                                                    fd.roleId = this.state.roles[0] && this.state.roles[0].value;
                                                    Object.keys(settings).forEach(setting => fd[setting] = settings[setting]);
                                                    if (!settings.selectAllCompanies) fd.companies = [];
                                                    if (!settings.selectAllCompanies) fd.providers = [];
                                                    if (!settings.selectAllCompanies) fd.consignees = [];
                                                    if (!settings.selectAllPlatforms) fd.platforms = [];
                                                    if (!settings.selectAllActivities) fd.activities = [];
                                                });
                                            });
                                    }
                                });
                            });
                        });
                    });
                });
            });
        });

    save = () => {
        return callApi("Users/SaveOrCreate", this.state.formData)
            .then(data => {
                if (data.error) {
                    this.setState({
                        errorText: data.error
                    });
                }
                return data;
            });
    };

    validate = () => {
        if (!this.state.formData.email) {
            this.setState({ errorText: t("UM_ERR_empty_email") });
            return false;
        }

        if (!this.state.formData.name) {
            this.setState({ errorText: t("UM_ERR_empty_name") });
            return false;
        }

        if (!this.state.formData.id && !this.state.formData.password) {
            this.setState({ errorText: t("UM_ERR_empty_pass") });
            return false;
        }

        if (!this.state.formData.roleId) {
            this.setState({ errorText: t("UM_ERR_empty_role") });
            return false;
        }

        this.setState({ errorText: null });
        return true;
    };

    okBtnClick = () => {
        if (!this.validate())
            return;
        return this.save();
    };

    changeFormData = (changer) => {
        this.setState((prevState, props) => {
            changer(prevState.formData);
            return prevState.formData;
        });
    };

    handleChange = (e, { name, value, checked }) => {
        this.changeFormData((fd) => fd[name] = checked === undefined ? value : checked);
    };

    rolesChange = (e, { role: value, checked }) => {
        this.changeFormData((fd) => {
            let rolesArray = fd.rolesKu ? [...fd.rolesKu] : [];
            checked ? rolesArray.push(value) : rolesArray = rolesArray.filter(r => r !== value);
            fd.rolesKu = rolesArray;
        });
    };

    handlePlatformChange = (e, { value, checked }) => {
        this.changeFormData((fd) => {
            fd.platforms = value ? value : checked;
            if (fd.activities && fd.activities.length > 0) {
                let activityList = fd.platforms
                    ? this.state.activityList.filter(x => x.platforms.some(y => fd.platforms.includes(y)))
                    : this.state.activityList.filter(x => x.platforms.length > 0);
                fd.activities = fd.activities.filter(x => activityList.some(y => y.value === x) || !this.state.activityList.find(y => y.value === x));
            }
        });

    };

    render() {
        const currentPlatforms = this.state.formData.platforms;
        let activityList = currentPlatforms
            ? this.state.activityList.filter(x => x.platforms.some(y => currentPlatforms.includes(y)))
            : this.state.activityList.filter(x => x.platforms.length > 0);

        const { permissionsData } = this.context;
        const { permissions } = permissionsData;

        const isSuperAdmin = permissions.includes("ExcludeFromAvailableRoles");
        const disabled = !isSuperAdmin && this.state.formData.isCurrentUser;
        const isAdminKu = permissionsData.isAdminKU;

        let panes = [
            {
                key: "info",
                menuItem: t("Information"),
                render: () => <Tab.Pane>
                    <Form error>
                        <Grid columns="equal">
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Input label={t("First name")}>
                                            <Input disabled={!isAdminKu && disabled} name="name"
                                                value={this.state.formData.name || ""} onChange={this.handleChange} />
                                        </Form.Input>
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input label={t("Email")} iconPosition="left">
                                            <Icon name="at" />
                                            <Input disabled={disabled} name="email"
                                                value={this.state.formData.email || ""}
                                                onChange={this.handleChange} />
                                        </Form.Input>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("role")}</label>
                                        <Form.Dropdown disabled={disabled} fluid selection name="roleId"
                                            value={this.state.formData.roleId || ""}
                                            options={this.state.roles || []} onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("pass")}</label>
                                        <Input disabled={!isAdminKu && disabled} type="password" name="password"
                                            value={this.state.formData.password || ""} onChange={this.handleChange} />
                                        {this.props.id
                                            ? <Label
                                                pointing>{t("Leave the field blank if you do not want to change the password")}</Label>
                                            : null}
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Checkbox className="without-background" disabled={disabled}
                                            label={t("Active")} name="isActive"
                                            checked={this.state.formData.isActive}
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    {!this.state.formData.disableSelectAdminKu && <Form.Field>
                                        <Form.Checkbox
                                            className="without-background"
                                            label={t("administratorKU")}
                                            name="adminKu"
                                            disabled={!permissions.includes("AdministrationForUsers") || disabled}
                                            checked={this.state.formData.adminKu}
                                            onChange={this.handleChange} />
                                    </Form.Field>}
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <label>{t("platforms")}</label>
                                        <Segment>
                                            <Form.Checkbox className="without-background"
                                                disabled={disabled || !this.state.formData.selectAllPlatforms}
                                                label={t("All platforms")}
                                                checked={this.state.formData.platforms === null}
                                                onChange={(e, { checked }) => {
                                                    this.handlePlatformChange(e, {
                                                        name: "platforms",
                                                        checked: checked ? null : []
                                                    });
                                                }} />
                                            {this.state.formData.platforms !== null
                                                ? <Form.Dropdown disabled={disabled} fluid multiple search selection
                                                    name="platforms"
                                                    value={this.state.formData.platforms || ""}
                                                    options={this.state.platformList || []}
                                                    onChange={this.handlePlatformChange} />
                                                : null
                                            }
                                        </Segment>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("activities")}</label>
                                        <Segment>
                                            <Form.Checkbox className="without-background"
                                                disabled={disabled || !this.state.formData.selectAllActivities}
                                                label={t("All activities")}
                                                checked={this.state.formData.activities === null}
                                                onChange={(e, { checked }) => {
                                                    this.handleChange(e, {
                                                        name: "activities",
                                                        checked: checked ? null : []
                                                    });
                                                }} />
                                            {this.state.formData.activities !== null
                                                ? <Form.Dropdown disabled={disabled} fluid multiple search selection
                                                    name="activities"
                                                    value={this.state.formData.activities || ""}
                                                    options={activityList || []}
                                                    onChange={this.handleChange} />
                                                : null
                                            }
                                        </Segment>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Tab.Pane>
            },
            {
                key: "available_companies",
                menuItem: t("available_companies"),
                render: () => <Tab.Pane key="tab2">
                    <Form error>
                        <Grid columns="equal">
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <Form.Field>
                                        <Form.Checkbox
                                            className="without-background"
                                            disabled={disabled || !this.state.formData.selectAllCompanies}
                                            label={t("setAllCompanies")}
                                            name="allCompanies"
                                            checked={this.state.formData.companies === null && this.state.formData.providers === null && this.state.formData.consignees === null}
                                            onChange={(e, { checked }) => {
                                                this.handleChange(e, {
                                                    name: "companies",
                                                    checked: checked ? null : []
                                                });
                                                this.handleChange(e, {
                                                    name: "providers",
                                                    checked: checked ? null : []
                                                });
                                                this.handleChange(e, {
                                                    name: "consignees",
                                                    checked: checked ? null : []
                                                });
                                            }}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("TransportCompanies")}</label>
                                        <Segment>
                                            <Form.Group>
                                                <Form.Checkbox
                                                    className="without-background"
                                                    label={t("All companies")}
                                                    disabled={disabled || !this.state.formData.selectAllCompanies}
                                                    checked={this.state.formData.companies === null}
                                                    onChange={(e, { checked }) => {
                                                        this.handleChange(e, {
                                                            name: "companies",
                                                            checked: checked ? null : []
                                                        });
                                                    }}
                                                />
                                                {this.state.formData.companies !== null && <Form.Checkbox
                                                    className="without-background"
                                                    label={t("showWithEmpty")}
                                                    disabled={disabled}
                                                    name={"showWithEmptyCompanies"}
                                                    checked={this.state.formData.showWithEmptyCompanies}
                                                    onChange={this.handleChange}
                                                />}
                                            </Form.Group>

                                            {this.state.formData.companies !== null
                                                ? <Form.Dropdown disabled={disabled} fluid multiple search selection
                                                    name="companies"
                                                    value={this.state.formData.companies || ""}
                                                    options={this.state.companyList || []}
                                                    onChange={this.handleChange} />
                                                : null
                                            }
                                        </Segment>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Providers")}</label>
                                        <Segment>
                                            <Form.Group>
                                                <Form.Checkbox
                                                    className="without-background"
                                                    label={t("All companies")}
                                                    disabled={disabled || !this.state.formData.selectAllCompanies}
                                                    checked={this.state.formData.providers === null}
                                                    onChange={(e, { checked }) => {
                                                        this.handleChange(e, {
                                                            name: "providers",
                                                            checked: checked ? null : []
                                                        });
                                                    }}
                                                />
                                                {this.state.formData.providers !== null && <Form.Checkbox
                                                    className="without-background"
                                                    label={t("showWithEmpty")}
                                                    disabled={disabled}
                                                    name={"showWithEmptyProviders"}
                                                    checked={this.state.formData.showWithEmptyProviders}
                                                    onChange={this.handleChange}
                                                />}
                                            </Form.Group>
                                            {this.state.formData.providers !== null
                                                ? <Form.Dropdown disabled={disabled} fluid multiple search selection
                                                    name="providers"
                                                    value={this.state.formData.providers || ""}
                                                    options={this.state.providerList || []}
                                                    onChange={this.handleChange} />
                                                : null
                                            }
                                        </Segment>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Consignees")}</label>
                                        <Segment>
                                            <Form.Group>
                                                <Form.Checkbox
                                                    className="without-background"
                                                    label={t("All companies")}
                                                    disabled={disabled || !this.state.formData.selectAllCompanies}
                                                    checked={this.state.formData.consignees === null}
                                                    onChange={(e, { checked }) => {
                                                        this.handleChange(e, {
                                                            name: "consignees",
                                                            checked: checked ? null : []
                                                        });
                                                    }}
                                                />
                                                {this.state.formData.consignees !== null && <Form.Checkbox
                                                    className="without-background"
                                                    label={t("showWithEmpty")}
                                                    disabled={disabled}
                                                    name={"showWithEmptyConsignees"}
                                                    checked={this.state.formData.showWithEmptyConsignees}
                                                    onChange={this.handleChange}
                                                />}
                                            </Form.Group>

                                            {this.state.formData.consignees !== null
                                                ? <Form.Dropdown disabled={disabled} fluid multiple search selection
                                                    name="consignees"
                                                    value={this.state.formData.consignees || ""}
                                                    options={this.state.consigneeList || []}
                                                    onChange={this.handleChange} />
                                                : null
                                            }
                                        </Segment>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Tab.Pane>
            }
        ];

        this.state.formData.adminKu && !this.state.formData.disableSelectAdminKu && panes.push({
            menuItem: t("available_roles"),
            render: () => <Tab.Pane>
                <Form>
                    {
                        this.state.rolesKuList.map(role => (
                            <Form.Field key={"key_" + role.value}>
                                <Form.Checkbox
                                    className="without-background"
                                    label={role.text}
                                    role={role.value}
                                    disabled={!permissions.includes("AdministrationForUsers") || disabled}
                                    checked={this.state.formData.rolesKu && this.state.formData.rolesKu.includes(role.value)}
                                    onChange={this.rolesChange} />
                            </Form.Field>
                        ))
                    }
                </Form>
            </Tab.Pane>
        });

        return (
            <ControlledModal
                buttonName={this.props.children}
                buttonSize={this.props.size}
                title={this.props.title}
                onOpen={this.load}
                onClose={() => {
                    this.props.onClose();
                    this.setState(this.initialState);
                }}
                error={this.state.errorText}
                saveButtonName={this.props.actionButton}
                onSave={this.okBtnClick}
            >
                <Tab panes={panes} />
            </ControlledModal>
        );
    }
}
