import React, {Component} from "react";
import {Modal, Form, Button, Icon, Input, Table, GridRow, GridColumn} from "semantic-ui-react";
import Webcam from "react-webcam";

export default class FtpUploader extends Component {
    state = {
        modalOpen: false,
        files: []
    }

    handleOpen = () => {
        callApi("ftpFileUploader/list")
            .then((files) => {
                this.setState({
                    files: files,
                });
            });
        this.setState({modalOpen: true});
    }

    handleClose = () => {
        this.setState({modalOpen: false});
    }

    select = (fileName) => {
        callApi("files/uploadFileFromFtp?fileName=" + fileName)
            .then((fileDto) => {
                callApi('ftpFileUploader/Delete?fileName='+fileName)
                    .then(()=>{
                        this.props.onChange(fileDto);
                        this.setState({
                            modalOpen: false,
                        });
                    })
            });
    }

    render() {
        return (
            <Modal trigger={this.props.children}
                   onOpen={this.handleOpen}
                   open={this.state.modalOpen}
                   closeOnEscape
                   closeOnDimmerClick={false}
                   onClose={this.handleClose}>
                <Modal.Header>Прикрепление файла из FTP</Modal.Header>
                <Modal.Content>
                    <Table celled striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell >Имя файла</Table.HeaderCell>
                                <Table.HeaderCell ></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {this.state.files.map(file =>
                                <Table.Row>
                                    <Table.Cell collapsing>
                                        <Icon name='file' /> {file.name}
                                    </Table.Cell>
                                    <Table.Cell><Button onClick={()=> {this.select(file.name)}}>Прикрепить</Button></Table.Cell>
                                </Table.Row>
                            )
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose}>
                        <Icon name='ban'/> Отмена
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}
