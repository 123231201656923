import "./resetPassword.css";
import React from "react";
import { Link } from "react-router-dom";
import { Form, Message, Button, Header, Icon, Modal, Grid } from "semantic-ui-react";

class PasswordField extends React.Component{
    state = {
        type: 'password',
    }


    handleClick = () => this.setState(({type}) => ({
        type: type === 'text' ? 'password' : 'text'
    }))


    render() {
        console.log(this.state);
        const { label } = this.props

        return (
            <Form.Field  className={'password-with-eye'}>
                <Icon name={this.state.type === 'text' ? 'hide' : 'eye'} onClick={this.handleClick} />
                <Form.Input label={label} iconPosition='left'>
                    <Icon name='lock' />
                    <input type={this.state.type} onChange={this.props.onChange}/>
                </Form.Input>
            </Form.Field>
)
    }
}

export default class ResetPassword extends React.Component {

    resetToken;

    constructor(props) {
        super(props);

        this.state = {
            password: null,
            passwordConfirm: null,
            errorText: null,
            modalOpen: false,
            tokenError: false
        };
        

        if (this.props.match) {
            this.resetToken = this.props.match.params.resetToken;
        }
    }
    
    componentDidMount() {
        callApi(`ForgotPassword/ValidateToken`,this.resetToken)
            .then((result) => {
                if(result.isError){
                    this.setState({ tokenError: true });
                }
            });
        
    }

    clearStates = () => {
        this.setState({
            password: "",
            passwordConfirm: "",
            errorText: null,
            tokenError: false
        });
    }

    handlePasswordChange = (e) => {
        this.setState({
            password: e.target.value
        });
    }

    handleConfirmPasswordChange = (e) => {
        this.setState({
            passwordConfirm: e.target.value
        });
    }
    
    okBtnClick = () => {
        if (!(this.validateLength() && this.validateDigital() && this.validateRegistry()))
            return;

        callApi(`Identity/ResetUserPassword`,{
            id: this.resetToken,
            newPassword: this.state.password
        }, false)
            .then(() => {
                this.setState({ modalOpen: true });
                this.clearStates();
            })
            .catch(err => {
                this.setState({ errorText: err.message });
            });
    }
    
    validateLength = () => {
        if(!this.validateEq()) return false;
        let password = this.state.password;
        return password.length >= 6 && password.length <= 32 
    }
    
    validateDigital = () => {
        if(!this.validateEq()) return false;
        return /^.*(?=.*\d).*$/.test(this.state.password);
    }
    
    validateRegistry = () => {
        if(!this.validateEq()) return false;
        return /^.*(?=.*[a-z]).*$/.test(this.state.password) && /^.*(?=.*[A-Z]).*$/.test(this.state.password) && /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/.test(this.state.password);
    }
    
    validateEq = () => {
        if(this.state.password !== null)
            return this.state.password === this.state.passwordConfirm;
        return false;
    }

    render() {
        return (
            <div className="centered-div">
                <Modal dimmer="blurring" size={"tiny"} open={true}>
                    <Modal.Content>
                        {this.state.tokenError ? t("Token Error") : <Form error>
                            <Form.Field>
                                <Header as='h2'>
                                    {t("enter_new_pass")}
                                </Header>
                            </Form.Field>
                            <PasswordField label={t("pass")} onChange={this.handlePasswordChange} />
                            <PasswordField label={t("retype_pass")} onChange={this.handleConfirmPasswordChange} />
                            <Message>
                                <div><Icon name={'check' + (this.validateEq() ? ' green': '') } /> {t("Passwords must match")}</div>
                                <div><Icon name={'check' + (this.validateLength() ? ' green': '') } /> {t("Password must be between 6 and 32 characters")}</div>
                                <div><Icon name={'check' + (this.validateDigital() ? ' green': '') } /> {t("Password must contain numbers")}</div>
                                <div><Icon name={'check' + (this.validateRegistry() ? ' green': '') } /> {t("Password must contain latin letters in different case")}</div>
                            </Message>
                        </Form>}
                    </Modal.Content>
                    {this.state.tokenError ? '' : <Modal.Actions>
                        <Modal dimmer="blurring" size='mini' open={this.state.modalOpen}>
                            <Modal.Content>
                                <p>{t("new_pass_set")}</p>
                            </Modal.Content>
                            <Modal.Actions>
                                <Link to='/'>
                                    <Button icon='check' content={t('Ok')} />
                                </Link>
                            </Modal.Actions>
                        </Modal>
                        <Button 
                            disabled={!(this.validateLength() && this.validateDigital() && this.validateRegistry())} 
                            positive 
                            content={t("setup_pass")} 
                            onClick={this.okBtnClick} />
                    </Modal.Actions>}
                </Modal>
            </div>
        );
    }
};
