import React from "react";
import {
    Modal,
    Form,
    Button,
    Input,
    Icon,
    Message,
    Tab,
    Checkbox,
    Dropdown,
    Table,
    Label,
    Popup, Confirm
} from "semantic-ui-react";

export default class BacklightsEditor extends React.Component {
    state = {
        nameForAdd: "",
        colorForAdd: "",
        classForAdd: "",
        confirmation: {
            open: false
        },
        notChange: true
    };

    componentDidMount() {
        this.setState({
            colorForAdd: this.props.backLightColors[0].value,
            classForAdd: this.props.classes[0].value
        });
    }

    handleInputChange = (event, obj) => {
        const target = event.target;
        let value = target.type === "checkbox" ? target.checked : target.value;

        if (value === undefined)
            value = obj.value;

        let name = target.name;

        if (name === undefined)
            name = target.parentElement.parentElement.getAttribute("name");
        this.setState({
            [name]: value
        });
    }

    handleChangeColorForAdd = (e, { value }) => {
        this.setState({ colorForAdd: value });
    }

    handleChangeClassForAdd = (e, { value }) => {
        this.setState({ classForAdd: value });
    }

    handleChangeForAdd = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleChangeEditRow = (e, { name, value }) => {
        this.setState(state => ({ editRowData: { ...state.editRowData, [name]: value }, notChange: false }));
    }

    onEditClick = (backlight) => {
        this.setState({
            editRow: backlight.id,
            editRowData: {
                nameForAdd: backlight.name,
                colorForAdd: backlight.color,
                platformIds: backlight.platformIds,
                activityIds: backlight.activityIds,
                classForAdd: backlight.realizationClassName,
            },
            notChange: true
        });
    }

    backlightRowEdit = (values, backlight) => {
        let availableBackLightColors = this.props.backLightColors;
        let availableClasses = this.props.classes;

        let dataSource = backlight ? this.state.editRowData : this.state;

        let nameForAdd = dataSource.nameForAdd;
        let colorForAdd = dataSource.colorForAdd;
        let platformIds = dataSource.platformIds || [];
        let activityIds = dataSource.activityIds || [];
        let classForAdd = dataSource.classForAdd;

        let isNotAvailableForAdd = !nameForAdd || !nameForAdd.replace(/\s/g, "").length ||
            values.filter(v => backlight ? v.id !== backlight.id : true)
                .some(x => x.name === nameForAdd || (x.color === colorForAdd && x.realizationClassName === classForAdd &&
            JSON.stringify((x.platformIds || []).sort()) === JSON.stringify(platformIds.sort()) && JSON.stringify((x.activityIds || []).sort()) === JSON.stringify(activityIds.sort())));


        return <Table.Row>
            <Table.Cell>
                <Input
                    maxLength="40"
                    name="nameForAdd"
                    value={nameForAdd}
                    onChange={!backlight ? this.handleInputChange : this.handleChangeEditRow}
                    placeholder={t('Name')}
                />
            </Table.Cell>
            <Table.Cell>
                {availableBackLightColors.length > 0 ?
                    <Dropdown labeled
                              value={colorForAdd}
                              onChange={!backlight ? this.handleChangeColorForAdd : this.handleChangeEditRow}
                              placeholder={t('Colour')}
                              fluid
                              options={availableBackLightColors.map(backLightColor => {
                                  backLightColor.label = {
                                      color: backLightColor.value.toLowerCase(),
                                      empty: true,
                                      circular: true
                                  };
                                  return backLightColor;
                              })}
                              className='padding-0'
                              name='colorForAdd'
                    /> : `${t('No colors available')}`}
            </Table.Cell>

            <Table.Cell>
                <Dropdown
                    text={t('All platforms')}
                    className='padding-0'
                    fluid
                    multiple
                    search
                    name="platformIds"
                    value={platformIds}
                    options={this.props.platforms}
                    onChange={!backlight ? this.handleChangeForAdd : this.handleChangeEditRow}
                />
            </Table.Cell>
            <Table.Cell>
                <Dropdown
                    text={t('All activities')}
                    className='padding-0'
                    fluid
                    multiple
                    search
                    name="activityIds"
                    value={activityIds}
                    options={this.props.activities}
                    onChange={!backlight ? this.handleChangeForAdd : this.handleChangeEditRow}
                />
            </Table.Cell>

            <Table.Cell>
                {availableClasses.length > 0 ? <Dropdown value={classForAdd} name='classForAdd'
                                                         onChange={!backlight ? this.handleChangeClassForAdd : this.handleChangeEditRow}
                                                         placeholder={t('Class')} fluid className='padding-0'
                                                         options={availableClasses} /> : `${t('No classes available')}`}
            </Table.Cell>
            <Table.Cell>
                <Button size="small"
                        disabled={isNotAvailableForAdd}

                        onClick={() => {
                            if (isNotAvailableForAdd)
                                return;
                            let value = {
                                name: dataSource.nameForAdd,
                                color: dataSource.colorForAdd,
                                realizationClassName: dataSource.classForAdd,
                                platformIds: dataSource.platformIds,
                                activityIds: dataSource.activityIds,
                                id: backlight ? backlight.id : "new"+Math.random(),
                                isNew: backlight ? backlight.isNew : true
                            };

                            if (backlight) {
                                if (!this.state.notChange) {
                                    let index = values.findIndex(v => v.id === backlight.id);
                                    values[index] = value;
                                }
                            } else values.push(value);

                            backlight ? this.setState({editRow: null, editRowData: {}, notChange: true}) : this.setState({
                                nameForAdd: "",
                                platformIds: [],
                                activityIds: [],
                            }, () => {
                                this.props.onChange(values);
                            });
                        }
                        } icon>
                    <Icon name='add' />
                </Button>
            </Table.Cell>
        </Table.Row>;
    }

    render() {
        let values = this.props.value;

        let { confirmation } = this.state;

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Colour')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Platform')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Activity')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('Implementation class')}</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.value.map((backlight, index) => this.state.editRow === backlight.id
                        ? this.backlightRowEdit(values, backlight) : <Table.Row key={index}>
                        <Table.Cell>{backlight.name}</Table.Cell>
                        <Table.Cell>{backlight.color}</Table.Cell>
                        <Table.Cell>{backlight.platformIds && backlight.platformIds.length ? this.props.platforms.filter(i=>backlight.platformIds.includes(i.key)).map(l=><Label>{l.text}</Label>) : t('All platforms')} </Table.Cell>
                        <Table.Cell>{backlight.activityIds && backlight.activityIds.length ? this.props.activities.filter(i=>backlight.activityIds.includes(i.key)).map(l=><Label>{l.text}</Label>) : t('All activities')}</Table.Cell>
                        <Table.Cell>{backlight.realizationClassName}</Table.Cell>
                        <Table.Cell>
                            <div style={{display: 'flex'}}>
                                <Button size="small" onClick={() => {
                                    values = values.filter(x => { return x.name !== backlight.name; });
                                    this.props.onChange(values);
                                }
                                } icon>
                                    <Icon name='remove' />
                                </Button>
                                <Popup
                                    content={t('Edit')}
                                    trigger={<Button size="small" onClick={() => (this.state.editRow && !this.state.notChange) ? this.setState({
                                        confirmation: {
                                            open: true,
                                            onConfirm: ()=> {
                                                this.setState({confirmation: {open: false}});
                                                this.onEditClick(backlight);
                                            },
                                            content: t("your_changes_wont_save")
                                        }
                                    }) : this.onEditClick(backlight)} icon={'edit'}/>}
                                />
                            </div>
                        </Table.Cell>
                    </Table.Row>
                    )}
                    {this.backlightRowEdit(values)}
                    <Confirm dimmer="blurring"
                             size="small"
                             open={confirmation.open}
                             onCancel={() => this.setState({confirmation: {open: false}})}
                             onConfirm={confirmation.onConfirm}
                             header={t('the confirmation')}
                             content={confirmation.content}
                             cancelButton={t('No')}
                             confirmButton={t('Yes')}
                    />
                </Table.Body>
            </Table>
        );
    }
}
