import React from "react";
import { Button, Grid, Label, Dropdown, Input, Popup, Form } from "semantic-ui-react";

const Edit = ({ value, name, onChange, isDisabled, noLabel, className }) => {
    return (
        <Form.Field>
            {!noLabel ? <label className={isDisabled ? "label-disabled" : null}>{t(name)}</label> : null}
            <Input className={className} disabled={isDisabled || false} name={name} value={value || ""} onChange={onChange} autoComplete="off" />
        </Form.Field>
    );
};
export default Edit;