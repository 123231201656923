import React from "react";
import {Button, Table, Checkbox, Icon, Label, Container, Grid} from "semantic-ui-react";
import TransportTypeFormModal from "./components/TransportTypeEditModal";
import LazyLoadDictionary from "../../shared/LazyLoadDictionary";

export default function TransportTypes() {

    const newModal = (load) =>
        <TransportTypeFormModal
            title={t('Adding a trailer type')}
            actionButton={t('Add trailer type')}
            id={null}
            onClose={load}>
            <Icon name='plus'/>{` ${t('Add trailer type')}`}
        </TransportTypeFormModal>;
    const headerRow = <Table.Row>
        <Table.HeaderCell>{t('Name')}</Table.HeaderCell>
        <Table.HeaderCell/>
    </Table.Row>;
    return (
        <LazyLoadDictionary
            apiGetList="TransportTypes/GetList"
            name={t("trailerTypes")}
            className='container-margin-top-bottom'
            newModal={newModal}
            headerRow={headerRow}>
            {(transportTypes, load) =>
                transportTypes.map(transportType => {
                    return (
                        <Table.Row key={transportType.id}>
                            <Table.Cell>{transportType.name}</Table.Cell>
                            <Table.Cell textAlign='center'>
                                <TransportTypeFormModal
                                    size="small"
                                    title={t('Trailer Type Editing')}
                                    actionButton={t('Save changes')}
                                    id={transportType.id}
                                    onClose={load}>
                                    <Icon name='edit'/>{` ${t('Edit')}`}
                                </TransportTypeFormModal>
                            </Table.Cell>
                        </Table.Row>
                    );
                })
            }
        </LazyLoadDictionary>
    );
}
