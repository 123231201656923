import React from "react";
import {Popup, Button, Grid, Checkbox, Message} from "semantic-ui-react";
import DateEdit from "../../../shared/columns/date/Edit";
import InputTime from '../../../shared/columns/time/inputTime';
import inputTime from '../../../shared/columns/time/inputTime';

const initState ={
    date: '',
    timeStart:'',
    timeEnd: '',
    isLoad: false,
    isUnload: false,
    isMoving: false,
    isOpen: false,
    textError: '',
    isValidEndTime: true,
    isValidStartTime: true
}
export default class ScheduleExceptions extends React.Component {

    state = {
        ...initState
    };

    handleClose = () => {
   /*     this.setState({
            isOpen: false,
            ...scheduleException})*/
    };

    handleOpen = () => {
        const {scheduleException, onOpen} = this.props;
       this.setState({
           isOpen: true,
           ...scheduleException
       });
        onOpen && onOpen()
    };

    changeIsOpen = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    };

    addEditScheduleException = () => {
        const {date, timeStart, timeEnd, isLoad, isUnload, isMoving} = this.state;
        if (this.isValid())
        {
            this.setState({
                ...initState
            });
            this.props.onAdd && this.props.onAdd({date, timeStart, timeEnd, isLoad, isUnload, isMoving})
            this.changeIsOpen()
        } else this.setState({
            textError: `${t('Fill in the fields')}`
        });
    };

    saveEditScheduleException = () => {
        const {date, timeStart, timeEnd, isLoad, isUnload, isMoving} = this.state;
        if (!this.isValid()) {
            this.setState({
                textError: `${t('Fill in the fields')}`
            });
            return
        }
        this.setState({
            textError: ''
        });
        this.props.onChange && this.props.onChange({date, timeStart, timeEnd, isLoad, isUnload, isMoving})
        this.changeIsOpen()
    };

    cancelEditScheduleException = () => {
        const {onCancel} = this.props;
        this.setState({
            ...initState
        });
        onCancel && onCancel()
    };

    isValid = () => {
        const {date, isLoad, isUnload, isMoving, isValidStartTime, isValidEndTime} = this.state;
        return !!(date && date.length && isValidStartTime && isValidEndTime && (isLoad || isUnload || isMoving));

    };

    onClickBtn = () => {
    };

    render() {
        const {scheduleException, disabled, contentButton} = this.props;
        const {date, timeStart, timeEnd, isLoad, isUnload, isMoving} = this.state;

        let content = <div onClick={e => { e.stopPropagation(); }} >
            <Grid columns="equal" className=" ui form padding-bottom-0" style={{width: "400px"}}>
                <Grid.Row columns="equal">
                    <Grid.Column width={11}>
                            <Grid.Column >
                            <DateEdit
                                noLabel
                                required
                                placeholder={"dd.mm.yyyy"}
                                popperPlacement="top-end"
                                value={date}
                                onChange={(e, {name, value}) =>
                                    this.handleChange("date", value)}

                            />
                            </Grid.Column>
                        <div className="schedule-exception-block-time">
                            <Grid.Column style={{ paddingRight: "5px"}}>
                                <InputTime
                                    value={timeStart}
                                    placeholder={"HH:mm"}
                                    validation={(value) =>
                                        this.setState({
                                            isValidStartTime: value})}
                                    max={timeEnd}
                                    onChange={(e, {value}) =>
                                        this.handleChange("timeStart", value)}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ paddingLeft: "5px"}}>
                                <InputTime
                                    value={timeEnd}
                                    placeholder={"HH:mm"}
                                    validation={(value) => this.setState({
                                        isValidEndTime: value})}
                                    min={timeStart}
                                    onChange={(e, {value}) =>
                                        this.handleChange("timeEnd", value)}
                                />
                            </Grid.Column>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Checkbox className={(!isLoad && !isUnload && !isMoving) ? "checkbox-red-border" : null}
                                  checked={isLoad} label={t('Loading')}
                                  onChange={(e, {name, checked}) => this.handleChange("isLoad", checked)}/>
                        <Checkbox  className={(!isLoad && !isUnload && !isMoving) ? "checkbox-red-border" : null}
                                   checked={isUnload}
                                   label={t('Unloading')}
                                   onChange={(e, {name, checked}) => this.handleChange("isUnload", checked)}/>
                        <Checkbox  className={(!isLoad && !isUnload && !isMoving) ? "checkbox-red-border" : null}
                                   checked={isMoving}
                                   label={t('Sorting')}
                                   onChange={(e, {name, checked}) => this.handleChange("isMoving", checked)}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="padding-margin-0">
                    <Grid.Column>
                        {this.state.textError &&
                        <Message negative>
                            <p>{this.state.textError}</p>
                        </Message>}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column textAlign={"center"} >
                        { !scheduleException &&
                            <Button size="small" onClick={this.addEditScheduleException}>{t('AddButton')}</Button>
                        }
                        {scheduleException &&
                        <Button size="small" onClick={this.saveEditScheduleException}>{t('SaveButton')}</Button>
                        }
                        <Button size="small" onClick={this.cancelEditScheduleException}>{t('Cancel')}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>;
        return (
            <Popup
                trigger={<Button content={contentButton} />}
                content={content}
                on='click'
                size={'huge'}
                disabled={disabled}
                open={this.state.isOpen}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                position='top right'
            />
        )
    }

}
