import React from 'react';
import { Grid } from 'semantic-ui-react';
import Schedule from '../../platforms/components/StandardSchedule';

export default class CompanySchedule extends React.Component {
    render() {
        const { disabled, activityIndex, scheduleIndex, item, onChangeIsValid, onDelete, onChange } = this.props;

        return (
            <Grid
                columns="equal"
                key={`standard-days-${activityIndex}-${scheduleIndex}`}
                style={{ borderColor: 'grey' }}
                className={`clear-margin-grid schedule-modal-item-standard-days-${activityIndex}-${scheduleIndex}`}
            >
                <Schedule
                    disabled={disabled}
                    activityIndex={activityIndex}
                    scheduleIndex={scheduleIndex}
                    item={item}
                    onChangeIsValid={onChangeIsValid}
                    onDelete={onDelete}
                    onChange={onChange}
                />
            </Grid>
        );
    }
}
