import React from "react";
import * as StateHelper from "../../utils/stateHelper";
import SuperGrid from "../../shared/grid/SuperGrid";

export default function Tasks() {
    return <SuperGrid
        storageSortItem="TaskSort"
        storageFilterItem="TaskFilters"
        apiGetColumns="tasks/getColumns"
        apiGetList="tasks/getList"
        apiAction="tasks/action"
        userColumnsName="task"
        stateColors={StateHelper.taskStateColors()}
    />;
}