import React from "react";
import * as StateHelper from "../../utils/stateHelper";
import SuperGrid from "../../shared/grid/SuperGrid";
import VisitTasksModal from "../../shared/modals/visitTaskModal/VisitTasksModal";

export default function Visits(props) {
    
    return <><SuperGrid
        storageSortItem="VisitSort"
        storageFilterItem="VisitFilters"
        apiGetColumns="visits/getColumns"
        apiGetList="visits/getList"
        apiAction="visits/action"
        userColumnsName="visit"
        createButtonEnabled
        createButtonPermission="CreateVisits"
        createButtonName={t("create_visit_btn")}
        createButtonModalTitle={t("create_visit_modal_label")}
        stateColors={StateHelper.visitStateColors()}
    />
        {props.match.params.id !== undefined && props.match.params.id !== null ? <VisitTasksModal isOpen={true} visitId={props.match.params.id}/>: ''}
    </>;
}