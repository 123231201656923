import React from 'react';
import { Button, Confirm, Grid, Table } from 'semantic-ui-react';
import EditedActivity from './EditedActivity';
import ReadOnlyActivity from './ReadOnlyActivity';

export default class Activities extends React.Component {
    state = {
        activities: [],
        editedActivityIndex: null,
        confirmation: {
            open: false,
            content: '',
            onConfirm: () => {},
        },
    };

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps, prevState) {
        const { editedActivityIndex } = this.state;
        const { isEdit } = this.props;
        if (JSON.stringify(prevState.editedActivityIndex) !== JSON.stringify(editedActivityIndex)) {
            isEdit && isEdit(editedActivityIndex);
        }
    }

    load = () => {
        const { activities } = this.props;
        this.setState({ activities: activities });
    };

    getActivities = (index) => {
        const { allActivities } = this.props;
        const { activities } = this.state;

        return allActivities
            .filter((item) => {
                let a = [];
                activities.forEach((i = {}) => {
                    if (i.activityId === item.id) {
                        a.push(true);
                    }
                });

                if (item.id === (activities[index] || {}).activityId) {
                    a = [];
                }

                return !a.length;
            })
            .map((row) => {
                return { key: row.id, text: row.name, value: row.id };
            });
    };

    // renderHeightScheduleItem = (editIndex) => {
    //     const {editedActivity} = this.state;
    //     if (!Object.keys(editedActivity).length) {
    //         return;
    //     }
    //     editedActivity.schedules && editedActivity.schedules.length > 0 && editedActivity.schedules.forEach((item, index) => {
    //         const load = document.getElementsByClassName(`schedule-activities-modal-item-task-schedule-load-${editIndex}-${index}`);
    //         const unload = document.getElementsByClassName(`schedule-activities-modal-item-task-schedule-unload-${editIndex}-${index}`);
    //         const moving = document.getElementsByClassName(`schedule-activities-modal-item-task-schedule-moving-${editIndex}-${index}`);
    //         const schedule = document.getElementsByClassName(`schedule-activities-modal-item-standard-days-${editIndex}-${index}`);
    //         const heightNew = !!schedule[0] && !!schedule[0].clientHeight ? schedule[0].clientHeight : 50
    //         if (load && unload && moving && load[0].style && unload[0].style && moving[0].style) {
    //             load[0].style.height = `${heightNew}px`
    //             unload[0].style.height = `${heightNew}px`
    //             moving[0].style.height = `${heightNew}px`

    //         }
    //     })
    // }

    handleEditedActivitySave = (index, activity) => {
        const { activities } = this.state;

        /*this.renderHeightScheduleItem(0);*/
        activity.schedules.sort((a, b) => b.dayOfWeeks > a.dayOfWeeks);
        let newActivities = activities.concat();
        if (newActivities[index]) newActivities[index] = activity;
        else newActivities.push(activity);

        this.setState({
            editedActivityIndex: null,
            activities: newActivities,
        });

        const { onChange } = this.props;
        onChange && onChange(newActivities);
    };

    discardChangesInEditedActivity = () => {
        const { activities, editedActivityIndex, editedActivityOld } = this.state;

        let oldActivities = [...activities];
        if (editedActivityOld && Object.keys(editedActivityOld).length !== 0) {
            oldActivities.splice(editedActivityIndex, 1, editedActivityOld);
        } else {
            oldActivities.splice(editedActivityIndex, 1);
        }

        this.setState({
            editedActivityOld: {},
            editedActivityIndex: null,
            activities: oldActivities,
        });
    };

    createActivity = () =>{
        const { activities } = this.state;
        let newActivities = [...activities];
        newActivities.push({
            activityId: '',
            schedules: []
        });
        this.setState({
            activities: newActivities,
            editedActivityIndex: newActivities.length - 1,
            activitiesOld: activities,
        });
    };

    deleteActivity = (index) => {
        const { activities } = this.state;
        let newActivities = activities.concat();
        newActivities.splice(index, 1);

        this.setState({
            editedActivityIndex: null,
            confirmation: {
                open: true,
                content: t('Are you sure you want to delete the entire line with activity?'),
                onConfirm: () => {
                    this.setState(
                        {
                            activities: newActivities,
                            confirmation: {
                                open: false,
                            },
                        },
                        () => {
                            const { onChange } = this.props;
                            onChange && onChange(newActivities);
                        },
                    );
                },
            },
        });
    };

    showConfirmation = (content, onConfirm) => {
        this.setState({
            confirmation: {
                open: true,
                content: content,
                onConfirm: () => {
                    this.setState(() => {
                        return { confirmation: { open: false } };
                    });
                    onConfirm();
                },
            },
        });
    };

    editActivity = (index) => {
        const { activities } = this.state;
        this.setState({
            editedActivityIndex: index,
            editedActivityOld: JSON.parse(JSON.stringify(activities[index])),
        });
    };

    render() {
        const { allActivities } = this.props;
        const { confirmation, activities } = this.state;
        return (
            <div className={'padding-margin-0 schedule-activities-modal-table'}>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={3}>{t('activities')}</Table.HeaderCell>
                            <Table.HeaderCell>{t('Schedule')}</Table.HeaderCell>
                            <Table.HeaderCell width={1}>{t('Green corridor')}</Table.HeaderCell>
                            <Table.HeaderCell width={1}>
                                {t('Maximum number of tasks per time slot')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {activities &&
                            activities.map((item, index) => {
                                const isEdit = this.state.editedActivityIndex === index;

                                if (isEdit) {
                                    const activityOptions = this.getActivities(index);
                                    return (
                                        <EditedActivity
                                            activity={item}
                                            index={index}
                                            key={`edited-activity-${index}`}
                                            allActivities={activityOptions}
                                            showConfirmation={this.showConfirmation}
                                            handleEditedActivitySave={this.handleEditedActivitySave}
                                            onDiscardChanges={this.discardChangesInEditedActivity}
                                        />
                                    );
                                }

                                return (
                                    <ReadOnlyActivity
                                        activity={item}
                                        index={index}
                                        key={`read-only-activity-${index}`}
                                        allActivities={allActivities}
                                        editActivity={this.editActivity}
                                        deleteActivity={this.deleteActivity}
                                        showActionButtons={
                                            this.state.editedActivityIndex !== index &&
                                            this.state.editedActivityIndex === null
                                        }
                                    />
                                );
                            })}
                    </Table.Body>
                    <Confirm
                        dimmer="blurring"
                        size="small"
                        open={confirmation.open}
                        onCancel={() => this.setState({ confirmation: { open: false } })}
                        onConfirm={confirmation.onConfirm}
                        header={t('the confirmation')}
                        content={confirmation.content}
                        cancelButton={t('No')}
                        confirmButton={t('Yes')}
                    />
                </Table>
                {/*   {(this.state.editIndex === null) &&*/}
                <Grid.Row columns="equal">
                    <Grid.Column>
                        <Button size="small" onClick={this.createActivity}>
                            {t('Add Activity')}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                {/* }*/}
            </div>
        );
    }
}
